import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';

// import { Config } from '../config/config';
// import {HttpResponse} from '@angular/common/http';
import { Message } from 'primeng/api';
import { Common } from '../ViewModel/Common';
import { BU } from '../ViewModel/BU';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { environment } from 'src/environments/environment';
import { HelperService } from './helper.service';


@Injectable({
  providedIn: 'root'
})
export class BUService {

  private buURL = environment.WEBAPIURL + 'bu/bu';
  private AddBulkURL = environment.WEBAPIURL + 'DataUpload/bulk-bus';

  constructor(private _http: HttpClient, private _helperService: HelperService) {
  }

  //Excel value bulk upload
  addBulkValue(BU) {

    return this._http.post(this.AddBulkURL, BU, { observe: 'response' })

  }

  //Get All the BU Details
  getAllBU() {
    let objVMHierarchySearch = new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));
    return this._http.get<BU[]>(this.buURL, {
      params: params
    })
  }

  //Get All the BU Details
  getAllBUByRole() {
    let objVMHierarchySearch = new HierarchySearchViewModel();
    objVMHierarchySearch.Active = true;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));
    return this._http.get<BU[]>(this.buURL, {
      params: params
    });
  }

  //Add new BU
  addBU(bu: BU) {
    return this._http.post(this.buURL, bu, { observe: 'response' });
  }

  //Update BU
  editBU(BU: BU) {
    return this._http.put(this.buURL, BU, { observe: 'response' });
  }

  //Search BU
  searchBU(BUName, isActive) {
    let objVMHierarchySearch = new HierarchySearchViewModel();
    objVMHierarchySearch.BuName = BUName;
    objVMHierarchySearch.Active = isActive;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));
    return this._http.get<BU[]>(this.buURL, {
      params: params
    });
  }
  GetBUChildrens(_objBuId) {
    let objVMHierarchySearch = new HierarchySearchViewModel();
    objVMHierarchySearch.BuId = _objBuId;
    objVMHierarchySearch.languageId = +localStorage.getItem("_languageID");
    let params = new HttpParams().set("searchOptions", this._helperService.EscapeSpecialCharacters(JSON.stringify(objVMHierarchySearch)));
    return this._http.get<BU[]>(this.buURL, {
      params: params
    });
  }
  //Add Update BU Translations
  AddUpdateBUTranslations(bu: BU) {
    return this._http.post(this.buURL, bu, { observe: 'response' });
  }
  EscapeSpecialCharacters(input: string): string {
    let inputText = input;
    let specialCharArray = [
      { 'Character': '!', 'Code': 'U0021' },
      { 'Character': '#', 'Code': 'U0023' },
      { 'Character': '$', 'Code': 'U0024' },
      { 'Character': '%', 'Code': 'U0025' },
      { 'Character': '&', 'Code': 'U0026' },
      { 'Character': '(', 'Code': 'U0028' },
      { 'Character': ')', 'Code': 'U0029' },
      { 'Character': '*', 'Code': 'U002A' },
      { 'Character': '+', 'Code': 'U002B' },
      { 'Character': '-', 'Code': 'U002D' },
      { 'Character': '/', 'Code': 'U002F' },
      { 'Character': '?', 'Code': 'U003F' },
      { 'Character': '@', 'Code': 'U0040' },
      { 'Character': '\\', 'Code': 'U005C' },
      { 'Character': '=', 'Code': 'U003D' },
      { 'Character': '_', 'Code': 'U005F' },
      { 'Character': ':', 'Code': 'U003A' },
      { 'Character': '{', 'Code': 'U007B' },
      { 'Character': '}', 'Code': 'U007D' },
      { 'Character': '[', 'Code': 'U005B' },
      { 'Character': ']', 'Code': 'U005D' },
      { 'Character': ',', 'Code': 'U002C' },
      { 'Character': '"', 'Code': 'U0022' },
      { 'Character': "'", 'Code': 'U0027' }
    ]
    let charArray = Array.from(inputText);
    let stringArray = [];
    for (let objChar of charArray) {
      let obj = specialCharArray.find(x => x.Character == objChar);
      stringArray.push(obj == undefined ? objChar : obj.Code);
    }

    inputText = stringArray.join('');

    return inputText;
  }
}
