import '../polyfills';
import { BrowserModule, Title, DomSanitizer } from '@angular/platform-browser';
import { NgModule, ErrorHandler, Pipe, PipeTransform, ApplicationRef } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { provideHttpClient, HttpClient, HTTP_INTERCEPTORS, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MessagesModule } from 'primeng/messages';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
// import { GrowlModule } from 'primeng/growl';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';

import { BlockUIModule } from 'primeng/blockui';
import { DropdownModule } from 'primeng/dropdown';

import { ToastModule } from 'primeng/toast';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { CalendarModule } from 'primeng/calendar';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { TooltipModule } from 'primeng/tooltip';
import { GalleriaModule } from 'primeng/galleria';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { FieldsetModule } from 'primeng/fieldset';
import { LightboxModule } from 'ngx-lightbox';

import { MultiSelectModule } from 'primeng/multiselect';
import { TreeModule } from 'primeng/tree';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';

// multilingual
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LanguageComponent } from './Menu/language/language.component';
import { UserService } from './Services/user.service';
import { DepartmentService } from './Services/department.service';
import { BUService } from './Services/bu.service';
import { RegionService } from './Services/region.service';
import { SiteService } from './Services/site.service';
import { SubSiteService } from './Services/subsite.service';
import { SRUService } from './Services/sru.service';
import { SubBUService } from './Services/subbu.service';
import { CrewService } from './Services/crew.service';
import { PermitTypeService } from './Services/permit-type.service';
import { CategoryService } from './Services/category.service';
import { HelperService } from './Services/helper.service';
import { CommonService } from './Services/common.service';
import { RoleService } from './Services/role.service';
import { ExcelService } from './Services/excel.service';
import { PDFService } from './Services/pdf.service';
import { CommonhelpersModule } from './commonhelpers/commonhelpers.module';
import { CreatePermitComponent } from './create-permit/create-permit.component';
import { ListboxModule } from 'primeng/listbox';
import { PermitTypeVersionService } from './Services/pemit-type-version.service';
import { HttpRequestHandler } from './ViewModel/HttpRequestHandler';
import { AccessTokenExpiryHandler } from './ViewModel/AccessTokenExpiryHandler';
//import { SelfServiceComponent } from './self-service/self-service.component';
import { GlobalErrorHandler } from './ViewModel/GlobalErrorHandler';
import { PermitComponent } from './permit/permit.component';
import { DynamicDataSourceService } from './Services/dynamicdatasource.service';
// import { ManageDashboardComponent } from './manage-dashboard/manage-dashboard.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { MenuModule } from 'primeng/menu';
import { TieredMenuModule } from 'primeng/tieredmenu';

//import { NotificationComponent } from './notification/notification.component';
import { ContractorService } from './Services/contractor.service';
import { MediaService } from './Services/media.service';
import { MsalModule, MsalInterceptor, MsalService, MSAL_INSTANCE, MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG, MsalGuard, MsalBroadcastService, MsalRedirectComponent, MsalInterceptorConfiguration, MsalGuardConfiguration} from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { LogLevel } from 'msal';
import { AuthGuardService } from './auth-guard.service';
// import { EntryLeaderComponent } from './entry-leader/entry-leader.component';
// import { FireCrewComponent } from './fire-crew/fire-crew.component';
// import { MyPermitsComponent } from './my-permits/my-permits.component';
import { CommentBoxComponent } from './comment-box/comment-box.component';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './ViewModel/helpers/LowerCaseUrlSerializer';
// import { ViewRegisterComponent } from './view-register/view-register.component';
import { MenuService } from './Services/menu.service';
//import { SecurityComponent } from './security/security.component';
import { GeoTagService } from './Services/geotag.service';
import { RoleGuardService } from './role-guard.services';
import { InputSwitchModule } from 'primeng/inputswitch';

import { StepsModule } from 'primeng/steps';

// Import kendo pdf export library
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { SafeHtmlPipe } from './Pipes/SafeHtmlPipe';
import { DefaultPipeModule } from './DefaultPipeModule';
import { PrintPdfSelectedComponent } from './print-pdf-selected/print-pdf-selected.component';
import { FAQComponent } from './faq/faq.component';
import { WorkPermitComponent } from './work-permit/work-permit.component';
import { WorkPrintPdfComponent } from './work-print-pdf/work-print-pdf.component';
import { WorkPrintPdfSelectedComponent } from './work-print-pdf-selected/work-print-pdf-selected.component';
import { Configuration } from 'msal';
import { AssetTreeComponent } from './asset-tree/asset-tree.component';
import { SelectWorkOrderComponent } from './select-work-order/select-work-order.component';
import { SelectOperationComponent } from './select-operation/select-operation.component'
import { TreeTableModule } from 'primeng/treetable';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { MsalComponent } from './msal.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { AppMsalInterceptor } from './Interceptor/AppMsalInterceptor';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
let protectedResourceMap;
let env = environment.ENVIRONMENT;
  switch (env) {
    case 'LOCAL': {
      protectedResourceMap = new Map([
          ['http://localhost:51165/', ['https://permitsystem.devapi.alcoa.com/user_impersonation']],
          ['https://permitsystem.devapi.alcoa.com/', ['https://permitsystem.devapi.alcoa.com/user_impersonation']],
          ['https://devapi.alcoa.com/utility/media/v1/', ['https://permitsystem.devapi.alcoa.com/user_impersonation']],
          ['https://graph.microsoft.com/v1.0/me', ['group.read.all']]
      ]);
      break;
    }
    case 'AUA-DEV': {
      protectedResourceMap = new Map([
          ['https://permitsystem.devapi.alcoa.com/', ['https://permitsystem.devapi.alcoa.com/user_impersonation']],
          ['https://devapi.alcoa.com/utility/media/v1/', ['https://permitsystem.devapi.alcoa.com/user_impersonation']],
          ['https://graph.microsoft.com/v1.0/me', ['group.read.all']]
      ]);
      break;
    }
    case 'NOA-DEV': {
      protectedResourceMap = new Map([
          ['https://mypermits.devapi.alcoa.com/', ['https://mypermits.devapi.alcoa.com/user_impersonation']],
          ['https://devapi.alcoa.com/utility/media/v1/', ['https://mypermits.devapi.alcoa.com/user_impersonation']],
          ['https://graph.microsoft.com/v1.0/me', ['group.read.all']]
      ]);
      break;
    }
    case 'AUA-TEST': {
      protectedResourceMap = new Map([        
          ['https://permitsystem.testapi.alcoa.com/', ['https://permitsystem.testapi.alcoa.com/user_impersonation']],
          ['https://devapi.alcoa.com/utility/media.TEST/v1/', ['https://permitsystem.testapi.alcoa.com/user_impersonation']],
          ['https://graph.microsoft.com/v1.0/me', ['group.read.all']]
      ]);
      break;
    }
    case 'NOA-TEST': {
      protectedResourceMap = new Map([        
          ['https://mypermits.testapi.alcoa.com/', ['https://mypermits.testapi.alcoa.com/user_impersonation']],
          ['https://devapi.alcoa.com/utility/media.TEST/v1/', ['https://mypermits.testapi.alcoa.com/user_impersonation']],
          ['https://graph.microsoft.com/v1.0/me', ['group.read.all']]
      ]);
      break;
    }
    case 'AUA-PROD': {
      protectedResourceMap = new Map([        
          ['https://permitsystem.api.alcoa.com/', ['https://permitsystem.api.alcoa.com/user_impersonation']],
          ['https://api.alcoa.com/utility/media/v1/', ['https://permitsystem.api.alcoa.com/user_impersonation']],
          ['https://graph.microsoft.com/v1.0/me', ['group.read.all']]
      ]);
      break;
    }
    case 'NOA-PROD': {
      protectedResourceMap = new Map([        
          ['https://mypermits.api.alcoa.com/', ['https://mypermits.api.alcoa.com/user_impersonation']],
          ['https://api.alcoa.com/utility/media/v1/', ['https://mypermits.api.alcoa.com/user_impersonation']],
          ['https://graph.microsoft.com/v1.0/me', ['group.read.all']]
      ]);
      break;
    }
    default: {
      protectedResourceMap = new Map([
        ['http://localhost:51165/', ['https://permitsystem.devapi.alcoa.com/user_impersonation']],
        ['https://permitsystem.devapi.alcoa.com/', ['https://permitsystem.devapi.alcoa.com/user_impersonation']],
        ['https://devapi.alcoa.com/utility/media/v1/', ['https://permitsystem.devapi.alcoa.com/user_impersonation']],
        ['https://graph.microsoft.com/v1.0/me', ['group.read.all']]
      ]);
      break;
    }
  }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function loggerCallback(message) {
  //console.log('client logging ' + message);
}
export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {      
      clientId: environment.clientID,
      authority: environment.authority,
      redirectUri: window.location.origin,
      navigateToLoginRequestUrl: true
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: true
      }
    }
  });
}
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    LanguageComponent,
    CreatePermitComponent,
    //SelfServiceComponent,
    GlobalErrorHandler,
    PermitComponent,
    // Deleting these components
    // ManageDashboardComponent,
    // NotificationComponent,
    // FireCrewComponent,
    // EntryLeaderComponent,
    // MyPermitsComponent,
    // ViewRegisterComponent, 
    // SecurityComponent,
    CommentBoxComponent,      
    PrintPdfSelectedComponent, 
    // FAQComponent, 
    WorkPermitComponent, 
    WorkPrintPdfComponent, 
    WorkPrintPdfSelectedComponent, 
    AssetTreeComponent, 
    SelectWorkOrderComponent, 
    SelectOperationComponent,
    MsalComponent,   
  ],
  imports: [
    BrowserModule,DefaultPipeModule,
    AppRoutingModule,
    MessageModule, MessagesModule, TieredMenuModule, ReactiveFormsModule, BrowserAnimationsModule, PDFExportModule,NgxExtendedPdfViewerModule,
    FormsModule, BlockUIModule, DropdownModule, PanelModule, TableModule, TriStateCheckboxModule, StepsModule, TreeTableModule,
    ConfirmDialogModule, FieldsetModule, DialogModule, LoadingBarHttpClientModule, ToastModule, InputTextModule,
    CheckboxModule, RadioButtonModule, FileUploadModule, CalendarModule, TooltipModule, GalleriaModule, LightboxModule,
    MultiSelectModule, TreeModule, BreadcrumbModule, MenuModule, AutoCompleteModule, CardModule, ListboxModule,AccordionModule,CommonhelpersModule, OverlayPanelModule, TabViewModule,InputSwitchModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgHttpLoaderModule.forRoot(),
    MsalModule
  ],
  providers: [ConfirmationService, Title, MessageService, UserService, BUService, RegionService, SubBUService,
    SiteService, SubSiteService, SRUService, DepartmentService, CrewService, MenuService, provideHttpClient(withInterceptors([AppMsalInterceptor])),
    MessageService, HelperService, PermitTypeService, CategoryService, PermitTypeVersionService, ContractorService,
    // Authentication  
    {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpRequestHandler,
          multi: true
      },     
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
  },
  {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
  },
  {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
  },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    MsalRedirectComponent,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    MessageService, HelperService, PermitTypeService, CategoryService,
    { provide: UrlSerializer, useClass: LowerCaseUrlSerializer },
    //{ provide: HTTP_INTERCEPTORS, useClass: MockBackendService, multi: true },
    CommonService, RoleService, ExcelService, PDFService, DynamicDataSourceService, MediaService, AuthGuardService,
    RoleGuardService, GeoTagService],
  bootstrap: [AppComponent]
})
export class AppModule {
  ngDoBootstrap(ref: ApplicationRef) {
    if (window !== window.parent && !window.opener)
    {
      ref.bootstrap(MsalComponent);
    }
    else
    {
      //this.router.resetConfig(RouterModule);
      ref.bootstrap(AppComponent);
    }
  }
 }


