import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchPanelComponent } from './search-panel/search-panel.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MessageModule } from 'primeng/message';
import { FormsModule } from '@angular/forms';
import { TreeModule } from 'primeng/tree';
import { ToastModule } from 'primeng/toast';
import { CalendarModule } from 'primeng/calendar';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FileUploadModule } from 'primeng/fileupload';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { provideHttpClient, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { FieldsetModule } from 'primeng/fieldset';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { HierarchyTreeComponent } from './hierarchy-tree/hierarchy-tree.component';
import {AutoCompleteModule} from 'primeng/autocomplete';
import { PrintPdfComponent } from '../print-pdf/print-pdf.component';
import { GalleriaModule } from 'primeng/galleria';
import { DefaultPipeModule } from '../DefaultPipeModule';
export function HttpLoaderFactory(http: HttpClient) {

  return new TranslateHttpLoader(http);

}

@NgModule({
  exports:[SearchPanelComponent,UserProfileComponent,HierarchyTreeComponent, PrintPdfComponent],
  declarations: [SearchPanelComponent,UserProfileComponent,HierarchyTreeComponent, PrintPdfComponent],
  imports: [
    CommonModule,
    DefaultPipeModule,
    ConfirmDialogModule,
    TranslateModule,AutoCompleteModule,
    MessageModule, FormsModule, TreeModule, ToastModule, CalendarModule,
    PanelModule, TableModule, RadioButtonModule, FileUploadModule,
    DialogModule, CheckboxModule,FieldsetModule,CardModule,DropdownModule,
    GalleriaModule,
    TranslateModule.forChild({
      loader: {
        provide: [TranslateLoader],
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    LoadingBarHttpClientModule,
    NgHttpLoaderModule,
  ],
  providers:[provideHttpClient()]
})
export class CommonhelpersModule { }
