import { LinkedPermits } from "./LinkedPermits";

export class LinkedPermitViewModel {
    LinkedPermits: LinkedPermits[] | null;
}

export class ManualPermit {
    Id : number;
    PermitName : string | null;
    PermitTypeId : number | null;
    PermitStateId : number | null;
    AttachmentId : number | null;
    IssueDate : Date | null;
    FileOriginalUrl : string | null;
}