import { Component, OnInit, ElementRef, OnChanges, DoCheck } from '@angular/core';
import { Category } from 'src/app/ViewModel/Category';
import { CategoryService } from '../Services/category.service'
import { SelectItem, MessageService, ConfirmationService } from 'primeng/api';
import { SelectionType } from '../ViewModel/selection-type.enum';
import { PermitTypeViewModel, PermitType } from '../ViewModel/PermitBuilder/PermitType';
import { TempPermitType } from '../ViewModel/temp-permit-type';

import { PermitTypeService } from '../Services/permit-type.service';
import { TranslateService } from '@ngx-translate/core';
import { MediaService } from '../Services/media.service';
import { HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { MenuService } from '../Services/menu.service';
import { CommonService } from '../Services/common.service';
import { HierarchyTree } from '../ViewModel/HierarchyTree';
import { PermitControlService } from '../Services/permit-control.service';
import { PermitActionService } from '../Services/permit-action.service';
import { User } from '../ViewModel/User';
import { UserService } from '../Services/user.service';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { PermitActionViewModel } from '../ViewModel/PermitActionViewModel';
import { SuspendedPermitViewModel } from '../ViewModel/SuspendedPermitViewModel';
import { PermitControlRoleViewModel, SuspendPermitTypeViewModel } from '../ViewModel/PermitControlRoleViewModel';
import { RoleTrainingStateViewModel } from '../ViewModel/RoleTrainingStateViewModel';
import { color } from 'html2canvas/dist/types/css/types/color';
import { url } from 'inspector';
import { CommonMethods } from '../common-methods/common-methods.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-create-permit',
  templateUrl: './create-permit.component.html',
  styleUrls: ['./create-permit.component.css'],
  providers: [MediaService, CommonMethods]
})

export class CreatePermitComponent implements OnInit, OnChanges, DoCheck {
  categories: SelectItem[] = [];
  permitTypes: SelectItem[] = [];
  selectedCategory: any;
  selectedPermits: string[];
  permits: TempPermitType[];
  selectionType: string;
  isMultiSelect: boolean;
  isCategorySelected: boolean = false;
  categoryImageFiles: any[] = [];
  categoryAttachedImages: any[] = [];
  isPermitTypePresent: boolean = false;
  iscategoryselected: boolean = false;
  selectedText: string;
  name: string;
  objAzureFileForPermit: any[] = [];
  ctr: number = 0;
  _previousHierarchy: HierarchyTree;
  _categoryNotFound: boolean = false;

  // Variables for Suspension
  permitsSuspended: boolean;
  suspendIconFlag: boolean = true;
  permitsUnSuspended: boolean;
  _currentLoggedInUser: User;
  _objArrCategories: Category[] = [];
  _objArrPermitType: PermitType[] = [];
  _objArrPermitTypeNames: SelectItem[] = [];
  selectedPermitTypesForSuspend: any[] = [];
  presentCategoryId = 0;
  //showSuspendAllPopUp: boolean;
  attrHeader: string;
  _ddlCategory: number = 0;
  _ddlPermitType: number = 0;
  siteTobeSuspended: boolean = false;
  permitTypeTobeSuspended: boolean = false;
  filteredCategories: any[] = [];
  selectedCategoryFromDropDown: Category
  SuspendIconPath = "../../assets/Images/suspend.png";
  UnsuspendIconPath = "../../assets/Images/unsuspend.png";
  WhitePath = "../../assets/Images/white.png";
  showTabularView: boolean = false;
  containerHeight: number;
  disabledSuspendIconPath = "../../assets/Images/disabledSuspend.png"
  disabledUnsuspendIconPath = "../../assets/Images/disabledunsuspend.png"
  suspendImage: string
  unsuspendImage: string
  whiteImage: string
  isAdminUser: boolean = false
  hasSuspensionAccess: boolean = false;
  _suspendablePermitTypeIds: number[] = [];
  _selectedSiteForSuspension: string
  siteNotSelected: boolean = false
  statusColor: string
  // LMS training check
  _trainingNotPresentForRole: RoleTrainingStateViewModel[] = [];
  _roleTrainingStateList: RoleTrainingStateViewModel[] = [];
  _displayLMSErrorForUser: boolean = false;
  public circlecolor = [
    { "color": "#3180be" },
    { "color": "#165688" },
    { "color": "#3180be" },
    { "color": "#165688" },
    { "color": "#165788" },
    { "color": "#3180be" },
    { "color": "#165688" },
    { "color": "#3180be" },
    { "color": "#3180be" },
    { "color": "#165688" },
    { "color": "#3180be" },
    { "color": "#165688" },
  ]
  public tilecolor = [
    { "color": "#165688" },
    { "color": "#3482bf" },
    { "color": "#165688" },
    { "color": "#3482bf" },
    { "color": "#3482bf" },
    { "color": "#b4cee1" },
    { "color": "#3482bf" },
    { "color": "#165688" },
    { "color": "#165688" },
    { "color": "#3482bf" },
    { "color": "#165688" },
    { "color": "#3482bf" },
  ]
  objAzureFile: any[] = [];
  constructor(
    private _categoryService: CategoryService,
    private elRef: ElementRef,
    private translate: TranslateService,
    private _messageService: MessageService,
    private _mediaService: MediaService,
    private _permitTypeService: PermitTypeService,
    private router: Router,
    private _menu: MenuService,
    private _commonService: CommonService,
    private _permitControlService: PermitControlService,
    private _permitActionService: PermitActionService,
    private _userService: UserService,
    private _confirmationService: ConfirmationService,
    private _commonMethods: CommonMethods,
    private sanitizerdash: DomSanitizer,
  ) {
  }

  get userHierarchy(): HierarchyTree {
    return this._commonService.getUserHierarchy();

  }
  set userHierarchy(value: HierarchyTree) {
    this._commonService.setUserHierarchy(value);
  }

  ngOnInit() {
    this.GetAllCategory();
    this.selectedPermits = [];
    this.isMultiSelect = false;
    this.selectionType = SelectionType.Single;
    this.containerHeight = window.innerHeight - 90;
    let loginId = localStorage.getItem('_loginId');

    if (this.router.url == '/dashboard') {
      this.containerHeight = window.innerHeight - 140;
      this.showTabularView = true;
      this.GetUserDetails(loginId);
      this.CanSuspendPermit(loginId);
      // this.GetAllCategoryForDropDown();
    }
    this.whiteImage = this.WhitePath;
    //this.GetAllCategoryForDropDown();
  }

  GoBack() {
    let redirectURL = localStorage.getItem("MasterpermitURL");
    localStorage.removeItem("MasterpermitURL");
    this.router.navigate([redirectURL]);
    
  }

  ngAfterViewInit() {
    // this.elRef.nativeElement.querySelector('.ui-listbox').style.width = "100%";
    // this.elRef.nativeElement.querySelector('.ui-listbox').style.height = this.containerHeight + "px";
    // this.elRef.nativeElement.querySelector('.ui-listbox').style.overflow = "auto";
    // this.elRef.nativeElement.querySelector('.ui-listbox').style.border = "1px solid #dddddd";
    // this.elRef.nativeElement.querySelector('.ui-listbox').style.borderRadius = "5px";
    // this.elRef.nativeElement.querySelector('.ui-listbox').style.boxShadow = "5px 5px 10px rgb(0,0,0,0.4)";
    // this.elRef.nativeElement.querySelector('.ui-listbox').style.padding = "5px";
    // this.elRef.nativeElement.querySelector('.ui-listbox').style.backgroundColor = "#fff";
  }

  ngDoCheck(): void {
    let userHier = new HierarchyTree();
    if (this.userHierarchy) {
      userHier = Object.assign({}, this.userHierarchy);
      if (this._previousHierarchy) {
        if (this._previousHierarchy.BuId != userHier.BuId || this._previousHierarchy.RegionId != userHier.RegionId
          || this._previousHierarchy.SubBuId != userHier.SubBuId || this._previousHierarchy.SiteId != userHier.SiteId
          || this._previousHierarchy.SubSiteId != userHier.SubSiteId || this._previousHierarchy.SruId != userHier.SruId
          || this._previousHierarchy.DepartmentId != userHier.DepartmentId || this._previousHierarchy.CrewId != userHier.CrewId) {
          //this._objArrCategories = [];
          this.permitTypes = [];
          this._ddlCategory = 0;
          this._ddlPermitType = 0;
          this.selectedPermitTypesForSuspend = [];

          this.GetAllCategory();
          
          this.CanSuspendPermit(localStorage.getItem('_loginId'));

          this.GetUserDetails(localStorage.getItem('_loginId'))

          if (this.presentCategoryId != 0) {
            this.GetPermits(this.presentCategoryId);
          }

          if (this.userHierarchy.SiteId && this.userHierarchy.SiteId > 0) {
            this.siteNotSelected = false;
            let permtiActionVMObj = new PermitActionViewModel();
            permtiActionVMObj.SiteId = this.userHierarchy.SiteId;
            permtiActionVMObj.CategoryId = 0;
            permtiActionVMObj.PermitTypeIds = [];

            this.CheckIfSuspended(permtiActionVMObj);
          }
          else {
            // this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
            this.siteNotSelected = true;
          }

        }
      }
      this._previousHierarchy = userHier
    }
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.userHierarchy = changes.selectedHierarchy.currentValue;
  }

  CanSuspendPermit(loginId: string) {
    if (this.userHierarchy) {
      let hierarchySearchVMObj = new HierarchySearchViewModel();
      hierarchySearchVMObj.BuId = this.userHierarchy.BuId;
      hierarchySearchVMObj.RegionId = this.userHierarchy.RegionId;
      hierarchySearchVMObj.SubBuId = this.userHierarchy.SubBuId;
      hierarchySearchVMObj.SiteId = this.userHierarchy.SiteId;
      hierarchySearchVMObj.SubSiteId = this.userHierarchy.SubSiteId;
      hierarchySearchVMObj.SruId = this.userHierarchy.SruId;
      hierarchySearchVMObj.DepartmentId = this.userHierarchy.DepartmentId;
      hierarchySearchVMObj.CrewId = this.userHierarchy.CrewId;
      this._permitTypeService.getPublishedPermitTypes(hierarchySearchVMObj).subscribe(_result => {
        if (_result.length > 0) {
          let permitTypeIds: number[] = [];
          _result.forEach(permitType => {
            permitTypeIds.push(permitType.PermitType.PermitTypeId);
          });
          let permitControlRoleVMObj = new PermitControlRoleViewModel();
          permitControlRoleVMObj.PermitTypeIds = permitTypeIds;
          permitControlRoleVMObj.LoginId = loginId;
          this._permitControlService.canSuspendPermit(permitControlRoleVMObj).subscribe((result: boolean) => {
            if (result == true) {
              this.hasSuspensionAccess = true;
            }
            else {
              this.hasSuspensionAccess = false;
            }
          });
        }
      });
    }
  }
  handleChange(e) {
    var index = e.index;
    if (index == 1) {

      this.GetAllCategoryForDropDown();
    }
  }

  GetAllCategory(): void {
    let regionId=0;
    let siteId=0;
     this.permitTypes = [];
      if(this.userHierarchy.RegionId>0){
        regionId = this.userHierarchy.RegionId
      }
      if(this.userHierarchy.SiteId>0){
        siteId = this.userHierarchy.SiteId
      }
      this._categoryService.GetCategoryBasedOnPermit(regionId,siteId).subscribe(
      (data: Category[]) => {
        if (data.length == 0) {
          this.categories = [];
         
          this._categoryNotFound = true;
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.categoryNotFound') });
        }
        else {
          // this._objArrCategories = data
          this.GetMediaForCategories(data);
        }
      },
      () => {
        this.categories = [];
       // this.permitTypes = [];
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.categoryNotFound') });
      }
    );
  }

  CheckIfSuspended(permtiActionVMObj: PermitActionViewModel) {
    if (permtiActionVMObj.SiteId > 0) {
      this._permitActionService.isSuspended(permtiActionVMObj).subscribe(result => {
        this.suspendIconFlag = result
      })
    }
    else {
      this.siteNotSelected = true;
    }

  }

  GetAllCategoryForDropDown(): void {
    this._categoryService.searchCategory('', true, '').subscribe(
      (data: Category[]) => {
        let permtiActionVMObj = new PermitActionViewModel();
        permtiActionVMObj.SiteId = this.userHierarchy.SiteId;
        permtiActionVMObj.CategoryId = 0;
        permtiActionVMObj.PermitTypeIds = [];

        this.CheckIfSuspended(permtiActionVMObj);

        if (data != undefined && data.length > 0) {
          //this._objArrCategories = data
          this._objArrCategories = data.sort((a, b) => b.CategoryName.toLocaleUpperCase() < a.CategoryName.toLocaleUpperCase() ? 1 : -1);
        }
        else {
        }
      },
      () => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Category.searchErrorCategory') });
      }
    );
  }

  populatePermitType(category, isSelected, cardNumber) {
    //Change card clicked css
    this.setClickedCSS(cardNumber);

    this.iscategoryselected = false;
    this.presentCategoryId = category.value.CategoryId;
    this.categories.forEach(obj => { obj.value.isCategorySelected = false });
    if (isSelected == false)
      category.value.isCategorySelected = !category.value.isCategorySelected;
    if (this.router.url == '/createpermit') {
      if (category.value != undefined) {
        this.GetPermits(category.value.CategoryId);
        // this._permitActionService.checkCategorySuspension(this.userHierarchy.SiteId, category.value.CategoryId).subscribe((data: boolean) => {
        //   if (data == true) {
        //     this.permitTypes = [];
        //     this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CategorySuspensionMessage') });
        //   }
        //   else {
        //     this.GetPermits(category.value.CategoryId);
        //   }
        // });

      } else {
        // this._permitActionService.checkCategorySuspension(this.userHierarchy.SiteId, category.CategoryId).subscribe((data: boolean) => {
        //   if (data == true) {
        //     this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CategorySuspensionMessage') });
        //   }
        //   else {
        //     this.GetPermits(category.CategoryId);
        //   }
        // });
        this.GetPermits(category.CategoryId);
      }
    }
    else {
      this.GetPermits(category.value.CategoryId);
    }
    this.selectedText = category.value.CategoryName;
    // this._menu.breadcrumbMenu.push({ label: this.selectedText });
  }

  setClickedCSS(cardNumber) {
    try {
      let elements = this.elRef.nativeElement.querySelectorAll('.clicked');
      if (elements && elements.length > 0) {
        for(let i=0; i<elements.length; i++) {
          elements[i].classList.remove('clicked');
        }
      }
      let selectedElem = '.category-card-'+cardNumber.toString();
      let selectedElements = this.elRef.nativeElement.querySelectorAll(selectedElem);
      if (selectedElements && selectedElements.length > 0) {
        const classList = selectedElements[0].classList;
        const classes = selectedElements[0].className;
        if(!classes.includes('clicked')){
          selectedElements[0].classList.add('clicked');
        }       
      }
    }
    catch(ex){
      console.log('Error adding class for clicked list.');
    }    
  }

  GetPermits(categoryId) {
    this.permitTypes.length = 0;

    this.permitTypes = [];
    let hierarchySearchVMObj = new HierarchySearchViewModel();
    if (this.userHierarchy) {
      hierarchySearchVMObj.BuId = this.userHierarchy.BuId;
      hierarchySearchVMObj.RegionId = this.userHierarchy.RegionId;
      hierarchySearchVMObj.SubBuId = this.userHierarchy.SubBuId;
      hierarchySearchVMObj.SiteId = this.userHierarchy.SiteId;
      hierarchySearchVMObj.SubSiteId = this.userHierarchy.SubSiteId;
      hierarchySearchVMObj.SruId = this.userHierarchy.SruId;
      hierarchySearchVMObj.DepartmentId = this.userHierarchy.DepartmentId;
      hierarchySearchVMObj.CrewId = this.userHierarchy.CrewId;
    }
    hierarchySearchVMObj.CategoryId = categoryId;
    this._permitTypeService.getPublishedPermitTypes(hierarchySearchVMObj).subscribe(
      (data: PermitTypeViewModel[]) => {
        if (data.length > 0) {
          this.isPermitTypePresent = true;
          if (this.router.url == '/createpermit') {
            data = data.filter(x => x.Published == true && !!x.PermitType.Active ||(x.PermitType.DocumentLink != null && x.PermitType.DocumentLink != ''));
          }
          else if (this.router.url == '/dashboard') {
            data = data.filter(x => x.Published == true);
          }
          this.CreatePermitTypeObj(data);
        }
        else {
          this.isPermitTypePresent == false;
          this.permitTypes = []
        }

      },
      () => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('PermitType.searchErrorPermitType') });
      }

    );
  }

  PermitSelectionTypeClick() {
    this.permits.map(item => item.IsSelected = false);
    if (this.selectionType === SelectionType.Single) {
      this.isMultiSelect = false;
    }
    else if (this.selectionType === SelectionType.Multiple) {
      this.isMultiSelect = true;
    }
  }

  OnSelectPermit(event): void {
    if(event)
    {
      let permit = event.value ? event.value : event.option.value;
      if(permit)
         {
          this.permitTypes.forEach(obj => { obj.value.isSelected = false });
          let redirectURL = '';
          let permitTypeIds: number[] = [];
          if (this.router.url == '/createpermit') {
            localStorage.setItem('SourceURL', 'createpermit');
            if (permit.PublishedStatus == false && permit.DocumentLink != null && permit.DocumentLink != '') {
              for (let i = 0; i < permit.PermitTypeDocumentLink.length; i++) {
                let objsite = permit.PermitTypeDocumentLink[i];
                if (objsite.SiteId == this.userHierarchy.SiteId) {
                  window.open(objsite.DocumentLink, "_blank");
                  return;
                }
                //  else{
                //       window.open(permit.DocumentLink, "_blank");
                //     }
              }
    
              window.open(permit.DocumentLink, "_blank");
    
            }
            // if (permit.PublishedStatus == false && permit.DocumentLink != null && permit.DocumentLink != '') {
            //   window.open(permit.DocumentLink, "_blank");
            // }
            else {
              if (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null) {
    
                if (permit.PermitTypeId == null) {
                }
                else {
                  permitTypeIds.push(permit.PermitTypeId);
                }
                // this._permitActionService.checkSiteSuspension(this.userHierarchy.SiteId).subscribe((data: boolean) => {
                //   if (data == true) {
                //     this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteSuspensionMessage') });
                //   }
                //   else {
                //     this._permitActionService.checkPermitTypeSuspension(this.userHierarchy.SiteId, permit.PermitTypeId).subscribe((data: boolean) => {
                //       if (data == true) {
                //         this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SuspensionMessage') });
                //       }
                //       else {
                //         redirectURL = '/permit/' + permit.PermitTypeId + '/0';
                //         this._permitControlService.getPermitControlRoles(permit.PermitTypeId, localStorage.getItem("_loginId"), this.userHierarchy.SiteId).subscribe(
                //           (data: boolean) => {
                //             if (data == true) {
                //               this._commonService.GetTrainingStatusForCreateNew(this.userHierarchy.SiteId, permit.PermitTypeId).subscribe((data: RoleTrainingStateViewModel[]) => {
                //                 if (data.length == 0) {
                //                   this.router.navigate([redirectURL]);
                //                 }
                //                 else {
                //                   this._trainingNotPresentForRole = data.filter(x => x.Status == false);
                //                   let objList = data.filter(x => x.Status == true);
                //                   if (objList.length > 0) {
                //                     this.router.navigate([redirectURL]);
                //                   }
                //                   else {
                //                     this._displayLMSErrorForUser = true;
                //                     // this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingNotPresentForUser') });
                //                   }
                //                 }
                //               },
                //                 error => {
                //                   this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorLMSServer') });
                //                 })
    
    
                //             }
                //             else {
                //               this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('Permit.NoRoleFound') });
                //             }
                //           });
                //       }
                //     });
                //   }
                // });
    
                this._permitActionService.checkSuspensionStatus(this.userHierarchy.SiteId, this.presentCategoryId, permit.PermitTypeId).subscribe((data) => {
                  if (data.SiteSuspended) {
                    this._messageService.clear();
                    this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteSuspensionMessage') });
                  }
                  else if (data.CategorySuspended) {
                    this._messageService.clear();
                    this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CategorySuspensionMessage') });
                  }
                  else if (data.PermitTypeSuspended) {
                    this._messageService.clear();
                    this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SuspensionMessage') });
                  }
                  else {
                    redirectURL = '/permit/' + permit.PermitTypeId + '/0';
                    this._permitControlService.getPermitControlRoles(permit.PermitTypeId, localStorage.getItem("_loginId"), this.userHierarchy.SiteId).subscribe(
                      (data: boolean) => {
                        if (data == true) {
                          this._commonService.GetTrainingStatusForCreateNew(this.userHierarchy.SiteId, permit.PermitTypeId).subscribe((data: RoleTrainingStateViewModel[]) => {
                            if (data.length == 0) {
                              this.router.navigate([redirectURL]);
                            }
                            else {
                              this._trainingNotPresentForRole = data.filter(x => x.Status == false);
                              let objList = data.filter(x => x.Status == true);
                              if (objList.length > 0) {
                                this.router.navigate([redirectURL]);
                              }
                              else {
                                this._displayLMSErrorForUser = true;
                                // this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingNotPresentForUser') });
                              }
                            }
                          },
                            error => {
                              this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorLMSServer') });
                            })
    
    
                        }
                        else {
                          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('Permit.NoRoleFound') });
                        }
                      });
                  }
                });
    
              }
              else {
                permit.isSelected = permit.isSelected;
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
              }
            }
          }
          else if (this.router.url == '/dashboard') {
            localStorage.setItem('SourceURL', 'dashboard');
            if (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null) {
              redirectURL = '/manage-dashboard/' + permit.PermitTypeId;
              this.router.navigate([redirectURL]);
              this._menu.breadcrumbMenu.push({ label: "" });
            }
            else {
              this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('EntryLeader.NoRoleFound') });
            }
          }
        }
    }
  }

  GetMediaForCategories(categoryList: Category[]) {
    //console.log(categoryList);
    this.categories = [];
    let arrayOfAzureFile = [];
    let j = 0
    for (let i = 0; i < categoryList.length; i++) {
      if (categoryList[i].Attachment != null) {
        if (categoryList[i].Attachment.AzureFileId != null) {
          arrayOfAzureFile[j++] = categoryList[i].Attachment.AzureFileId;
          arrayOfAzureFile = arrayOfAzureFile.filter(x => x != 0 && x != undefined);
        }
      }
    }
    let obj: any;    
    this._mediaService.getFilePropertiesArray(arrayOfAzureFile).subscribe(
      (res: HttpResponse<any>) => {
        this.objAzureFile = res.body;
        for (let categoryObj of categoryList) {
          if (categoryObj.Attachment != null) {
            if (categoryObj.Attachment.AzureFileId != 0 && categoryObj.Attachment.AzureFileId != null) {
              let fileObj = res.body.find(x => x.fileId == categoryObj.Attachment.AzureFileId);
              if (fileObj) {
                if (fileObj.uri != null && fileObj.uri != '') {
                  if (fileObj.contentType.indexOf('image/') != -1) {                    
                    obj = {
                      CategoryId: categoryObj.Category.CategoryId,
                      CategoryName: categoryObj.Category.CategoryName,
                      AzureFileId: categoryObj.Attachment.AzureFileId,
                      isCategorySelected: false,
                      ImageSource: fileObj.uri
                    }                                        
                    this.categories.push({ value: obj });
                    this.categories = this.categories.sort((a, b) => b.value.CategoryName.toLocaleUpperCase() < a.value.CategoryName.toLocaleUpperCase() ? 1 : -1);
                  }
                }
                else {
                  obj = {
                    CategoryId: categoryObj.Category.CategoryId,
                    CategoryName: categoryObj.Category.CategoryName,
                    AzureFileId: null,
                    ImageSource: null,
                    isCategorySelected: false
                  }
                  this.categories.push({ value: obj });
                  this.categories = this.categories.sort((a, b) => b.value.CategoryName.toLocaleUpperCase() < a.value.CategoryName.toLocaleUpperCase() ? 1 : -1);
                }
              }
              else {
                obj = {
                  CategoryId: categoryObj.Category.CategoryId,
                  CategoryName: categoryObj.Category.CategoryName,
                  AzureFileId: null,
                  ImageSource: null,
                  isCategorySelected: false
                }
                this.categories.push({ value: obj });
                this.categories = this.categories.sort((a, b) => b.value.CategoryName.toLocaleUpperCase() < a.value.CategoryName.toLocaleUpperCase() ? 1 : -1);
              }
            }
            else {
              obj = {
                CategoryId: categoryObj.Category.CategoryId,
                CategoryName: categoryObj.Category.CategoryName,
                AzureFileId: null,
                ImageSource: null,
                isCategorySelected: false
              }
              this.categories.push({ value: obj });
              this.categories = this.categories.sort((a, b) => b.value.CategoryName.toLocaleUpperCase() < a.value.CategoryName.toLocaleUpperCase() ? 1 : -1);
            }
          }

          else {
            obj = {
              CategoryId: categoryObj.Category.CategoryId,
              CategoryName: categoryObj.Category.CategoryName,
              AzureFileId: null,
              ImageSource: null,
              isCategorySelected: false
            }
            this.categories.push({ value: obj });
            this.categories = this.categories.sort((a, b) => b.value.CategoryName.toLocaleUpperCase() < a.value.CategoryName.toLocaleUpperCase() ? 1 : -1);
          }
        }
        for (let i = 0, j = 0; i < this.categories.length; i++ , j++) {
          if (j == this.circlecolor.length) {
            j = 0;
          }
          this.categories[i].value.circlecolor = this.circlecolor[j].color;
          this.categories[i].value.tilecolor = this.tilecolor[j].color;
        }
        this.bindcategoryImage();
      },
      error => {
        for (let categoryObj of categoryList) {
          obj = {
            CategoryId: categoryObj.Category.CategoryId,
            CategoryName: categoryObj.Category.CategoryName,
            AzureFileId: null,
            ImageSource: null,
            isCategorySelected: false,
            Initials: this.makeInitials(categoryObj.Category.CategoryName)
          }
          this.categories.push({ value: obj });
          this.categories = this.categories.sort((a, b) => b.value.CategoryName.toLocaleUpperCase() < a.value.CategoryName.toLocaleUpperCase() ? 1 : -1);
        }
        for (let i = 0, j = 0; i < this.categories.length; i++ , j++) {
          if (j == this.circlecolor.length) {
            j = 0;
          }
          this.categories[i].value.circlecolor = this.circlecolor[j].color;
          this.categories[i].value.tilecolor = this.tilecolor[j].color;
        }
      }
    );    
  }

  makeInitials(categoryName: string): string {
    let cName = categoryName.split(' ');
    if (cName.length == 1) {
      let obj = cName[0].trim();
      if (obj.length > 1) {
        return obj.substring(0, 2);
      }        
    }
    else if (cName.length > 1) {
      let ob1 = cName[0].trim();
      let ob2 = cName[1].trim();
      if (ob1 && ob2) {
        return  ob1[0] + ob2[0];
      }
    }
  }

  bindcategoryImage() {
    if (this.categories && this.categories.length > 0) {
      this.categories.forEach(category => {
        if (category.value && category.value.ImageSource != null) {
          try{
            this._commonService.getMediaBlob(this._commonMethods.EscapeSpecialCharacters(category.value.ImageSource)).subscribe(
              (data: any) => {
                let base64Image = "data:image/png;base64," + data.toString();
                let thumbnailImage = this.sanitizerdash.bypassSecurityTrustUrl(base64Image);
                if(thumbnailImage){
                  category.value.ImageSource = thumbnailImage;
                }
              }
            );
          }
          catch(ex){
            console.log('Error getting image for category - ' + category.value.CategoryName);
          }
        }        
      });
    }
  }

  GetMediaForPermitTypes(permitTypeList: PermitTypeViewModel[]) {
    let arrayOfAzureFile = [];
    let j = 0
    for (let i = 0; i < permitTypeList.length; i++) {
      if (permitTypeList[i].Attachment != null) {
        if (permitTypeList[i].Attachment.AzureFileId != null) {
          arrayOfAzureFile[j++] = permitTypeList[i].Attachment.AzureFileId;
          arrayOfAzureFile = arrayOfAzureFile.filter(x => x != 0 && x != undefined);
        }
      }
    }
    // for (let permitTypeObj of permitTypeList) {
    let obj: any;
    // let azurefileid = permitTypeObj.Attachment == null ? null : permitTypeObj.Attachment.AzureFileId;
    // if (azurefileid != null && azurefileid != 0) {
    this._mediaService.getFilePropertiesArray(arrayOfAzureFile).subscribe(
      (res: HttpResponse<any>) => {
        this.objAzureFileForPermit = res.body;
        for (let permitTypeObj of permitTypeList) {
          if (permitTypeObj.Attachment != null) {
            if (permitTypeObj.Attachment.AzureFileId != 0 && permitTypeObj.Attachment.AzureFileId != null) {
              let fileObjPermit = res.body.find(x => x.fileId == permitTypeObj.Attachment.AzureFileId);
              if (fileObjPermit) {
                if (fileObjPermit.uri != null && fileObjPermit.uri != '') {
                  if (fileObjPermit.contentType.indexOf('image/') != -1) {
                    obj = {

                      PermitTypeId: permitTypeObj.PermitType.PermitTypeId,
                      PermitTypeName: permitTypeObj.PermitType.PermitTypeName,
                      AzureFileId: permitTypeObj.Attachment.AzureFileId,
                      //ImageSource: fileObjPermit.ImageUri,
                      isSelected: false,
                      PublishedStatus: permitTypeObj.Published,
                      DocumentLink: permitTypeObj.PermitType.DocumentLink,
                      PermitTypeDocumentLink: permitTypeObj.PermitType.PermitTypeDocumentLinks,
                      ImageSource: fileObjPermit.uri,
                    }
                    this.permitTypes.push({ value: obj });
                    this.permitTypes = this.permitTypes.sort((a, b) => b.value.PermitTypeName.toLocaleUpperCase() < a.value.PermitTypeName.toLocaleUpperCase() ? 1 : -1);
                  }
                }
                else {
                  obj = {
                    PermitTypeId: permitTypeObj.PermitType.PermitTypeId,
                    PermitTypeName: permitTypeObj.PermitType.PermitTypeName,
                    AzureFileId: null,
                    ImageSource: null,
                    isSelected: false,
                    PublishedStatus: permitTypeObj.Published,
                    DocumentLink: permitTypeObj.PermitType.DocumentLink,
                    PermitTypeDocumentLink: permitTypeObj.PermitType.PermitTypeDocumentLinks
                  }
                  this.permitTypes.push({ value: obj });
                  this.permitTypes = this.permitTypes.sort((a, b) => b.value.PermitTypeName.toLocaleUpperCase() < a.value.PermitTypeName.toLocaleUpperCase() ? 1 : -1);
                }
              }
              else {
                obj = {
                  PermitTypeId: permitTypeObj.PermitType.PermitTypeId,
                  PermitTypeName: permitTypeObj.PermitType.PermitTypeName,
                  AzureFileId: null,
                  ImageSource: null,
                  isSelected: false,
                  PublishedStatus: permitTypeObj.Published,
                  DocumentLink: permitTypeObj.PermitType.DocumentLink,
                  PermitTypeDocumentLink: permitTypeObj.PermitType.PermitTypeDocumentLinks
                }
                this.permitTypes.push({ value: obj });
                this.permitTypes = this.permitTypes.sort((a, b) => b.value.PermitTypeName.toLocaleUpperCase() < a.value.PermitTypeName.toLocaleUpperCase() ? 1 : -1);
              }
            }
            else {
              obj = {
                PermitTypeId: permitTypeObj.PermitType.PermitTypeId,
                PermitTypeName: permitTypeObj.PermitType.PermitTypeName,
                AzureFileId: null,
                ImageSource: null,
                isSelected: false,
                PublishedStatus: permitTypeObj.Published,
                DocumentLink: permitTypeObj.PermitType.DocumentLink,
                PermitTypeDocumentLink: permitTypeObj.PermitType.PermitTypeDocumentLinks
              }
              this.permitTypes.push({ value: obj });
              this.permitTypes = this.permitTypes.sort((a, b) => b.value.PermitTypeName.toLocaleUpperCase() < a.value.PermitTypeName.toLocaleUpperCase() ? 1 : -1);
            }
          }
          else {
            obj = {
              PermitTypeId: permitTypeObj.PermitType.PermitTypeId,
              PermitTypeName: permitTypeObj.PermitType.PermitTypeName,
              AzureFileId: null,
              ImageSource: null,
              isSelected: false,
              PublishedStatus: permitTypeObj.Published,
              DocumentLink: permitTypeObj.PermitType.DocumentLink,
              PermitTypeDocumentLink: permitTypeObj.PermitType.PermitTypeDocumentLinks
            }
            this.permitTypes.push({ value: obj });
            this.permitTypes = this.permitTypes.sort((a, b) => b.value.PermitTypeName.toLocaleUpperCase() < a.value.PermitTypeName.toLocaleUpperCase() ? 1 : -1);
          }
        }
      }
    );
  }

  CreatePermitTypeObj(permitTypeList: PermitTypeViewModel[]) {
    let obj: any;
    for (let permitTypeObj of permitTypeList) {
      obj = {
        PermitTypeId: permitTypeObj.PermitType.PermitTypeId,
        PermitTypeName: permitTypeObj.PermitType.PermitTypeName,
        AzureFileId: null,
        ImageSource: null,
        isSelected: false,
        PublishedStatus: permitTypeObj.Published,
        DocumentLink: permitTypeObj.PermitType.DocumentLink,
        PermitTypeDocumentLink: permitTypeObj.PermitType.PermitTypeDocumentLinks
      }
      this.permitTypes.push({ value: obj });
      this.permitTypes = this.permitTypes.sort((a, b) => b.value.PermitTypeName.toLocaleUpperCase() < a.value.PermitTypeName.toLocaleUpperCase() ? 1 : -1);
    }
  }

  onOK() {
    this._messageService.clear('error');
    this._messageService.clear('success');
  }  

  GetUserDetails(loggedinUserId) {
    if (this.userHierarchy) {
      this._selectedSiteForSuspension = this.userHierarchy.SiteName;
      this._userService.getUserByLoginId(loggedinUserId).subscribe(
        (data: User) => {
          this._currentLoggedInUser = data;
          let suspenedPermitVMObj = new SuspendedPermitViewModel();
          suspenedPermitVMObj.categoryId = null;
          suspenedPermitVMObj.permitTypeId = null;
          if (this.userHierarchy) {
            suspenedPermitVMObj.siteId = this.userHierarchy.SiteId;
          }
          else {
            suspenedPermitVMObj.siteId = this._currentLoggedInUser.SiteId;
          }
          this.CanSuspendPermit(loggedinUserId);
          this.permitsSuspended = false;
          // this.suspendIconFlag = true;
          this.permitsUnSuspended = true;
        });
    }
  }

  onSelectCategory() {
    this._ddlCategory = +this._ddlCategory;
    this._ddlPermitType = 0;
    if (this._ddlCategory > 0) {
      // Taking Decision for showing Suspend/UnSuspend button
      let permtiActionVMObj = new PermitActionViewModel();
      permtiActionVMObj.SiteId = this.userHierarchy.SiteId;
      permtiActionVMObj.CategoryId = this._ddlCategory;
      permtiActionVMObj.PermitTypeIds = [];
      this.CheckIfSuspended(permtiActionVMObj)
      let objHierarchySearchViewModel = new HierarchySearchViewModel();
      objHierarchySearchViewModel.BuId = this.userHierarchy.SiteId;
      objHierarchySearchViewModel.RegionId = this.userHierarchy.RegionId;
      objHierarchySearchViewModel.SubBuId = this.userHierarchy.SubBuId;
      objHierarchySearchViewModel.SiteId = this.userHierarchy.SiteId;
      objHierarchySearchViewModel.SubSiteId = this.userHierarchy.SubSiteId;
      objHierarchySearchViewModel.SruId = this.userHierarchy.SruId;
      objHierarchySearchViewModel.DepartmentId = this.userHierarchy.DepartmentId;
      objHierarchySearchViewModel.CrewId = this.userHierarchy.CrewId;
      objHierarchySearchViewModel.CategoryId = this._ddlCategory;
      this._permitTypeService.getPublishedPermitTypes(objHierarchySearchViewModel).subscribe(
        (data: PermitTypeViewModel[]) => {
          this._objArrPermitType = [];
          this._objArrPermitTypeNames = [];
          this.selectedPermitTypesForSuspend = [];
          let permitTypeIds: number[] = []
          data.forEach(x => {
            this._objArrPermitType.push(x.PermitType)
            this._objArrPermitTypeNames.push({ label: x.PermitType.PermitTypeName, value: x.PermitType.PermitTypeId });
            permitTypeIds.push(x.PermitType.PermitTypeId)
          });
        });
    }
    else {
      this._objArrPermitType = [];
      this._objArrPermitTypeNames = [];
      this.selectedPermitTypesForSuspend = [];
      this._ddlPermitType = 0;
      let permtiActionVMObj = new PermitActionViewModel();
      permtiActionVMObj.SiteId = this.userHierarchy.SiteId;
      permtiActionVMObj.CategoryId = 0;
      permtiActionVMObj.PermitTypeIds = [];
      this.CheckIfSuspended(permtiActionVMObj)
    }

  }

  suspendPermits() {
    //this.showSuspendAllPopUp = true;
    this.attrHeader = this.translate.instant('AttrHeader');
    if (this._objArrCategories.length == 0) {
      this.GetAllCategory();
    }
  }

  cancelSuspendAllPermit() {
    //this.showSuspendAllPopUp = false;
    this._objArrPermitType = [];
    this._objArrPermitTypeNames = [];
    this.selectedPermitTypesForSuspend = [];
    this._objArrCategories = [];
    this._ddlCategory = 0;
    this._ddlPermitType = 0;
  }

  // CheckPermitSuspensionStatus(permitTypeIds: number[]) {
  //   this._permitTypeService.checkPermitSuspension(permitTypeIds).subscribe((data: boolean) => {
  //     if (data == true) {
  //       this.permitsSuspended = true;
  //       this.suspendIconFlag = false;
  //       this.permitsUnSuspended = false;
  //       this.suspendImage = this.disabledSuspendIconPath
  //       this.unsuspendImage = this.UnsuspendIconPath;
  //     }
  //     else {
  //       this.permitsSuspended = false;
  //       this.suspendIconFlag = true;
  //       this.permitsUnSuspended = true;
  //       this.suspendImage = this.SuspendIconPath
  //       this.unsuspendImage = this.disabledUnsuspendIconPath;
  //     }
  //   });
  // }

  selectPermitTypes() {
    let permitTypeIds: number[] = []
    permitTypeIds.push(this._ddlPermitType)
    let permtiActionVMObj = new PermitActionViewModel();
    permtiActionVMObj.SiteId = this.userHierarchy.SiteId;
    permtiActionVMObj.CategoryId = this._ddlCategory;
    permtiActionVMObj.PermitTypeIds = permitTypeIds;

    this.CheckIfSuspended(permtiActionVMObj)
  }

  CanSuspendSelectedPermitType(selectedPermitTypesForSuspend) {
    let permitControlRoleVMObj = new PermitControlRoleViewModel();
    permitControlRoleVMObj.LoginId = localStorage.getItem('_loginId');
    permitControlRoleVMObj.PermitTypeIds = selectedPermitTypesForSuspend;
    this._permitControlService.canSuspendPermit(permitControlRoleVMObj).subscribe(() => {

    });
  }

  filterCategorySuggest(event) {
    let query = event.query;
    this.filteredCategories = this.filterCategory(query, this._objArrCategories);
  }

  filterCategory(query, categories: Category[]): any[] {
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    for (let i = 0; i < categories.length; i++) {
      let category = categories[i];
      if (category.Category.CategoryName.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(category);
      }
    }
    return filtered;
  }

  confirmPermitSuspension() {
    if (this.userHierarchy.SiteId > 0) {
      if (this._ddlCategory > 0) {
        let PermitTypeName: string = ''
        let permitTypes: string[] = []
        if (this._ddlPermitType > 0) {
          PermitTypeName = this._objArrPermitType.find(x => x.PermitTypeId == this._ddlPermitType).PermitTypeName;
        }
        else {
          this._objArrPermitType.forEach(x => { permitTypes.push(x.PermitTypeName); PermitTypeName = permitTypes.join(', '); });
        }
        this._confirmationService.confirm({
          message: "Are you sure you want to suspend " + PermitTypeName + " for site " + this.userHierarchy.SiteName,
          header: this.translate.instant('confirmationHeader'),
          key: 'suspendPermitConfirmation',
          icon: 'pi pi-info-circle',
          accept: () => {
            this.SuspendAllPermits(false);
          },
          reject: () => {
            this.onOK();
          }
        });
      }
      else {
        this._confirmationService.confirm({
          message: "Are you sure you want to suspend site " + this.userHierarchy.SiteName,
          header: this.translate.instant('confirmationHeader'),
          key: 'suspendPermitConfirmation',
          icon: 'pi pi-info-circle',
          accept: () => {
            this.SuspendAllPermits(true);
          },
          reject: () => {
            this.onOK();
          }
        });
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.PleaseSelectSite') });
    }

    // if (siteSuspenstionIdentifier) {
    //   let siteName = this.userHierarchy.SiteName;
    //   let message = this.translate.instant('Permit.siteSuspendConfirmation')
    //   this._confirmationService.confirm({
    //     message: message + siteName,
    //     header: this.translate.instant('confirmationHeader'),
    //     key: 'suspendPermitConfirmation',
    //     icon: 'pi pi-info-circle',
    //     accept: () => {
    //       this.SuspendAllPermits(siteSuspenstionIdentifier);
    //     },
    //     reject: () => {
    //       this.onOK();
    //     }
    //   });
    // }
    // if (this._objArrPermitType.length > 0) {
    //   let PermitTypeName = '';
    //   let permitTypes: string[] = []
    //   if (this._ddlPermitType > 0) {
    //     PermitTypeName = this._objArrPermitType.find(x => x.PermitTypeId == this._ddlPermitType).PermitTypeName;
    //   }
    //   else {
    //     this._objArrPermitType.forEach(x => {
    //       permitTypes.push(x.PermitTypeName);
    //       PermitTypeName = permitTypes.join(', ');
    //     });
    //   }
    //   let siteName = this.userHierarchy.SiteName;
    //   let message = this.translate.instant('Permit.typeSuspensionConfirmation')
    //   this._confirmationService.confirm({
    //     message: message + PermitTypeName + ' for ' + siteName,
    //     header: this.translate.instant('confirmationHeader'),
    //     key: 'suspendPermitConfirmation',
    //     icon: 'pi pi-info-circle',
    //     accept: () => {
    //       this.SuspendAllPermits(false);
    //     },
    //     reject: () => {
    //       this.onOK();
    //     }
    //   });
    // }
    // else {
    //   this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.PermitTypeNotFoundError') });
    // }
    // else {
    //   this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    // }
  }

  confirmPermitUnSuspension() {
    if (this.userHierarchy.SiteId > 0) {
      if (this._ddlCategory > 0) {
        let PermitTypeName: string = ''
        let permitTypes: string[] = []
        if (this._ddlPermitType > 0) {
          PermitTypeName = this._objArrPermitType.find(x => x.PermitTypeId == this._ddlPermitType).PermitTypeName;
        }
        else {
          this._objArrPermitType.forEach(x => { permitTypes.push(x.PermitTypeName); PermitTypeName = permitTypes.join(', '); });
        }
        this._confirmationService.confirm({
          message: "Are you sure you want to unsuspend " + PermitTypeName + " for site " + this.userHierarchy.SiteName,
          header: this.translate.instant('confirmationHeader'),
          key: 'suspendPermitConfirmation',
          icon: 'pi pi-info-circle',
          accept: () => {
            this.UnSuspendAllPermits(false);
          },
          reject: () => {
            this.onOK();
          }
        });
      }
      else {
        this._confirmationService.confirm({
          message: "Are you sure you want to unsuspend site " + this.userHierarchy.SiteName,
          header: this.translate.instant('confirmationHeader'),
          key: 'suspendPermitConfirmation',
          icon: 'pi pi-info-circle',
          accept: () => {
            this.UnSuspendAllPermits(true);
          },
          reject: () => {
            this.onOK();
          }
        });
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    }
    // if (this.userHierarchy.SiteId > 0) {
    //   let siteSuspenstionIdentifier = false
    //   if (this._ddlCategory == 0) {
    //     //siteSuspenstionIdentifier = true
    //     this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.PleaseSelectCategory') });

    //   }
    //   // if (siteSuspenstionIdentifier) {
    //   //   {
    //   //     let siteName = this.userHierarchy.SiteName;
    //   //     let message = this.translate.instant('Permit.siteunSuspendConfirmation')
    //   //     this._confirmationService.confirm({
    //   //       message: message + siteName,
    //   //       header: this.translate.instant('confirmationHeader'),
    //   //       key: 'suspendPermitConfirmation',
    //   //       icon: 'pi pi-info-circle',
    //   //       accept: () => {
    //   //         this.UnSuspendAllPermits(siteSuspenstionIdentifier);
    //   //       },
    //   //       reject: () => {
    //   //         this.onOK();
    //   //       }
    //   //     });
    //   //   }
    //   // }
    //   else {
    //     if (this._objArrPermitType.length > 0) {

    //       let PermitTypeName = '';
    //       let siteName = this.userHierarchy.SiteName;
    //       if (this.selectedPermitTypesForSuspend.length > 0) {
    //         let permitTypes: string[] = []
    //         this.selectedPermitTypesForSuspend.forEach(x => {
    //           permitTypes.push(this._objArrPermitTypeNames.find(t => t.value == x).label);
    //           PermitTypeName = permitTypes.join(', ');
    //         });
    //       }
    //       else {
    //         PermitTypeName = "All";
    //       }
    //       let message = this.translate.instant('Permit.typeunSuspensionConfirmation')
    //       this._confirmationService.confirm({
    //         message: message + PermitTypeName + ' Permit Types for ' + siteName,
    //         header: this.translate.instant('confirmationHeader'),
    //         key: 'suspendPermitConfirmation',
    //         icon: 'pi pi-info-circle',
    //         accept: () => {
    //           this.UnSuspendAllPermits(siteSuspenstionIdentifier);
    //         },
    //         reject: () => {
    //           this.onOK();
    //         }
    //       });
    //     }
    //     else {
    //       this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.PermitTypeNotFoundError') });

    //     }
    //   }
    // }
    // else {
    //   this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    // }
  }

  selectSuspendSite() {
    this.siteTobeSuspended = true;
    this.permitTypeTobeSuspended = false;
  }

  selectSuspendPermitType() {
    this.siteTobeSuspended = false;
    this.permitTypeTobeSuspended = true;
  }

  SuspendAllPermits(siteSuspensionIdentifier: boolean) {
    if (siteSuspensionIdentifier) {
      let permitActionVMObj = new PermitActionViewModel();
      permitActionVMObj.SiteId = this.userHierarchy.SiteId
      permitActionVMObj.CategoryId = null
      permitActionVMObj.PermitTypeIds = []
      permitActionVMObj.CreatedBy = localStorage.getItem('_loginId');
      permitActionVMObj.CreatedDate = new Date();
      this._permitActionService.suspendAllPermits(permitActionVMObj).subscribe(data => {
        if (data.status == 200) {
          this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.SiteSuspendSuccess') });
          this.CheckIfSuspended(permitActionVMObj)
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteSuspendError') });
        }
      });
    }
    else {
      let permitTypeIds: number[] = [];
      if (this._ddlPermitType > 0) {
        permitTypeIds.push(this._ddlPermitType)
      }
      else {
        this._objArrPermitType.forEach((type) => {
          permitTypeIds.push(type.PermitTypeId)
        });
      }

      let permitActionVMObj = new PermitActionViewModel();
      permitActionVMObj.SiteId = this.userHierarchy.SiteId
      permitActionVMObj.CategoryId = this._ddlCategory
      permitActionVMObj.PermitTypeIds = permitTypeIds
      permitActionVMObj.CreatedBy = localStorage.getItem('_loginId');
      permitActionVMObj.CreatedDate = new Date();
      this._permitActionService.suspendAllPermits(permitActionVMObj).subscribe(data => {
        if (data.status == 200) {
          this.CheckIfSuspended(permitActionVMObj)
          this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.SuspendSuccess') });
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SuspendError') });
        }
      });
    }
  }

  UnSuspendAllPermits(siteunsuspenstionIdentifier: boolean) {
    if (siteunsuspenstionIdentifier) {
      let permitActionVMObj = new PermitActionViewModel();
      permitActionVMObj.SiteId = this.userHierarchy.SiteId
      permitActionVMObj.CategoryId = null
      permitActionVMObj.PermitTypeIds = []
      permitActionVMObj.CreatedBy = localStorage.getItem('_loginId');
      permitActionVMObj.CreatedDate = new Date();
      this._permitActionService.unSuspendAllPermits(permitActionVMObj).subscribe(data => {
        if (data.status == 200) {
          this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.SiteUnSuspendSuccess') });
          this.CheckIfSuspended(permitActionVMObj)
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteUnSuspendError') });
        }
      });
    }
    else {
      let permitTypeIds: number[] = [];
      if (this._ddlPermitType > 0) {
        permitTypeIds.push(this._ddlPermitType)
      }
      else {
        this._objArrPermitType.forEach((type) => {
          permitTypeIds.push(type.PermitTypeId)
        });
      }

      let permitActionVMObj = new PermitActionViewModel();
      permitActionVMObj.SiteId = this.userHierarchy.SiteId
      permitActionVMObj.CategoryId = this._ddlCategory
      permitActionVMObj.PermitTypeIds = permitTypeIds
      // permitActionVMObj.isSiteSuspended = false;
      permitActionVMObj.CreatedBy = localStorage.getItem('_loginId');
      // permitActionVMObj.ModifiedBy = localStorage.getItem('_loginId');
      permitActionVMObj.CreatedDate = new Date();
      this._permitActionService.unSuspendAllPermits(permitActionVMObj).subscribe(data => {
        if (data.status == 200) {
          this.CheckIfSuspended(permitActionVMObj)
          this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.UnSuspendSuccess') });
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.UnSuspendError') });
        }
      });
    }
  }

  // Methods for Suspend Permit ends here
}