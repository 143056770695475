// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .pi-chevron-right{
    color: #0e3551 !important;
    margin-top: 2px !important;
}
:host ::ng-deep .pi-chevron-down{
    color: #0e3551 !important;
    margin-top: 2px !important;
}
.wo-text{
    font-weight: bold; 
    color: #0e3551 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/select-operation/select-operation.component.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,0BAA0B;AAC9B;AACA;IACI,yBAAyB;IACzB,0BAA0B;AAC9B;AACA;IACI,iBAAiB;IACjB,yBAAyB;AAC7B","sourcesContent":[":host ::ng-deep .pi-chevron-right{\r\n    color: #0e3551 !important;\r\n    margin-top: 2px !important;\r\n}\r\n:host ::ng-deep .pi-chevron-down{\r\n    color: #0e3551 !important;\r\n    margin-top: 2px !important;\r\n}\r\n.wo-text{\r\n    font-weight: bold; \r\n    color: #0e3551 !important;\r\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
