import { Component, OnInit, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Permit } from 'src/app/ViewModel/Permit';
import { Response } from 'src/app/ViewModel/Response';
import { PermitTypeQuestion } from 'src/app/ViewModel/PermitBuilder/PermitDivision/PermitTypeQuestion';
import { TableTypeResponse } from 'src/app/ViewModel/TableTypeResponse';
import { PermitTypeDivision } from 'src/app/ViewModel/PermitBuilder/PermitDivision/PermitTypeDivision';
import { PermitTypeSection } from 'src/app/ViewModel/PermitBuilder/PermitDivision/PermitTypeSection';
import { PermitTypeAnswerOption } from 'src/app/ViewModel/PermitBuilder/PermitDivision/PermitTypeAnswerOption';
import { PermitTypeVersion } from 'src/app/ViewModel/PermitBuilder/PermitTypeVersion';
import { PermitService } from 'src/app/Services/permit.service';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MessageService, ConfirmationService, LazyLoadEvent, SelectItem, TreeNode } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { PermitTypeService } from 'src/app/Services/permit-type.service';
import { HierarchySearchViewModel } from 'src/app/ViewModel/HierarchySearchViewModel';
import { PermitType, PermitTypeViewModel } from 'src/app/ViewModel/PermitBuilder/PermitType';
import { PermitState } from 'src/app/ViewModel/PermitState';
import { SpinnerVisibilityService } from 'ng-http-loader';
import { CommonService } from '../Services/common.service';
import { SRUService } from '../Services/sru.service';
import { SRU } from 'src/app/ViewModel/sru';
import html2canvas from 'html2canvas';
// import * as jspdf from 'jspdf';
import { PermitTypeVersionService } from '../Services/pemit-type-version.service';
import { HierarchyTree } from '../ViewModel/HierarchyTree';
import { PermitTypeTableFieldType } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeTableFieldType';
import { User } from '../ViewModel/User';
import { PermitStates as Status, PermitStates } from 'src/app/ViewModel/helpers/PermitStates'
import { PermitActivityLog } from '../ViewModel/PermitActivityLog';
import { PermitTypeTableAnswerOption } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeTableAnswerOption';
import { DynamicDataSourceService } from '../Services/dynamicdatasource.service';
import { DynamicDataSourceOption } from '../ViewModel/DynamicDataSourceOption';
import { MediaService } from '../Services/media.service';
import { PermitControlService } from '../Services/permit-control.service';
import { PermitControl } from '../ViewModel/PermitBuilder/PermitControl/PermitControl';
import { Attachment } from '../ViewModel/Attachment';
import { Location, DatePipe } from '@angular/common';
import { UserService } from '../Services/user.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Role } from '../ViewModel/Role';
import { Category } from '../ViewModel/Category';
import { RoleService } from '../Services/role.service';
import { CategoryService } from '../Services/category.service';
import { PermitCreatedByUserViewModel } from '../ViewModel/PermitCreatedByUserViewModel';
import { Comment } from '../ViewModel/comment';
import { ChangelogService } from '../Services/changelog.service';
import { PermitTypeLevelApproval } from '../ViewModel/PermitBuilder/PermitTypeLevelApproval';
import { TimeOption } from '../ViewModel/helpers/TimeOption';
import { RevalidateService } from '../Services/revalidate.service';
import { PermitRevision } from '../ViewModel/PermitRevision';
import { ResponseAttachment } from '../ViewModel/ResponseAttachment';
import { SubQuesResponseAttachment } from '../ViewModel/SubQuesResponseAttachment';
import { PermitWorkFlow } from '../ViewModel/PermitWorkFlow';
import { ManualPermitType } from '../ViewModel/ManualPermitType';
import { PermitWorkflowViewModel } from '../ViewModel/PermitWorkflowViewModel';
import { GISMapViewModel } from '../ViewModel/Map/GISMapViewModel';
import { GeoTagService } from '../Services/geotag.service';
import { Dialog } from 'primeng/dialog';
import { environment } from 'src/environments/environment';
import { RoleTrainingStateViewModel } from '../ViewModel/RoleTrainingStateViewModel';
import { IndexedDBService } from '../Services/indexedDB.service';
import { UserForRoleGroupViewModel, UserShortInfo, RoleGroupApproval, RoleGroupUserDetails } from '../ViewModel/UserForRoleGroupViewModel';
import { UserForSectionViewModel } from '../ViewModel/UserForSectionViewModel';
import { Group, exportPDF } from '@progress/kendo-drawing';
import { HelperService } from '../Services/helper.service';
import { AssetWorkOrderViewModel } from '../ViewModel/AssetWorkOrderViewModel'
import { WorkOrder } from '../ViewModel/WorkOrderViewModel';
import { OPSequence } from '../ViewModel/OPSequenceViewModel';
import { DeviceDetectorService } from 'ngx-device-detector';

import * as FileSaver from 'file-saver';

import { saveAs } from '@progress/kendo-file-saver';
import { PrintPdfSelectedComponent } from '../print-pdf-selected/print-pdf-selected.component';
import { EAMAssetDetails } from '../ViewModel/EAMAssetDetails';
import { PermitAssetAndWorkOrder } from '../ViewModel/PermitAssetAndWorkOrder';
import { PermitOperationSequence } from '../ViewModel/PermitOperationSequence';
import { color } from 'html2canvas/dist/types/css/types/color';
import { NotificationService } from '../Services/notification.service';
import { PermitTypeSubQuestion } from 'src/app/ViewModel/PermitBuilder/PermitDivision/PermitTypeSubQuestion';
import { PermitTypeSubQuesAnswerOption } from 'src/app/ViewModel/PermitBuilder/PermitDivision/PermitTypeSubQuesAnswerOption';
import { SubQuesResponse } from 'src/app/ViewModel/SubQuesResponse';
import { SubQuesTableTypeResponse } from '../ViewModel/SubQuesTableTypeResponse';
import { PermitTypeTableSubQuesAnswerOption } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeTableSubQuesAnswerOption';
import { debounceTime, distinctUntilChanged, sequenceEqual } from 'rxjs/operators';
import { PermitTypeTableSubQues } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeTableSubQues';
import { MenuItem } from 'primeng/api';
import { AssetTreeComponent } from '../asset-tree/asset-tree.component';
import { SelectWorkOrderComponent } from '../select-work-order/select-work-order.component';
import { SelectOperationComponent } from '../select-operation/select-operation.component';
import { ChangeLog } from '../ViewModel/Dashboard/ChangeLog';
import { argv0 } from 'process';
import { format } from 'path';
import { PermitIdentifier } from '../ViewModel/PermitIdentifier';
import { IdentifierValues } from '../ViewModel/PermitIdentifier';
import { Table } from 'primeng/table'
import { setInterval } from 'timers';
declare var jspdf: any;
import { PrimeNGConfig } from 'primeng/api';
import { PermitAdditionalData, SecurityDashboardRootObject, SecurityDashboardViewModel } from '../ViewModel/SecurityDashboardViewModel';
import { FilterKeyValue } from '../ViewModel/FilterKeyValue';
import { CustomPermitDashboardSettings } from '../ViewModel/PermitBuilder/CustomPermitDashboardSettings';
import { Subject } from 'rxjs';
import { LinkedPermitViewModel, ManualPermit } from '../ViewModel/LinkedPermitViewModel';
import { LinkedPermits } from '../ViewModel/LinkedPermits';
import { truncate } from 'fs';
import { error } from 'console';
import { AddEntityAsset } from '../ViewModel/DASModels/addEntityAssetModel';
import { Entity } from '../ViewModel/DASModels/EntityModel';
import { EntityTag } from '../ViewModel/DASModels/EntityTagModel';
import { EntityAsset } from '../ViewModel/DASModels/EntityAssetModel';
import { EntityAssetTag } from '../ViewModel/DASModels/EntityAssetTagModel';
import { FileUpload } from 'primeng/fileupload';

declare var jspdf: any;

@Component({
  selector: 'app-permit',
  templateUrl: './permit.component.html',
  styleUrls: ['./permit.component.css'],
  providers: [DatePipe, IndexedDBService]
})
export class PermitComponent implements OnInit, OnChanges {
 
  @ViewChild('fileUploader') fileUploader!: FileUpload;
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.userHierarchy = changes.selectedHierarchy.currentValue;
  }

  constructor(private _location: Location, private _commonService: CommonService, private _spinner: SpinnerVisibilityService,
    private _permitService: PermitService, private translate: TranslateService, private _permitControlService: PermitControlService,
    private _messageService: MessageService, private _confirmationService: ConfirmationService, private route: ActivatedRoute, private _router: Router,
    private _permitTypeService: PermitTypeService, private _permitTypeVersionService: PermitTypeVersionService,
    private _dynamicDataSourceService: DynamicDataSourceService,
    private _mediaService: MediaService, private _userService: UserService,
    private _helperService: HelperService, private _deviceDetetorService: DeviceDetectorService,
    private sanitizer: DomSanitizer, private _roleService: RoleService,
    private _datePipe: DatePipe, private _revalidateService: RevalidateService, private _geotagService: GeoTagService,
    private _categoryService: CategoryService, private _indexDbService: IndexedDBService, private _SruService: SRUService,
    private _notificationService: NotificationService, private _changeLogService: ChangelogService,
    private primengConfig: PrimeNGConfig,) {
    this.setLangugae();
    this.globalFilterUpdate.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.filterOnGlobalFilter(this.lazyLoadFilter);
      });
     }

  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild('dt') table: Table;
  //roleDisplayedOverCommentBox:string="";
  _userSiteId: number = 0;
  rejFlag: boolean = false;
  roleDisplayedOverCommentBoxUpdated: string = "";
  globalFilterUpdate = new Subject<string>();
  @ViewChild('NonCSEsearchInput') NonCSEsearchInput: ElementRef;
  @ViewChild('WorkOrdersearchInput') WorkOrdersearchInput: ElementRef;
  // @ViewChild('OPSeqSearchInput') OPSeqSearchInput: ElementRef;
  public selectedRoleSecurityName;
  public showdownloadmodal = false;
  public showError = false;
  public zipcontentURL: any = [];
  WorkArea:string="";
  _changeLog: ChangeLog[] = [];
  displayChangeLog: boolean = false;
  isLMSIntegrationEnabled : boolean = false;
  public selectedvalue = '';
  clsBorderColor: string = "#0a0a0a40";
  // clsBorderColor: string = "none";   dbdbdb
  permitContainerHeight: number;
  displayPreview: boolean = false;
  _latestCustomDashboardList: CustomPermitDashboardSettings[];
  _permitsList: SecurityDashboardViewModel[] = []
  isSinglePermitType: boolean = false;
  _linkPermit :boolean=false;
  normalpermitLinking: boolean=false;
  _PermitLink :LinkedPermits;
  FilteredPermits: SecurityDashboardViewModel[] = [];
  PermitsToLink: LinkedPermitViewModel;
  displayuserforrole: boolean = false
  permits: PermitTypeVersion[] = [];
  permit: PermitTypeVersion;
  au: any;
  statusIds: number[] = [];
  selectedPermitTypeId: number;
  showChildPermits: boolean = false;
  _manualPermits: ManualPermitType[]=[];
  lastSectionName: string = '';
  showFilter: boolean = false;
  isFirstLoad: boolean = true;
  _multiplePermitsForPrint: Permit[] = [];
  _multiplePermitsForPrintIDs: Number[] = [];
  _permitTypeIdsFilterMasterData: FilterKeyValue[] = [];
  _permitAreaIdsFilterMasterData: FilterKeyValue[] = [];
  _permitStateIdsFilterMasterData: FilterKeyValue[] = [];

  _permitIdentifierIdsFilter1_MasterData: PermitIdentifier[] = [];
  _permitIdentifierIdsFilter2_MasterData: PermitIdentifier[] = [];
  _permitIdentifierIdsFilter3_MasterData: PermitIdentifier[] = [];
  _permitIdentifierIdsFilter4_MasterData: PermitIdentifier[] = [];
  _permitIdentifierIdsFilter5_MasterData: PermitIdentifier[] = [];
  _permitIdentifierIdsFilter6_MasterData: PermitIdentifier[] = [];
  _permitIdentifierIdsFilter7_MasterData: PermitIdentifier[] = [];

  _selectedPermitTypeFilter: FilterKeyValue[];
  _selectedPermitAreaFilter: FilterKeyValue[];
  _selectedPermitStateFilter: FilterKeyValue[];

  _selectedPermitIdentifier1_Filter: PermitIdentifier[];
  _selectedPermitIdentifier2_Filter: PermitIdentifier[];
  _selectedPermitIdentifier3_Filter: PermitIdentifier[];
  _selectedPermitIdentifier4_Filter: PermitIdentifier[];
  _selectedPermitIdentifier5_Filter: PermitIdentifier[];
  _selectedPermitIdentifier6_Filter: PermitIdentifier[];
  _selectedPermitIdentifier7_Filter: PermitIdentifier[];

  _selectedDateRangeFilter: Date[];

  lazyloadFirst?: number;
  lazyloadRow?: number;
  lazyLoadFilter?: any;
  revalidateCount = 0;
  _addEditUserRoleObj = new UserShortInfo();
  assetDetails: any[] = [];
  _EAMAssetDetails: any[] = [];
  _EAMNonCSEAssetDetails: any[] = [];
  _EAMWorkOrderDetails: any[] = [];
  _EAMOPSeqDetails: any[] = [];
  workOrderArray: string[] = [];
  selectedWorkOrderDesc: string = "";
  selectedOPDesc: string = "";
  oPNumberList: string[] = [];
  oPDescList: string[] = [];
  _displayLinkPermitContinueaction :boolean=false;
  ApprovalPopupRequired :boolean=false;
  _first = 1;
  _last = 20;
  _isMoreRecordExist: boolean = false;
  workOrderGlobalFilter: string = null;
  _EAMProcDetails: any[] = [];
  EAMassetListFlag: boolean = false;
  EAMNonCSEassetListFlag: boolean = false;
  EAMWorkOrderFlag: boolean = false;
  EAMOPListFlag: boolean = false;
  lstboxAssetDetails = [];
  tempAssetDetails: any[] = [];
  entryLeaders;
  selectedUserShortInfo: string[] = [];
  rows: any[];
  w_rows: any[];
  OP_rows: any[];
  _sectionId: any[] = [];
  usershortinfoforroles: any[] = [];
  _PermitVersion: PermitTypeVersion;
  _PermitVersionForUser: PermitTypeVersion;
  _PermitVersionList: PermitTypeVersion[] = [];
  _permitTypeName: string
  _divisions: PermitTypeDivision[] = []
  _sections: PermitTypeSection[] = []
  _questions: PermitTypeQuestion[] = []
  _answerOptions: PermitTypeAnswerOption[] = []
  _permitObj: Permit = new Permit();
  _question: PermitTypeQuestion;
  _subQuestionArray: PermitTypeSubQuestion[] = []
  _userbutton: boolean = false;
  ddlUserForRole: number = 0
  _tableTypeResponse: TableTypeResponse[] = []
  _tableTypeSubQuestionResponse: SubQuesTableTypeResponse[] = []
  _permitTypeAbbr: string = ''
  _screenGrab: any;
  _showImage: boolean = false;
  _permitTypeId: number;
  _permitId: number;
  _UserForRole: UserForRoleGroupViewModel[] = [];
  _permitTypeSiteAbbr: string = ''
  _permitNumber: string = ''
  _permitType: PermitTypeViewModel;
  _permitTypeBUName: string;
  _permitTypeRegionName: string;
  _permitTypeSubBUName: string;
  _permitTypeSiteName: string;
  _permitTypeSubSiteName: string;
  _permitTypeSRUName: string;
  _permitTypeDeptName: string;
  _permitTypeCrewName: string;
  _permitBackColor: string
  _permitForeColor: string
  _permitState: PermitState
  _userButton: boolean = false;
  _userForBind: UserForSectionViewModel[] = []
  _userForApprover: UserForSectionViewModel[] = []
  totalHeight: number = 0;
  sub: any
  counter: number = 0;
  checkRevalidation: boolean = false;
  _currentLoggedInUser: User;
  _currentLoggedInUserRoles: any[] = [];
  _roles: Number[] = [];
  defaultValues: boolean = true; // to disable the first four textboxes in the form
  defaultValueIssuedDateTime: boolean = true;
  permitIssuedDateTime: Date;
  permitIssuedDateTimeStr: string;
  permitExpiryDateTime: Date;
  permitExpiryDateTimeStr: string;
  minDateValue: Date = new Date();
  permitDateTimeEditable: boolean = false;
  isPDFFlag: boolean = false;
  isPDFFlagRow: boolean = false;
  isEnabled: boolean = false;
  selectedDropdownText: string;
  selectedDropdownText1: string;
  selectedDropdownText2: string;
  permitTypeImage: any;
  revalidationHours: number = 12
  revalidationPeriod: string = ''
  displayStatusPopUp: boolean = false;
  permitApprovalStatus: any;
  assetListFlag: boolean = false;
  procListFlag: boolean = false;
  assetDescFlag: boolean = false;
  entryLeaderFlag: boolean = false;
  HasPermitNoTextChanged: boolean=false;
  HasPermitIssueDateTimeText: boolean=false;
  IsPermitIssueDateEnabled : boolean = true;
  IsPermitExpireDateEnabled : boolean = true;
  HasPermitExpireDateTimeText : boolean= false;
  HasSiteTextChanged : boolean=false;
  HasAreaTextChanged : boolean=false;
  HasRequestorNameTextChanged :boolean=false;
  rowCount: number;
  selectedAsset;
  selectedAssetID;
  selectedAssetDesc;
  selectedEntryLeader;
  allRoles: Role[] = [];
  _category: Category[] = [];
  _categoryIds: number[] = [];
  _objArrPermitTypeIds: number[] = []
  allRolesFiltered: Role[] = [];
  permitCreatedByUser: PermitCreatedByUserViewModel;
  userProfilePicture: any;
  showStatus: boolean = true;
  previewFlag: string = "0";
  _procRefVal: string;
  _riskAssesmentVal: string;
  _procFlag: boolean = false;
  _riskFlag: boolean = false;
  first: number = 0;
  workOrder_first: number = 0;
  OP_first: number = 0;
  allSelected = false;
  searchText;
  issuedTime;
  permitNos;
  ModifyDate;
  SruId; 
  SiteId;
  RaisedBy;
  Area;
  workOrder;
  field_1;
  field_2;
  field_3;
  field_4;
  field_5;
  field_6;
  field_7;
  filter_1 = [];
  filter_2 = [];
  filter_3 = [];
  filter_4 = [];
  filter_5 = [];
  filter_6 = [];
  filter_7 = [];
  _displayContinueaction = false;

  _toggleFilter: boolean = false;
  _gridHeightPX: string = '300px';
  
  _gridRowCount: number = 8;
  _permitno = [];
  perArr = [];
  _issuetime = [];
  _raiseby = [];
  _siteid = [];
  _sruid = [];
  _modify = [];
  _area = [];
  _workorder = [];
  dynamicField_1 = [];
  dynamicField_2 = [];
  dynamicField_3 = [];
  dynamicField_4 = [];
  dynamicField_5 = [];
  dynamicField_6 = [];
  dynamicField_7 = [];

  DynamicQus_1: string;
  DynamicQus_2: string;
  DynamicQus_3: string;
  DynamicQus_4: string;
  DynamicQus_5: string;
  DynamicQus_6: string;
  DynamicQus_7: string;

  IdentifierQId_1: number;
  IdentifierQId_2: number;
  IdentifierQId_3: number;
  IdentifierQId_4: number;
  IdentifierQId_5: number;
  IdentifierQId_6: number;
  IdentifierQId_7: number;

  IdentifierQRef_1: string;
  IdentifierQRef_2: string;
  IdentifierQRef_3: string;
  IdentifierQRef_4: string;
  IdentifierQRef_5: string;
  IdentifierQRef_6: string;
  IdentifierQRef_7: string;



  _permitIdentifierIdsFilter1: any[] = [];
  _permitIdentifierIdsFilter2: any[] = [];
  _permitIdentifierIdsFilter3: any[] = [];
  _permitIdentifierIdsFilter4: any[] = [];
  _permitIdentifierIdsFilter5: any[] = [];
  _permitIdentifierIdsFilter6: any[] = [];
  _permitIdentifierIdsFilter7: any[] = [];


  _allDynamicDatasourceOptions: any[] = [];
  _allDynamicDatasourceOptionSubQ: any[] = [];
  _permitActivityLog: PermitActivityLog[] = [];
  _activeQuestions: number[] = [];
  _sequenceList: any[];
  _activeSequence: any[];

  _canCopyPermit: boolean;
  _copyPermit: boolean = false;
  _displayPermitSelectPopUp: boolean = false;
  _selectedPermitId: number;
  popUpHeader: string;
  Action: string = this.translate.instant('Permit.Action');
  popuplabel: string;
  _permits: Permit[] = [];
  _permitsFilterTabledata: Permit[] = [];
  _permitIdentifiers: PermitIdentifier[] = [];
  AllPermit: Permit[] = [];
  AllPermitList: Permit[] = [];
  filteredPermits: string[] = [];
  _permitNumbers: string[] = [];
  _issueTime: string[] = [];
  _selectedPermit: Permit;
  _clonedPermitObj: Permit;
  _resetFlag: boolean = false;
  _showApproverDetail: boolean = false;
  _approverUserDetail: any;
  isEntryLeader: boolean = false;
  _EntryLeaderName: string;
  takeOverMessage: string;
  takeOverHeader: string;
  isPermitActive: boolean = false;//false
  hasEntryLeaderQuestion: boolean = false;//false
  isConfinedSpace: boolean = false;
  entryLeaderName: string = "";
  _permitTypeLevelApprovalList: PermitTypeLevelApproval[] = [];
  _activePermitLevelSequence: number;
  displayMap: boolean;
  geoTag: boolean = false;
  permitTypeAssetFlag: boolean = false;
  // assetWorkOrderList: AssetWorkOrderViewModel = new AssetWorkOrderViewModel();
  assetWorkOrderList: AssetWorkOrderViewModel[] = [];
  _PermitVersionPdf: PermitTypeVersion;
  _printableSections: string[] = [];
  _pritableSectionTreeNode: TreeNode[] = [];
  _selectedSectionToPrint: TreeNode[] = [];
  _sectionToPrint: string[] = [];
  _permitPdf: Permit;
  permitCreatedByUserPdf: PermitCreatedByUserViewModel;
  private _ObjArrResponseAttachment: ResponseAttachment[] = []
  private _ObjArrSubQuestionResponseAttachment: SubQuesResponseAttachment[] = []
  responseAttachedFiles: any;
  subQuestionResponseAttachedFiles: any;
  responseAttachments: any[] = [];
  subQuesResponseAttachments: any[] = [];
  responseAttachmentFiles: any[] = [];
  subQuesResponseAttachmentFiles: any[] = [];
  uploadedFiles: any[] = [];
  commentBoxIsVisible: boolean = false;

  _displayCommentPopUp: boolean = false; // This is for entering comments while approving the permit
  _commentFlag: number;
  _approverComments: string = null;
  _commentsCount: number = 0;
  _roleForUser: RoleGroupApproval[] = [];
  _canBeRevalidated: boolean = false
  _maxOccursExhausted: boolean = false
  revisionCounter: number = 0;
  _permitControl: PermitControl = new PermitControl();
  count = 0;
  assetlistingTable: PermitAssetAndWorkOrder[] = [];

  finalAttachmentList: any[] = []
  finalSubQuestionAttachmentList: any[] = []
  
  _docxContentType: string = ''
  _docContentType: string = ''
  _xlsxContentType: string = ''
  _xlsContentType: string = ''
  _pdfContentType: string = ''
  _pptxContentType: string = ''
  _pptContentType: string = ''
  screengrab: any;

  isMailRequired: boolean = false;
  showPermitWithoutOperations: boolean = false;
  _excelThumbnailIconPath: string = '../../assets/Images/excel-icon.png'
  _pdfThumbnailIconPath: string = '../../assets/Images/pdf-icon.png'
  _imageThumbnailIconPath: string = '../../assets/Images/image-icon.png'
  _wordThumbnailIconPath: string = '../../assets/Images/word-icon.png'
  _pptThumbnailIconPath: string = '../../assets/Images/ppt-icon.png'
  _allowedFileTypes: string = ''
  _maxAttachmentFileSize: number

  _permitWorkflowViewModel: PermitWorkflowViewModel;

  // event capturing
  _buttonSaveClicked: boolean = false;
  _buttonSubmitClicked: boolean = false;
  _buttonApproveClicked: boolean = false;
  _buttonRejectClicked: boolean = false;
  _buttonUserApproverClicked: boolean = false;
  loading: boolean = true;
  w_loading: boolean = true;
  OP_loading: boolean = true;

  _divisionIndex: number = 0;
  _sectionIndex: number = 0;
  _questionIndex: number = 0;
  _mailDistributionRequired: boolean = false;
  ddlArea: number = 0;
  _txtWorkOrder = '';
  _txtAsset = '';
  _txtOP = '';
  ismandatoryFieldValidated: boolean = true;
  @ViewChild('ChildOPComponent') childOPSelection: SelectOperationComponent;
  @ViewChild('pdfExport') pdfExport;
  @ViewChild('pdfSelectedExport') pdfSelectedExport;
  @ViewChild('childPrint') childPDFComponent: PrintPdfSelectedComponent;
  @ViewChild('ChildAssetComponent') childAssetTree: AssetTreeComponent;
  @ViewChild('ChildAssetComponentResponse') childAssetTreeResponse: AssetTreeComponent;
  @ViewChild('ChildWOComponent') childWOSelection: SelectWorkOrderComponent;

  _allSectionNames: string[] = [];
  

  // LMS training check
  _trainingNotPresentForRole: RoleTrainingStateViewModel[] = [];
  _roleTrainingStateList: RoleTrainingStateViewModel[] = [];
  _displayLMSErrorForUser: boolean = false;
  _isRevalidate: boolean = false;
  previousEntryleader: string;
  entryLeaderRole: number[] = [];

  _db: IDBDatabase;

  _roleGroupApprover: RoleGroupApproval[] = [];
  _submitButtonClicked: boolean = false;
  isRevalidateBeforeLapse: boolean = false;
  isBtnEntryLeaderVisible: boolean = false;
  _lineColor_1: string = "#f8f8f8";
  _sru: SRU[] = [];
  _filteredSru: SRU[] = [];
  editorsworkFlow: any;
  copyPermitNumber: string = '';
  permitSelected: string = '';
  permitClosedBy: string = ''
  notificationCount: string = '0';
  isAssetReadyToLazyLoad: boolean = false;
  workorderFlag: boolean = false;
  nonCSEAssetPopUp: boolean = false;
  nonCSEWOPopUp: boolean = false;
  selectAssetWOItems: MenuItem[];
  selectWOOperationActiveIndex: number = 1;
  selectedAssetForWOSelection: string = '';
  _selectedWorkOrderPop: any[] = [];
  goToOperationClicked: boolean = false;
  copyPermitPopupClicked: boolean = false;
  rowsCount: number = 20;
  totalLength: number = 5;
  totalPermitCount: number = 0;
  _displayOperationPopup: boolean = false;
  operationSequence: PermitOperationSequence[] = [];
  isAssetSelected: boolean = false;
  selectedAssetList: string = '';
  isAssetChecked: boolean;
  assetDataList: any[] = [];
  selectAllOperationClicked: boolean = false;
  _selectedOperations: any[] = [];
  _selectedWoNumber: string[] = [];
  _selectedWorkOrder: any[] = [];
  _assetCheckboxDetails: any[] = [];
  selectedNode: TreeNode[];
  selectedNodeArr: TreeNode[] = [];
  _selectedSectionToBePrint: TreeNode[] = [];

  hierarchyTreeObj: HierarchyTree;
  _isChangeLog: boolean = true;
  
  permitDrpTitle: string = this.translate.instant('Security.Choose');
  issueDrpTitle: string = this.translate.instant('Security.Choose');
  raisedByDrpTitle: string = this.translate.instant('Security.Choose');
  SRUDrpTitle: string = this.translate.instant('Security.Choose');
  raisedBydropDownTitle: string = this.translate.instant('Security.Choose');
  DynamicQus_1DrpTitle: string = this.translate.instant('Security.Choose');
  DynamicQus_2DrpTitle: string = this.translate.instant('Security.Choose');
  DynamicQus_3DrpTitle: string = this.translate.instant('Security.Choose');
  DynamicQus_4DrpTitle: string = this.translate.instant('Security.Choose');
  DynamicQus_5DrpTitle: string = this.translate.instant('Security.Choose');
  DynamicQus_6DrpTitle: string = this.translate.instant('Security.Choose');
  DynamicQus_7DrpTitle: string = this.translate.instant('Security.Choose');
  DynamicQus_8DrpTitle: string = this.translate.instant('Security.Choose');

  //#region for all variables used in the Upload of Manual Permit PDF
  UploadPermitValue: boolean = false;
  PermitPDF: ManualPermitType[]=[];
  _selectedManualPermitTypeFilter: FilterKeyValue= new FilterKeyValue();
  _selectedManualPermitStateFilter: FilterKeyValue = new FilterKeyValue();
  _permitTypeIdsFilterManualMasterData: FilterKeyValue[] = [];
  _permitStateIdsFilterManualMasterData: FilterKeyValue[] = [];
  _selectedManualDateRangeFilter: Date = new Date();
  inputValue: string ='';
  entitySourceId: any;
  source:string;
  entityType:string;
  addEntityAsset: AddEntityAsset;
  entityTags: EntityTag[] = [];
  entityId: number;
  newEntityTag: string[] = [];
  appRegion: any;
  entityAssetId: number;
  OrginalUrl: string;
  site: string;
  manualUploadData = new ManualPermitType();
  editableManualPermitId: number = 0;
  //#endregion

  get userHierarchy(): HierarchyTree {
    return this._commonService.getUserHierarchy();
  }

  set userHierarchy(value: HierarchyTree) {
    this._commonService.setUserHierarchy(value);
  }


  RedirectToCreatePermit()
  {
    localStorage.removeItem("MasterpermitURL");
    localStorage.setItem("MasterpermitURL",
      '/'+this.route.snapshot.url[0].path +'/'+ this.route.snapshot.url[1].path + '/'+
      this.route.snapshot.url[2].path );
    let redirectURL = '/createpermit';
    localStorage.removeItem("AutoLinkMasterPermitNumber");
    localStorage.setItem("AutoLinkMasterPermitNumber",this.route.snapshot.url[2].path);
    this._router.navigate([redirectURL]);
  }

  GoBack() {
    let redirectURL = '/createpermit';
    
    let sourceUrl = localStorage.getItem('SourceURL') ? localStorage.getItem('SourceURL').toString() : '';
    if (sourceUrl == 'dashboard') {
      redirectURL = '/permitmanagement';
      this._router.navigate([redirectURL]);
    }
    else {
      this._router.navigate([redirectURL]);
    }
    //this._location.back();
  }

  ngOnInit() {
  
    // this.permitContainerHeight = window.innerHeight - 273;
    // if (document.getElementById("navbar")) {
    //   document.getElementById("navbar").setAttribute("hidden", "true");
    // }
    // if (document.getElementById("navbar-menu")) {
    //   document.getElementById("navbar-menu").setAttribute("hidden", "true");
    // }
    
    this.rowCount = 20;
    if (document.getElementById("page")) {
      document.getElementById("page").style.padding = "0px 15px 0px 0px";
    }
    this._EntryLeaderName = localStorage.getItem("_userName");
    this.permitContainerHeight = window.innerHeight - 179;
    this._allowedFileTypes = environment.ALLOWEDFILETYPES;
    this._maxAttachmentFileSize = environment.MAXIMUMATTACHMENTFILESIZE;
    this._docxContentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    this._docContentType = 'application/msword';
    this._xlsxContentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    this._xlsContentType = 'application/vnd.ms-excel';
    this._pdfContentType = 'application/pdf';
    this._pptxContentType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
    this._pptContentType = 'application/vnd.ms-powerpoint';
    this.getPermitTypeIdFromURL();
    this.GetAllPermitStatus();
    this.getSruBasedOnUserHirearchy();

    this.InitializeDb();
    this.selectAssetWOItems = [
      {
        label: 'Work Order',
        command: (event: any) => {
          this.selectWOOperationActiveIndex = 1;
        }
      },
      {
        label: 'Operation',
        command: (event: any) => {
          this.selectWOOperationActiveIndex = 2;
        }
      }

    ];
  }


  filterGrid(isMasterPermit) {
    var isAllPermits: boolean=true;
    if(isMasterPermit==true || this.showChildPermits)
    {
      isAllPermits=false;
    }
    this.lazyloadFirst=0;
    let startIndex = this.lazyloadFirst;
    let endIndex = this.lazyloadRow > 0 ? this.lazyloadRow : this.rowCount;
    if (!this.userHierarchy.SiteId || !this.userHierarchy) {
      this._permitsList = [];
      this.FilteredPermits = [];
      this.loading = false;
      this._messageService.clear();
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
      return;
    }
    
      this.GetAllLinkedPermitData(startIndex, endIndex, null, this.lazyLoadFilter, false, isAllPermits,isMasterPermit,this.showChildPermits);
    
    this.first = 1;
    
  }


  areAllPermitsSelected() {
    let filteredSet = new Set(this._permitsList.map(i => i.PermitId));
    let selectedSet = new Set(this._multiplePermitsForPrintIDs);
    let hasCount = 0;
    filteredSet.forEach((el) => {
      if (selectedSet.has(el))
        hasCount += 1;
    });
    if (hasCount == this._permitsList.length)
      this.allSelected = true;
    else
      this.allSelected = false;
  }

  toggleAllPermits(_permitsList) {
    if (this.allSelected == true) {
      _permitsList.forEach(element => {
        if (this._multiplePermitsForPrint.map(i => i.PermitId).includes(element.PermitId))
          this._multiplePermitsForPrint = this._multiplePermitsForPrint.filter(x => x.PermitId != element.PermitId);
      });
    }
    else {
      _permitsList.forEach(element => {
        if (!this._multiplePermitsForPrint.map(i => i.PermitId).includes(element.PermitId))
          this._multiplePermitsForPrint.push(element);
      });
    }
    this._multiplePermitsForPrintIDs = this._multiplePermitsForPrint.map(i => i.PermitId);
    this.areAllPermitsSelected();
  }

  setLangugae() {
    // this.calendar.firstDayOfWeek = 1;
    this.setCalender();
    this.primengConfig.setTranslation(this.au);
  }

  setCalender() {
    // Set Calendar's Month, Day
    let language = parseInt(localStorage.getItem("_languageID"));
    switch (language) {
      case 1: {
        this.au = {
          firstDayOfWeek: 1,
          dayNames: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday, Saturday',
          ],
          dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          monthNamesShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          today: 'Today',
          weekHeader: 'Wk',
        };
        break;
      }
      case 3: {
        //statements;
        this.au = {
          firstDayOfWeek: 0,
          dayNames: [
            'Dimanche',
            'Lundi',
            'Mardi',
            'Mercredi',
            'Jeudi',
            'Vendredi',
            'Samedi',
          ],
          dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
          dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
          monthNames: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre',
          ],
          monthNamesShort: [
            'Jan',
            'Fév',
            'Mar',
            'Avr',
            'Mai',
            'Jui',
            'Jul',
            'Aoû',
            'Sep',
            'Oct',
            'Nov',
            'Déc',
          ],
          today: "Aujourd'hui",
          clear: 'Vider',
          dateFormat: 'dd/M/yy',
          weekHeader: 'Semaine',
        };


        break;
      }
      default: {
        this.au = {
          firstDayOfWeek: 1,
          dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          today: "Today",
          clear: "Clear"
        };
        break;
      }
    }
  }

  ngOnDestroy() {
    // if (document.getElementById("navbar")) {
    //   document.getElementById("navbar").removeAttribute("hidden");
    // }
    // if (document.getElementById("navbar-menu")) {
    //   document.getElementById("navbar-menu").removeAttribute("hidden");
    // }
    if (document.getElementById("page")) {
      document.getElementById("page").style.padding = "7px 15px 0px 10px";
    }
  }
  getPermitTypeIdFromURL() {
    this.sub = this.route.params.subscribe(params => {

      this._permitTypeId = +params['permitTypeId'];
      this._permitId = +params['permitId'];
      if (this._permitId == 0) {
        sessionStorage.removeItem("mapJson");
        sessionStorage.removeItem("screengrab");
      }
      this.GetUserDetails();      
    });
  }

  getSruBasedOnUserHirearchy() {    
    this._SruService.getAllSru().subscribe(
      (data: SRU[]) => {
        this._sru = [];
        this._filteredSru = [];
        this._sru = data;
        if (this.userHierarchy) {
          this._filteredSru = this._sru.filter(x => x.BUId == this.userHierarchy.BuId
            && x.RegionId == this.userHierarchy.RegionId && x.SubBUId == this.userHierarchy.SubBuId && x.SiteId == this.userHierarchy.SiteId);

        }
        return this._filteredSru;
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('errorGettingSRU') });
      });


  }

  loadSecurityData(event: LazyLoadEvent) {
    this.lazyloadFirst = event.first;
    this.lazyloadRow = event.rows;
    this.lazyLoadFilter = event.globalFilter;

    let pageNumber: number = 0;
    let rowsCount: number = 0;
    if (event.first == 0) {
      pageNumber = 0;
    }
    else {
      pageNumber = Math.round((event.first / event.rows));
    }
    rowsCount = event.rows;

    if (!this.userHierarchy.SiteId || !this.userHierarchy) {
      this._permitsList = [];
      this.FilteredPermits = [];
      this.loading = false;
      this._messageService.clear();
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
      return;
    }

    
      //this.GetAllLinkedPermitData(pageNumber, rowsCount, null, event.globalFilter,this.normalpermitLinking, false,false);
      this.GetAllLinkedPermitData(pageNumber,rowsCount,null,null,false,false,this.normalpermitLinking,false,false);
    
  }

   GetAllPermitStatus()
   {
     this._permitService.getAllPermitStatus().subscribe(
      data=>{
        
          data.forEach(item => {
            if(item.PermitStateName != 'Closed' 
              && item.PermitStateName != 'Rejected' && item.PermitStateName != 'Cancelled' 
              && item.PermitStateName != 'Suspended' && item.PermitStateName != 'Permitclose'
              && item.PermitStateName != 'Lapsed')
            {
              this.statusIds.push(item.PermitStateId);
            }
          });
        
    });
   }

   GetAllLinkedPermitData(startIndex, endIndex, loginId, globalFilter, isMyAction, isAllPermits,isMasterPermit, isChildPermit=false, allMasterPermits=true) {    
    
    let typeIds: number[] = [];
    let statusIds: number[] = this.statusIds;
      
    let areaIds: number[] = [];
    let dateRange: Date[] = [];

    //Just the declaration in place , in case future we want to filter by identifiers , we can use this
    let identifier1_Ids: string[] = [];
    let identifier1_Val: string[] = [];
    let identifier2_Ids: string[] = [];
    let identifier2_Val: string[] = [];
    let identifier3_Ids: string[] = [];
    let identifier3_Val: string[] = [];
    let identifier4_Ids: string[] = [];
    let identifier4_Val: string[] = [];
    let identifier5_Ids: string[] = [];
    let identifier5_Val: string[] = [];
    let identifier6_Ids: string[] = [];
    let identifier6_Val: string[] = [];
    let identifier7_Ids: string[] = [];
    let identifier7_Val: string[] = [];

    

    if (this._selectedPermitTypeFilter && this._selectedPermitTypeFilter.length > 0) {
      this._selectedPermitTypeFilter.forEach(element => {
        typeIds.push(element.Value);
      });
    }
    
    if (this._selectedPermitStateFilter && this._selectedPermitStateFilter.length > 0) {
      statusIds=[];
      this._selectedPermitStateFilter.forEach(element => {
        statusIds.push(element.Value);
      });
    }
    if (this._selectedDateRangeFilter && this._selectedDateRangeFilter.length > 0) {
      let startDate = this._selectedDateRangeFilter[0];
      let endDate = this._selectedDateRangeFilter[0];
      if (this._selectedDateRangeFilter.length > 1 && this._selectedDateRangeFilter[1]) {
        endDate = this._selectedDateRangeFilter[1];
      }
      endDate = new Date(endDate);
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);
      dateRange.push(startDate);
      dateRange.push(endDate);
    }

    
    

    if (this.userHierarchy && this.userHierarchy.SiteId) {
      this._permitService.getPermitDetails(this.userHierarchy, startIndex, endIndex, loginId, globalFilter, isMyAction, isAllPermits, isMasterPermit, isChildPermit, this._permitObj.PermitId, allMasterPermits, typeIds, statusIds, areaIds, dateRange, 
        identifier1_Ids, identifier1_Val, identifier2_Ids, identifier2_Val, identifier3_Ids, identifier3_Val, identifier4_Ids, identifier4_Val, identifier5_Ids, identifier5_Val, identifier6_Ids, identifier6_Val, identifier7_Ids, identifier7_Val).subscribe(securityDashboardObject => {        
        let data: SecurityDashboardRootObject;
        if (securityDashboardObject.status == 200) {
          data = securityDashboardObject.body;
          if (data.SecurityData && data.SecurityData.length > 0) {
            data.SecurityData.forEach(element => {
              element.PermitIssuedDateTime = this.adjustForTimezone(element.PermitIssuedDateTime);
              element.PermitExpiryDateTime = this.adjustForTimezone(element.PermitExpiryDateTime);
            });
          }

          if (this.isFirstLoad) {
            this._permitTypeIdsFilterMasterData = data.FilterData ? data.FilterData.PermitType : [];
            this._permitAreaIdsFilterMasterData = data.FilterData ? data.FilterData.PermitArea : [];
            this._permitStateIdsFilterMasterData = data.FilterData ? data.FilterData.PermitState : [];
            this.isFirstLoad = false;
            this._permitTypeIdsFilterManualMasterData = data.FilterData ? data.FilterData.PermitType : [];
            this._permitStateIdsFilterManualMasterData = data.FilterData ? data.FilterData.PermitState : [];
          }

          this._permitsList = data.SecurityData;
          this.FilteredPermits = data.SecurityData;
          
          this.areAllPermitsSelected();
          this.totalLength = data.TotalLength;
          
          this.loading = false;
          this.getClosedByStatus(data.SecurityData);

          this.RefreshManualPermits();

          if(this.normalpermitLinking==false)
          {
          this._permitService.getManualPermits(this._permitObj.PermitId).subscribe(
            data=>{
              data.forEach(element => {
                element.IssueDate = this.adjustForTimezone(element.IssueDate);
              });
              this._manualPermits = data;
          });

        }
        }
      });
    }

    
  }

  getClosedByStatus(SecurityData: SecurityDashboardViewModel[]) {
    let permitIds = '';
    if (SecurityData && SecurityData.length > 0) {
      SecurityData.forEach(element => {
        permitIds = permitIds + element.PermitId.toString() + ',';
      });
    }
    if (permitIds != '') {
      let languageId = +localStorage.getItem("_languageID");
      this._permitService.getDashboardClosedByRole(permitIds, languageId).subscribe((data: PermitAdditionalData[]) => {
        if (data && data.length > 0) {
          data.forEach(element => {
            this.FilteredPermits.forEach(fp => {
              if (fp.PermitId == element.PermitId) {
                fp.PendingOn = element.PendingOn;
                if (element.AssetName != null) {
                  let asset_array = element.AssetName.split("@@@")
                  fp.AssetName = asset_array[0];
                }
                else {
                  fp.AssetName = '';
                }
                fp.ClosedBy = element.ClosedBy;
              }
            });
          });
        }
      });
    }
  }
 

  

  displayDashboardCustomHeaders(data: SecurityDashboardRootObject) {
    this.isSinglePermitType = false;
    if (data.SecurityData && data.SecurityData.length > 0) {
      let filteredPermitTypeIds = data.SecurityData.map(item => { return item.PermitTypeId; });
      let distinctPermiTypeCount = filteredPermitTypeIds.map(item => item).filter((value, index, self) => self.indexOf(value) === index);
      if (distinctPermiTypeCount.length == 1) {
        this.isSinglePermitType = true;
        this.selectedPermitTypeId = filteredPermitTypeIds[0];
        this._permitTypeService.GetCustomizedPermitDashboardHeaders(this.selectedPermitTypeId).subscribe(
          (latestDashboardHeaders: CustomPermitDashboardSettings[]) => {
           // this.showFilterLoader();
            this._latestCustomDashboardList = latestDashboardHeaders;
            if (this.isSinglePermitType == true) {
              //this.bindDataForSinglePermitType(data);
            }
            //this.isActionFilterClicked = false;
          },
          error => {
            //this.isActionFilterClicked = false;
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('PermitType.errorGetDashboardSetting') });
          });
      }
      else {
        this.isSinglePermitType = false;
        //this.isActionFilterClicked = false;
      }
    }
  }
 
  ResetFilters()
  {
    this._selectedPermitStateFilter=[];
    this._selectedDateRangeFilter=[];
    this._selectedPermitTypeFilter=[];
  }

  ShowPermitsToLink()
  {
    this.ResetFilters();
    this.GetAllLinkedPermitData(0, this.rowCount, null, null, false, true,false);
    this._linkPermit=true;
    this.popUpHeader = this.translate.instant('Permit.LinkPermitHeader');
  }

    ShowMasterPermitsToLink(all=true)
  {
    this.ResetFilters();
    //this.GetAllLinkedPermitData(0, this.rowCount, null, null, false, false,false,all);
    this.normalpermitLinking=true;
    this.GetAllLinkedPermitData(0,this.rowCount,null,null,false,false,true,false,all);
    this._linkPermit=true;
    this.popUpHeader = this.translate.instant('Permit.LinkPermitHeader');
  }
  ShowChildPermits()
  {
    this.ResetFilters();
    this.showChildPermits = true;
    this.GetAllLinkedPermitData(0, this.rowCount, null, null, false, false,false,true);
    this._linkPermit=true;
    this.popUpHeader = this.translate.instant('Permit.ViewChildPermitHeader');
  }
  // User details is needed to get the roles of the Logged-In user
  GetUserDetails() {
    let userLoginId: string
    userLoginId = localStorage.getItem("_loginId");

    this._userService.getUserWithRoleBasedOnPermit(userLoginId, this._permitId).subscribe(
      (data: User) => {
        if (data != null) {
          this._userSiteId = data.SiteId
          // intentionally kept blank as we needed the else part only
          if (this._permitObj && this._permitObj.PermitId > 0) {
          }
          else {
            this.permitCreatedByUser = new PermitCreatedByUserViewModel();
            this.permitCreatedByUser.FullName = data.LastName.toUpperCase() + ', ' + data.FirstName.toUpperCase();
          }
          this._currentLoggedInUser = data;
          if (this._currentLoggedInUserRoles) {
            this._currentLoggedInUserRoles = this._currentLoggedInUser.UserRoles.filter(x => x.Active == true);
          }

          this._currentLoggedInUserRoles.forEach(x => {
            this._roles.push(x.Role.RoleId);
            if (x.Role.RoleKey == "ENTRYLEADER" && this.userHierarchy && this.userHierarchy.SiteId != null) {
              if (x.Region.RegionId == this.userHierarchy.RegionId && x.Site.SiteId == this.userHierarchy.SiteId) {
                this.entryLeaderRole.push(x.Role.RoleId);
                this.isEntryLeader = true;
              }
              else if (x.Region.RegionId == this.userHierarchy.RegionId && (x.Site.SiteId == null || x.Site.SiteId == 0)) {
                this.entryLeaderRole.push(x.Role.RoleId);
                this.isEntryLeader = true;
              }
            }
          });
        }
        this.getAllRoles();
        this.getCSECategory();
        // this.getUserwithclosedbyRoles();
      })

  }

  getAllRoles() {
    this._roleService.getAllRoles().subscribe((data: Role[]) => {
      if (data != undefined) {
        this.allRoles = data;
        this.allRolesFiltered = this.allRoles.filter(x => x.Active == true);        
        this.getPermitVersionDetails(this._permitTypeId);
        this.getLocalCalender();
      }
    })
  }
  getCSECategory() {    
    this._categoryService.searchCategory('CONFINED SPACE', null, null).subscribe(
      (data: Category[]) => {
        this._category = [];
        this._category = data;
        this._category.forEach(x => {
          this._categoryIds.push(x.Category.CategoryId);
        });
        if (this._categoryIds.length > 0) {
          this.getCSEPermitTypeIDs();
        }
      }
    );
  }
  getCSEPermitTypeIDs() {    
    let objHierarchySearchViewModel = new HierarchySearchViewModel();
    objHierarchySearchViewModel.CategoryIds = this._categoryIds;
    objHierarchySearchViewModel.BuId = this.userHierarchy.BuId;
    objHierarchySearchViewModel.RegionId = this.userHierarchy.RegionId;
    objHierarchySearchViewModel.SubBuId = this.userHierarchy.SubBuId;
    objHierarchySearchViewModel.SiteId = this.userHierarchy.SiteId;
    objHierarchySearchViewModel.SubSiteId = this.userHierarchy.SubSiteId;
    objHierarchySearchViewModel.SruId = this.userHierarchy.SruId;
    objHierarchySearchViewModel.DepartmentId = this.userHierarchy.DepartmentId;
    objHierarchySearchViewModel.CrewId = this.userHierarchy.CrewId;

    this._permitTypeService.getPublishedPermitTypes(objHierarchySearchViewModel).subscribe(
      (data: PermitTypeViewModel[]) => {        
        this._objArrPermitTypeIds = [];
        data.forEach(x => {
          this._objArrPermitTypeIds.push(x.PermitType.PermitTypeId);
        });
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.searchErrorPermit') });
      }
    );
  }

  displayLinkPermitContinueaction()
  {
    this._displayLinkPermitContinueaction=true;
  }

  LinkPermits()
  {
    this.PermitsToLink= new LinkedPermitViewModel();
    this.PermitsToLink.LinkedPermits=[];
    if(this.normalpermitLinking==true)
    {
      this._multiplePermitsForPrintIDs.forEach( x=>{
        this._PermitLink = new LinkedPermits();
        this._PermitLink.PermitId =x;
        this._PermitLink.ChildPermitId=this._permitId;
        this._PermitLink.Active=true;
        this._PermitLink.CreatedBy=this._currentLoggedInUser.LoginId;
        this._PermitLink.CreatedDate=new Date();
        this.PermitsToLink.LinkedPermits.push(this._PermitLink);
      });

    }
    else
    {
    this._multiplePermitsForPrintIDs.forEach( x=>{
      this._PermitLink = new LinkedPermits();
      this._PermitLink.PermitId = this._permitId;
      this._PermitLink.ChildPermitId=x;
      this._PermitLink.Active=true;
      this._PermitLink.CreatedBy=this._currentLoggedInUser.LoginId;
      this._PermitLink.CreatedDate=new Date();
      this.PermitsToLink.LinkedPermits.push(this._PermitLink);
    });
  }
    this._permitService.linkPermit(this.PermitsToLink).subscribe((data: HttpResponse<any>) => {
      if (data.status === 200) {
        this._displayLinkPermitContinueaction=false;
        this._linkPermit=false;
        if(this.normalpermitLinking==true)
          {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.LinkNormalPermitSuccess') });
          }
          else{
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.LinkSuccess') });
          }
       
      }
    }
    )
  }

  OLD_getPermitVersionDetails(permitTypeId) {
    
    this._permitTypeVersionService.getPermitTypeVersionWithActiveQuestions(permitTypeId, true).subscribe((permitTypeVersion: PermitTypeVersion[]) => {
      if (permitTypeVersion.length > 0) {
        this._PermitVersionList = permitTypeVersion;
        this._PermitVersion = new PermitTypeVersion();
        this._PermitVersion = permitTypeVersion.find(p => p.PublishStatus == true);
       

        this._permitTypeLevelApprovalList = [];
        this._permitTypeLevelApprovalList = this._PermitVersion.PermitType.PermitTypeLevelApprovals.filter(x => x.Active == true);
        this.setDefaultValues();

        this.getPermiTypeDetails(this._PermitVersion.PermitTypeId);



        
        if (this._permitId > 0) {
          this.finalAttachmentList = [];
          this.responseAttachedFiles = [];
          this.responseAttachmentFiles = []
          
        }
        else {
          this.AddRowsToTables();
        }
        this.rows = [];
        this.rows.push({ Prop: "Prop" });
        
      }
      else {
        this._messageService.clear('error');
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.NoVersionAvailable') });
      }

    });
  }

  getOptionValue(event) {
    event.target.parentElement.lastElementChild.innerHTML = event.target.options[event.target.options.selectedIndex].text;
  }

  getLocalCalender() {
    this.au = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',

      clear: 'Clear'
    }
  }

  Counter(value: number) {
    this.counter = this.counter + value;
    return true;
  }
  loadEAMAssetData(event) {
    if (this.isAssetReadyToLazyLoad) {
      let siteId = this._permitObj.SiteId ? this._permitObj.SiteId : this.userHierarchy.SiteId;
      if (event.first / 20 == 0) {
        event.first = 1;
      }
      else {
        event.first = (event.first / 20) + 1;
      }
      if (this._PermitVersion && this._PermitVersion.PermitType.CSEPermitType == true) {
        this._permitService.getAssetDetailsFromEAM(event.globalFilter, siteId, event.first, event.rows).subscribe(data => {
          this._EAMAssetDetails = data;
          
          this.loading = false;
        });
      }
      else {
        this._permitService.getNonCSEAssetDetailsFromEAM(event.globalFilter, siteId, event.first, event.rows).subscribe(data => {
          this._EAMNonCSEAssetDetails = data;
          this.loading = false;
        }, error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
        });
      }
    }
  }
  SelectAssetIDPermitType() {
    this.isAssetReadyToLazyLoad = true;
    this.searchInput.nativeElement.value = '';
    
    this.permitTypeAssetFlag = true;
    let siteId = this._permitObj.SiteId ? this._permitObj.SiteId : this.userHierarchy.SiteId;
    if (this._PermitVersion && this._PermitVersion.PermitType.CSEPermitType == true) {
      this._permitService.getAssetDetailsFromEAM(null, siteId, 1, 20).subscribe(data => {
        this._EAMAssetDetails = data;
        this.EAMassetListFlag = true;
        this.loading = false;
      });
    }
    else {
      this._permitService.getNonCSEAssetDetailsFromEAM(null, siteId, 1, 20).subscribe(
        data => {
          this._EAMNonCSEAssetDetails = data;
          this.EAMNonCSEassetListFlag = true;
          this.loading = false;
        }, error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
        });
    }
  }
  SelectAssetID(divisionIndex, sectionIndex, questionIndex) {
    this.isAssetReadyToLazyLoad = true;
    this.searchInput.nativeElement.value = '';
   
    let siteId = this._permitObj.SiteId ? this._permitObj.SiteId : this.userHierarchy.SiteId;
    if (this._PermitVersion && this._PermitVersion.PermitType.CSEPermitType == true) {
      this._permitService.getAssetDetailsFromEAM(null, siteId, 1, 20).subscribe(data => {
        this._EAMAssetDetails = data;
        this.EAMassetListFlag = true;
        this.loading = false;
      });
    }
    else {
            
      this.openNonCSEAssetPopUp(false);
    }
    this._divisionIndex = divisionIndex;
    this._sectionIndex = sectionIndex;
    this._questionIndex = questionIndex;
  }

  SelectProcID(assetNumber) {
    if (assetNumber != null || assetNumber != undefined || assetNumber != '') {
      let siteId = this._permitObj.SiteId ? this._permitObj.SiteId : this.userHierarchy.SiteId;

      this._permitService.getProcedureDetailsFromEAM(assetNumber, siteId).subscribe(data => {
        if (data.length > 0) {
          this._EAMProcDetails = data;
          this.procListFlag = true;
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ProcNotFound') });
        }
      });
    }
  }

  SelectWorkOrderID() {
    let siteId = this._permitObj.SiteId ? this._permitObj.SiteId : this.userHierarchy.SiteId;
    this.WorkOrdersearchInput.nativeElement.value = '';
    this._first = 1;
    this.workOrder_first = 0;
    this._last = 20;
    this._permitService.getWorkOrderFromEAM(null, null, siteId, 1, 20).subscribe(data => {
      this._EAMWorkOrderDetails = data;
      this.EAMWorkOrderFlag = true;
      this.w_loading = false;
      this._isMoreRecordExist = (data.MoreRecordExists == "Y")
    }, error => {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
    });
  }

  loadNextEAMWorkOrderData(val) {
    let siteId = this._permitObj.SiteId ? this._permitObj.SiteId : this.userHierarchy.SiteId;
    if (val == 2 && this._isMoreRecordExist) {

      this._first += 20;
      this._last += 20;
      this.workOrder_first = this._first;
    }
    else {
      if (this._first > 1) {
        this._first -= 20;
        this._last -= 20;
        this.workOrder_first = this._first;
      }
    }

    this._permitService.getWorkOrderFromEAM(this._txtAsset, this.workOrderGlobalFilter, siteId, this._first, this._last).subscribe(data => {
      this._EAMWorkOrderDetails = data;
      this.w_loading = false;
      
    }, error => {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
    });
  }

  loadEAMWorkOrderData(event) {
    let siteId = this._permitObj.SiteId ? this._permitObj.SiteId : this.userHierarchy.SiteId;
    if (event.first / 20 == 0) {
      event.first = 1;
    }
    else {
      event.first = (event.first / 20) + 1;
    }
    this.workOrderGlobalFilter = event.globalFilter;

    if ((event.globalFilter != null || event.globalFilter != "")) {
      if (event.globalFilter.length >= 3) {
        this._permitService.getWorkOrderFromEAM(null, event.globalFilter, siteId, event.first, event.rows).subscribe(data => {
          this._EAMWorkOrderDetails = data;
          this.w_loading = false;
          this._isMoreRecordExist = (data.MoreRecordExists == "Y")
        }, error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
        });
      }
    }
  }

  selectWorkOrder(value, description) {

    this._txtWorkOrder = value;
    this.EAMWorkOrderFlag = false;
    this.selectedWorkOrderDesc = description;

    this.workOrder_first = 0;
  }

  selectAsset(value, description) {
    if (this.permitTypeAssetFlag) {
      if (this._txtAsset != value) {
        this.assetlistingTable = []
      }
      this._txtAsset = value;
      this.EAMassetListFlag = false;
      this.EAMNonCSEassetListFlag = false;
      this.first = 0;
      this.permitTypeAssetFlag = false;
    }
    else {
      this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].FieldValue = value;
     
      this.EAMassetListFlag = false;
      this.EAMNonCSEassetListFlag = false;
      this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].FieldDesc = description;

      this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].ProcName = '';
      this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].ProcRef = '';
      this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].RiskAssesment = '';
      this.first = 0;
    }
  }

  selectProcedure(procName, procRef, riskAssesment) {
    this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].ProcName = procName;
    this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].ProcRef = procRef;
    this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].RiskAssesment = riskAssesment;
    this.procListFlag = false;
  }

  selectDesc(value, divisionIndex, sectionIndex, questionIndex) {
    this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].FieldDesc = value;
    this.assetDescFlag = false;
    document.getElementById("links").removeAttribute("disabled");
  }

  AddToTable() {
    let obj: PermitAssetAndWorkOrder = new PermitAssetAndWorkOrder()
    obj.PermitId = this._permitObj.PermitId;
    obj.AssetNo = this._txtAsset;
    obj.workOrderNo = this._txtWorkOrder
    obj.workOrderDesc = this.selectedWorkOrderDesc;
    obj.oPNumber = this._txtOP;
    obj.oPdesc = this.selectedOPDesc;
    obj.Active = true;
    obj.CreatedBy = this._permitObj.CreatedBy;
    obj.CreatedDate = this._permitObj.CreatedDate;
    this.assetlistingTable.push(obj);
    this.buildWorkOrderStructure(this.assetlistingTable);
    this.workorderFlag = false;
    this._txtWorkOrder = "";
    this.selectedWorkOrderDesc = "";
    this._txtOP = "";
    this.selectedOPDesc = "";
  }

  AddAssetToAssetWOList() {
    this.childAssetTree.OnSelectAssetClick();
  }

  AddAssetToAssetWOListFromResponse() {
    this.childAssetTreeResponse.OnSelectAssetClick();
  }

  SelectEntryLeader(divisionIndex, sectionIndex, questionIndex) {
   
    this._userService.getEntryLeader(this.userHierarchy.RegionId, this.userHierarchy.SiteId).subscribe(res => {
      this.entryLeaders = res
      this.entryLeaderFlag = true;
    });
    this._divisionIndex = divisionIndex;
    this._sectionIndex = sectionIndex;
    this._questionIndex = questionIndex;
  }

  selectLeader(value: string) {
    this.previousEntryleader = this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].AnswerText;
    this._PermitVersion.Divisions[this._divisionIndex].Sections[this._sectionIndex].Questions[this._questionIndex].AnswerText = value.toUpperCase();
    this.TakeOver(false);
    this.entryLeaderFlag = false;
  }


  

  ExportToPdfEdit() {
    this._spinner.show();
    
    this.isEnabled = true;
    this.isPDFFlag = true;
    this.isPDFFlagRow = true;
    this.previewFlag = "1";

    setTimeout(() => {

      this.ExportToPdf()
    }, 0);

  }
  // async ExportToPdf() {

  //   if (document.getElementById('pnlPreviewTemp')) {
  //     document.getElementById('divPreviewTemp').style.height = '100%';
  //   }
  //   if (document.getElementById('printPermit')) {
  //     document.getElementById('printPermit').style.marginTop = '12%';
  //   }

  //   //To add page break in PDF generation
  //   // Delaying execution so that the html gets parsed properly
  //   await this.DelayExecution();
  //   var windowWidth = window.innerWidth;
  //   var pdfWidth = 210;
  //   var pdfHeight = 297;
  //   var windowHeight = windowWidth * pdfHeight / pdfWidth;
  //   windowHeight = Math.floor(windowHeight);


  //   // let offsetHeight = (windowHeight < 2000) ? 0 : 100;
  //   // offsetHeight = 0;


  //   // var sections = document.getElementsByClassName("tblPermitTemplate");
  //   // var header = document.getElementById("divPreviewHeader");

  //   // let totalHeight: number = header.offsetHeight + 20;

  //   // for (let i = 0; i < sections.length; i++) {
  //   //   let table = sections[i] as HTMLTableElement;
  //   //   let sectionHeight = table.offsetHeight;
  //   //   totalHeight = totalHeight + sectionHeight;

  //   //   totalHeight = totalHeight + 5;

  //   //   if (totalHeight > windowHeight) {
  //   //     totalHeight = totalHeight - sectionHeight;

  //   //     for (let j = 0; j < table.rows.length; j++) {
  //   //       var row = table.rows[j] as HTMLTableRowElement;

  //   //       let rowHeight = row.offsetHeight + 0;
  //   //       totalHeight = totalHeight + rowHeight;
  //   //       if (totalHeight > windowHeight) {
  //   //         totalHeight = totalHeight - rowHeight;

  //   //         let flag = this.CheckForInnerTable(row);
  //   //         if (flag == true) {
  //   //           let innerTable = row.firstElementChild.firstElementChild.firstElementChild.firstElementChild as HTMLTableElement;
  //   //           totalHeight = totalHeight + 5;
  //   //           for (let k = 0; k < innerTable.rows.length; k++) {
  //   //             var innerRow = innerTable.rows[k] as HTMLTableRowElement;

  //   //             let innerRowHeight = innerRow.offsetHeight + 2;
  //   //             totalHeight = totalHeight + innerRowHeight;

  //   //             if (totalHeight > windowHeight) {
  //   //               let extraHeight = innerRowHeight - (totalHeight - windowHeight);
  //   //               offsetHeight = (extraHeight > 30) ? 0 : 100;
  //   //               let x = 'extraHeight: ' + extraHeight + ' | totalHeight: ' + totalHeight + ' | windowHeight: ' + windowHeight;
  //   //               let str = '<div class="page-break" style="height:' + (extraHeight + offsetHeight) + 'px;"></div>';
  //   //               let row = innerTable.insertRow(k);
  //   //               let cell = row.insertCell(0);
  //   //               cell.innerHTML = str;
  //   //               totalHeight = offsetHeight == 0 ? 25 : 75;
  //   //             }
  //   //           }
  //   //           totalHeight = totalHeight + 10;
  //   //         }
  //   //         else {
  //   //           totalHeight = totalHeight + rowHeight;
  //   //           if (totalHeight > windowHeight) {
  //   //             let extraHeight = rowHeight - (totalHeight - windowHeight);
  //   //             offsetHeight = (extraHeight > 30) ? 0 : 100;
  //   //             let x = 'extraHeight: ' + extraHeight + ' | totalHeight: ' + totalHeight + ' | windowHeight: ' + windowHeight;
  //   //             let str = '<div class="page-break" style="height:' + (extraHeight + offsetHeight) + 'px;"></div>';
  //   //             let row = table.insertRow(j);
  //   //             let cell = row.insertCell(0);
  //   //             cell.innerHTML = str;
  //   //             totalHeight = offsetHeight == 0 ? 25 : 75;
  //   //           }
  //   //         }
  //   //       }
  //   //     }
  //   //   }
  //   //   totalHeight = totalHeight + 5;
  //   // }

  //   var data = document.getElementById('pnlPreviewTemp');

  //   html2canvas(data).then(canvas => {
  //     var imgWidth = 208;
  //     var pageHeight = 295;
  //     var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //     var heightLeft = imgHeight;
  //     const contentDataURL = canvas.toDataURL('image/png')
  //     let pdf = new jspdf('p', 'mm', 'a4');
  //     var position = 0;
  //     heightLeft -= pageHeight;
  //     //pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  //     pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')

  //     while (heightLeft >= 0) {
  //       position = heightLeft - imgHeight + 1;//8 
  //       pdf.addPage();
  //       pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')
  //       heightLeft -= pageHeight;
  //     }
  //     pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')
  //     pdf.save(this._permitTypeName + '_' + new Date().toDateString() + '.pdf')
  //     this._spinner.hide();
  //     this.displayPreview = false;

  //     if (document.getElementById('printPermit')) {
  //       document.getElementById('printPermit').style.marginTop = '';
  //     }
  //     var pageBreakDiv = document.getElementsByClassName("page-break");

  //     for (let i = 0; i < pageBreakDiv.length; i++) {
  //       let divObj = pageBreakDiv[i] as HTMLElement;
  //       divObj.parentNode.removeChild(divObj);
  //     }
  //   });

  // }

  async ExportToPdf() {

    if (document.getElementById('printPermit')) {
      document.getElementById('printPermit').style.marginTop = '12%';
    }
    
    var data = document.getElementById('pnlPreviewTemp');
    html2canvas(data).then(canvas => {
      var imgWidth = 210;
      var pageHeight = 297;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf('p', 'mm', 'a4');

      var position = 0;
      heightLeft -= pageHeight;
      pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight + 1;
        pdf.addPage();
        pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')
        heightLeft -= pageHeight;
      }
      pdf.addImage(contentDataURL, 'JPEG', 0, position, imgWidth, imgHeight, 'alias', 'FAST')

      pdf.save(this._permitTypeName + '_' + new Date().toDateString() + '.pdf')
      // convert PDF object to base64 string
      var obj = btoa(pdf.output())
      this._spinner.hide();

      var pageBreakDiv = document.getElementsByClassName("page-break");
      for (let i = 0; i < pageBreakDiv.length; i++) {
        let divObj = pageBreakDiv[i] as HTMLElement;
        divObj.parentNode.removeChild(divObj);
      }
    });
    if (document.getElementById('printPermit')) {
      document.getElementById('printPermit').style.marginTop = '';
    }
  }


  CheckForInnerTable(element: HTMLElement) {
    if (element.childElementCount > 0) {
      if (element.classList.contains('clsTable')) {
        return true;
      }
      else {
        return this.CheckForInnerTable(element.firstElementChild as HTMLElement);
      }
    }
    else {
      return false;
    }
  }

  openMap() {
    this.displayMap = true;
    
  }
  setuserSelected(selectedUsername) {
    this.selectedRoleSecurityName = selectedUsername;
  }
  AddUserRole(selectedUserShortInfo: string[]) {
    let counter: number[] = [];
    let errFlag: boolean = false;
    if (this.selectedRoleSecurityName != undefined && this.selectedRoleSecurityName.length > 0) {
      let roleGroupApprover = this._permitObj.RoleGroupApproval;

     
      roleGroupApprover.forEach((rg, index) => {
        let updatedUserDetails = this._roleForUser[index].UserDetails;
       
        if (rg.UserDetails.length > 0) {
          rg.UserDetails.forEach(ud => {
            if (updatedUserDetails.filter(x => x.LoginId == ud.LoginId).length > 0) {
              ud.Active = true;
            }
            else {
              ud.Active = false;
            }
            if (ud.RoleGroupUserDetailsId > 0) {
              ud.ModifiedDate = new Date();
              ud.ModifiedBy = localStorage.getItem("_loginId");
            }
            else {
              ud.CreatedDate = new Date();
              ud.CreatedBy = localStorage.getItem("_loginId");
            }
          });
          // }
        }
        else {
          let userRoleGroupDetails: RoleGroupUserDetails[] = [];
          
          rg.UserShortInfo.forEach(us => {
            let userDetail: any = {};
            if (updatedUserDetails.filter(x => x.LoginId == us.LoginId).length > 0) {
              userDetail.Active = true;
              userDetail.CreatedDate = new Date();
              userDetail.CreatedBy = localStorage.getItem("_loginId");
              userDetail.LoginId = us.LoginId;
              userRoleGroupDetails.push(userDetail);
            }
            else {
              userDetail.LoginId = us.LoginId;
              userDetail.Active = false;
              userDetail.CreatedDate = new Date();
              userDetail.CreatedBy = localStorage.getItem("_loginId");
              userRoleGroupDetails.push(userDetail);
            }
          });
          //}
          rg.UserDetails = userRoleGroupDetails;
        }
      });

      this._roleGroupApprover = roleGroupApprover;
      this._permitObj.RoleGroupApproval = roleGroupApprover;
      this._roleForUser.forEach(ru => {
        ru.Active = true;
        ru.CreatedDate = new Date();
        ru.ModifiedBy = '';
        ru.CreatedBy = localStorage.getItem("_loginId");

      })
      this._helperService._roleForApproval = this._roleForUser
      roleGroupApprover.forEach((rg, index) => {
        counter[index] = 0;
        if (rg.UserDetails.length > 0) {
          rg.UserDetails.forEach(ud => {
            if (ud.Active == true) {
              counter[index]++;
            }
          })
        }
      })

      if (counter.filter(x => x == 0).length > 0) {
        errFlag = true;
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.RoleSecurity') });
      }
      else {
        errFlag = false;
      }
      if (!errFlag) {
        // Call save permit methods based on Save/Submit button click
        if (this._submitButtonClicked) {
          this._submitButtonClicked = false;
          this.SubmitPermitResponse();
        }
        else {
          this.SaveResponse();
        }
        this.displayuserforrole = false;
      }
      // }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.RoleSecurity') });    
    }
  }

  setCoordinates(permitId) {

    this._geotagService.getGeoTag(permitId).subscribe((data: GISMapViewModel) => {
      if (data) {
        if (data.features.length > 0) {
          data.type = "FeatureCollection";
          data.name = "mapJson_1";
          this._indexDbService.editRecordAsync("mapJson", data).subscribe((data) => {
          });
          this._mediaService.getFileProperties(data.AzureFileId).subscribe(
            (res: HttpResponse<any>) => {
              if (res.body.uri != null && res.body.uri != '') {
                this._commonService.getMediaBlob(this.EscapeSpecialCharacters(res.body.uri)).subscribe(
                  (imageData: any) => {
                    let base64Image = "data:image/png;base64," + imageData.toString();

                    let screenGrab: any = {};
                    screenGrab.name = "screengrab"
                    screenGrab.screengrab = base64Image;
                    if (base64Image != null || base64Image != undefined) {
                      this._showImage = true;
                      this._screenGrab = base64Image;
                    }
                    this._indexDbService.editRecordAsync("mapJson", screenGrab).subscribe((data) => {

                    });
                    let site: any = {};
                    site.name = "site";
                    site.sitename = data.SiteName;
                    this._indexDbService.editRecordAsync("mapJson", site).subscribe((data) => {
                      // console.log("Site Name", data);
                    });
                    
                  });
              }
            });         
        }
        else {          
          sessionStorage.setItem('mapJson', null)
          sessionStorage.removeItem('screengrab');
        }
        let featEditable: any = {};
        featEditable.name = "Editable";
        if (data.features.length > 0) {
          featEditable.Editable = data.Editable;
        }
        else {
          featEditable.Editable = true;
        }
        this._indexDbService.editRecordAsync("mapJson", featEditable).subscribe((data) => {
        });
      }
    });
  }

  multiplePermitsToLink(rowData) {
    if (this._multiplePermitsForPrint.map(i => i.PermitId).includes(rowData.PermitId))
      this._multiplePermitsForPrint = this._multiplePermitsForPrint.filter(x => x.PermitId != rowData.PermitId);
    else
      this._multiplePermitsForPrint.push(rowData);
    this._multiplePermitsForPrintIDs = this._multiplePermitsForPrint.map(i => i.PermitId);    
  }

  getCoordinates(): void {
    if (localStorage.getItem("unsavedGraphics") != "true") {
      this.displayMap = false;
      //let screenGrab: any;
      this._indexDbService.getRecord("mapJson", "screengrab").subscribe((data) => {
        if (data.screengrab) {
          this._showImage = true;
          this._screenGrab = data.screengrab;
        }
      });
    }
    else {
      let text = "You have unsaved features.\nWould you like to close without saving?";
      if (confirm(text) == true) {
        this.displayMap = false;
        localStorage.setItem("unsavedGraphics", "false");
        //let screenGrab: any;
        this._indexDbService.getRecord("mapJson", "screengrab").subscribe((data) => {
          if (data.screengrab) {
            this._showImage = true;
            this._screenGrab = data.screengrab;
          }
        });
      }
    }
  }

  saveCoordinates() {
   
  }

  private delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  private async DelayExecution() {
    // Sleep thread for 5 seconds
    await this.delay(5000);
  }

  hideRow(currenRow, flag) {
    if (flag == "hidden") {
      (currenRow as HTMLTableRowElement).hidden = true;
    }
  }

  GetUsersForRoleGroup() {


  }

  BindEmptyTable(divisionIndex, sectionIndex, questionIndex, ques) {
    let idx = (ques.TableData == undefined) ? 0 : ques.TableData.length
    //Other login
    let question: PermitTypeQuestion = this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex];
    if (question.TableData == undefined) {
      question.TableData = [];

      question.TableData[idx] = [];
      for (let headerIndex in question.TableFieldType) {
        if (question.TableFieldType[headerIndex].AnswerTypeId == 1) {
          question.TableData[idx][question.TableFieldType[headerIndex].ColumnName] = { "SelectedAnswerOptionId": 0 };
        }
        else {
          question.TableData[idx][question.TableFieldType[headerIndex].ColumnName] = {};
        }
      }
    }
    else {
      question.TableData[idx] = [];
      for (let headerIndex in question.TableFieldType) {
        if (question.TableFieldType[headerIndex].AnswerTypeId == 1) {
          question.TableData[idx][question.TableFieldType[headerIndex].ColumnName] = { "SelectedAnswerOptionId": 0 };
        }
        else {
          question.TableData[idx][question.TableFieldType[headerIndex].ColumnName] = {};
        }
      }
    }
  }
  BindEmptySubQuestionTable(divisionIndex, sectionIndex, questionIndex, answerOptionIndex, subQuestionIndex, subQuestion) {
    let idx = (subQuestion.TableData == undefined) ? 0 : subQuestion.TableData.length
    //Other login    
    let subquestion: PermitTypeSubQuestion = this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[answerOptionIndex].SubQuestions[subQuestionIndex];
    if (subquestion.TableData == undefined) {
      subquestion.TableData = [];

      subquestion.TableData[idx] = [];
      for (let headerIndex in subquestion.PermitTypeTableSubQues) {
        if (subquestion.PermitTypeTableSubQues[headerIndex].AnswerTypeId == 1) {
          subquestion.TableData[idx][subquestion.PermitTypeTableSubQues[headerIndex].ColumnName] = { "SelectedAnswerOptionId": 0 };
        }
        else {
          subquestion.TableData[idx][subquestion.PermitTypeTableSubQues[headerIndex].ColumnName] = {};
        }
      }
    }
    else {
      subquestion.TableData[idx] = [];
      for (let headerIndex in subquestion.PermitTypeTableSubQues) {
        if (subquestion.PermitTypeTableSubQues[headerIndex].AnswerTypeId == 1) {
          subquestion.TableData[idx][subquestion.PermitTypeTableSubQues[headerIndex].ColumnName] = { "SelectedAnswerOptionId": 0 };
        }
        else {
          subquestion.TableData[idx][subquestion.PermitTypeTableSubQues[headerIndex].ColumnName] = {};
        }
      }
    }
  }

  AddRowClick(divisionIndex, sectionIndex, questionIndex, ques) {
    this.BindEmptyTable(divisionIndex, sectionIndex, questionIndex, ques);
  }
  AddRowSubQuestionClick(divisionIndex, sectionIndex, questionIndex, answerOptionIndex, subQuestionIndex, subQuestion) {
    this.BindEmptySubQuestionTable(divisionIndex, sectionIndex, questionIndex, answerOptionIndex, subQuestionIndex, subQuestion);
  }

  
  DeleteRow(question: PermitTypeQuestion, pIndex) {
    let deletedData = question.TableData;
    if (deletedData.length > 0) {
      for (let i = 0; i < deletedData.length; i++) {
        if (i == pIndex) {
          deletedData.splice(i, 1);

        }
      }
    }
  }

  DeleteRowSubQTable(subquestion: PermitTypeSubQuestion, ptIndex) {
    let deletedData = subquestion.TableData;
    if (deletedData.length > 0) {
      for (let i = 0; i < deletedData.length; i++) {
        if (i == ptIndex) {
          deletedData.splice(i, 1);

        }
      }
    }
  }

  AddRowsToTables() {
    for (let divisionIndex in this._PermitVersion.Divisions) {
      for (let sectionIndex in this._PermitVersion.Divisions[divisionIndex].Sections) {
        for (let questionIndex in this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions) {
          if (this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerTypeId == 9) {
            let rowCount = this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].RowCount;
            for (var index = 0; index < rowCount; index++) {
              this.BindEmptyTable(divisionIndex, sectionIndex, questionIndex, this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex]);
            }
          }
        }
      }
    }
  }

  AddRowsToSubQuestionTables() {
    for (let divisionIndex in this._PermitVersion.Divisions) {
      for (let sectionIndex in this._PermitVersion.Divisions[divisionIndex].Sections) {
        for (let questionIndex in this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions) {
          for (let answerOptionIndex in this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions) {
            for (let subQuestionIndex in this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[answerOptionIndex].SubQuestions) {
              if (this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[answerOptionIndex].SubQuestions[subQuestionIndex].AnswerTypeId == 9) {
                let rowCount = this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[answerOptionIndex].SubQuestions[subQuestionIndex].RowCount;
                for (var index = 0; index < rowCount; index++) {
                  this.BindEmptySubQuestionTable(divisionIndex, sectionIndex, questionIndex, answerOptionIndex, subQuestionIndex, this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[answerOptionIndex].SubQuestions[subQuestionIndex]);
                }
              }
            }
          }
        }
      }
    }
  }

  showDialogMaximized(dialog: Dialog) {
    setTimeout(() => {
      dialog.maximize();
    }, 0);
  }

  // Fetch all the dynamic data source values associated with the selected permit type and store in a local variable.
  // This local variable will be used to populate the dropdown going forward.
  GetAllDynamicDataSourceValues() {
    this._PermitVersion.Divisions.forEach(div => {
      div.Sections.forEach(sec => {
        sec.Questions.forEach(ques => {
          if (ques.AnswerTypeId == 9) {
            ques.TableFieldType.forEach(tableItem => {
              if (tableItem.AnswerTypeId == 1) {
                if (tableItem.AnswerOptions[0].DynamicDataSource) {

                  this._dynamicDataSourceService.getDataSourceOptions(tableItem.AnswerOptions[0].DynamicDataSourceId)
                    .subscribe((data: DynamicDataSourceOption[]) => {
                      let obj = {
                        permitTypeQuestionId: ques.PermitTypeQuestionId,
                        dynamicDataSourceOptions: data
                      }
                      this._allDynamicDatasourceOptions.push(obj);
                    });
                }
              }
            });
          }
          if (ques.AnswerOptions.length > 0 && ques.AnswerOptions[0].DynamicDataSource == true) {
            this._dynamicDataSourceService.getDataSourceOptions(ques.AnswerOptions[0].DynamicDataSourceId)
              .subscribe((data: DynamicDataSourceOption[]) => {
                let obj = {
                  permitTypeQuestionId: ques.PermitTypeQuestionId,
                  dynamicDataSourceOptions: data
                }
                this._allDynamicDatasourceOptions.push(obj);
              });
          }

        })
      })
    })
  }

  GetAllDynamicDataSourceValueSubQ() {
    this._PermitVersion.Divisions.forEach(div => {
      div.Sections.forEach(sec => {
        sec.Questions.forEach(ques => {
          ques.AnswerOptions.forEach(ao => {
            if (ao.SubQuestions.length > 0) {
              ao.SubQuestions.forEach(sq => {

                if (sq.AnswerTypeId == 9) {
                  sq.PermitTypeTableSubQues.forEach(tableItem => {
                    if (tableItem.AnswerTypeId == 1) {
                      if (tableItem.AnswerOptions[0].DynamicDataSource) {

                        this._dynamicDataSourceService.getDataSourceOptions(tableItem.AnswerOptions[0].DynamicDataSourceId)
                          .subscribe((data: DynamicDataSourceOption[]) => {
                            let obj = {
                              permitTypeSubQuestionId: sq.PermitTypeSubQuestionId,
                              dynamicDataSourceOptions: data
                            }
                            this._allDynamicDatasourceOptionSubQ.push(obj);
                          });
                      }
                    }
                  });
                }

                if (sq.SubQuesAnswerOptions.length > 0 && sq.SubQuesAnswerOptions[0].DynamicDataSource == true) {
                  this._dynamicDataSourceService.getDataSourceOptions(sq.SubQuesAnswerOptions[0].DynamicDataSourceId)
                    .subscribe((data: DynamicDataSourceOption[]) => {
                      let obj = {
                        permitTypeSubQuestionId: sq.PermitTypeSubQuestionId,
                        dynamicDataSourceOptions: data
                      }
                      this._allDynamicDatasourceOptionSubQ.push(obj);
                    });
                }

              })
            }
          })
        })
      })
    })
  }

  getAnswerOption(question: PermitTypeQuestion) {
    if (this._allDynamicDatasourceOptions != undefined) {
      let obj = this._allDynamicDatasourceOptions.find(x => x.permitTypeQuestionId == question.PermitTypeQuestionId)
      if (obj != undefined) {
        var sortedDynamicDataSourceOptions = obj.dynamicDataSourceOptions.sort((n1, n2) =>
          n1.OptionText > n2.OptionText ? 1 : -1);

        return sortedDynamicDataSourceOptions;
      }
    }
    else {
      return [];
    }
  }
  getAnswerOptionSubQuestion(subquestion: PermitTypeSubQuestion) {
    if (this._allDynamicDatasourceOptionSubQ != undefined) {
      let obj = this._allDynamicDatasourceOptionSubQ.find(x => x.permitTypeSubQuestionId == subquestion.PermitTypeSubQuestionId)
      if (obj != undefined) {
        var sortedDynamicDataSourceOptions = obj.dynamicDataSourceOptions.sort((n1, n2) =>
          n1.OptionText > n2.OptionText ? 1 : -1);

        return sortedDynamicDataSourceOptions;
      }
    }
    else {
      return [];
    }
  }

  getAnswerOptionForTableType(field: PermitTypeTableFieldType) {
    let obj: any[];
    obj = [];
    field.AnswerOptions.forEach(ans => {
      obj.push(ans)
    });
    return obj;
  }

  chkOnChange() {
  }

  MapTableResponseValues() {
    for (let section of this._PermitVersion.Divisions[0].Sections) {
      for (let question of section.Questions) {

        if (question.AnswerTypeId == 9) {

          for (let tResponse of question.TableTypeResponses) {
            for (var i = 0; i < question.TableData.length; i++) {
              for (let header of question.TableFieldType) {

                if (header.AnswerTypeId == 1 || header.AnswerTypeId == 2 || header.AnswerTypeId == 4) {
                  (question.TableData[i][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tResponse.SelectedAnswerOptionId;
                }
                else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                  (question.TableData[i][header.ColumnName] as TableTypeResponse).ResponseText = tResponse.ResponseText;
                }

              }
            }
          }

        }
      }
    }
  }

  getPermiTypeDetails(permitTypeId) {

    let hierarchySearch = new HierarchySearchViewModel();
    hierarchySearch.PermitTypeId = permitTypeId;
    this._permitTypeService.getPermitTypes(hierarchySearch).subscribe(permitType => {
      this._permitType = permitType.find(p => p.PermitType.PermitTypeId == permitTypeId);
      this._permitTypeAbbr = this._permitType.PermitType.Abbreviation
      this._permitTypeName = this._permitType.PermitType.PermitTypeName
      this._permitTypeId = this._permitType.PermitType.PermitTypeId;
      this._permitBackColor = this._permitType.PermitType.BackColor;
      this._permitForeColor = this._permitType.PermitType.ForeColor;

      this._canCopyPermit = this._permitType.PermitType.CopyPermitType;

      if (this._canCopyPermit && this._resetFlag == false && this._permitId == 0) {
        
        this._displayPermitSelectPopUp = true;
      }

      // To get revalidation hours for the permit version
      this.getPermitControlsForPermitVersion();
      this.DisablePermitIssuedDateTime();
      this.GetAllDynamicDataSourceValues();
      this.GetAllDynamicDataSourceValueSubQ();
      if (this._permitType.Attachment != null && this._permitType.Attachment.AzureFileId) {
        this.getPermitTypeImage(this._permitType.Attachment.AzureFileId);
      }
    })
  }

  getPermitTypeImage(azurefileid: number) {
    if (azurefileid != null && azurefileid != 0) {
      this._mediaService.getFileProperties(azurefileid).subscribe(
        (res: HttpResponse<any>) => {
          if (res.body.uri != null && res.body.uri != '') {
            this._commonService.getMediaBlob(this.EscapeSpecialCharacters(res.body.uri)).subscribe(
              (data: any) => {

                let base64Image = "data:image/png;base64," + data.toString();
                let thumbnailImage = this.sanitizer.bypassSecurityTrustUrl(base64Image);
                this.permitTypeImage = thumbnailImage;
              });

          }
        });
    }
  }

  EscapeSpecialCharacters(input: string): string {
    let inputText = input;
    let specialCharArray = [
      { 'Character': '!', 'Code': 'U0021' },
      { 'Character': '#', 'Code': 'U0023' },
      { 'Character': '$', 'Code': 'U0024' },
      { 'Character': '%', 'Code': 'U0025' },
      { 'Character': '&', 'Code': 'U0026' },
      { 'Character': '(', 'Code': 'U0028' },
      { 'Character': ')', 'Code': 'U0029' },
      { 'Character': '*', 'Code': 'U002A' },
      { 'Character': '+', 'Code': 'U002B' },
      { 'Character': '-', 'Code': 'U002D' },
      { 'Character': '/', 'Code': 'U002F' },
      { 'Character': '?', 'Code': 'U003F' },
      { 'Character': '@', 'Code': 'U0040' },
      { 'Character': '\\', 'Code': 'U005C' },
      { 'Character': '=', 'Code': 'U003D' },
      { 'Character': '_', 'Code': 'U005F' }
    ]

    let charArray = Array.from(inputText);
    let stringArray = [];
    for (let objChar of charArray) {
      let obj = specialCharArray.find(x => x.Character == objChar);
      stringArray.push(obj == undefined ? objChar : obj.Code);
    }

    inputText = stringArray.join('');

    return inputText;
  }

  filterOnGlobalFilter(filterValue: string) {
    
    if (filterValue && filterValue.length > 2) {
      this.lazyLoadFilter = filterValue;
      this.filterGrid(this.normalpermitLinking);
    }
    else if (filterValue.length == 0) {
      this.lazyLoadFilter = '';
      this.filterGrid(this.normalpermitLinking);
    }
  }

  AssignValues() {
   
    if (this._permitObj.PermitId == undefined || this._permitObj.PermitId == 0) {
      this._permitObj = new Permit();
      
      this._permitObj.PermitNumber = '-' + this._permitTypeAbbr + '-' + new Date().getFullYear().toString().substring(2, 4) + '-';
      
      this._permitObj.PermitId = 0;
      this._permitObj.Responses = [];      
      this._permitObj.PermitIssuedDateTime = this.permitIssuedDateTime != undefined ? this.permitIssuedDateTime : null;
      this._permitObj.RevalidationHours = this.revalidationHours;
      this._permitObj.PermitExpiryDateTime = this.permitExpiryDateTime != undefined ? this.permitExpiryDateTime : null;
      this._permitObj.PermitTypeVersionId = this._PermitVersion.PermitTypeVersionId;
      this._permitObj.PermitTypeId = this._PermitVersion.PermitTypeId;
      this._permitObj.SiteId = this.userHierarchy.SiteId;
      this._permitObj.CreatedBy = localStorage.getItem("_loginId");
      this._permitObj.CreatedDate = new Date();
      this._permitObj.SiteName = this.userHierarchy.SiteName;
      this._permitObj.WorkArea= this.WorkArea;
      this.permitClosedBy = '';
      this._permitObj.PermitAssetAndWorkOrder = [];
    }
    else {
      this._permitObj.PermitId = this._permitObj.PermitId;
      this._permitObj.PermitNumber = this._permitObj.PermitNumber;      
      this._permitObj.PermitIssuedDateTime = this.permitIssuedDateTime != undefined ? this.permitIssuedDateTime : null;
      this._permitObj.PermitExpiryDateTime = this.permitExpiryDateTime != undefined ? this.permitExpiryDateTime : null;
      this._permitObj.PermitAssetAndWorkOrder = this._permitObj.PermitAssetAndWorkOrder;
      this.assetlistingTable = this._permitObj.PermitAssetAndWorkOrder;
      this._permitObj.WorkArea=this.WorkArea;
    }
    this._permitObj.Active = true;
    this._permitObj.ModifiedBy = localStorage.getItem("_loginId");
    this._permitObj.ModifiedDate = new Date();

    let responseArray: Response[] = [];
    this._PermitVersion.Divisions.forEach((div: PermitTypeDivision) => {
      div.Sections.forEach((sec: PermitTypeSection) => {
        sec.Questions.forEach((ques: PermitTypeQuestion) => {
          let checkBoxAnswerOptionsArray = ques.AnswerOptions.filter(ao => ao.PermitTypeQuestionId == ques.PermitTypeQuestionId);
          let response = new Response();
          if (this._permitObj.PermitId > 0) {
            response = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId)
          }
          else {
            response.ResponseId = 0;
          }

          this._tableTypeResponse = [];

          // For handling checkboxes
          if (ques.AnswerTypeId == 2) {
            let checkBoxAnswers: number[]
            let checkBoxAnswersref: string[]
            checkBoxAnswers = ques.SelectedAnswerCheckBox
            if (this._permitObj.PermitId > 0) {
              if (ques.SelectedAnswerCheckBox) {
                let checkBoxRespArray: Response[] = [];
                checkBoxRespArray = this._permitObj.Responses.filter(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId);
                checkBoxRespArray.forEach(r => {
                  r.SelectedAnswerOptionId = null;
                  r.ModifiedBy = localStorage.getItem("_loginId");
                  r.ModifiedDate = new Date();
                  r.Active = true;
                  r.CreatedDate = new Date();
                  r.CreatedBy = localStorage.getItem("_loginId");
                  r.PermitTypeQuestionId = ques.PermitTypeQuestionId;
                  r.PermitTypeQuestionRef = ques.PermitTypeQuestionRef;
                  r.SubQuesResponse = [];
                });
                checkBoxAnswers.forEach((ans: number) => {
                  if (ans != null) {
                    let indexOfSelectedAnswerOption = checkBoxAnswerOptionsArray.findIndex(x => x.PermitTypeAnswerOptionId == ans)
                    checkBoxRespArray[indexOfSelectedAnswerOption].SelectedAnswerOptionId = ans
                  }
                });

                checkBoxRespArray.forEach(x => {
                  responseArray.push(x);
                })
              }
            }
            else {
              for (let i = 0; i < checkBoxAnswerOptionsArray.length; i++) {
                let checkBoxResponseForNew = new Response()
                checkBoxResponseForNew.ResponseId = 0;
                checkBoxResponseForNew.Active = true;
                checkBoxResponseForNew.CreatedBy = localStorage.getItem("_loginId");
                checkBoxResponseForNew.CreatedDate = new Date();
                checkBoxResponseForNew.ModifiedBy = localStorage.getItem("_loginId");
                checkBoxResponseForNew.ModifiedDate = new Date();
                checkBoxResponseForNew.PermitTypeQuestionId = ques.PermitTypeQuestionId
                checkBoxResponseForNew.SelectedAnswerOptionId = null;
                checkBoxResponseForNew.PermitTypeQuestionRef = ques.PermitTypeQuestionRef;
                checkBoxResponseForNew.PermitTypeAnswerOptionRef = checkBoxAnswerOptionsArray[i].PermitTypeAnswerOptionRef;
                checkBoxResponseForNew.SubQuesResponse = [];
                if (checkBoxAnswers) {
                  for (let j = 0; j < checkBoxAnswers.length; j++) {
                    if (checkBoxAnswerOptionsArray[i].PermitTypeAnswerOptionId == checkBoxAnswers[j]) {
                      checkBoxResponseForNew.SelectedAnswerOptionId = checkBoxAnswers[j]

                    }
                  }
                }

                responseArray.push(checkBoxResponseForNew);
              }
            }
          }
          // For handling Table Responses
          else if (ques.AnswerTypeId == 9) {
            if (this._permitObj.PermitId > 0) {
              //Update Start
              let response: any;

              for (let res of this._permitObj.Responses) {
                if (res.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                  response = res;
                  break;

                }
              }
              let deletedIndexes: number[];
              deletedIndexes = [];
              for (let rowIndex in ques.TableData) {
                if (rowIndex.toString().startsWith('deleted')) {
                  if (ques.TableData[rowIndex.toString()] == "hidden") {
                    deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                  }
                }
              }


              for (let rowIndex in ques.TableData) {
                if (rowIndex.toString().startsWith('delete') == false) {
                  let status = this.checkRowForDeletion(rowIndex, ques.TableFieldType, ques.TableData, deletedIndexes);
                  if (status != "SKIP") {

                    for (let header of ques.TableFieldType) {
                      let tblTypeResponse = response.TableTypeResponse.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == +rowIndex);//new TableTypeResponse();

                      if (tblTypeResponse) {
                        let tblCheckboxResponses = response.TableTypeResponse.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == parseInt(rowIndex));//new TableTypeResponse();
                        for (let respObj of tblCheckboxResponses) {
                          respObj.Active = status == "ACTIVE" ? true : false;
                          if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                            let PermitTypeTableAnswerOptionRef: string | null;
                            if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                              tblTypeResponse.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                              tblTypeResponse.PermitTypeTableAnswerOptionRef = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).PermitTypeTableAnswerOptionRef;
                              this._tableTypeResponse.push(tblTypeResponse);
                            }
                            else {
                              tblTypeResponse.SelectedAnswerOptionId = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId;
                              if (tblTypeResponse.SelectedAnswerOptionId != null && tblTypeResponse.SelectedAnswerOptionId > 0) {
                                PermitTypeTableAnswerOptionRef = ques.TableFieldType.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.AnswerTypeId == header.AnswerTypeId).AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tblTypeResponse.SelectedAnswerOptionId).PermitTypeTableAnswerOptionRef;
                              }
                              tblTypeResponse.PermitTypeTableAnswerOptionRef = PermitTypeTableAnswerOptionRef;
                              this._tableTypeResponse.push(tblTypeResponse);
                            }

                          }
                          else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                            respObj.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                            this._tableTypeResponse.push(respObj);

                          }

                        }
                        if (header.AnswerTypeId == 2) {
                          let tableFields = ques.TableFieldType.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.PermitTypeTableFieldTypeId == header.PermitTypeTableFieldTypeId);
                          let PermitTypeTableAnsOptions: PermitTypeTableAnswerOption[] = []
                          tableFields.AnswerOptions.forEach(x => {
                            PermitTypeTableAnsOptions.push(x);
                          })

                          let tblTypeResponseForCheckBox = response.TableTypeResponse.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == parseInt(rowIndex));

                          let selectedCheckBoxes = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable;

                          for (let chkbox of tblTypeResponseForCheckBox) {
                            chkbox.SelectedAnswerOptionId = null;
                          }
                          for (let j = 0; j < selectedCheckBoxes.length; j++) {
                            for (let i = 0; i < PermitTypeTableAnsOptions.length; i++) {

                              if (PermitTypeTableAnsOptions[i].PermitTypeTableAnswerOptionId == selectedCheckBoxes[j]) {
                                tblTypeResponseForCheckBox[i].SelectedAnswerOptionId = selectedCheckBoxes[j];
                                tblTypeResponseForCheckBox[i].PermitTypeTableAnswerOptionRef = PermitTypeTableAnsOptions[i].PermitTypeTableAnswerOptionRef;
                                break;
                              }
                            }
                          }
                          tblTypeResponseForCheckBox.forEach(resp => {
                            resp.Active = status == "ACTIVE" ? true : false;
                            this._tableTypeResponse.push(resp);
                          });
                        }

                      }
                      else {
                        let tblTypeResponse = new TableTypeResponse();
                        tblTypeResponse.TableTypeResponseId = 0
                        tblTypeResponse.Active = true;
                        tblTypeResponse.CreatedBy = localStorage.getItem("_loginId");
                        tblTypeResponse.CreatedDate = new Date()
                        tblTypeResponse.ModifiedBy = localStorage.getItem("_loginId");
                        tblTypeResponse.ModifiedDate = new Date()
                        tblTypeResponse.TableFieldTypeId = header.PermitTypeTableFieldTypeId;
                        tblTypeResponse.PermitTypeTableFieldTypeRef = header.PermitTypeTableFieldTypeRef;
                        tblTypeResponse.RowIndex = +rowIndex
                        tblTypeResponse.ResponseId = response.ResponseId;
                        if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                          if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                            tblTypeResponse.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                            tblTypeResponse.PermitTypeTableAnswerOptionRef = header.AnswerOptions[0].PermitTypeTableAnswerOptionRef;
                            this._tableTypeResponse.push(tblTypeResponse);
                          }
                          else {
                            tblTypeResponse.SelectedAnswerOptionId = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId;
                            tblTypeResponse.PermitTypeTableAnswerOptionRef = header.AnswerOptions[0].PermitTypeTableAnswerOptionRef;
                            this._tableTypeResponse.push(tblTypeResponse);
                          }

                        }
                        else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                          tblTypeResponse.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                          this._tableTypeResponse.push(tblTypeResponse);

                        }
                        else if (header.AnswerTypeId == 2) {
                          let tableFields = ques.TableFieldType.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.AnswerTypeId == header.AnswerTypeId);
                          let PermitTypeTableAnsOptions: PermitTypeTableAnswerOption[] = []
                          tableFields.AnswerOptions.forEach(x => {
                            PermitTypeTableAnsOptions.push(x);
                          })

                          for (let i = 0; i < PermitTypeTableAnsOptions.length; i++) {
                            let tblTypeResponseForCheckBox = new TableTypeResponse();
                            tblTypeResponseForCheckBox.TableTypeResponseId = 0
                            tblTypeResponseForCheckBox.ResponseId = response.ResponseId;
                            tblTypeResponseForCheckBox.Active = true;
                            tblTypeResponseForCheckBox.CreatedBy = localStorage.getItem("_loginId");
                            tblTypeResponseForCheckBox.CreatedDate = new Date()
                            tblTypeResponseForCheckBox.ModifiedBy = localStorage.getItem("_loginId");
                            tblTypeResponseForCheckBox.ModifiedDate = new Date()
                            tblTypeResponseForCheckBox.TableFieldTypeId = header.PermitTypeTableFieldTypeId;
                            tblTypeResponseForCheckBox.PermitTypeTableFieldTypeRef = header.PermitTypeTableFieldTypeRef;
                            tblTypeResponseForCheckBox.PermitTypeTableAnswerOptionRef = PermitTypeTableAnsOptions[i].PermitTypeTableAnswerOptionRef;
                            tblTypeResponseForCheckBox.RowIndex = +rowIndex
                            tblTypeResponseForCheckBox.SelectedAnswerOptionId = null
                            let selectedCheckBoxes = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable;
                            if (selectedCheckBoxes) {
                              for (let j = 0; j < selectedCheckBoxes.length; j++) {
                                if (PermitTypeTableAnsOptions[i].PermitTypeTableAnswerOptionId == selectedCheckBoxes[j]) {
                                  tblTypeResponseForCheckBox.SelectedAnswerOptionId = selectedCheckBoxes[j]
                                }
                              }
                            }
                            this._tableTypeResponse.push(tblTypeResponseForCheckBox);
                          }
                        }
                      }
                    }
                  }
                }
              }
              response.ModifiedBy = localStorage.getItem('_loginId');
              response.ModifiedDate = new Date();
              response.TableTypeResponse = this._tableTypeResponse;
              responseArray.push(response);
              //Update End
            }
            else {
              let response = new Response();
              response.ResponseId = 0
              response.Active = true;
              response.CreatedBy = localStorage.getItem("_loginId");
              response.CreatedDate = new Date()
              
              response.PermitTypeQuestionId = ques.PermitTypeQuestionId;
              response.PermitTypeQuestionRef = ques.PermitTypeQuestionRef;

              let deletedIndexes: number[];
              deletedIndexes = [];
              for (let rowIndex in ques.TableData) {
                if (rowIndex.toString().startsWith('deleted')) {
                  if (ques.TableData[rowIndex.toString()] == "hidden") {
                    deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                  }
                }
              }
              let currentRowIndex = 0;
              for (let rowIndex in ques.TableData) {
                if (rowIndex.toString().startsWith('delete') == false) {

                  let status = this.checkRowForDeletion(rowIndex, ques.TableFieldType, ques.TableData, deletedIndexes);
                  if (status != "SKIP") {
                    for (let header of ques.TableFieldType) {
                      let tblTypeResponse = new TableTypeResponse();
                      tblTypeResponse.TableTypeResponseId = 0
                      tblTypeResponse.ResponseId = 0
                      tblTypeResponse.Active = status == "ACTIVE" ? true : false;
                      tblTypeResponse.CreatedBy = localStorage.getItem("_loginId");
                      tblTypeResponse.CreatedDate = new Date()
                      tblTypeResponse.ModifiedBy = localStorage.getItem("_loginId");
                      tblTypeResponse.ModifiedDate = new Date()
                      tblTypeResponse.TableFieldTypeId = header.PermitTypeTableFieldTypeId;
                      tblTypeResponse.PermitTypeTableFieldTypeRef = header.PermitTypeTableFieldTypeRef;
                      tblTypeResponse.RowIndex = currentRowIndex

                      if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                        let PermitTypeTableAnswerOptionRef: string | null;
                        if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                          tblTypeResponse.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                          this._tableTypeResponse.push(tblTypeResponse);
                        }
                        else {
                          tblTypeResponse.SelectedAnswerOptionId = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId;
                          if (tblTypeResponse.SelectedAnswerOptionId != null && tblTypeResponse.SelectedAnswerOptionId > 0) {
                            PermitTypeTableAnswerOptionRef = ques.TableFieldType.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.AnswerTypeId == header.AnswerTypeId).AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tblTypeResponse.SelectedAnswerOptionId).PermitTypeTableAnswerOptionRef;
                          }
                          tblTypeResponse.PermitTypeTableAnswerOptionRef = PermitTypeTableAnswerOptionRef;
                          this._tableTypeResponse.push(tblTypeResponse);
                        }

                      }
                      else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                        tblTypeResponse.ResponseText = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText;
                        this._tableTypeResponse.push(tblTypeResponse);

                      }
                      else if (header.AnswerTypeId == 2) {
                        let tableFields = ques.TableFieldType.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.AnswerTypeId == header.AnswerTypeId);
                        let PermitTypeTableAnsOptions: PermitTypeTableAnswerOption[] = []
                        tableFields.AnswerOptions.forEach(x => {
                          PermitTypeTableAnsOptions.push(x);
                        })

                        for (let i = 0; i < PermitTypeTableAnsOptions.length; i++) {
                          let tblTypeResponseForCheckBox = new TableTypeResponse();
                          tblTypeResponseForCheckBox.TableTypeResponseId = 0
                          tblTypeResponseForCheckBox.ResponseId = 0
                          tblTypeResponseForCheckBox.Active = status == "ACTIVE" ? true : false;
                          tblTypeResponseForCheckBox.CreatedBy = localStorage.getItem("_loginId");
                          tblTypeResponseForCheckBox.CreatedDate = new Date()
                          tblTypeResponseForCheckBox.ModifiedBy = localStorage.getItem("_loginId");
                          tblTypeResponseForCheckBox.ModifiedDate = new Date()
                          tblTypeResponseForCheckBox.TableFieldTypeId = header.PermitTypeTableFieldTypeId;
                          tblTypeResponseForCheckBox.PermitTypeTableFieldTypeRef = header.PermitTypeTableFieldTypeRef;
                          tblTypeResponseForCheckBox.PermitTypeTableAnswerOptionRef = PermitTypeTableAnsOptions[i].PermitTypeTableAnswerOptionRef;
                          tblTypeResponseForCheckBox.RowIndex = currentRowIndex;
                          tblTypeResponseForCheckBox.SelectedAnswerOptionId = null;
                          let selectedCheckBoxes = (ques.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable;
                          if (selectedCheckBoxes) {
                            for (let j = 0; j < selectedCheckBoxes.length; j++) {
                              if (PermitTypeTableAnsOptions[i].PermitTypeTableAnswerOptionId == selectedCheckBoxes[j]) {
                                tblTypeResponseForCheckBox.SelectedAnswerOptionId = selectedCheckBoxes[j]
                              }
                            }
                          }



                          this._tableTypeResponse.push(tblTypeResponseForCheckBox);
                        }
                      }
                    }
                    currentRowIndex++;
                  }
                }
              }
              response.TableTypeResponse = this._tableTypeResponse;
              responseArray.push(response);
            }
          }
          // For handling drop downs types
          else if (ques.AnswerTypeId == 1) {
            if (this._permitObj.PermitId > 0) {
              let responseId = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseId;
              response.ResponseId = responseId;
              response.ModifiedBy = localStorage.getItem('_loginId');
              response.ModifiedDate = new Date();
            }
            else {
              response.ResponseId = 0;
              response.SubQuesResponse = [];
              response.Active = true;
              response.CreatedDate = new Date();
              response.CreatedBy = localStorage.getItem('_loginId');
            }
            response.PermitTypeQuestionId = ques.PermitTypeQuestionId;
            response.PermitTypeQuestionRef = ques.PermitTypeQuestionRef;

            if (ques.AnswerOptions.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId).DynamicDataSource == true) {
              response.ResponseText = ques.AnswerText;
            }
            else {
              response.SelectedAnswerOptionId = +ques.SelectedAnswerOptionID
              response.ResponseText = ques.AnswerOptions.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId
                && x.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID) ? ques.AnswerOptions.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId
                  && x.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID).OptionText : '';
              response.PermitTypeAnswerOptionRef = ques.AnswerOptions.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId
                && x.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID) ? ques.AnswerOptions.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId
                  && x.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID).PermitTypeAnswerOptionRef : null;


            }
           
            responseArray.push(response)

          }
          // For handling other types
          else {
            if (this._permitObj.PermitId > 0) {
              let responseId = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseId;
              response.ResponseId = responseId;
              response.ModifiedBy = localStorage.getItem('_loginId');
              response.ModifiedDate = new Date();
            }
            else {
              response.ResponseId = 0;
              response.SubQuesResponse = [];
              response.Active = true;
              response.CreatedDate = new Date();
              response.CreatedBy = localStorage.getItem('_loginId');
            }
            response.PermitTypeQuestionId = ques.PermitTypeQuestionId;
            response.PermitTypeQuestionRef = ques.PermitTypeQuestionRef;
            if (ques.AnswerTypeId == 11) {
              if (ques.FieldValue != undefined && ques.FieldValue != null) {
                response.ResponseText = ques.FieldDesc != null ? ques.FieldValue + "@@@" + ques.FieldDesc : ques.FieldValue;
                response.ResponseText = ques.ProcName != null && ques.ProcName != '' && ques.ProcName != undefined ? response.ResponseText + "@@@" + ques.ProcName : response.ResponseText + "@@@" + "NULL";
                response.ResponseText = ques.ProcRef != undefined && ques.ProcRef != null && ques.ProcRef != '' ? response.ResponseText + "@@@" + ques.ProcRef : response.ResponseText + "@@@" + "NULL";
                response.ResponseText = ques.RiskAssesment != undefined && ques.RiskAssesment != null && ques.RiskAssesment != '' ? response.ResponseText + "@@@" + ques.RiskAssesment : response.ResponseText + "@@@" + "NULL";
              }

            }
            else {
              response.ResponseText = ques.AnswerText;
            }

            response.SelectedAnswerOptionId = ques.SelectedAnswerOptionID // This will be fetched from realtime user response
            response.PermitTypeAnswerOptionRef = ques.AnswerOptions.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId
              && x.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID) ? ques.AnswerOptions.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId
                && x.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID).PermitTypeAnswerOptionRef : null;

           
            responseArray.push(response)
          }
          //sub question response
          ques.AnswerOptions.forEach(ao => {
           
            if (ao.SubQuestions.length > 0) {
              let subquesresponseArray: SubQuesResponse[] = [];
              ao.SubQuestions.forEach(sq => {
                this._tableTypeSubQuestionResponse = [];
                if (ao.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID) {

                  let subQuestionAnswerOptionsArray = sq.SubQuesAnswerOptions.filter(sqa => sqa.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId);
                  let subquesResponse = new SubQuesResponse();
                  if (this._permitObj.PermitId > 0 && this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) != undefined) {

                    subquesResponse = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId)
                  }
                  else {
                    subquesResponse.SubQuesResponseId = 0;
                  }


                  // handling SubQuestion drop downs types
                  if (sq.AnswerTypeId == 1) {
                    if (this._permitObj.PermitId > 0 && this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) != undefined) {

                      let subquesResponseId = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesResponseId;
                      subquesResponse.SubQuesResponseId = subquesResponseId;
                      subquesResponse.ResponseId = response.ResponseId;
                      subquesResponse.ModifiedBy = localStorage.getItem('_loginId');
                      subquesResponse.ModifiedDate = new Date();
                    }
                    else {
                      subquesResponse.SubQuesResponseId = 0;
                      subquesResponse.ResponseId = response.ResponseId;
                      subquesResponse.Active = true;
                      subquesResponse.CreatedDate = new Date();
                      subquesResponse.CreatedBy = localStorage.getItem('_loginId');
                    }
                    subquesResponse.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId;
                    subquesResponse.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef;
                    if (sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).DynamicDataSource == true) {
                      subquesResponse.SubQuesResponseText = sq.AnswerText;
                    }
                    else {
                      subquesResponse.SelectedAnswerOptionId = +sq.SelectedAnswerOptionID;
                      subquesResponse.PermitTypeSubQuesAnswerOptionRef = sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                        && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID) ? sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                          && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID).PermitTypeSubQuesAnswerOptionRef : null;
                      subquesResponse.SubQuesResponseText = sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                        && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID) ? sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                          && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID).SubQuesOptionText : '';


                    }
                    responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(subquesResponse);
                  }
                  //handling SubQuestion checkbox
                  else if (sq.AnswerTypeId == 2) {
                    let checkBoxAnswersSubQues: number[]
                    checkBoxAnswersSubQues = sq.SelectedAnswerCheckBox
                    if (this._permitObj.PermitId > 0) {
                      if (sq.SelectedAnswerCheckBox) {
                        let checkBoxsubQuesRespArray: SubQuesResponse[] = [];
                        checkBoxsubQuesRespArray = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse;

                        checkBoxsubQuesRespArray.forEach(r => {
                          r.SelectedAnswerOptionId = null;
                          r.ModifiedBy = localStorage.getItem("_loginId");
                          r.ModifiedDate = new Date();
                          r.Active = true;
                          r.CreatedDate = new Date();
                          r.CreatedBy = localStorage.getItem("_loginId");
                          r.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId;
                          r.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef;
                        });
                        checkBoxAnswersSubQues.forEach((ans: number) => {
                          if (ans != null) {
                            let indexOfSelectedAnswerOption = subQuestionAnswerOptionsArray.findIndex(x => x.PermitTypeSubQuesAnswerOptionId == ans)
                            checkBoxsubQuesRespArray[indexOfSelectedAnswerOption].SelectedAnswerOptionId = ans
                          }
                        });

                        checkBoxsubQuesRespArray.forEach(x => {
                          responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(x);
                        })
                      }
                    }
                    else {
                      for (let i = 0; i < subQuestionAnswerOptionsArray.length; i++) {
                        let checkBoxResponseForNewSQ = new SubQuesResponse()
                        checkBoxResponseForNewSQ.SubQuesResponseId = 0;
                        checkBoxResponseForNewSQ.ResponseId = response.ResponseId;
                        checkBoxResponseForNewSQ.Active = true;
                        checkBoxResponseForNewSQ.CreatedBy = localStorage.getItem("_loginId");
                        checkBoxResponseForNewSQ.CreatedDate = new Date();
                        checkBoxResponseForNewSQ.ModifiedBy = localStorage.getItem("_loginId");
                        checkBoxResponseForNewSQ.ModifiedDate = new Date();
                        checkBoxResponseForNewSQ.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId;
                        checkBoxResponseForNewSQ.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef;
                        checkBoxResponseForNewSQ.PermitTypeSubQuesAnswerOptionRef = subQuestionAnswerOptionsArray[i].PermitTypeSubQuesAnswerOptionRef;
                        checkBoxResponseForNewSQ.SelectedAnswerOptionId = null;
                        if (checkBoxAnswersSubQues) {
                          for (let j = 0; j < checkBoxAnswersSubQues.length; j++) {
                            if (subQuestionAnswerOptionsArray[i].PermitTypeSubQuesAnswerOptionId == checkBoxAnswersSubQues[j]) {
                              checkBoxResponseForNewSQ.SelectedAnswerOptionId = checkBoxAnswersSubQues[j]
                            }
                          }
                        }

                        responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(checkBoxResponseForNewSQ);
                      }
                    }
                  }
                  // handling SubQuestion Table Responses
                  else if (sq.AnswerTypeId == 9) {
                    if (this._permitObj.PermitId > 0) {
                      //Update Start
                      //let response: any;
                      let subQuestionResponseArray = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse;

                      for (let res of subQuestionResponseArray) {
                        if (res.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) {
                          subquesResponse = res;
                          break;

                        }
                      }
                      let deletedIndexes: number[];
                      deletedIndexes = [];
                      for (let rowIndex in sq.TableData) {
                        if (rowIndex.toString().startsWith('deleted')) {
                          if (sq.TableData[rowIndex.toString()] == "hidden") {
                            deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                          }
                        }
                      }


                      for (let rowIndex in sq.TableData) {
                        if (rowIndex.toString().startsWith('delete') == false) {
                          let status = this.checkRowForDeletionSQ(rowIndex, sq.PermitTypeTableSubQues, sq.TableData, deletedIndexes);
                          if (status != "SKIP") {

                            for (let header of sq.PermitTypeTableSubQues) {
                              let tblTypeResponse = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
                                this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesTableTypeResponse.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == +rowIndex) : null;

                              if (tblTypeResponse != null) {
                                let tblCheckboxResponses = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
                                  this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesTableTypeResponse.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == parseInt(rowIndex)) : null;//new TableTypeResponse();
                                for (let respObj of tblCheckboxResponses) {
                                  respObj.Active = status == "ACTIVE" ? true : false;
                                  if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                    let PermitTypeTableSQAnswerOptionRef: string | null;
                                    if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                      tblTypeResponse.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                      this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                    }
                                    else {
                                      tblTypeResponse.SelectedAnswerOptionId = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId;
                                      if (tblTypeResponse.SelectedAnswerOptionId != null && tblTypeResponse.SelectedAnswerOptionId > 0) {
                                        PermitTypeTableSQAnswerOptionRef = sq.PermitTypeTableSubQues.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId && x.AnswerTypeId == header.AnswerTypeId).AnswerOptions.find(ao => ao.PermitTypeTableSubQuesAnswerOptionId == tblTypeResponse.SelectedAnswerOptionId).PermitTypeTableSubQuesAnswerOptionRef;
                                      }
                                      tblTypeResponse.PermitTypeTableSubQuesAnswerOptionRef = PermitTypeTableSQAnswerOptionRef;
                                      this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                    }

                                  }
                                  else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                    respObj.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                    this._tableTypeSubQuestionResponse.push(respObj);

                                  }

                                }
                                if (header.AnswerTypeId == 2) {
                                  let tableFields = sq.PermitTypeTableSubQues.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId && x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId);
                                  let PermitTypeTableSQAnsOptions: PermitTypeTableSubQuesAnswerOption[] = []
                                  tableFields.AnswerOptions.forEach(x => {
                                    PermitTypeTableSQAnsOptions.push(x);
                                  })

                                  //let tblTypeResponseForCheckBox = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId==ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s=>s.PermitTypeSubQuestionId==sq.PermitTypeSubQuestionId).SubQuesTableTypeResponse.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == parseInt(rowIndex));

                                  let tblTypeResponseForCheckBox = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
                                    this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesTableTypeResponse.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == parseInt(rowIndex)) : null;

                                  let selectedCheckBoxes = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable;

                                  for (let chkbox of tblTypeResponseForCheckBox) {
                                    chkbox.SelectedAnswerOptionId = null;
                                  }
                                  for (let j = 0; j < selectedCheckBoxes.length; j++) {
                                    for (let i = 0; i < PermitTypeTableSQAnsOptions.length; i++) {

                                      if (PermitTypeTableSQAnsOptions[i].PermitTypeTableSubQuesAnswerOptionId == selectedCheckBoxes[j]) {
                                        tblTypeResponseForCheckBox[i].SelectedAnswerOptionId = selectedCheckBoxes[j]
                                        tblTypeResponseForCheckBox[i].PermitTypeTableSubQuesAnswerOptionRef = PermitTypeTableSQAnsOptions[i].PermitTypeTableSubQuesAnswerOptionRef;
                                        break;
                                      }
                                    }
                                  }
                                  tblTypeResponseForCheckBox.forEach(resp => {
                                    resp.Active = status == "ACTIVE" ? true : false;
                                    this._tableTypeSubQuestionResponse.push(resp);
                                  });
                                }

                              }
                              else {
                                let tblTypeResponse = new SubQuesTableTypeResponse();
                                tblTypeResponse.SubQuesTableTypeResponseId = 0
                                tblTypeResponse.Active = true;
                                tblTypeResponse.CreatedBy = localStorage.getItem("_loginId");
                                tblTypeResponse.CreatedDate = new Date()
                                tblTypeResponse.ModifiedBy = localStorage.getItem("_loginId");
                                tblTypeResponse.ModifiedDate = new Date()
                                tblTypeResponse.PermitTypeTableSubQuesId = header.PermitTypeTableSubQuesId;
                                tblTypeResponse.PermitTypeTableSubQuesRef = header.PermitTypeTableSubQuesRef;
                                tblTypeResponse.RowIndex = +rowIndex
                                tblTypeResponse.SubQuesResponseId = subquesResponse.SubQuesResponseId;
                                if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                  let PermitTypeTableSQAnswerOptionRef: string | null;
                                  if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                    tblTypeResponse.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                    this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                  }
                                  else {
                                    tblTypeResponse.SelectedAnswerOptionId = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId;
                                    if (tblTypeResponse.SelectedAnswerOptionId != null && tblTypeResponse.SelectedAnswerOptionId > 0) {
                                      PermitTypeTableSQAnswerOptionRef = sq.PermitTypeTableSubQues.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId && x.AnswerTypeId == header.AnswerTypeId).AnswerOptions.find(ao => ao.PermitTypeTableSubQuesAnswerOptionId == tblTypeResponse.SelectedAnswerOptionId).PermitTypeTableSubQuesAnswerOptionRef;
                                    }
                                    tblTypeResponse.PermitTypeTableSubQuesAnswerOptionRef = PermitTypeTableSQAnswerOptionRef;
                                    this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                  }

                                }
                                else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                  tblTypeResponse.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                  this._tableTypeSubQuestionResponse.push(tblTypeResponse);

                                }
                                else if (header.AnswerTypeId == 2) {
                                  let tableFields = sq.PermitTypeTableSubQues.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId && x.AnswerTypeId == header.AnswerTypeId);
                                  let PermitTypeTableAnsOptions: PermitTypeTableSubQuesAnswerOption[] = []
                                  tableFields.AnswerOptions.forEach(x => {
                                    PermitTypeTableAnsOptions.push(x);
                                  })

                                  for (let i = 0; i < PermitTypeTableAnsOptions.length; i++) {
                                    let tblTypeResponseForCheckBox = new SubQuesTableTypeResponse();
                                    tblTypeResponseForCheckBox.SubQuesTableTypeResponseId = 0
                                    tblTypeResponseForCheckBox.SubQuesResponseId = subquesResponse.SubQuesResponseId;
                                    tblTypeResponseForCheckBox.Active = true;
                                    tblTypeResponseForCheckBox.CreatedBy = localStorage.getItem("_loginId");
                                    tblTypeResponseForCheckBox.CreatedDate = new Date()
                                    tblTypeResponseForCheckBox.ModifiedBy = localStorage.getItem("_loginId");
                                    tblTypeResponseForCheckBox.ModifiedDate = new Date()
                                    tblTypeResponseForCheckBox.PermitTypeTableSubQuesId = header.PermitTypeTableSubQuesId;
                                    tblTypeResponseForCheckBox.PermitTypeTableSubQuesRef = header.PermitTypeTableSubQuesRef;
                                    tblTypeResponseForCheckBox.PermitTypeTableSubQuesAnswerOptionRef = PermitTypeTableAnsOptions[i].PermitTypeTableSubQuesAnswerOptionRef;
                                    tblTypeResponseForCheckBox.RowIndex = +rowIndex
                                    tblTypeResponseForCheckBox.SelectedAnswerOptionId = null
                                    let selectedCheckBoxes = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable;
                                    if (selectedCheckBoxes) {
                                      for (let j = 0; j < selectedCheckBoxes.length; j++) {
                                        if (PermitTypeTableAnsOptions[i].PermitTypeTableSubQuesAnswerOptionId == selectedCheckBoxes[j]) {
                                          tblTypeResponseForCheckBox.SelectedAnswerOptionId = selectedCheckBoxes[j]
                                        }
                                      }
                                    }
                                    this._tableTypeSubQuestionResponse.push(tblTypeResponseForCheckBox);
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      subquesResponse.ModifiedBy = localStorage.getItem('_loginId');
                      subquesResponse.ModifiedDate = new Date();
                      subquesResponse.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId;
                      subquesResponse.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef;
                      subquesResponse.SubQuesTableTypeResponse = this._tableTypeSubQuestionResponse;
                      responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(subquesResponse);

                      //Update End
                    }
                    else {

                      subquesResponse.SubQuesResponseId = 0
                      subquesResponse.Active = true;
                      subquesResponse.CreatedBy = localStorage.getItem("_loginId");
                      subquesResponse.CreatedDate = new Date()
                      subquesResponse.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId;
                      subquesResponse.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef;

                      let deletedIndexes: number[];
                      deletedIndexes = [];
                      for (let rowIndex in sq.TableData) {
                        if (rowIndex.toString().startsWith('deleted')) {
                          if (sq.TableData[rowIndex.toString()] == "hidden") {
                            deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                          }
                        }
                      }
                      let currentRowIndex = 0;
                      for (let rowIndex in sq.TableData) {
                        if (rowIndex.toString().startsWith('delete') == false) {

                          let status = this.checkRowForDeletionSQ(rowIndex, sq.PermitTypeTableSubQues, sq.TableData, deletedIndexes);
                          if (status != "SKIP") {
                            for (let header of sq.PermitTypeTableSubQues) {
                              let tblTypeResponse = new SubQuesTableTypeResponse();
                              tblTypeResponse.SubQuesTableTypeResponseId = 0
                              tblTypeResponse.SubQuesResponseId = 0
                              tblTypeResponse.Active = status == "ACTIVE" ? true : false;
                              tblTypeResponse.CreatedBy = localStorage.getItem("_loginId");
                              tblTypeResponse.CreatedDate = new Date()
                              tblTypeResponse.ModifiedBy = localStorage.getItem("_loginId");
                              tblTypeResponse.ModifiedDate = new Date()
                              tblTypeResponse.PermitTypeTableSubQuesId = header.PermitTypeTableSubQuesId;
                              tblTypeResponse.PermitTypeTableSubQuesRef = header.PermitTypeTableSubQuesRef;
                              tblTypeResponse.RowIndex = currentRowIndex


                              if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                let PermitTypeTableSQAnswerOptionRef: string | null;
                                if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                  tblTypeResponse.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                  this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                }
                                else {
                                  tblTypeResponse.SelectedAnswerOptionId = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId;
                                  if (tblTypeResponse.SelectedAnswerOptionId != null && tblTypeResponse.SelectedAnswerOptionId > 0) {
                                    PermitTypeTableSQAnswerOptionRef = sq.PermitTypeTableSubQues.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId && x.AnswerTypeId == header.AnswerTypeId).AnswerOptions.find(ao => ao.PermitTypeTableSubQuesAnswerOptionId == tblTypeResponse.SelectedAnswerOptionId).PermitTypeTableSubQuesAnswerOptionRef;
                                  }
                                  tblTypeResponse.PermitTypeTableSubQuesAnswerOptionRef = PermitTypeTableSQAnswerOptionRef;
                                  this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                }

                              }
                              else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                tblTypeResponse.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                this._tableTypeSubQuestionResponse.push(tblTypeResponse);

                              }
                              else if (header.AnswerTypeId == 2) {
                                let tableFields = sq.PermitTypeTableSubQues.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId && x.AnswerTypeId == header.AnswerTypeId);
                                let PermitTypeTableAnsOptions: PermitTypeTableSubQuesAnswerOption[] = []
                                tableFields.AnswerOptions.forEach(x => {
                                  PermitTypeTableAnsOptions.push(x);
                                })

                                for (let i = 0; i < PermitTypeTableAnsOptions.length; i++) {
                                  let tblTypeResponseForCheckBox = new SubQuesTableTypeResponse();
                                  tblTypeResponseForCheckBox.SubQuesTableTypeResponseId = 0
                                  tblTypeResponseForCheckBox.SubQuesResponseId = 0
                                  tblTypeResponseForCheckBox.Active = status == "ACTIVE" ? true : false;
                                  tblTypeResponseForCheckBox.CreatedBy = localStorage.getItem("_loginId");
                                  tblTypeResponseForCheckBox.CreatedDate = new Date()
                                  tblTypeResponseForCheckBox.ModifiedBy = localStorage.getItem("_loginId");
                                  tblTypeResponseForCheckBox.ModifiedDate = new Date()
                                  tblTypeResponseForCheckBox.PermitTypeTableSubQuesId = header.PermitTypeTableSubQuesId;
                                  tblTypeResponseForCheckBox.PermitTypeTableSubQuesRef = header.PermitTypeTableSubQuesRef;
                                  tblTypeResponseForCheckBox.PermitTypeTableSubQuesAnswerOptionRef = PermitTypeTableAnsOptions[i].PermitTypeTableSubQuesAnswerOptionRef;
                                  tblTypeResponseForCheckBox.RowIndex = currentRowIndex;
                                  tblTypeResponseForCheckBox.SelectedAnswerOptionId = null
                                  let selectedCheckBoxes = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable;
                                  if (selectedCheckBoxes) {
                                    for (let j = 0; j < selectedCheckBoxes.length; j++) {
                                      if (PermitTypeTableAnsOptions[i].PermitTypeTableSubQuesAnswerOptionId == selectedCheckBoxes[j]) {
                                        tblTypeResponseForCheckBox.SelectedAnswerOptionId = selectedCheckBoxes[j]
                                      }
                                    }
                                  }
                                  this._tableTypeSubQuestionResponse.push(tblTypeResponseForCheckBox);
                                }
                              }
                            }
                            currentRowIndex++;
                          }
                        }
                      }
                      subquesResponse.SubQuesTableTypeResponse = this._tableTypeSubQuestionResponse;
                      responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(subquesResponse);

                    }
                  }
                  //handling Other Types
                  else {

                    if (this._permitObj.PermitId > 0 && this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) != undefined) {

                      let subquesResponseId = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesResponseId;
                      subquesResponse.SubQuesResponseId = subquesResponseId;
                      subquesResponse.ResponseId = response.ResponseId;
                      subquesResponse.ModifiedBy = localStorage.getItem('_loginId');
                      subquesResponse.ModifiedDate = new Date();
                    }
                    else {
                      subquesResponse.SubQuesResponseId = 0;
                      subquesResponse.ResponseId = response.ResponseId;
                      subquesResponse.Active = true;
                      subquesResponse.CreatedDate = new Date();
                      subquesResponse.CreatedBy = localStorage.getItem('_loginId');
                    }
                    subquesResponse.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId
                    subquesResponse.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef
                    subquesResponse.SelectedAnswerOptionId = sq.SelectedAnswerOptionID
                    subquesResponse.SubQuesResponseText = sq.AnswerText;
                    subquesResponse.PermitTypeSubQuesAnswerOptionRef = sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                      && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID) ? sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                        && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID).PermitTypeSubQuesAnswerOptionRef : null;


                    responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(subquesResponse);

                  }


                }
                else if (ques.SelectedAnswerCheckBox != undefined && ques.SelectedAnswerCheckBox.includes(ao.PermitTypeAnswerOptionId)) {
                  ques.SelectedAnswerCheckBox.forEach(sc => {
                    if (sc == ao.PermitTypeAnswerOptionId) {
                      let subQuestionAnswerOptionsArray = sq.SubQuesAnswerOptions.filter(sqa => sqa.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId);
                      let subquesResponse = new SubQuesResponse();
                      if (this._permitObj.PermitId > 0 && this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) != undefined) {

                        subquesResponse = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId)
                      }


                      // handling SubQuestion drop downs types
                      if (sq.AnswerTypeId == 1) {
                        if (this._permitObj.PermitId > 0 && this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) != undefined) {

                          let subquesResponseId = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesResponseId;
                          subquesResponse.SubQuesResponseId = subquesResponseId;
                          subquesResponse.ResponseId = response.ResponseId;
                          subquesResponse.ModifiedBy = localStorage.getItem('_loginId');
                          subquesResponse.ModifiedDate = new Date();
                        }
                        else {
                          subquesResponse.SubQuesResponseId = 0;
                          subquesResponse.ResponseId = response.ResponseId;
                          subquesResponse.Active = true;
                          subquesResponse.CreatedDate = new Date();
                          subquesResponse.CreatedBy = localStorage.getItem('_loginId');
                        }
                        subquesResponse.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId;
                        subquesResponse.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef;
                        if (sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).DynamicDataSource == true) {
                          subquesResponse.SubQuesResponseText = sq.AnswerText;
                        }
                        else {
                          subquesResponse.SelectedAnswerOptionId = +sq.SelectedAnswerOptionID
                          subquesResponse.PermitTypeSubQuesAnswerOptionRef = sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                            && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID) ? sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                              && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID).PermitTypeSubQuesAnswerOptionRef : null;
                          subquesResponse.SubQuesResponseText = sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                            && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID) ? sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                              && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID).SubQuesOptionText : '';


                        }

                        responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(subquesResponse);
                      }
                      //handling SubQuestion checkbox
                      else if (sq.AnswerTypeId == 2) {
                        let checkBoxAnswersSubQues: number[]
                        checkBoxAnswersSubQues = sq.SelectedAnswerCheckBox
                        if (this._permitObj.PermitId > 0) {
                          if (sq.SelectedAnswerCheckBox) {
                            let checkBoxsubQuesRespArray: SubQuesResponse[] = [];
                            checkBoxsubQuesRespArray = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse;

                            checkBoxsubQuesRespArray.forEach(r => {
                              r.SelectedAnswerOptionId = null;
                              r.ModifiedBy = localStorage.getItem("_loginId");
                              r.ModifiedDate = new Date();
                              r.Active = true;
                              r.CreatedDate = new Date();
                              r.CreatedBy = localStorage.getItem("_loginId");
                              r.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId;
                              r.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef;
                            });
                            checkBoxAnswersSubQues.forEach((ans: number) => {
                              if (ans != null) {
                                let indexOfSelectedAnswerOption = subQuestionAnswerOptionsArray.findIndex(x => x.PermitTypeSubQuesAnswerOptionId == ans)
                                if (checkBoxsubQuesRespArray[indexOfSelectedAnswerOption] != undefined) {
                                  checkBoxsubQuesRespArray[indexOfSelectedAnswerOption].SelectedAnswerOptionId = ans
                                }

                              }
                            });

                            checkBoxsubQuesRespArray.forEach(x => {
                              responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(x);
                            })
                          }
                        }
                        else {
                          for (let i = 0; i < subQuestionAnswerOptionsArray.length; i++) {
                            let checkBoxResponseForNewSQ = new SubQuesResponse()
                            checkBoxResponseForNewSQ.SubQuesResponseId = 0;
                            checkBoxResponseForNewSQ.ResponseId = response.ResponseId;
                            checkBoxResponseForNewSQ.Active = true;
                            checkBoxResponseForNewSQ.CreatedBy = localStorage.getItem("_loginId");
                            checkBoxResponseForNewSQ.CreatedDate = new Date();
                            checkBoxResponseForNewSQ.ModifiedBy = localStorage.getItem("_loginId");
                            checkBoxResponseForNewSQ.ModifiedDate = new Date();
                            checkBoxResponseForNewSQ.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId;
                            checkBoxResponseForNewSQ.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef;
                            checkBoxResponseForNewSQ.PermitTypeSubQuesAnswerOptionRef = subQuestionAnswerOptionsArray[i].PermitTypeSubQuesAnswerOptionRef;
                            checkBoxResponseForNewSQ.SelectedAnswerOptionId = null;
                            if (checkBoxAnswersSubQues) {
                              for (let j = 0; j < checkBoxAnswersSubQues.length; j++) {
                                if (subQuestionAnswerOptionsArray[i].PermitTypeSubQuesAnswerOptionId == checkBoxAnswersSubQues[j]) {
                                  checkBoxResponseForNewSQ.SelectedAnswerOptionId = checkBoxAnswersSubQues[j]
                                }
                              }
                            }

                            responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(checkBoxResponseForNewSQ);
                          }
                        }
                      }
                      // handling SubQuestion Table Responses
                      else if (sq.AnswerTypeId == 9) {
                        if (this._permitObj.PermitId > 0) {
                          //Update Start
                          //let response: any;
                          let subQuestionResponseArray = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse;

                          for (let res of subQuestionResponseArray) {
                            if (res.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) {
                              subquesResponse = res;
                              break;

                            }
                          }
                          let deletedIndexes: number[];
                          deletedIndexes = [];
                          for (let rowIndex in sq.TableData) {
                            if (rowIndex.toString().startsWith('deleted')) {
                              if (sq.TableData[rowIndex.toString()] == "hidden") {
                                deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                              }
                            }
                          }


                          for (let rowIndex in sq.TableData) {
                            if (rowIndex.toString().startsWith('delete') == false) {
                              let status = this.checkRowForDeletionSQ(rowIndex, sq.PermitTypeTableSubQues, sq.TableData, deletedIndexes);
                              if (status != "SKIP") {

                                for (let header of sq.PermitTypeTableSubQues) {
                                  //let tblTypeResponse = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId==ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s=>s.PermitTypeSubQuestionId==sq.PermitTypeSubQuestionId).SubQuesTableTypeResponse.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == +rowIndex);

                                  let tblTypeResponse = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
                                    this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesTableTypeResponse.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == +rowIndex) : null;

                                  if (tblTypeResponse) {
                                    let tblCheckboxResponses = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
                                      this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesTableTypeResponse.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == parseInt(rowIndex)) : null;//new TableTypeResponse();
                                    for (let respObj of tblCheckboxResponses) {
                                      respObj.Active = status == "ACTIVE" ? true : false;
                                      if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                        if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                          tblTypeResponse.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                          this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                        }
                                        else {
                                          tblTypeResponse.SelectedAnswerOptionId = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId;
                                          this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                        }

                                      }
                                      else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                        respObj.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                        this._tableTypeSubQuestionResponse.push(respObj);

                                      }

                                    }
                                    if (header.AnswerTypeId == 2) {
                                      let tableFields = sq.PermitTypeTableSubQues.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId && x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId);
                                      let PermitTypeTableSQAnsOptions: PermitTypeTableSubQuesAnswerOption[] = []
                                      tableFields.AnswerOptions.forEach(x => {
                                        PermitTypeTableSQAnsOptions.push(x);
                                      })

                                      //let tblTypeResponseForCheckBox = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId==ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s=>s.PermitTypeSubQuestionId==sq.PermitTypeSubQuestionId).SubQuesTableTypeResponse.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == parseInt(rowIndex));
                                      let tblTypeResponseForCheckBox = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
                                        this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesTableTypeResponse.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == parseInt(rowIndex)) : null;

                                      let selectedCheckBoxes = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable;

                                      for (let chkbox of tblTypeResponseForCheckBox) {
                                        chkbox.SelectedAnswerOptionId = null;
                                      }
                                      for (let j = 0; j < selectedCheckBoxes.length; j++) {
                                        for (let i = 0; i < PermitTypeTableSQAnsOptions.length; i++) {

                                          if (PermitTypeTableSQAnsOptions[i].PermitTypeTableSubQuesAnswerOptionId == selectedCheckBoxes[j]) {
                                            tblTypeResponseForCheckBox[i].SelectedAnswerOptionId = selectedCheckBoxes[j]
                                            break;
                                          }
                                        }
                                      }
                                      tblTypeResponseForCheckBox.forEach(resp => {
                                        resp.Active = status == "ACTIVE" ? true : false;
                                        this._tableTypeSubQuestionResponse.push(resp);
                                      });
                                    }

                                  }
                                  else {
                                    let tblTypeResponse = new SubQuesTableTypeResponse();
                                    tblTypeResponse.SubQuesTableTypeResponseId = 0
                                    tblTypeResponse.Active = true;
                                    tblTypeResponse.CreatedBy = localStorage.getItem("_loginId");
                                    tblTypeResponse.CreatedDate = new Date()
                                    tblTypeResponse.ModifiedBy = localStorage.getItem("_loginId");
                                    tblTypeResponse.ModifiedDate = new Date()
                                    tblTypeResponse.PermitTypeTableSubQuesId = header.PermitTypeTableSubQuesId;
                                    tblTypeResponse.PermitTypeTableSubQuesRef = header.PermitTypeTableSubQuesRef;
                                    tblTypeResponse.RowIndex = +rowIndex
                                    tblTypeResponse.SubQuesResponseId = subquesResponse.SubQuesResponseId;
                                    if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                      if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                        tblTypeResponse.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                        this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                      }
                                      else {
                                        tblTypeResponse.SelectedAnswerOptionId = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId;
                                        this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                      }

                                    }
                                    else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                      tblTypeResponse.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                      this._tableTypeSubQuestionResponse.push(tblTypeResponse);

                                    }
                                    else if (header.AnswerTypeId == 2) {
                                      let tableFields = sq.PermitTypeTableSubQues.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId && x.AnswerTypeId == header.AnswerTypeId);
                                      let PermitTypeTableAnsOptions: PermitTypeTableSubQuesAnswerOption[] = []
                                      tableFields.AnswerOptions.forEach(x => {
                                        PermitTypeTableAnsOptions.push(x);
                                      })

                                      for (let i = 0; i < PermitTypeTableAnsOptions.length; i++) {
                                        let tblTypeResponseForCheckBox = new SubQuesTableTypeResponse();
                                        tblTypeResponseForCheckBox.SubQuesTableTypeResponseId = 0
                                        tblTypeResponseForCheckBox.SubQuesResponseId = subquesResponse.SubQuesResponseId;
                                        tblTypeResponseForCheckBox.Active = true;
                                        tblTypeResponseForCheckBox.CreatedBy = localStorage.getItem("_loginId");
                                        tblTypeResponseForCheckBox.CreatedDate = new Date()
                                        tblTypeResponseForCheckBox.ModifiedBy = localStorage.getItem("_loginId");
                                        tblTypeResponseForCheckBox.ModifiedDate = new Date()
                                        tblTypeResponseForCheckBox.PermitTypeTableSubQuesId = header.PermitTypeTableSubQuesId;
                                        tblTypeResponseForCheckBox.PermitTypeTableSubQuesRef = header.PermitTypeTableSubQuesRef;
                                        tblTypeResponseForCheckBox.RowIndex = +rowIndex
                                        tblTypeResponseForCheckBox.SelectedAnswerOptionId = null
                                        let selectedCheckBoxes = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable;
                                        if (selectedCheckBoxes) {
                                          for (let j = 0; j < selectedCheckBoxes.length; j++) {
                                            if (PermitTypeTableAnsOptions[i].PermitTypeTableSubQuesAnswerOptionId == selectedCheckBoxes[j]) {
                                              tblTypeResponseForCheckBox.SelectedAnswerOptionId = selectedCheckBoxes[j]
                                            }
                                          }
                                        }
                                        this._tableTypeSubQuestionResponse.push(tblTypeResponseForCheckBox);
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          subquesResponse.ModifiedBy = localStorage.getItem('_loginId');
                          subquesResponse.ModifiedDate = new Date();
                          subquesResponse.SubQuesTableTypeResponse = this._tableTypeSubQuestionResponse;
                          responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(subquesResponse);

                          //Update End
                        }
                        else {

                          subquesResponse.SubQuesResponseId = 0
                          subquesResponse.Active = true;
                          subquesResponse.CreatedBy = localStorage.getItem("_loginId");
                          subquesResponse.CreatedDate = new Date()
                          subquesResponse.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId;
                          subquesResponse.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef;

                          let deletedIndexes: number[];
                          deletedIndexes = [];
                          for (let rowIndex in sq.TableData) {
                            if (rowIndex.toString().startsWith('deleted')) {
                              if (sq.TableData[rowIndex.toString()] == "hidden") {
                                deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                              }
                            }
                          }
                          let currentRowIndex = 0;
                          for (let rowIndex in sq.TableData) {
                            if (rowIndex.toString().startsWith('delete') == false) {

                              let status = this.checkRowForDeletionSQ(rowIndex, sq.PermitTypeTableSubQues, sq.TableData, deletedIndexes);
                              if (status != "SKIP") {
                                for (let header of sq.PermitTypeTableSubQues) {
                                  let tblTypeResponse = new SubQuesTableTypeResponse();
                                  tblTypeResponse.SubQuesTableTypeResponseId = 0
                                  tblTypeResponse.SubQuesResponseId = 0
                                  tblTypeResponse.Active = status == "ACTIVE" ? true : false;
                                  tblTypeResponse.CreatedBy = localStorage.getItem("_loginId");
                                  tblTypeResponse.CreatedDate = new Date()
                                  tblTypeResponse.ModifiedBy = localStorage.getItem("_loginId");
                                  tblTypeResponse.ModifiedDate = new Date()
                                  tblTypeResponse.PermitTypeTableSubQuesId = header.PermitTypeTableSubQuesId;
                                  tblTypeResponse.PermitTypeTableSubQuesRef = header.PermitTypeTableSubQuesRef;
                                  tblTypeResponse.RowIndex = currentRowIndex


                                  if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                    if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                      tblTypeResponse.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                      this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                    }
                                    else {
                                      tblTypeResponse.SelectedAnswerOptionId = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId;
                                      this._tableTypeSubQuestionResponse.push(tblTypeResponse);
                                    }

                                  }
                                  else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                    tblTypeResponse.SubQuesResponseText = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText;
                                    this._tableTypeSubQuestionResponse.push(tblTypeResponse);

                                  }
                                  else if (header.AnswerTypeId == 2) {
                                    let tableFields = sq.PermitTypeTableSubQues.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId && x.AnswerTypeId == header.AnswerTypeId);
                                    let PermitTypeTableAnsOptions: PermitTypeTableSubQuesAnswerOption[] = []
                                    tableFields.AnswerOptions.forEach(x => {
                                      PermitTypeTableAnsOptions.push(x);
                                    })

                                    for (let i = 0; i < PermitTypeTableAnsOptions.length; i++) {
                                      let tblTypeResponseForCheckBox = new SubQuesTableTypeResponse();
                                      tblTypeResponseForCheckBox.SubQuesTableTypeResponseId = 0
                                      tblTypeResponseForCheckBox.SubQuesResponseId = 0
                                      tblTypeResponseForCheckBox.Active = status == "ACTIVE" ? true : false;
                                      tblTypeResponseForCheckBox.CreatedBy = localStorage.getItem("_loginId");
                                      tblTypeResponseForCheckBox.CreatedDate = new Date()
                                      tblTypeResponseForCheckBox.ModifiedBy = localStorage.getItem("_loginId");
                                      tblTypeResponseForCheckBox.ModifiedDate = new Date()
                                      tblTypeResponseForCheckBox.PermitTypeTableSubQuesId = header.PermitTypeTableSubQuesId;
                                      tblTypeResponseForCheckBox.PermitTypeTableSubQuesRef = header.PermitTypeTableSubQuesRef;
                                      tblTypeResponseForCheckBox.RowIndex = currentRowIndex;
                                      tblTypeResponseForCheckBox.SelectedAnswerOptionId = null
                                      let selectedCheckBoxes = (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable;
                                      if (selectedCheckBoxes) {
                                        for (let j = 0; j < selectedCheckBoxes.length; j++) {
                                          if (PermitTypeTableAnsOptions[i].PermitTypeTableSubQuesAnswerOptionId == selectedCheckBoxes[j]) {
                                            tblTypeResponseForCheckBox.SelectedAnswerOptionId = selectedCheckBoxes[j]
                                          }
                                        }
                                      }
                                      this._tableTypeSubQuestionResponse.push(tblTypeResponseForCheckBox);
                                    }
                                  }
                                }
                                currentRowIndex++;
                              }
                            }
                          }
                          subquesResponse.SubQuesTableTypeResponse = this._tableTypeSubQuestionResponse;
                          responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(subquesResponse);

                        }
                      }
                      //handling Other Types
                      else {

                        if (this._permitObj.PermitId > 0 && this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) != undefined) {

                          let subquesResponseId = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesResponseId;
                          subquesResponse.SubQuesResponseId = subquesResponseId;
                          subquesResponse.ResponseId = response.ResponseId;
                          subquesResponse.ModifiedBy = localStorage.getItem('_loginId');
                          subquesResponse.ModifiedDate = new Date();
                        }
                        else {
                          subquesResponse.SubQuesResponseId = 0;
                          subquesResponse.ResponseId = response.ResponseId;
                          subquesResponse.Active = true;
                          subquesResponse.CreatedDate = new Date();
                          subquesResponse.CreatedBy = localStorage.getItem('_loginId');
                        }
                        subquesResponse.PermitTypeSubQuestionId = sq.PermitTypeSubQuestionId;
                        subquesResponse.PermitTypeSubQuestionRef = sq.PermitTypeSubQuestionRef;

                        subquesResponse.SelectedAnswerOptionId = sq.SelectedAnswerOptionID
                        subquesResponse.SubQuesResponseText = sq.AnswerText;
                        subquesResponse.PermitTypeSubQuesAnswerOptionRef = sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                          && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID) ? sq.SubQuesAnswerOptions.find(x => x.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId
                            && x.PermitTypeSubQuesAnswerOptionId == sq.SelectedAnswerOptionID).PermitTypeSubQuesAnswerOptionRef : null;


                        responseArray.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.push(subquesResponse);

                      }
                    }

                  })
                }



              }) //end of Sub question loop
            }

          })

        })
      })
    });

    responseArray.forEach(sr => {
      if (sr.SubQuesResponse != undefined && sr.SubQuesResponse.length > 0)
        sr.SubQuesResponse = sr.SubQuesResponse.map(item => item)
          .filter((value, index, self) => self.indexOf(value) === index)
    })
    this._permitObj.Responses = responseArray;
    this.AssignAttachments();
    this.AssignSubQuestionAttachments();

  }

  AssignAttachments() {
    this.finalAttachmentList = [];
    this.finalAttachmentList = this.responseAttachmentFiles;
    let previousQuesId = 0;
    if (this._permitObj.PermitId > 0) {
      // this._ObjArrResponseAttachment = [];
      if (this.finalAttachmentList.length > 0) {
        this._permitObj.Responses.forEach(response => {
          let attachments = this.finalAttachmentList.filter(x => x.questionId == response.PermitTypeQuestionId);
          if (attachments && (previousQuesId == 0 || previousQuesId != response.PermitTypeQuestionId)) {
            attachments.forEach(attachment => {
              if (response.PermitTypeQuestionId == attachment.questionId) {
                let respAttachment = response.ResponseAttachment.find(x => x.ResponseId == response.ResponseId && x.AzureFileId == attachment.AzureFileId);
                if (respAttachment) {
                  // responseAttachment.ResponseAttachmentId = respAttachment.ResponseAttachmentId
                  respAttachment.AzureFileId = attachment.AzureFileId
                  respAttachment.ModifiedBy = localStorage.getItem('_loginId');
                  respAttachment.ModifiedDate = new Date();
                  respAttachment.Active = attachment.Active;
                }
                else {
                  let responseAttachment = new ResponseAttachment();
                  responseAttachment.ResponseAttachmentId = 0
                  responseAttachment.AzureFileId = attachment.AzureFileId
                  responseAttachment.ResponseId = response.ResponseId;
                  responseAttachment.Active = attachment.Active;
                  responseAttachment.CreatedBy = localStorage.getItem('_loginId');
                  responseAttachment.CreatedDate = new Date();

                  // this._ObjArrResponseAttachment.push(responseAttachment);
                  response.ResponseAttachment.push(responseAttachment)
                }


              }
            })

          }
          previousQuesId = response.PermitTypeQuestionId;
        });
      }
      else {
        this._permitObj.Responses.forEach(resp => {
          resp.ResponseAttachment = []
        })
      }
    }
    else {
      if (this.finalAttachmentList.length > 0) {
        this._ObjArrResponseAttachment = [];

        this.finalAttachmentList.forEach(attachment => {

          let responseAttachment = new ResponseAttachment();
          responseAttachment.ResponseAttachmentId = 0;
          responseAttachment.AzureFileId = attachment.AzureFileId
          responseAttachment.ResponseId = attachment.questionId;
          responseAttachment.Active = attachment.Active;
          responseAttachment.CreatedBy = localStorage.getItem('_loginId');
          responseAttachment.CreatedDate = new Date();
          responseAttachment.ModifiedBy = null;
          responseAttachment.ModifiedDate = null;
          this._ObjArrResponseAttachment.push(responseAttachment);

          let response = this._permitObj.Responses.find(resp => resp.PermitTypeQuestionId == attachment.questionId);
          response.ResponseAttachment = this._ObjArrResponseAttachment.filter(x => x.ResponseId == response.PermitTypeQuestionId);

          
        });


       
      }
    }

  }

  AssignSubQuestionAttachments() {
    this.finalSubQuestionAttachmentList = [];
    this.finalSubQuestionAttachmentList = this.subQuesResponseAttachmentFiles;
    if (this._permitObj.PermitId > 0) {
      if (this.finalSubQuestionAttachmentList.length > 0) {
        this._permitObj.Responses.forEach(response => {
          response.SubQuesResponse.forEach(sqresponse => {

            let attachments = this.finalSubQuestionAttachmentList.filter(x => x.subquestionId == sqresponse.PermitTypeSubQuestionId);
            if (attachments) {
              attachments.forEach(attachment => {
                if (sqresponse.PermitTypeSubQuestionId == attachment.subquestionId) {
                  let respAttachment = sqresponse.SubQuesResponseAttachment != undefined ?
                    sqresponse.SubQuesResponseAttachment.find(x => x.SubQuesResponseId == sqresponse.SubQuesResponseId && x.AzureFileId == attachment.AzureFileId) : null;
                  if (respAttachment != null) {
                    respAttachment.AzureFileId = attachment.AzureFileId
                    respAttachment.ModifiedBy = localStorage.getItem('_loginId');
                    respAttachment.ModifiedDate = new Date();
                    respAttachment.Active = attachment.Active;
                  }
                  else {
                    sqresponse.SubQuesResponseAttachment = [];
                    let responseAttachment = new SubQuesResponseAttachment();
                    responseAttachment.SubQuesResponseAttachmentId = 0
                    responseAttachment.AzureFileId = attachment.AzureFileId
                    responseAttachment.SubQuesResponseId = sqresponse.SubQuesResponseId;
                    responseAttachment.Active = attachment.Active;
                    responseAttachment.CreatedBy = localStorage.getItem('_loginId');
                    responseAttachment.CreatedDate = new Date();

                    sqresponse.SubQuesResponseAttachment.push(responseAttachment);

                  }


                }
              })

            }

          })

        });
      }
      else {
        this._permitObj.Responses.forEach(resp => {
          resp.SubQuesResponse.forEach(sqresp => {
            sqresp.SubQuesResponseAttachment = [];
          })
        })
      }
    }
    else {
      if (this.finalSubQuestionAttachmentList.length > 0) {
        this._ObjArrSubQuestionResponseAttachment = [];

        this.finalSubQuestionAttachmentList.forEach(attachment => {

          let responseAttachment = new SubQuesResponseAttachment();
          responseAttachment.SubQuesResponseAttachmentId = 0;
          responseAttachment.AzureFileId = attachment.AzureFileId
          responseAttachment.SubQuesResponseId = attachment.subquestionId;
          responseAttachment.Active = attachment.Active;
          responseAttachment.CreatedBy = localStorage.getItem('_loginId');
          responseAttachment.CreatedDate = new Date();
          responseAttachment.ModifiedBy = null;
          responseAttachment.ModifiedDate = null;
          this._ObjArrSubQuestionResponseAttachment.push(responseAttachment);

          let response = this._permitObj.Responses.find(resp => resp.PermitTypeQuestionId == attachment.questionId).SubQuesResponse.find(sr => sr.PermitTypeSubQuestionId == attachment.subquestionId)
          response.SubQuesResponseAttachment = this._ObjArrSubQuestionResponseAttachment.filter(x => x.SubQuesResponseId == response.PermitTypeSubQuestionId);


        });



      }
    }

  }

  OLD_SaveValues(permitStateId) {
    if (this._permitObj.PermitId > 0) {
      let languageId = localStorage.getItem("_languageID");
      this._permitObj.LanguageId = languageId;
      this._permitService.updatePermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          if (!(this._approverComments == null || this._approverComments.trim() == '')) {
            this.SaveApproverComments();
          }
          //this.getPermit(this._permitObj.PermitId, false)
          if (permitStateId == Status.DRAFT) {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.PermitSavedAsDraft') });
          }
          else if (permitStateId == Status.ACTIVE) {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.CreatePermitSuccess') });
          }
          else {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.UpdatePermitSuccess') });
          }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.UpdatePermitError') });
        }
      });
    }
    else {
      this._permitService.addPermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          
          //this.getPermit(data.body.PermitId, false);
          let redirectURL = '/permit/' + this._permitTypeId + '/' + data.body.PermitId;
          if (permitStateId == Status.DRAFT) {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.PermitSavedAsDraft') });
          }
          else {
            this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.CreatePermitSuccess') });
          }
          this._router.navigate([redirectURL]);

        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CreatePermitError') });
        }
      });
    }
  }

  nullifyPermitDetails() {
    this._permitObj.PermitId = 0;
    this._permitObj.PermitIssuedDateTime = null;
    this._permitObj.PermitExpiryDateTime = null;
    this._permitObj.RevalidationHours = 0;
    this._permitObj.PermitNumber = null;
    this._permitObj.PermitActivityLogs = null;
    this._permitObj.PermitState = null;
    this._permitObj.PermitStateId = null;
    this._permitObj.CreatedBy = null;
    this._permitObj.CreatedDate = null;
    this._permitObj.ModifiedBy = null;
    this._permitObj.ModifiedDate = null;
    this.permitIssuedDateTime = null;
    this.permitExpiryDateTime = null;
  }

  OLD_getPermit(permitId: number, permitCloneFlag: boolean) {
    if (permitCloneFlag) {
      this._permitService.getPermit(permitId).subscribe((permit: Permit) => {
        if (permit) {
          this._permitObj = permit;
          this.PopulateResponsesForPermit(permitCloneFlag);
          this.nullifyPermitDetails();
          this.permitClosedBy = permit.ClosedByName;
        }
      });
    }
    else {
      if (permitId > 0) {
        this._permitService.getPermit(permitId).subscribe((permit: Permit) => {
          if (permit != undefined) {
            this._permitObj = permit;
            this._commentsCount = this._permitObj.CommentsCount;
            if (this._currentLoggedInUserRoles != undefined) {              
              if (this._permitObj.PermitStateId == Status.ACTIVE) {
                this.isPermitActive = true;
              }
              this._PermitVersion = this.fetchVersionBasedOnPermit();
              this._permitActivityLog = [];
              this._activeQuestions = [];
              this._permitTypeLevelApprovalList = [];
              this._permitTypeLevelApprovalList = this._PermitVersion.PermitType.PermitTypeLevelApprovals.filter(x => x.Active == true);
              this.GetActiveQuestions();
              this.GetSequenceWiseSections();
              this.GetActiveSequence();
              this.GetPermitLevelSequence();
              this.GetDataForPermitStatus();
              this.getPermitControlsForPermitVersion();
              this.PopulateResponsesForPermit(false);
              this.PopulateAttachmentForPermit();
              this.GetPermitCreatedByUserDetails(this._permitObj.PermitId);
              this.GetMaxOccurance(permitId);
              this.permitClosedBy = permit.ClosedByName;
            }
          }
          else {
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.PermitNotFoundError') });
            let redirectURL = '/permit/' + this._permitTypeId + '/0';
            this._router.navigate([redirectURL]);
          }
        });
      }
    }

  }

  async PopulateAttachmentForPermit() {
    this.responseAttachedFiles = [];
    let flag = 0;
    let quesId;
    this._permitObj.Responses.forEach(response => {
      this.responseAttachmentFiles = [];
      if (response.ResponseAttachment.length > 0) {
        response.ResponseAttachment.forEach(async attachment => {
          if (response.Question.AnswerTypeId == 2) {
            if (quesId != response.PermitTypeQuestionId) {
              flag = 0;
            }
            if (flag == 0) {
              this.SetAttachedFilesArray(attachment.AzureFileId, response.PermitTypeQuestionId, attachment.Active);
              await this.DelayExecution();
              quesId = response.PermitTypeQuestionId;
              flag++;
            }
          }
          else {
            this.SetAttachedFilesArray(attachment.AzureFileId, response.PermitTypeQuestionId, attachment.Active);
            await this.DelayExecution();
          }
        })
      }
    })
  }

  async PopulateSubQuestionAttachmentForPermit() {
    this.subQuestionResponseAttachedFiles = [];
    let flag = 0;
    let subQuesId;
    let AnswerTypeId = 0;
    this._permitObj.Responses.forEach(response => {
      response.SubQuesResponse.forEach(sqResponse => {

        this.subQuesResponseAttachmentFiles = [];
        if (sqResponse.SubQuesResponseAttachment.length > 0) {
          sqResponse.SubQuesResponseAttachment.forEach(async sqAttachment => {
            AnswerTypeId = this._subQuestionArray.length > 0 ?
              this._subQuestionArray.find(x => x.PermitTypeSubQuestionId == sqResponse.PermitTypeSubQuestionId).AnswerTypeId : 0;
            if (AnswerTypeId == 2) {
              if (subQuesId != sqResponse.PermitTypeSubQuestionId) {
                flag = 0;
              }
              if (flag == 0) {
                this.SubQuestionSetAttachedFilesArray(sqAttachment.AzureFileId, response.PermitTypeQuestionId, sqResponse.PermitTypeSubQuestionId, sqAttachment.Active);
                await this.DelayExecution();
                subQuesId = sqResponse.PermitTypeSubQuestionId;
                flag++;
              }
            }
            else {
              this.SubQuestionSetAttachedFilesArray(sqAttachment.AzureFileId, response.PermitTypeQuestionId, sqResponse.PermitTypeSubQuestionId, sqAttachment.Active);
              await this.DelayExecution();
            }

          })
        }

      })

    })
  }

  // Method to get the checkbox responses for a permit
  bindCheckBoxResponses(ques: PermitTypeQuestion, permitCloneFlag: boolean): number[] {
    if (permitCloneFlag) {
      let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;
      if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) //copying from current version
      {
        let responses = this._permitObj.Responses.filter(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId);
        let arrayForCheckBox: number[] = [];
        responses.forEach(response => {
          arrayForCheckBox.push(response.SelectedAnswerOptionId);
        });
        return arrayForCheckBox;
      }
      else  //copying from previous version
      {
        let arrayForCheckBox: number[] = [];
        let responses = this._permitObj.Responses.filter(x => x.PermitTypeQuestionRef == ques.PermitTypeQuestionRef && x.SelectedAnswerOptionId != null);

        responses.forEach(response => {
          let arrayNewAnswerOption = ques.AnswerOptions.filter(x => x.PermitTypeAnswerOptionRef == response.PermitTypeAnswerOptionRef);
          arrayNewAnswerOption.forEach(ao => {
            arrayForCheckBox.push(ao.PermitTypeAnswerOptionId);
          })

        });
        return arrayForCheckBox;
      }

    }
    else {
      let responses = this._permitObj.Responses.filter(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId);
      let arrayForCheckBox: number[] = [];
      responses.forEach(response => {
        arrayForCheckBox.push(response.SelectedAnswerOptionId);
      });
      return arrayForCheckBox;
    }

  }

  bindCheckBoxResponsesSubQuestion(sq: PermitTypeSubQuestion, ques: PermitTypeQuestion, ao: PermitTypeAnswerOption, permitCloneFlag: boolean): number[] {
    if (permitCloneFlag) {
      let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;
      let arrayForCheckBoxSQ: number[] = [];
      let arrayAnswerOptionSQ: PermitTypeSubQuesAnswerOption[] = [];

      if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) //copying from current version
      {

        let responses = this._permitObj.Responses.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.filter(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId);
        responses.forEach(response => {
          arrayForCheckBoxSQ.push(response.SelectedAnswerOptionId);
        });
        return arrayForCheckBoxSQ;
      }
      else //copying from previous version
      {
        let responses = this._permitObj.Responses.find(x => x.PermitTypeQuestionRef == ques.PermitTypeQuestionRef && x.PermitTypeAnswerOptionRef == ao.PermitTypeAnswerOptionRef && x.SelectedAnswerOptionId != null).SubQuesResponse.filter(s => s.PermitTypeSubQuestionRef == sq.PermitTypeSubQuestionRef && s.SelectedAnswerOptionId != null);
        responses.forEach(r => {
          let arrayNewSQAnswerOption = sq.SubQuesAnswerOptions.filter(ao => ao.PermitTypeSubQuesAnswerOptionRef == r.PermitTypeSubQuesAnswerOptionRef);
          arrayNewSQAnswerOption.forEach(sao => {
            arrayForCheckBoxSQ.push(sao.PermitTypeSubQuesAnswerOptionId);
          })
        });
        return arrayForCheckBoxSQ;
      }


    }
    else {
      let responses = this._permitObj.Responses.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.filter(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId);
      let arrayForCheckBoxSQ: number[] = [];
      responses.forEach(response => {
        arrayForCheckBoxSQ.push(response.SelectedAnswerOptionId);
      });
      return arrayForCheckBoxSQ;
    }

  }

  // Method to get responses for a permit
  bindResponseText(ques: PermitTypeQuestion, permitCloneFlag: boolean) {   
    if (permitCloneFlag) {
      let ansText: string = null;
      let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;

      if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) //copying from current version
      {
        ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
        if (ques.AnswerTypeId == 10 || ques.AnswerTypeId == 7) {          
          let language = parseInt(localStorage.getItem("_languageID"));
          if (language != 3) 
          ansText = this.formatAMPM(ansText);
        }
        return ansText;
      }
      else {
        if (this._permitObj.Responses.filter(r => r.PermitTypeQuestionRef == ques.PermitTypeQuestionRef).length > 0) {
          ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionRef == ques.PermitTypeQuestionRef).ResponseText;
          if (ques.AnswerTypeId == 10 || ques.AnswerTypeId == 7) {
            let language = parseInt(localStorage.getItem("_languageID"));
            if (language != 3) 
            ansText = this.formatAMPM(ansText);
          }
          return ansText;
        }
        else {
          return ansText;
        }
      }
    }
    else {
      let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
      if (ques.AnswerTypeId == 10 || ques.AnswerTypeId == 7) {
        let language = parseInt(localStorage.getItem("_languageID"));
        if (language != 3) 
        ansText = this.formatAMPM(ansText);
      }
      return ansText;

    }
  }
  bindResponseTextSubQuestion(sq: PermitTypeSubQuestion, ques: PermitTypeQuestion, ao: PermitTypeAnswerOption, permitCloneFlag: boolean) {
    if (permitCloneFlag) {
      let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;

      if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) //copying from current version
      {
        let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
          this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesResponseText : null;
        if (sq.AnswerTypeId == 10 || sq.AnswerTypeId == 7) {
          let language = parseInt(localStorage.getItem("_languageID"));
          if (language != 3) 
          ansText = this.formatAMPM(ansText);
        }
        return ansText;
      }
      else {
        let ansText = this._permitObj.Responses.find(x => x.PermitTypeQuestionRef == ques.PermitTypeQuestionRef && x.SelectedAnswerOptionId > 0).SubQuesResponse.length > 0 ?
          this._permitObj.Responses.find(x => x.PermitTypeQuestionRef == ques.PermitTypeQuestionRef && x.SelectedAnswerOptionId > 0).SubQuesResponse.find(s => s.PermitTypeSubQuestionRef == sq.PermitTypeSubQuestionRef).SubQuesResponseText : null;

        if (sq.AnswerTypeId == 10 || sq.AnswerTypeId == 7) {
          let language = parseInt(localStorage.getItem("_languageID"));
          if (language != 3) 
          ansText = this.formatAMPM(ansText);
        }
        return ansText;

      }

    }
    else {
      let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
        this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesResponseText : null;
      if (sq.AnswerTypeId == 10 || sq.AnswerTypeId == 7) {
        let language = parseInt(localStorage.getItem("_languageID"));
        if (language != 3) 
        ansText = this.formatAMPM(ansText);
      }
      return ansText;
    }

  }

  bindSelectedAnswerOptionId(ques: PermitTypeQuestion, permitCloneFlag: boolean): number {
    if (permitCloneFlag) {
      let ansID: number = 0;
      let AnswerOptionRef: string = null;
      let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;

      if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) //copying from current version
      {
        let ansID = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).SelectedAnswerOptionId;
        return ansID;
      }
      else {
        if (this._permitObj.Responses.filter(r => r.PermitTypeQuestionRef == ques.PermitTypeQuestionRef).length > 0) {
          ansID = this._permitObj.Responses.find(r => r.PermitTypeQuestionRef == ques.PermitTypeQuestionRef).SelectedAnswerOptionId;
          if (ansID > 0) {
            AnswerOptionRef = this._permitObj.Responses.find(r => r.SelectedAnswerOptionId == ansID).PermitTypeAnswerOptionRef;
            let arrayForAnswerOption = ques.AnswerOptions.find(a => a.PermitTypeAnswerOptionRef == AnswerOptionRef) != undefined ? ques.AnswerOptions.find(a => a.PermitTypeAnswerOptionRef == AnswerOptionRef) : null;
            return arrayForAnswerOption.PermitTypeAnswerOptionId;
          }
        }
        else {

          return ansID;
        }

      }
    }
    else {
      let ansID = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).SelectedAnswerOptionId;
      return ansID;
    }

  }

  bindSelectedAnswerOptionIdSubQuestion(sq: PermitTypeSubQuestion, ques: PermitTypeQuestion, ao: PermitTypeAnswerOption, permitCloneFlag: boolean): number {
    if (permitCloneFlag) {
      let sqAnsID: number = 0;
      let sqAnswerOptionRef: string = null;
      let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;

      if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) //copying from current version
      {
        let ansID = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
          this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SelectedAnswerOptionId : null;
        return ansID;
      }
      else {
        if (this._permitObj.Responses.find(x => x.PermitTypeQuestionRef == ques.PermitTypeQuestionRef && x.SelectedAnswerOptionId > 0).SubQuesResponse.filter(s => s.PermitTypeSubQuestionRef == sq.PermitTypeSubQuestionRef).length > 0) {
          sqAnsID = this._permitObj.Responses.find(x => x.PermitTypeQuestionRef == ques.PermitTypeQuestionRef && x.SelectedAnswerOptionId > 0).SubQuesResponse.find(s => s.PermitTypeSubQuestionRef == sq.PermitTypeSubQuestionRef).SelectedAnswerOptionId;
          if (sqAnsID > 0) {
            sqAnswerOptionRef = this._permitObj.Responses.find(x => x.PermitTypeQuestionRef == ques.PermitTypeQuestionRef && x.SelectedAnswerOptionId > 0).SubQuesResponse.find(s => s.SelectedAnswerOptionId == sqAnsID).PermitTypeSubQuesAnswerOptionRef;
            let arrayAnswerOptionSQ = sq.SubQuesAnswerOptions.find(ao => ao.PermitTypeSubQuesAnswerOptionRef == sqAnswerOptionRef);
            return arrayAnswerOptionSQ.PermitTypeSubQuesAnswerOptionId;
          }
        }
        else {
          return sqAnsID;
        }
      }
    }
    else {
      let ansID = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
        this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SelectedAnswerOptionId : null;
      return ansID;
    }
  }

  PopulateResponsesForPermit(permitCloneFlag: boolean) {
    if (permitCloneFlag) {
      this._PermitVersion.Divisions.forEach((div: PermitTypeDivision) => {
        div.Sections.forEach((sec: PermitTypeSection) => {
          sec.Questions.forEach((ques: PermitTypeQuestion) => {
            if (ques.CopyQuestionValue) {
              if (ques.AnswerTypeId == 2) {
                ques.SelectedAnswerCheckBox = this.bindCheckBoxResponses(ques, permitCloneFlag);

                //For Sub Question Response
                ques.AnswerOptions.forEach(ao => {
                  if (ques.SelectedAnswerCheckBox.includes(ao.PermitTypeAnswerOptionId)) {
                    ao.IsSelected = true;
                    if (ao.SubQuestions.length > 0) {
                      ao.SubQuestions.forEach(sq => {
                        if (sq.AnswerTypeId == 2) {
                          sq.SelectedAnswerCheckBox = this.bindCheckBoxResponsesSubQuestion(sq, ques, ao, permitCloneFlag);
                        }
                        else if (sq.AnswerTypeId == 9) {
                          sq.TableData = [];
                          let tableTypeResponses: SubQuesTableTypeResponse[] = [];
                          let deletedTableTypeResponses: SubQuesTableTypeResponse[] = [];
                          let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;

                          this._permitObj.Responses.forEach(x => {
                            if (x.PermitTypeQuestionRef == ques.PermitTypeQuestionRef) {
                              x.SubQuesResponse.forEach(sr => {
                                if (sr.PermitTypeSubQuestionRef == sq.PermitTypeSubQuestionRef) {
                                  tableTypeResponses = sr.SubQuesTableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                                  deletedTableTypeResponses = sr.SubQuesTableTypeResponse.filter(x => x.Active == false);

                                  if (tableTypeResponses.length > 0) {
                                    sq.TableData = [];
                                    const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                                    let distinctRowIndexes = new Set(indexArray);
                                    let counter = 0;

                                    const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                                    let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                                    distinctRowIndexes.forEach(idx => {

                                      let rowCheck = 0;

                                      deletedDistinctRowIndexes.forEach(function (value) {

                                        if (idx == value) {
                                          rowCheck++;

                                        }
                                      });

                                      sq.TableData[counter] = [];

                                      for (let header of sq.PermitTypeTableSubQues) {
                                        {
                                          sq.TableData[counter][header.ColumnName] = {};
                                        }
                                      }

                                      for (let header of sq.PermitTypeTableSubQues) {
                                        if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) //Copy from current permit version)
                                        {
                                          if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                            if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                              (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                            }
                                            else {
                                              (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId;

                                              (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                                            }
                                          }
                                          else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                            (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                          }
                                          else if (header.AnswerTypeId == 2) {
                                            let responses: number[] = []
                                            tableTypeResponses.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).forEach(x => {
                                              if (x.SelectedAnswerOptionId != null) {
                                                responses.push(x.SelectedAnswerOptionId);
                                              }
                                            });
                                            (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                                          }
                                        }
                                        else {
                                          if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                            if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                              (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).SubQuesResponseText;
                                            }
                                            else {
                                              let sqAnsID: number = 0;
                                              let sqTableAnswerOptionRef: string | null;
                                              sqAnsID = tableTypeResponses.find(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).SelectedAnswerOptionId;
                                              sqTableAnswerOptionRef = tableTypeResponses.find(x => x.SelectedAnswerOptionId == sqAnsID).PermitTypeTableSubQuesAnswerOptionRef;

                                              if (sqTableAnswerOptionRef != null) {
                                                (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId = header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionRef == sqTableAnswerOptionRef).PermitTypeTableSubQuesAnswerOptionId;
                                              }


                                              (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                                            }
                                          }
                                          else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                            (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).SubQuesResponseText;
                                          }
                                          else if (header.AnswerTypeId == 2) {
                                            let responses: number[] = []
                                            tableTypeResponses.filter(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).forEach(x => {
                                              if (x.SelectedAnswerOptionId != null) {
                                                let arrayNewSQTableAnswerOption = header.AnswerOptions.filter(ao => ao.PermitTypeTableSubQuesAnswerOptionRef == x.PermitTypeTableSubQuesAnswerOptionRef);
                                                arrayNewSQTableAnswerOption.forEach(ntao => {
                                                  responses.push(ntao.PermitTypeTableSubQuesAnswerOptionId);
                                                })

                                              }
                                            });
                                            (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                                          }


                                        }

                                      }
                                      counter++;
                                    });
                                  }
                                }
                              })
                            }
                          });
                        }
                        else {

                          let SelectedAnswerOptionIDSQ = this.bindSelectedAnswerOptionIdSubQuestion(sq, ques, ao, permitCloneFlag);
                          sq.SelectedAnswerOptionID = SelectedAnswerOptionIDSQ != undefined ? SelectedAnswerOptionIDSQ : null;
                          sq.AnswerText = this.bindResponseTextSubQuestion(sq, ques, ao, permitCloneFlag);

                        }


                      })
                    }

                  }

                })
              }
              else if (ques.AnswerTypeId == 9) {
                ques.TableData = [];
                let tableTypeResponses: TableTypeResponse[] = [];
                let deletedTableTypeResponses: TableTypeResponse[] = [];

                this._permitObj.Responses.forEach(x => {
                  if (x.PermitTypeQuestionRef == ques.PermitTypeQuestionRef) {
                    tableTypeResponses = x.TableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                    deletedTableTypeResponses = x.TableTypeResponse.filter(x => x.Active == false);

                    if (tableTypeResponses.length > 0) {
                      ques.TableData = [];
                      const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                      let distinctRowIndexes = new Set(indexArray);
                      let counter = 0;

                      const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                      let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                      distinctRowIndexes.forEach(idx => {

                        let rowCheck = 0;

                        deletedDistinctRowIndexes.forEach(function (value) {

                          if (idx == value) {
                            rowCheck++;

                          }
                        });
                        

                        ques.TableData[counter] = [];

                        for (let header of ques.TableFieldType) {
                          {
                            ques.TableData[counter][header.ColumnName] = {};
                          }
                        }

                        for (let header of ques.TableFieldType) {
                          let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;
                          if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) //Copy from current permit version
                          {
                            if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                              if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                              }
                              else {
                                (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;
                                // (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).tabl;
                                (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                              }
                            }
                            else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                              (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                            }
                            else if (header.AnswerTypeId == 2) {
                              let responses: number[] = []
                              tableTypeResponses.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).forEach(x => {
                                if (x.SelectedAnswerOptionId != null) {
                                  responses.push(x.SelectedAnswerOptionId);
                                }
                              });
                              (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                            }
                          }
                          else {
                            if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                              if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.PermitTypeTableFieldTypeRef == header.PermitTypeTableFieldTypeRef && x.RowIndex == idx).ResponseText;
                              }
                              else {
                                let ansID: number = 0;
                                let TableAnswerOptionRef: string | null;

                                ansID = tableTypeResponses.find(x => x.PermitTypeTableFieldTypeRef == header.PermitTypeTableFieldTypeRef && x.RowIndex == idx).SelectedAnswerOptionId;
                                TableAnswerOptionRef = tableTypeResponses.find(x => x.SelectedAnswerOptionId == ansID).PermitTypeTableAnswerOptionRef;
                                if (TableAnswerOptionRef != null) {
                                  (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionRef == TableAnswerOptionRef).PermitTypeTableAnswerOptionId;
                                }
                                else {
                                  (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = 0;
                                }


                                (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableFieldTypeRef == header.PermitTypeTableFieldTypeRef && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableFieldTypeRef == header.PermitTypeTableFieldTypeRef && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                              }
                            }
                            else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                              (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.PermitTypeTableFieldTypeRef == header.PermitTypeTableFieldTypeRef && x.RowIndex == idx).ResponseText;
                            }
                            else if (header.AnswerTypeId == 2) {
                              let responses: number[] = [];
                              tableTypeResponses.filter(x => x.PermitTypeTableFieldTypeRef == header.PermitTypeTableFieldTypeRef && x.RowIndex == idx).forEach(x => {
                                if (x.SelectedAnswerOptionId != null) {
                                  let arrayNewTableAnswerOption = header.AnswerOptions.filter(ao => ao.PermitTypeTableAnswerOptionRef == x.PermitTypeTableAnswerOptionRef);
                                  arrayNewTableAnswerOption.forEach(ntao => {
                                    responses.push(ntao.PermitTypeTableAnswerOptionId);
                                  })

                                }
                              });
                              (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                            }
                          }


                        }
                        counter++;
                      });
                    }
                  }
                });
              }
              else if (ques.AnswerTypeId == 11) {
                let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;
                if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) {
                  let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
                  if (ansText != undefined && ansText != null && ansText.trim() != "") {
                    let data = ansText.split("@@@");
                    ques.FieldValue = data[0];
                    ques.FieldDesc = data[1];
                    if (data.length >= 3) {
                      if (data[2].trim() == "NULL") {
                        ques.ProcName = '';
                      }
                      else {
                        ques.ProcName = data[2];
                      }
                      if (data[3].trim() == "NULL") {
                        ques.ProcRef = '';
                        this._procRefVal = '';
                      }
                      else {
                        ques.ProcRef = data[3];
                        this._procRefVal = data[3];
                      }
                      if (data[4] != undefined && data[4] != null) {
                        if (data[4].trim() == "NULL") {
                          ques.RiskAssesment = '';
                          //this._riskAssesmentVal = '';
                        }
                        else {
                          ques.RiskAssesment = data[4];
                          this._riskAssesmentVal = data[4];
                        }
                      }
                    }

                  }
                }
                else {
                  if (this._permitObj.Responses.filter(r => r.PermitTypeQuestionRef == ques.PermitTypeQuestionRef).length > 0) {
                    let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionRef == ques.PermitTypeQuestionRef).ResponseText;
                    if (ansText != undefined && ansText != null && ansText.trim() != "") {
                      let data = ansText.split("@@@");
                      ques.FieldValue = data[0];
                      ques.FieldDesc = data[1];
                      if (data.length >= 3) {
                        if (data[2].trim() == "NULL") {
                          ques.ProcName = '';
                        }
                        else {
                          ques.ProcName = data[2];
                        }
                        if (data[3].trim() == "NULL") {
                          ques.ProcRef = '';
                          this._procRefVal = '';
                        }
                        else {
                          ques.ProcRef = data[3];
                          this._procRefVal = data[3];
                        }
                        if (data[4] != undefined && data[4] != null) {
                          if (data[4].trim() == "NULL") {
                            ques.RiskAssesment = '';
                            //this._riskAssesmentVal = '';
                          }
                          else {
                            ques.RiskAssesment = data[4];
                            this._riskAssesmentVal = data[4];
                          }
                        }
                      }

                    }
                  }
                }

              }
              else if (ques.AnswerTypeId == 13) {
                let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;
                if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) {
                  let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
                  if (ansText != undefined && ansText != null && ansText.trim() != "") {
                    let data = ansText.split("@@@");
                    ques.Approver = data[0];
                    ques.TimeStamp = data[1];
                    ques.ContactNumber = data[2];
                  }
                }
                else {
                  if (this._permitObj.Responses.filter(r => r.PermitTypeQuestionRef == ques.PermitTypeQuestionRef).length > 0) {
                    let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionRef == ques.PermitTypeQuestionRef).ResponseText;
                    if (ansText != undefined && ansText != null && ansText.trim() != "") {
                      let data = ansText.split("@@@");
                      ques.Approver = data[0];
                      ques.TimeStamp = data[1];
                      ques.ContactNumber = data[2];
                    }
                  }

                }

              }
              else {
                ques.SelectedAnswerOptionID = this.bindSelectedAnswerOptionId(ques, permitCloneFlag);
                ques.AnswerText = this.bindResponseText(ques, permitCloneFlag);

                //Sub Question Section
                if (ques.AnswerTypeId == 1 || ques.AnswerTypeId == 4) {
                  let PermitTypeAnswerOptionRef: string = null;
                  if (ques.SelectedAnswerOptionID > 0) {
                    PermitTypeAnswerOptionRef = ques.AnswerOptions.find(ao => ao.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID && ques.SelectedAnswerOptionID > 0).PermitTypeAnswerOptionRef;
                  }

                  ques.AnswerOptions.forEach(ao => {
                    if (ao.PermitTypeAnswerOptionRef == PermitTypeAnswerOptionRef && PermitTypeAnswerOptionRef != null && ques.SelectedAnswerOptionID != undefined) {
                      ao.IsSelected = true;
                      if (ao.SubQuestions.length > 0) {
                        ao.SubQuestions.forEach(sq => {
                          this._subQuestionArray.push(sq);
                          if (sq.AnswerTypeId == 2) {
                            sq.SelectedAnswerCheckBox = this.bindCheckBoxResponsesSubQuestion(sq, ques, ao, permitCloneFlag);
                          }
                          else if (sq.AnswerTypeId == 9) {
                            sq.TableData = [];
                            let tableTypeResponses: SubQuesTableTypeResponse[] = [];
                            let deletedTableTypeResponses: SubQuesTableTypeResponse[] = [];
                            let currentPermitTypeVersionId = this._PermitVersionList[0].PermitTypeVersionId;

                            this._permitObj.Responses.forEach(x => {
                              if (x.PermitTypeQuestionRef == ques.PermitTypeQuestionRef) {
                                x.SubQuesResponse.forEach(sr => {
                                  if (sr.PermitTypeSubQuestionRef == sq.PermitTypeSubQuestionRef) {
                                    tableTypeResponses = sr.SubQuesTableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                                    deletedTableTypeResponses = sr.SubQuesTableTypeResponse.filter(x => x.Active == false);

                                    if (tableTypeResponses.length > 0) {
                                      sq.TableData = [];
                                      const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                                      let distinctRowIndexes = new Set(indexArray);
                                      let counter = 0;

                                      const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                                      let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                                      distinctRowIndexes.forEach(idx => {

                                        let rowCheck = 0;

                                        deletedDistinctRowIndexes.forEach(function (value) {

                                          if (idx == value) {
                                            rowCheck++;

                                          }
                                        });

                                        sq.TableData[counter] = [];

                                        for (let header of sq.PermitTypeTableSubQues) {
                                          {
                                            sq.TableData[counter][header.ColumnName] = {};
                                          }
                                        }

                                        for (let header of sq.PermitTypeTableSubQues) {
                                          if (currentPermitTypeVersionId == this._permitObj.PermitTypeVersionId) //Copy from current permit version)
                                          {

                                            if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                              if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                                (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                              }
                                              else {
                                                (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId;

                                                (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                                              }
                                            }
                                            else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                              (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                            }
                                            else if (header.AnswerTypeId == 2) {
                                              let responses: number[] = []
                                              tableTypeResponses.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).forEach(x => {
                                                if (x.SelectedAnswerOptionId != null) {
                                                  responses.push(x.SelectedAnswerOptionId);
                                                }
                                              });
                                              (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                                            }
                                          }
                                          else {
                                            if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                              if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                                (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).SubQuesResponseText;
                                              }
                                              else {
                                                let sqAnsID: number = 0;
                                                let sqTableAnswerOptionRef: string | null;
                                                sqAnsID = tableTypeResponses.find(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).SelectedAnswerOptionId;
                                                sqTableAnswerOptionRef = tableTypeResponses.find(x => x.SelectedAnswerOptionId == sqAnsID).PermitTypeTableSubQuesAnswerOptionRef;

                                                (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId = header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionRef == sqTableAnswerOptionRef).PermitTypeTableSubQuesAnswerOptionId;

                                                (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                                              }
                                            }
                                            else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                              (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).SubQuesResponseText;
                                            }
                                            else if (header.AnswerTypeId == 2) {
                                              let responses: number[] = []
                                              tableTypeResponses.filter(x => x.PermitTypeTableSubQuesRef == header.PermitTypeTableSubQuesRef && x.RowIndex == idx).forEach(x => {
                                                if (x.SelectedAnswerOptionId != null) {
                                                  let arrayNewSQTableAnswerOption = header.AnswerOptions.filter(ao => ao.PermitTypeTableSubQuesAnswerOptionRef == x.PermitTypeTableSubQuesAnswerOptionRef);
                                                  arrayNewSQTableAnswerOption.forEach(ntao => {
                                                    responses.push(ntao.PermitTypeTableSubQuesAnswerOptionId);
                                                  })

                                                }
                                              });
                                              (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                                            }

                                          }

                                        }
                                        counter++;
                                      });
                                    }
                                  }
                                })
                              }
                            });
                          }
                          else {
                            let SelectedAnswerOptionIDSQ = this.bindSelectedAnswerOptionIdSubQuestion(sq, ques, ao, permitCloneFlag);
                            sq.SelectedAnswerOptionID = SelectedAnswerOptionIDSQ != undefined ? SelectedAnswerOptionIDSQ : null;
                            sq.AnswerText = this.bindResponseTextSubQuestion(sq, ques, ao, permitCloneFlag);

                          }


                        })
                      }

                    }
                  })
                }
              }
            }
          })
        })
      });

    }
    else {
      this._PermitVersion.Divisions.forEach((div: PermitTypeDivision) => {
        div.Sections.forEach((sec: PermitTypeSection) => {
          sec.Questions.forEach((ques: PermitTypeQuestion) => {
            if (ques.AnswerTypeId == 2) {
              ques.SelectedAnswerCheckBox = this.bindCheckBoxResponses(ques, permitCloneFlag);

              //For Sub Question Response
              ques.AnswerOptions.forEach(ao => {
                if (ques.SelectedAnswerCheckBox.includes(ao.PermitTypeAnswerOptionId)) {
                  ao.IsSelected = true;
                  if (ao.SubQuestions.length > 0) {
                    ao.SubQuestions.forEach(sq => {
                      this._subQuestionArray.push(sq);
                      if (sq.AnswerTypeId == 2) {
                        sq.SelectedAnswerCheckBox = this.bindCheckBoxResponsesSubQuestion(sq, ques, ao, permitCloneFlag);
                      }
                      else if (sq.AnswerTypeId == 9) {
                        sq.TableData = [];
                        let tableTypeResponses: SubQuesTableTypeResponse[] = [];
                        let deletedTableTypeResponses: SubQuesTableTypeResponse[] = [];

                        this._permitObj.Responses.forEach(x => {
                          if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                            x.SubQuesResponse.forEach(sr => {
                              if (sr.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) {
                                tableTypeResponses = sr.SubQuesTableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                                deletedTableTypeResponses = sr.SubQuesTableTypeResponse.filter(x => x.Active == false);

                                if (tableTypeResponses.length > 0) {
                                  sq.TableData = [];
                                  const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                                  let distinctRowIndexes = new Set(indexArray);
                                  let counter = 0;

                                  const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                                  let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                                  distinctRowIndexes.forEach(idx => {

                                    let rowCheck = 0;

                                    deletedDistinctRowIndexes.forEach(function (value) {

                                      if (idx == value) {
                                        rowCheck++;

                                      }
                                    });

                                    sq.TableData[counter] = [];

                                    for (let header of sq.PermitTypeTableSubQues) {
                                      {
                                        sq.TableData[counter][header.ColumnName] = {};
                                      }
                                    }

                                    for (let header of sq.PermitTypeTableSubQues) {

                                      if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                        if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                          (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                        }
                                        else {
                                          (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId;

                                          (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                                        }
                                      }
                                      else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                        (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                      }
                                      else if (header.AnswerTypeId == 2) {
                                        let responses: number[] = []
                                        tableTypeResponses.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).forEach(x => {
                                          if (x.SelectedAnswerOptionId != null) {
                                            responses.push(x.SelectedAnswerOptionId);
                                          }
                                        });
                                        (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                                      }
                                    }
                                    counter++;
                                  });
                                }
                              }
                            })
                          }
                        });
                      }
                      else {

                        let SelectedAnswerOptionIDSQ = this.bindSelectedAnswerOptionIdSubQuestion(sq, ques, ao, permitCloneFlag);
                        sq.SelectedAnswerOptionID = SelectedAnswerOptionIDSQ != undefined ? SelectedAnswerOptionIDSQ : null;
                        sq.AnswerText = this.bindResponseTextSubQuestion(sq, ques, ao, permitCloneFlag);

                      }

                    })
                  }

                }

              })
            }
            else if (ques.AnswerTypeId == 9) {
              ques.TableData = [];
              let tableTypeResponses: TableTypeResponse[] = [];
              let deletedTableTypeResponses: TableTypeResponse[] = [];

              this._permitObj.Responses.forEach(x => {
                if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                  tableTypeResponses = x.TableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                  deletedTableTypeResponses = x.TableTypeResponse.filter(x => x.Active == false);

                  if (tableTypeResponses.length > 0) {
                    ques.TableData = [];
                    const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                    let distinctRowIndexes = new Set(indexArray);
                    let counter = 0;

                    const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                    let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                    distinctRowIndexes.forEach(idx => {
                      let rowCheck = 0;
                      deletedDistinctRowIndexes.forEach(function (value) {
                        if (idx == value) {
                          rowCheck++;
                        }
                      });
                     
                      ques.TableData[counter] = [];

                      for (let header of ques.TableFieldType) {
                        {
                          ques.TableData[counter][header.ColumnName] = {};
                        }
                      }

                      for (let header of ques.TableFieldType) {
                        if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                          //(ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;
                          if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                          }
                          else {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;

                            let tableTypeResponseObj = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx);
                            if (tableTypeResponseObj) {
                              let headerObj = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponseObj.SelectedAnswerOptionId);
                              if (headerObj) {
                                (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = headerObj.OptionText;
                              }
                            }

                            // (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText;
                          }
                        }
                        else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                          (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                        }
                        else if (header.AnswerTypeId == 2) {
                          let responses: number[] = []
                          tableTypeResponses.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).forEach(x => {
                            if (x.SelectedAnswerOptionId != null) {
                              responses.push(x.SelectedAnswerOptionId);
                            }
                          });
                          (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                        }
                      }
                      counter++;
                    });
                  }
                }
              });
            }
            else if (ques.AnswerTypeId == 11) {
              let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
              if (ansText != undefined && ansText != null && ansText.trim() != "") {
                let data = ansText.split("@@@");
                ques.FieldValue = data[0];
                ques.FieldDesc = data[1];
                if (data.length >= 3) {
                  if (data[2].trim() == "NULL") {
                    ques.ProcName = '';
                  }
                  else {
                    ques.ProcName = data[2];
                  }
                  if (data[3].trim() == "NULL") {
                    ques.ProcRef = '';
                    this._procRefVal = '';
                  }
                  else {
                    ques.ProcRef = data[3];
                    this._procRefVal = data[3];
                  }
                  if (data[4] != undefined && data[4] != null) {
                    if (data[4].trim() == "NULL") {
                      ques.RiskAssesment = '';
                      this._riskAssesmentVal = '';
                    }
                    else {
                      ques.RiskAssesment = data[4];
                      this._riskAssesmentVal = data[4];
                    }
                  }
                }
              }
            }
            else if (ques.AnswerTypeId == 13) {
              let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;
              if (ansText != undefined && ansText != null && ansText.trim() != "") {
                let data = ansText.split("@@@");
                ques.Approver = data[0];
                ques.TimeStamp = data[1];
                ques.ContactNumber = data[2];
              }
            }
            else {
              ques.SelectedAnswerOptionID = this.bindSelectedAnswerOptionId(ques, permitCloneFlag);
              ques.AnswerText = this.bindResponseText(ques, permitCloneFlag);

              //Sub Question Section
              if (ques.AnswerTypeId == 1 || ques.AnswerTypeId == 4) {
                ques.AnswerOptions.forEach(ao => {
                  if (ao.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID && ques.SelectedAnswerOptionID != undefined) {
                    ao.IsSelected = true;
                    if (ao.SubQuestions.length > 0) {
                      ao.SubQuestions.forEach(sq => {
                        this._subQuestionArray.push(sq);
                        if (sq.AnswerTypeId == 2) {
                          sq.SelectedAnswerCheckBox = this.bindCheckBoxResponsesSubQuestion(sq, ques, ao, permitCloneFlag);
                        }
                        else if (sq.AnswerTypeId == 9) {
                          sq.TableData = [];
                          let tableTypeResponses: SubQuesTableTypeResponse[] = [];
                          let deletedTableTypeResponses: SubQuesTableTypeResponse[] = [];

                          this._permitObj.Responses.forEach(x => {
                            if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                              x.SubQuesResponse.forEach(sr => {
                                if (sr.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) {
                                  tableTypeResponses = sr.SubQuesTableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                                  deletedTableTypeResponses = sr.SubQuesTableTypeResponse.filter(x => x.Active == false);

                                  if (tableTypeResponses.length > 0) {
                                    sq.TableData = [];
                                    const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                                    let distinctRowIndexes = new Set(indexArray);
                                    let counter = 0;

                                    const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                                    let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                                    distinctRowIndexes.forEach(idx => {

                                      let rowCheck = 0;

                                      deletedDistinctRowIndexes.forEach(function (value) {

                                        if (idx == value) {
                                          rowCheck++;

                                        }
                                      });

                                      sq.TableData[counter] = [];

                                      for (let header of sq.PermitTypeTableSubQues) {
                                        {
                                          sq.TableData[counter][header.ColumnName] = {};
                                        }
                                      }

                                      for (let header of sq.PermitTypeTableSubQues) {

                                        if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                          if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                            (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                          }
                                          else {
                                            (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId;

                                            (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                                          }
                                        }
                                        else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                          (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                        }
                                        else if (header.AnswerTypeId == 2) {
                                          let responses: number[] = []
                                          tableTypeResponses.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).forEach(x => {
                                            if (x.SelectedAnswerOptionId != null) {
                                              responses.push(x.SelectedAnswerOptionId);
                                            }
                                          });
                                          (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                                        }
                                      }
                                      counter++;
                                    });
                                  }
                                }
                              })
                            }
                          });
                        }
                        else {
                          let SelectedAnswerOptionIDSQ = this.bindSelectedAnswerOptionIdSubQuestion(sq, ques, ao, permitCloneFlag);
                          sq.SelectedAnswerOptionID = SelectedAnswerOptionIDSQ != undefined ? SelectedAnswerOptionIDSQ : null;
                          sq.AnswerText = this.bindResponseTextSubQuestion(sq, ques, ao, permitCloneFlag);

                        }


                      })
                    }

                  }
                })
              }
            }
          })
        })

      });
    }
  }

  fetchVersionBasedOnPermit(): PermitTypeVersion {
    
    return this._PermitVersionList.find(x => x.PermitTypeVersionId == this._permitObj.PermitTypeVersionId);
  }

  getPermitControlsForPermitVersion() {
    this._permitControlService.getPermitTypeControl(this._PermitVersion.PermitTypeVersionId).subscribe((permitControl: PermitControl) => {
      this._permitControl = permitControl;
      if (permitControl) {
        if (permitControl.AutoLapse) {
          this.checkRevalidation = true;

          // this.revalidationHours = permitControl.AutoLapseHours != null ? +permitControl.AutoLapseHours.AutoLapseText : 0;
          if (permitControl.AutoLapseHours) {

            this.revalidationHours = (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Years) ? (+permitControl.AutoLapseHours.AutoLapseText * 24 * 365)
              : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Months) ? (+permitControl.AutoLapseHours.AutoLapseText * 24 * 30)
                : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Weeks) ? (+permitControl.AutoLapseHours.AutoLapseText * 24 * 7)
                  : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Days) ? (+permitControl.AutoLapseHours.AutoLapseText * 24)
                    : +permitControl.AutoLapseHours.AutoLapseText

            this.revalidationPeriod = permitControl.AutoLapseHours.AutoLapseText.concat(
              (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Years) ? ' Year(s)'
                : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Months) ? ' Month(s)'
                  : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Weeks) ? ' Week(s)'
                    : (permitControl.AutoLapseHours.TimeOptionId == TimeOption.Days) ? ' Day(s)'
                      : ' Hour(s)'

            );

          }
        }

        if (permitControl.AutoClose) {
          if (permitControl.AutoCloseHours) {

            this.revalidationHours = (permitControl.AutoCloseHours.TimeOptionId == TimeOption.Years) ? (+permitControl.AutoCloseHours.AutoCloseHour * 24 * 365)
              : (permitControl.AutoCloseHours.TimeOptionId == TimeOption.Months) ? (+permitControl.AutoCloseHours.AutoCloseHour * 24 * 30)
                : (permitControl.AutoCloseHours.TimeOptionId == TimeOption.Weeks) ? (+permitControl.AutoCloseHours.AutoCloseHour * 24 * 7)
                  : (permitControl.AutoCloseHours.TimeOptionId == TimeOption.Days) ? (+permitControl.AutoCloseHours.AutoCloseHour * 24)
                    : (permitControl.AutoCloseHours.TimeOptionId == TimeOption.Hours) ? (+permitControl.AutoCloseHours.AutoCloseHour * 1)
                      : +permitControl.AutoCloseHours



          }
        }
        if (permitControl.Revalidation) {
          if (this._permitObj.PermitStateId == PermitStates.SUSPENDED || this._permitObj.PermitStateId == PermitStates.LAPSED) {
            this._canBeRevalidated = true

            if (permitControl.rolesForRevalidation == null || permitControl.rolesForRevalidation == undefined || permitControl.rolesForRevalidation.length == 0) {
              this._canBeRevalidated = true
            }
            else if (permitControl.rolesForRevalidation && permitControl.rolesForRevalidation.length > 0 && this._currentLoggedInUserRoles) {
              for (let i = 0; i < permitControl.rolesForRevalidation.length; i++) {
                if (permitControl.rolesForRevalidation[i].RoleId != null || permitControl.rolesForRevalidation[i].RoleId > 0) {
                  if (this._currentLoggedInUserRoles.includes(permitControl.rolesForRevalidation[i])) {
                    this._canBeRevalidated = true;
                    break;
                  }
                }
                else {
                  this._canBeRevalidated = true;
                  break;
                }
              }
            }
          }
          else if (this._permitObj.PermitStateId == PermitStates.ACTIVE && permitControl.AutoLapse) {

            if (permitControl.rolesForRevalidation && permitControl.rolesForRevalidation.length > 0 && this._currentLoggedInUserRoles) {

              this.allRoles.forEach(x => {
                if (x.RoleKey == "ENTRYLEADER") {
                  for (let i = 0; i < permitControl.rolesForRevalidation.length; i++) {
                    // if (permitControl.rolesForRevalidation[i].RoleId == x.RoleId ) {
                    //if (this._currentLoggedInUserRoles.includes(permitControl.rolesForRevalidation[i])) {
                    if (permitControl.rolesForRevalidation[i].RoleId != null || permitControl.rolesForRevalidation[i].RoleId > 0) {
                      if (this.entryLeaderRole.includes(x.RoleId)) {
                        this._canBeRevalidated = true;
                        this.isRevalidateBeforeLapse = true;
                        break;
                      }
                    }
                  }
                }
              });
            }
          }
        }
        if (permitControl.GeoTag) {
          if (this._permitObj.PermitStateId == Status.ACTIVE || this._permitObj.PermitStateId == Status.DRAFT ||
            this._permitObj.PermitStateId == undefined || this._permitObj.PermitStateId == null ||
            this._permitObj.PermitStateId == Status.AWAITINGEDITORRESPONSE || this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {
            this.geoTag = permitControl.GeoTag;
            if (permitControl.SymbologyList && permitControl.SymbologyList.length > 0) {
              sessionStorage.setItem('lineSymbology', JSON.stringify(permitControl.SymbologyList[0]));


              let lineSymbology = permitControl.SymbologyList[0];
              lineSymbology.name = "lineSymbology";
              let areaSymbology = permitControl.SymbologyList[1];
              this._indexDbService.addRecordAsync("mapJson", lineSymbology).subscribe(data => {
              });
              this._indexDbService.addRecordAsync("mapJson", areaSymbology).subscribe(data => {
              });
              if (this._permitTypeName) {
                let permitType: any = {};
                permitType.name = "permitType";
                permitType.permitType = this._permitTypeName;
                this._indexDbService.addRecordAsync("mapJson", permitType).subscribe(data => {
                });
              }
              let site: any = {};
              site.name = "site";
              if (this._permitObj.SiteName) {
                site.sitename = this._permitObj.SiteName;
              }
              else {
                site.sitename = this.userHierarchy.SiteName;
              }
              this._indexDbService.editRecordAsync("mapJson", site).subscribe((data) => {
                // console.log("Site Name", data);

              });
              if (this._permitId == 0) {
                let featEditable: any = {};
                featEditable.name = "Editable";
                featEditable.Editable = true;
                this._indexDbService.editRecordAsync("mapJson", featEditable).subscribe((data) => {
                });
              }
              let arcGISKey = {
                key: this._commonService.getArcGISKey(),
                name: 'arcGISKey'
              }
              this._indexDbService.addRecordAsync("mapJson", arcGISKey).subscribe();

              sessionStorage.setItem('areaSymbology', JSON.stringify(permitControl.SymbologyList[1]));
            }
          }
        }
        else {
          // this._canBeRevalidated = false
        }
        if (this._canBeRevalidated) {
          if (this.revisionCounter < permitControl.MaxOccurrance.MaxOccurence) {
            this._maxOccursExhausted = false
          }
          else {
            this._maxOccursExhausted = true
          }
        }

        if (permitControl.IssuedDateTimeEditable) {
          this.permitDateTimeEditable = true;
        }
        let DefaultPermitNoText='Permit No';
        let DefaultPermitExpiryDateTimeText='Permit Expire Date/Time';
        let DefaultIssueDateTimeText='Permit Issue Date/Time';
        let DefaultPermitAreaText='Area';
        let DefaultPermitRequestorText='Permit Requestor Name';
        let DefaultPermitSiteText='Site';
        
        // check if translations have been changed, then get from DB else get it 
        //from tranlation file.
        if(permitControl.PermitNoText!=DefaultPermitNoText) 
        {
          this.HasPermitNoTextChanged=true;
        }
        if(permitControl.PermitIssueDateTimeText!=DefaultIssueDateTimeText) 
          {
            this.HasPermitIssueDateTimeText=true;
          }
          if(permitControl.PermitExpireDateTimeText!=DefaultPermitExpiryDateTimeText) 
            {
              this.HasPermitExpireDateTimeText=true;
            }
            if(permitControl.PermitRequestorText!=DefaultPermitRequestorText) 
              {
                this.HasRequestorNameTextChanged=true;
              }
              if(permitControl.SiteText!=DefaultPermitSiteText) 
                {
                  this.HasSiteTextChanged=true;
                }
                if(permitControl.AreaText!=DefaultPermitAreaText) 
                  {
                    this.HasAreaTextChanged=true;
                  }
          
        this.DisablePermitIssuedDateTime();

      }
    });
  }

 

  // Fetches all the active questions for this permit that needs to be edited based on the Logged-In user (Editor)
  GetActiveQuestions() {

    // Fethes active questiones based on Editor from the FIRST DIVISION as the permit status is PENDINGAPPROVAL
    if (this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {

      this._PermitVersion.Divisions[0].Sections.forEach(sec => {
        sec.Questions.forEach(ques => {

          if (this._PermitVersion.Divisions[0].Editor != null) {
            let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[0].Editor);
            if (userRole != undefined) {
              this._activeQuestions.push(ques.PermitTypeQuestionId);
            }
          }
          else {
            if (sec.Editor != null) {
              let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor);
              if (userRole != undefined) {
                this._activeQuestions.push(ques.PermitTypeQuestionId);
              }
            }
            else if (sec.Editor == null) {
              this._activeQuestions.push(ques.PermitTypeQuestionId);
            }
          }
        })
      })

      // Removes the questions from from the list that have already been approved or edited
      // If a response is approved, editing it is not required
      this._permitObj.Responses.forEach(res => {
        if (res.Approved == true || res.Edited == true) {
          this._activeQuestions = this._activeQuestions.filter(x => x != res.PermitTypeQuestionId)
        }
      })

    }

    // Fethes active questiones based on Editor from the SECOND & THIRD DIVISION as the permit status is ACTIVE
    if (this._permitObj.PermitStateId == Status.ACTIVE) {
      for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {
        let div = this._PermitVersion.Divisions[i];
        div.Sections.forEach(sec => {
          sec.Questions.forEach(ques => {

            if (div.Editor != null) {
              let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == div.Editor);
              if (userRole != undefined) {
                this._activeQuestions.push(ques.PermitTypeQuestionId);
              }
            }
            else {
              if (sec.Editor != null) {
                let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor);
                if (userRole != undefined) {
                  this._activeQuestions.push(ques.PermitTypeQuestionId);
                }
              }
              else if (sec.Editor == null) {
                this._activeQuestions.push(ques.PermitTypeQuestionId);
              }
            }

          })
        })
      }

      // Removes the questions from from the list that have already been approved or edited
      // If a response is approved, editing it is not required
      this._permitObj.Responses.forEach(res => {
        if (res.Approved == true || res.Edited == true) {
          this._activeQuestions = this._activeQuestions.filter(x => x != res.PermitTypeQuestionId)
        }
      })
    }

    // Removes duplicate questions from from the list
    this.RemoveDuplicateActiveQuestions();
  }

  GetSequenceWiseSections() {
    this._sequenceList = [];
    let versionObj: PermitTypeVersion = JSON.parse(JSON.stringify(this._PermitVersion));
    versionObj.Divisions.forEach(div => {
      let obj = {
        Division: div,
        SectionSequence: this.GetSequenceWiseSectionsBasedOnDivision(div)
      }
      this._sequenceList.push(obj);
    })
  }

  GetSequenceWiseSectionsBasedOnDivision(division: PermitTypeDivision) {

    let secList = division.Sections;

    if (secList.length == 0) {
      return [];
    }

    secList = secList.sort((x1, x2) => x1.SequenceNumber - x2.SequenceNumber);

    let seqList: any[] = [];
    let sectionIdLst: any[] = [];

    sectionIdLst.push(secList[0]);
    let obj = {
      SeqNumber: secList[0].SequenceNumber,
      Sections: sectionIdLst
    }
    seqList.push(obj);

    for (let i = 1; i < secList.length; i++) {
      if (seqList[seqList.length - 1].SeqNumber == secList[i].SequenceNumber) {
        sectionIdLst = [];
        sectionIdLst = seqList[seqList.length - 1].Sections;
        sectionIdLst.push(secList[i]);
        seqList[seqList.length - 1].Sections = sectionIdLst;
      }
      else {
        sectionIdLst = [];
        sectionIdLst.push(secList[i]);
        let obj = {
          SeqNumber: secList[i].SequenceNumber,
          Sections: sectionIdLst
        }
        seqList.push(obj);
      }
    }

    return seqList;
  }

  RemoveDuplicateActiveQuestions() {
    let tempList = Object.assign(this._activeQuestions);
    this._activeQuestions = [];
    this._activeQuestions.push(tempList[0]);
    tempList.forEach(val => {
      if (!this._activeQuestions.find(x => x == val || x == undefined)) {
        this._activeQuestions.push(val);
      }
    })
  }

  OLD_SaveResponse() {
    // if (this.ErrorCount() == 0) {
    if (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null) {
      this.getPermiTypeDetails(this._PermitVersion.PermitTypeId)
      this.AssignValues();

      if (this._permitObj != null && this._permitObj != undefined && this._permitObj.PermitStateId >= Status.PENDINGAPPROVAL) {
        this.BindEditorState(0);
        this._permitObj.PermitActivityLogs = this.RemoveDuplicateLogs();
      }
      this._permitObj.PermitStateId = (this._permitObj == null || this._permitObj.PermitStateId == undefined || this._permitObj.PermitStateId == Status.DRAFT) ? Status.DRAFT : this._permitObj.PermitStateId;

      if (this._permitObj.PermitStateId == Status.DRAFT) {
        this.SaveValues();
      }
      else {
        this.SaveValues();
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    }
    // }
  }

  isResponseValid() {
    errors: Number;
    if (this.ErrorCount() == 0) {
      var Hierarchy = (document.getElementById("selectedHierarchy") as HTMLSpanElement).innerText;
      if (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null && Hierarchy.search("SITE")) {
        this.getPermiTypeDetails(this._PermitVersion.PermitTypeId)
        this.AssignValues();
        this._permitObj.PermitStateId = 2;
        this.SaveValues();
      }
      else {
        //this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
      }
    }
  }

  OLD_ErrorCount() {
    let errorCount: number = 0;
    let permitDivision = new PermitTypeDivision()
    if (this._permitObj.PermitStateId == 3) {
      permitDivision = this._PermitVersion.Divisions[1]
    }
    else {
      permitDivision = this._PermitVersion.Divisions[0]
    }
    for (let section of permitDivision.Sections) {
      for (let question of section.Questions) { // Non table question
        if (question.AnswerTypeId != 9) {
          if (question.Mandatory) {
            if (question.AnswerTypeId == 1) {

              if (question.AnswerOptions[0].DynamicDataSource) {
                // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
                if (question.AnswerText == undefined || question.AnswerText == '0') {
                  this._messageService.clear('error');
                  this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                  errorCount++;
                  return errorCount;
                }
              }
              else {
                if (question.SelectedAnswerOptionID == undefined || question.SelectedAnswerOptionID == 0) {
                  this._messageService.clear('error');
                  this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                  errorCount++;
                  return errorCount;
                }
              }

            }
            if (question.AnswerTypeId == 2) {
              if (question.SelectedAnswerCheckBox == undefined || question.SelectedAnswerCheckBox.length == 0) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                errorCount++;
                return errorCount;
              }
            }
            if (question.AnswerTypeId == 3) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.AnswerText == undefined) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
            }
            if (question.AnswerTypeId == 4) {
              if (question.SelectedAnswerOptionID == undefined || question.SelectedAnswerOptionID == 0) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                errorCount++;
                return errorCount;
              }
            }
            if (question.AnswerTypeId == 6) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.AnswerText == undefined) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
            }

            if (question.AnswerTypeId == 11) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.FieldDesc == undefined || question.FieldDesc.trim() == "") {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
              if (question.FieldValue == undefined || question.FieldValue.trim() == "") {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }

            }


            if (question.AnswerTypeId == 12) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.AnswerText == undefined) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
            }

            if (question.AnswerTypeId == 7 || question.AnswerTypeId == 10) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.AnswerText == undefined) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
            }

            if (question.AnswerTypeId == 10) {
              // if (question.AnswerText == undefined || question.AnswerText.trim() == "") {
              if (question.AnswerText == undefined) {
                this._messageService.clear('error');
                this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                errorCount++;
                return errorCount;
              }
            }
          }
        }
        else {
          let isTableMandatory: boolean = false;
          for (let header of question.TableFieldType) {
            if (header.Mandatory) {
              isTableMandatory = true;
            }
          }
          if (isTableMandatory && this.GetRowCount(document.getElementById("TableId" + question.PermitTypeQuestionId)) < 2) {
            this._messageService.clear('error');
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTableBlank') });
            errorCount++;
            return errorCount;
          }

          //For table 
          let deletedIndexes: number[];
          deletedIndexes = [];
          for (let rowIndex in question.TableData) {
            if (rowIndex.toString().startsWith('deleted')) {
              if (question.TableData[rowIndex.toString()] == "hidden") {
                deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
              }
            }
          }
          for (let rowIndex in question.TableData) {
            if (rowIndex.toString().startsWith('delete') == false) {
              if (!this.IsRowDeleted(rowIndex, deletedIndexes)) {
                for (let header of question.TableFieldType) {
                  if (header.Mandatory) {

                    if (header.AnswerTypeId == 1) {

                      if (header.AnswerOptions[0].DynamicDataSource) {
                        if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == "") {
                          this._messageService.clear('error');
                          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                          errorCount++;
                          return errorCount;
                        }
                      }
                      else {
                        if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                          this._messageService.clear('error');
                          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                          errorCount++;
                          return errorCount;
                        }
                      }

                      if (header.AnswerOptions[0].DynamicDataSource) {
                        if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == "") {
                          this._messageService.clear('error');
                          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                          errorCount++;
                          return errorCount;
                        }
                      }
                      else {
                        if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                          this._messageService.clear('error');
                          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                          errorCount++;
                          return errorCount;
                        }
                      }

                    }
                    if (header.AnswerTypeId == 2) {
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable.length == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }
                    if (header.AnswerTypeId == 3) {
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }
                    if (header.AnswerTypeId == 4) {
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }
                    if (header.AnswerTypeId == 6) {
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }

                    if (header.AnswerTypeId == 7) {// TODO:
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }

                    if (header.AnswerTypeId == 10) {// TODO:
                      if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                        this._messageService.clear('error');
                        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
                        errorCount++;
                        return errorCount;
                      }
                    }
                  }
                }
              }
            }
          }

        }
      }
    }
    return errorCount;
  }

  OLD_SubmitPermitResponse() {
    this._confirmationService.confirm({
      message: this.translate.instant('Permit.ConfirmSumbitMsg'),
      header: this.translate.instant('Permit.ConfirmSubmitHeader'),
      key: 'confirmSubmit',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.SubmitResponse();
      },
      reject: () => {
      }
    });
  }

  OLD_SubmitResponse() {

    if (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null) {
      if (this.ErrorCount() == 0) {
        this.AssignValues();
        if (this._permitObj != null && this._permitObj != undefined && this._permitObj.PermitStateId >= Status.PENDINGAPPROVAL) {
          this.BindEditorState(1);
          this._permitObj.PermitActivityLogs = this.RemoveDuplicateLogs();
          this.BindEditorResponse(true);
        }
        this._permitObj.PermitStateId = (this._permitObj == null || this._permitObj.PermitStateId == undefined || this._permitObj.PermitStateId == Status.DRAFT) ? this.CheckIfApproverPresentAtPermitTypeLevelAndCreatePermitSectionLevel() : this._permitObj.PermitStateId;
        this._permitObj.PermitState = null;
        if (this._permitObj.PermitStateId >= Status.ACTIVE) {
          this.SetPermitTime()
        }
        this.SaveValues();
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    }

  }

  // Check if any appover is defined for the FIRST DIVISION.
  // If no approver is present, mark the permit as ACTIVE upon submit
  CheckIfApproverPresentAtPermitTypeLevelAndCreatePermitSectionLevel() {
    let count = 0;

    this._PermitVersion.Divisions[0].Sections.forEach(sec => {
      if (sec.Approver != null) {
        count++;
      }
    })

    if (this._permitTypeLevelApprovalList.length > 0 || count > 0) {
      return Status.PENDINGAPPROVAL;
    }

    else {
      return Status.ACTIVE;
    }
  }

  onOK() {
    this._messageService.clear('error');
    this._messageService.clear('success');
  }

  // Logic to show / hide the SAVE & SUBMIT button based on
  // 1. Logged-In user
  // 2. Permit State
  // 3. Active Questions
  OLD_showSaveSubmitButton() {
    let count = 0;
    if (this._permitObj) {
      // When the permit is created for the first time, the button should be visible for the user to SAVE/SUBMIT the permit
      if (this._permitObj.PermitStateId == undefined) {
        count++;
      }
      // When the permit is opened if draft mode, the buttons are visible only to the creator of the permit
      else if (this._permitObj.PermitStateId == Status.DRAFT && this._currentLoggedInUser) {
        if (this._currentLoggedInUser.LoginId == this._permitObj.CreatedBy) {
          count++;
        }
      }
      // When the permit has been submitted and is pending for approval, 
      // the buttons are visible only if the Logged-In user is the Editor of active-question sections/division.
      // Only the FIRST DIVISION is considered as the permit state is PENDINGAPPROVAL
      else if (this._permitObj.PermitStateId == Status.PENDINGAPPROVAL && this._currentLoggedInUserRoles) {
        if (this._PermitVersion.Divisions[0].Editor != null) {
          if (this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[0].Editor)) {
            this._PermitVersion.Divisions[0].Sections.forEach(sec => {
              sec.Questions.forEach(ques => {
                if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                  count++;
                }
              })
            })
          }
        }
        else {
          this._PermitVersion.Divisions[0].Sections.forEach(sec => {
            if (sec.Editor != null) {
              if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor)) {
                sec.Questions.forEach(ques => {
                  if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                    count++;
                  }
                })
              }
            }
            else {
              sec.Questions.forEach(ques => {
                if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                  count++;
                }
              })
            }
          })
        }
      }
      // When the permit is ACTIVE, 
      // the buttons are visible only if the Logged-In user is the Editor of active-question sections/division.
      // The SECOND & THIRD DIVISION is considered as the permit state is ACTIVE
      else if (this._permitObj.PermitStateId == Status.ACTIVE && this._currentLoggedInUserRoles) {
        if (this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[1].Editor)) {
          this._PermitVersion.Divisions[1].Sections.forEach(sec => {
            sec.Questions.forEach(ques => {
              if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                count++;
              }
            })
          })
        }
        else if (this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[2].Editor)) {
          this._PermitVersion.Divisions[2].Sections.forEach(sec => {
            sec.Questions.forEach(ques => {
              if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                count++;
              }
            })
          })
        }
        else {
          for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {
            let div = this._PermitVersion.Divisions[i];
            div.Sections.forEach(sec => {
              if (sec.Editor != null) {
                if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor)) {
                  sec.Questions.forEach(ques => {
                    if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                      count++;
                    }
                  })
                }
              }
              else if (sec.Editor == null) {
                sec.Questions.forEach(ques => {
                  if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                    count++;
                  }
                })
              }
            })
          }
        }
      }
    }
    if (count > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  GetActiveSequence() {

    this._activeSequence = [];

    if (this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {
      this.GetActiveSequenceList(this._sequenceList[0]);
    }

    else if (this._permitObj.PermitStateId == Status.ACTIVE) {
      for (let i = 1; i < this._sequenceList.length; i++) {
        this.GetActiveSequenceList(this._sequenceList[i]);
      }
    }
  }

  GetActiveSequenceList(sequenceObj) {
    let sectionSeq = sequenceObj.SectionSequence;

    for (let i = 0; i < sectionSeq.length; i++) {
      if (sectionSeq[i].SeqNumber == null) {
        continue;
      }

      let sectionApproved: number = 0;
      for (let j = 0; j < sectionSeq[i].Sections.length; j++) {
        let sec = sectionSeq[i].Sections[j];
        for (let k = 0; k < sec.Questions.length; k++) {
          let ques = sec.Questions[k];

          let response = this._permitObj.Responses.filter(res => res.PermitTypeQuestionId == ques.PermitTypeQuestionId && res.Approved == true)
          if (response && response.length > 0) {
            sectionApproved++;
            break;
          }
        }
      }

      if (sectionApproved < sectionSeq[i].Sections.length) {
        let obj = {
          DivisionId: sequenceObj.Division.PermitTypeDivisionId,
          SectionSequence: sectionSeq[i].SeqNumber
        }
        this._activeSequence.push(obj);
        break;
      }
    }
  }

  GetPermitLevelSequence() {

    if (!this.IsFirstDivisionApproved()) {
      return;
    }

    let objList: PermitTypeLevelApproval[] = this._permitTypeLevelApprovalList;
    let list = [];
    list.push(null);
    for (let i = 0; i < objList.length; i++) {
      if (!list.find(x => x == objList[i].ApprovalSequence)) {
        list.push(objList[i].ApprovalSequence);
      }
    }
    list = list.sort((x1, x2) => x1 - x2).filter(x => x != null);

    let flag = 0;
    for (let j = 0; j < list.length; j++) {
      let approvalList = objList.filter(x => x.ApprovalSequence == list[j]);

      for (let k = 0; k < approvalList.length; k++) {
        let permitLevelObj = this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == approvalList[k].PermitTypeLevelApprovalId && x.PermitStateId == Status.PERMITLEVELAPPROVED);
        if (permitLevelObj == undefined) {
          flag++;
          break;
        }
      }

      if (flag != 0) {
        this._activePermitLevelSequence = list[j];
        break;
      }
    }
  }

  OLD_showApproveRejectButton() {
    let count = 0;
    if (this._permitObj.PermitId > 0 && this._currentLoggedInUserRoles && this._activeSequence) {
      if (this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {
        if (!this.IsFirstDivisionApproved()) {
          this._PermitVersion.Divisions[0].Sections.forEach(sec => {
            let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
            if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
              if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
                let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
                if (logSectionObj == undefined) {
                  count++;
                }
              }
            }
          })
        }
        else {
          let permitLevelApprovalList = this._permitTypeLevelApprovalList.filter(x => x.ApprovalSequence == null || x.ApprovalSequence == this._activePermitLevelSequence);
          permitLevelApprovalList.forEach(item => {
            if (this._currentLoggedInUserRoles.find(x => x.RoleId == item.Approver)) {
              let logPermitLevelObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == item.PermitTypeLevelApprovalId && x.PermitStateId == Status.PERMITLEVELAPPROVED);
              if (logPermitLevelObj == undefined) {
                count++;
              }
            }
          })
        }
      }
      else if (this._permitObj.PermitStateId == Status.ACTIVE) {
        // for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {
        this._PermitVersion.Divisions[1].Sections.forEach(sec => {
          let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
          if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
            if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
              let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
              if (logSectionObj == undefined) {
                count++;
              }
            }
          }
        })
        // }
      }
    }
    if (count > 0) {
      return true;
    }
    else {
      return false;
    }
  }

  OLD_showInputElementsForEditor(divisonObj, sectionObj, questionObj) {
    if (this._permitObj && this._currentLoggedInUser) {

      // check if division / section has already been approved before edit
      if (this._permitObj.PermitActivityLogs && this._permitObj.PermitActivityLogs.length > 0 && this._permitObj.PermitActivityLogs != null) {
        if (this._permitObj.PermitActivityLogs.find(x => (x.PermitStateId == Status.DIVISIONAPPROVED || x.PermitStateId == Status.DIVISIONEDITED) && x.ResponseTagNumber == divisonObj.PermitTypeDivisionId)) {
          return true;
        }
        if (this._permitObj.PermitActivityLogs.find(x => (x.PermitStateId == Status.SECTIONAPPROVED || x.PermitStateId == Status.SECTIONEDITED) && x.ResponseTagNumber == sectionObj.PermitTypeSectionId)) {
          return true;
        }
      }

      // first time load or draft
      if (this._permitObj.PermitStateId == Status.DRAFT || this._permitObj.PermitStateId == null || this._permitObj.PermitStateId == undefined) {
        if (this._PermitVersion.Divisions[0].PermitTypeDivisionId != divisonObj.PermitTypeDivisionId) {
          return true;
        }
        else if (this._permitObj.CreatedBy == null || this._permitObj.CreatedBy == undefined || this._permitObj.CreatedBy == "") {
          return false;
        }
        else {
          return this._permitObj.CreatedBy == this._currentLoggedInUser.LoginId ? false : true
        }
      }
      // submitted by the creator or approved 
      if (this._permitObj.PermitStateId == Status.PENDINGAPPROVAL || this._permitObj.PermitStateId == Status.ACTIVE) {
        if (this._PermitVersion.Divisions[0].PermitTypeDivisionId != divisonObj.PermitTypeDivisionId && this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {
          return true;
        }
        if (this._PermitVersion.Divisions[0].PermitTypeDivisionId == divisonObj.PermitTypeDivisionId && this._permitObj.PermitStateId == Status.ACTIVE) {
          return true;
        }
        if (divisonObj.Editor == null && sectionObj.Editor == null) {
          return false;
        }
        if (divisonObj.Editor != null && this._currentLoggedInUserRoles) {
          let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == divisonObj.Editor);
          if (userRole != undefined) {
            if (userRole.length == 0) {
              return true;
            }
            else {
              if (this._permitObj.Responses.find(res => res.PermitTypeQuestionId == questionObj.PermitTypeQuestionId).Edited != true) {
                return false;
              }
            }
          }
          return true;
        }
        if (sectionObj.Editor != null && this._currentLoggedInUserRoles) {
          let userRole = this._currentLoggedInUserRoles.find(x => x.RoleId == sectionObj.Editor);
          if (userRole != undefined) {
            if (userRole.length == 0) {
              return true;
            }
            else {
              if (this._permitObj.Responses.find(res => res.PermitTypeQuestionId == questionObj.PermitTypeQuestionId).Edited != true) {
                return false;
              }
            }
          }
          return true;
        }
      }
      // suspended or lapsed or rejected or cancelled
      else {
        return true
      }
    }
    return false;
  }

  BindApproverResponse(value: boolean) {
    // IF the permit id is ACTIVE, all the responses for section/division for wich the Logged-In user is marked as approver will be approved.
    // For this, the SECOND & THIRD DIVISIONS are considered.
    // Also, a check is made if the section/division has not already been approved.
    if (this._permitObj != null && this._permitObj != undefined && this._permitObj.PermitStateId == Status.ACTIVE) {
      // for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {
      this._permitObj.Responses.forEach(res => {
        this._PermitVersion.Divisions[1].Sections.forEach(sec => {
          let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
          if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
            if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
              if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId)) {
                let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
                if (logSectionObj != undefined) {
                  res.Approved = value;
                }
              }
            }
          }
        })
      })
      // }
    }
    // IF the permit id is PENDINGFORAPPROVAL, all the responses for section under FIRST DIVISION for wich the Logged-In user is marked as approver will be approved.
    // For this, only the FIRST DIVISIONS is considered.
    // Also, a check is made if the section/division has not already been approved.
    else {
      if (!this.IsFirstDivisionApproved()) {
        this._permitObj.Responses.forEach(res => {
          this._PermitVersion.Divisions[0].Sections.forEach(sec => {
            let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
            if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
              if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
                if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId)) {
                  let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
                  if (logSectionObj != undefined) {
                    res.Approved = value;
                  }
                }
              }
            }
          })
        })
      }
    }
  }

  BindEditorResponse(value: boolean) {

    // IF the permit is ACTIVE, all the responses for section/division for wich the Logged-In user is marked as Editor will be edited.
    // For this, the SECOND & THIRD DIVISIONS are considered.
    // Also, a check is made if the section/division has not already been edited based on the acive-questions list.
    if (this._permitObj != null && this._permitObj != undefined && this._permitObj.PermitStateId == Status.ACTIVE) {
      for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {
        let div = this._PermitVersion.Divisions[i];
        this._permitObj.Responses.forEach(res => {
          if (this._currentLoggedInUserRoles.find(x => x.RoleId == div.Editor)) {
            div.Sections.forEach(sec => {
              if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
                res.Edited = value
              }
            })
          }
          else {
            div.Sections.forEach(sec => {
              if (sec.Editor != null) {
                if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor)) {
                  if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
                    res.Edited = value
                  }
                }
              }
              else {
                if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
                  res.Edited = value
                }
              }
            })
          }
        })
      }
    }
    // IF the permit is PENDINGFORAPPROVAL, all the responses for section under FIRST DIVISION for wich the Logged-In user is marked as Editor will be edited.
    // For this, only the FIRST DIVISION is considered.
    // Also, a check is made if the section/division has not already been edited based on the acive-questions list.
    else {
      this._permitObj.Responses.forEach(res => {
        if (this._currentLoggedInUserRoles.find(x => x.RoleId == this._PermitVersion.Divisions[0].Editor)) {
          this._PermitVersion.Divisions[0].Sections.forEach(sec => {
            if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
              res.Edited = value
            }
          })
        }
        else {
          this._PermitVersion.Divisions[0].Sections.forEach(sec => {
            if (sec.Editor != null) {
              if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor)) {
                if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
                  res.Edited = value
                }
              }
            }
            else {
              if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId) && this._activeQuestions.find(aq => aq == res.PermitTypeQuestionId)) {
                res.Edited = value
              }
            }
          })
        }
      })
    }
  }

  IsPermitTypeLevelApprovalCompleted(logList: any) {
    if (this.IsFirstDivisionApproved()) {
      let flag = 0;
      this._permitTypeLevelApprovalList.forEach(item => {
        let log = logList.find(x => x.ResponseTagNumber == item.PermitTypeLevelApprovalId && x.PermitStateId == Status.PERMITLEVELAPPROVED)
        if (log == undefined) {
          flag++;
        }
      })
      if (flag == 0) {
        return true;
      }
    }
    return false;
  }

  // Check if all the responses in the sections under FIRST DIVISION is approved.
  // If yes, the Permit State is marked as ACTIVE. 
  IsFirstDivisionApproved(): boolean {
    let count = 0;
    this._PermitVersion.Divisions[0].Sections.forEach(sec => {
      sec.Questions.forEach(ques => {
        if (sec.Approver != null && this._permitObj.Responses.find(res => res.PermitTypeQuestionId == ques.PermitTypeQuestionId && res.Approved != true)) {
          count++;
        }
      })
    })
    if (count > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  OLD_ApprovePermit() {
    this._displayCommentPopUp = false;

    this.BindApproverState(0);
    let distinctActivityLogs = this.RemoveDuplicateLogs();

    let logList: any[] = [];
    this._permitObj.PermitActivityLogs.forEach(x => logList.push(x));
    distinctActivityLogs.forEach(x => logList.push(x));

    this._permitObj.PermitActivityLogs = distinctActivityLogs;
    this.BindApproverResponse(true);
    this._permitObj.PermitStateId = this.IsPermitTypeLevelApprovalCompleted(logList) ? Status.ACTIVE : Status.PENDINGAPPROVAL;
    this._permitObj.PermitState = null;
    if (this._permitObj.PermitStateId >= Status.ACTIVE) {
      this.SetPermitTime();
    }
    this.SaveValues();

  }

  SetPermitTime() {
    let IssuedDateTime = new Date()
    let ExpiryDateTime = new Date()

    this._permitObj.PermitIssuedDateTime = IssuedDateTime
    if (this.revalidationHours > 0) {
      ExpiryDateTime.setMilliseconds(ExpiryDateTime.getMilliseconds() + (this.revalidationHours * 3600 * 1000))
      this._permitObj.PermitExpiryDateTime = ExpiryDateTime
    }
  }

  OLD_RejectPermit() {
    this._displayCommentPopUp = false;
    this.BindApproverState(1);
    this._permitObj.PermitActivityLogs = this.RemoveDuplicateLogs();
    this.BindApproverResponse(false);
    this._permitObj.PermitStateId = Status.REJECTED;
    this._permitObj.PermitState = null;
    this.SaveValues();
  }

  RemoveDuplicateLogs(): PermitActivityLog[] {
    let tempList = Object.assign(this._permitActivityLog);
    this._permitActivityLog = [];
    if (tempList.length > 0) {
      this._permitActivityLog.push(tempList[0]);
    }
    tempList.forEach(list => {
      if (!this._permitActivityLog.find(x => x.ResponseTagNumber == list.ResponseTagNumber && x.PermitStateId == list.PermitStateId)) {
        this._permitActivityLog.push(list);
      }
    })
    return this._permitActivityLog;
  }

  setFixSectionQuestions() {
    let objQ1 = new PermitTypeQuestion();
    let objQ2 = new PermitTypeQuestion();
    let objQ3 = new PermitTypeQuestion();
    let objQ4 = new PermitTypeQuestion();
    for (let prop in this._PermitVersion.Divisions[0].Sections[0].Questions[0]) {
      if (this._PermitVersion.Divisions[0].Sections[0].Questions[0].hasOwnProperty(prop)) {
        objQ1[prop] = this._PermitVersion.Divisions[0].Sections[0].Questions[0][prop];
        objQ2[prop] = this._PermitVersion.Divisions[0].Sections[0].Questions[0][prop];
        objQ3[prop] = this._PermitVersion.Divisions[0].Sections[0].Questions[0][prop];
        objQ4[prop] = this._PermitVersion.Divisions[0].Sections[0].Questions[0][prop];
      }
    }
    objQ1.QuestionText = "Permit No:";
    objQ1.AnswerTypeId = 3;
    objQ2.QuestionText = "Permit Issued Date/Time:";
    objQ2.AnswerTypeId = 3;
    objQ3.QuestionText = "Permit Expiry Date/Time:";
    objQ3.AnswerTypeId = 3;
    objQ4.QuestionText = "Revalidation Period:";
    objQ4.AnswerTypeId = 3;
    this._PermitVersion.Divisions[0].Sections[0].Questions.unshift(objQ4);
    this._PermitVersion.Divisions[0].Sections[0].Questions.unshift(objQ3);
    this._PermitVersion.Divisions[0].Sections[0].Questions.unshift(objQ2);
    this._PermitVersion.Divisions[0].Sections[0].Questions.unshift(objQ1);
  }

  setDefaultValues() {
    if (this._PermitVersion) {      
      for (let division of this._PermitVersion.Divisions) {
        for (let section of division.Sections) {
          for (let question of section.Questions) {
            if (question.AnswerTypeId == 12) {              
              this.hasEntryLeaderQuestion = true;
              this.entryLeaderName = question.AnswerText;
              if (this.isEntryLeader && !this.isPermitActive) {
                question.AnswerText = this.permitCreatedByUser.FullName;           
              }
            }
            if (question.AnswerTypeId == 1 && question.AnswerOptions[0].DynamicDataSource == true) {
              question.AnswerText = "0";
            }        
            question.SelectedAnswerOptionID = 0;
          }
        }
      }
    }
  }

  selectStartDate(divisionIndex, sectionIndex, questionIndex, pIndex, date, colName) {    
    let today: Date;
    today = new Date(date);
    let approvedDate = this.GetFormattedDateTime(today);

    if (this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerTypeId == 9) {
      let columnName: string = colName;
     
      if (columnName != '') {

        this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].TableData[pIndex][columnName].ResponseText = approvedDate;
        // return true;
      }
    }
    else {
      //selectedDate = (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) + '/' + (today.getMonth() < 9 ? "0" + (today.getMonth() + 1) : today.getMonth() + 1) + '/' + today.getFullYear();

      this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerText = approvedDate;
    }

  }
  selectStartDateSQ(divisionIndex, sectionIndex, questionIndex, aoIndex, subquestionIndex, pIndex, date, colName) {    
    let today: Date;
    today = new Date(date);
    let approvedDate = this.GetFormattedDateTime(today);

    if (this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[aoIndex].SubQuestions[subquestionIndex].AnswerTypeId == 9) {
      let columnName: string = colName;

      if (columnName != '') {

        this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[aoIndex].SubQuestions[subquestionIndex].TableData[pIndex][columnName].SubQuesResponseText = approvedDate;

      }
    }
    else {
      this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[aoIndex].SubQuestions[subquestionIndex].AnswerText = approvedDate;
    }

  }

  selectStartDateTimeSQ(divisionIndex, sectionIndex, questionIndex, aoIndex, subquestionIndex, pIndex, date, colName) {    
    let today: Date;
    today = new Date(date);
    let approvedDate = this.formatAMPM(today);

    if (this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[aoIndex].SubQuestions[subquestionIndex].AnswerTypeId == 9) {
      let columnName: string = colName;
      if (columnName != '') {

        this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[aoIndex].SubQuestions[subquestionIndex].TableData[pIndex][columnName].SubQuesResponseText = approvedDate;

      }
    }
    else {
      this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerOptions[aoIndex].SubQuestions[subquestionIndex].AnswerText = approvedDate;
    }

  }

  selectStartDateTime(divisionIndex, sectionIndex, questionIndex, pIndex, date, colName) {

    let today: Date;
    today = new Date(date);    
    
    let approvedDate = this.formatAMPM(today);

    if (this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerTypeId == 9) {
      let columnName: string = colName;
      
      if (columnName != '') {

        this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].TableData[pIndex][columnName].ResponseText = approvedDate;
        // return true;
      }
    }
    else {
     

      this._PermitVersion.Divisions[divisionIndex].Sections[sectionIndex].Questions[questionIndex].AnswerText = approvedDate;
    }

  }

  // To show who had approved the section along with date stamp
  getSectionApprovalMessage(divisionObj, sectionObj) {
    if (this._permitObj.PermitId > 0) {
      let logDivisionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == divisionObj.PermitTypeDivisionId && x.PermitStateId == Status.DIVISIONAPPROVED);
      if (logDivisionObj) {
        let approver = logDivisionObj.PerformedBy;
        let approvedDate = this.GetFormattedDateTime(logDivisionObj.PerformedDate);
        return ' (This division has already been approved by ' + approver + ' on ' + approvedDate + ')';
      }
      let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sectionObj.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
      if (logSectionObj) {
        let approver = logSectionObj.PerformedBy;
        let approvedDate = this.GetFormattedDateTime(logSectionObj.PerformedDate);
        return ' (This section has already been approved by ' + approver + ' on ' + approvedDate + ')';
      }
      return '';
    }
  }

  GetFormattedDateTime(obj): string {    
    let dateObj: Date = new Date(obj);

    let formattedDate: string = '';
    let MMM: string;

    let dd = dateObj.getDate();
    let M = dateObj.getMonth();
    let yyyy = dateObj.getFullYear();
    let language = parseInt(localStorage.getItem("_languageID"));


    switch (language) {
      case 1: {
        switch (M) {
          case 0: MMM = 'Jan';
            break;
          case 1: MMM = 'Feb';
            break;
          case 2: MMM = 'Mar';
            break;
          case 3: MMM = 'Apr';
            break;
          case 4: MMM = 'May';
            break;
          case 5: MMM = 'Jun';
            break;
          case 6: MMM = 'Jul';
            break;
          case 7: MMM = 'Aug';
            break;
          case 8: MMM = 'Sep';
            break;
          case 9: MMM = 'Oct';
            break;
          case 10: MMM = 'Nov';
            break;
          case 11: MMM = 'Dec';
            break;
        }
        break;
      }
      case 3: {
        switch (M) {
          case 0: MMM = 'Jan';
            break;
          case 1: MMM = 'Fév';
            break;
          case 2: MMM = 'Mar';
            break;
          case 3: MMM = 'Avr';
            break;
          case 4: MMM = 'Mai';
            break;
          case 5: MMM = 'Jui';
            break;
          case 6: MMM = 'Jul';
            break;
          case 7: MMM = 'Aoû';
            break;
          case 8: MMM = 'Sep';
            break;
          case 9: MMM = 'Oct';
            break;
          case 10: MMM = 'Nov';
            break;
          case 11: MMM = 'Déc'; //Dec
            break;
        }
        break;
      }
      default: {
        switch (M) {
          case 0: MMM = 'Jan';
            break;
          case 1: MMM = 'Feb';
            break;
          case 2: MMM = 'Mar';
            break;
          case 3: MMM = 'Apr';
            break;
          case 4: MMM = 'May';
            break;
          case 5: MMM = 'Jun';
            break;
          case 6: MMM = 'Jul';
            break;
          case 7: MMM = 'Aug';
            break;
          case 8: MMM = 'Sep';
            break;
          case 9: MMM = 'Oct';
            break;
          case 10: MMM = 'Nov';
            break;
          case 11: MMM = 'Dec';
            break;
        }
        break;
      }
    }
    // formattedDate = dd + '-' + MMM + '-' + yyyy + ' ' + hh + ':' + mm + ':' + ss;
    formattedDate = dd + '-' + MMM + '-' + yyyy;
    return formattedDate;
  }

  formatAMPM(obj): string {
    if (obj == null || obj == undefined) {
      return null;
    }
    let date: Date = new Date(obj);
    let MMM: string;
    let language = parseInt(localStorage.getItem("_languageID"));
    if ((Object.prototype.toString.call(date) === "[object Date]" && isNaN(date.getTime()))) {
      let dateStr = obj.toString().split('-');
      if (dateStr == null || dateStr == '') {
        dateStr = obj.split(' ');
      }

      // let MMM: string;
      let monthName = dateStr[1];
      switch (monthName) {
        case 'Jan': MMM = 'Jan';
          break;
        case 'Fév': MMM = 'Feb';
          break;
        case 'Mar': MMM = 'Mar';
          break;
        case 'Avr': MMM = 'Apr';
          break;
        case 'Mai': MMM = 'May';
          break;
        case 'Jui': MMM = 'Jun';
          break;
        case 'Jul': MMM = 'Jul';
          break;
        case 'Aoû': MMM = 'Aug';
          break;
        case 'Sep': MMM = 'Sep';
          break;
        case 'Oct': MMM = 'Oct';
          break;
        case 'Nov': MMM = 'Nov';
          break;
        case 'Déc': MMM = 'Dec';
          break;
      }

      obj = dateStr[0] + '-' + MMM + '-' + dateStr[2];
      let date: Date = new Date(obj);
      var d = date.getDate();

      var m = date.getMonth();
      var y = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
    }  

    if (!isNaN(date.getTime())) {      
      var d = date.getDate();
      var m = date.getMonth();

      var y = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      switch (m) {
        case 0: MMM = 'Jan';
          break;
        case 1: MMM = 'Feb';
          break;
        case 2: MMM = 'Mar';
          break;
        case 3: MMM = 'Apr';
          break;
        case 4: MMM = 'May';
          break;
        case 5: MMM = 'Jun';
          break;
        case 6: MMM = 'Jul';
          break;
        case 7: MMM = 'Aug';
          break;
        case 8: MMM = 'Sep';
          break;
        case 9: MMM = 'Oct';
          break;
        case 10: MMM = 'Nov';
          break;
        case 11: MMM = 'Dec';
          break;
      }
      language = parseInt(localStorage.getItem("_languageID"));
      if (language == 3) {
        switch (m) {
          case 0: MMM = 'Jan';
            break;
          case 1: MMM = 'Fév';
            break;
          case 2: MMM = 'Mar';
            break;
          case 3: MMM = 'Avr';
            break;
          case 4: MMM = 'Mai';
            break;
          case 5: MMM = 'Jui';
            break;
          case 6: MMM = 'Jul';
            break;
          case 7: MMM = 'Aoû';
            break;
          case 8: MMM = 'Sep';
            break;
          case 9: MMM = 'Oct';
            break;
          case 10: MMM = 'Nov';
            break;
          case 11: MMM = 'Déc';
            break;
        }
      }
    }
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    //minutes = minutes < 10 ? '0'+minutes : minutes;
    var strDateTime = d + '-' + MMM + '-' + y + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    if (hours == 12 && minutes == 0 && seconds == 0) {
      strDateTime = d + '-' + MMM + '-' + y;
    }
    return strDateTime;
  }

  formatTranslation(obj): string[] {    
    if (obj == null || obj == undefined) {
      return null;
    }
    let dateTime: string[] = [];
    obj.forEach(_obj => {
      let date: Date = new Date(_obj);
      let MMM: string;
      let language = parseInt(localStorage.getItem("_languageID"));
      if ((Object.prototype.toString.call(date) === "[object Date]" && isNaN(date.getTime()))) {
        let dateStr = _obj.toString().split('-');
        if (dateStr == null || dateStr == '') {
          dateStr = obj.split(' ');
        }

        // let MMM: string;
        let monthName = dateStr[1];
        switch (monthName) {
          case 'Jan': MMM = 'Jan';
            break;
          case 'Fév': MMM = 'Feb';
            break;
          case 'Mar': MMM = 'Mar';
            break;
          case 'Avr': MMM = 'Apr';
            break;
          case 'Mai': MMM = 'May';
            break;
          case 'Jui': MMM = 'Jun';
            break;
          case 'Jul': MMM = 'Jul';
            break;
          case 'Aoû': MMM = 'Aug';
            break;
          case 'Sep': MMM = 'Sep';
            break;
          case 'Oct': MMM = 'Oct';
            break;
          case 'Nov': MMM = 'Nov';
            break;
          case 'Déc': MMM = 'Dec';
            break;
        }

        _obj = dateStr[0] + '-' + MMM + '-' + dateStr[2];
        let date: Date = new Date(_obj);
        var d = date.getDate();

        var m = date.getMonth();
        var y = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
      }

      if (!isNaN(date.getTime())) {
        var d = date.getDate();
        var m = date.getMonth();

        var y = date.getFullYear();
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var seconds = date.getSeconds();
        switch (m) {
          case 0: MMM = 'Jan';
            break;
          case 1: MMM = 'Feb';
            break;
          case 2: MMM = 'Mar';
            break;
          case 3: MMM = 'Apr';
            break;
          case 4: MMM = 'May';
            break;
          case 5: MMM = 'Jun';
            break;
          case 6: MMM = 'Jul';
            break;
          case 7: MMM = 'Aug';
            break;
          case 8: MMM = 'Sep';
            break;
          case 9: MMM = 'Oct';
            break;
          case 10: MMM = 'Nov';
            break;
          case 11: MMM = 'Dec';
            break;
        }
        language = parseInt(localStorage.getItem("_languageID"));
        if (language == 3) {
          switch (m) {
            case 0: MMM = 'Jan';
              break;
            case 1: MMM = 'Fév';
              break;
            case 2: MMM = 'Mar';
              break;
            case 3: MMM = 'Avr';
              break;
            case 4: MMM = 'Mai';
              break;
            case 5: MMM = 'Jui';
              break;
            case 6: MMM = 'Jul';
              break;
            case 7: MMM = 'Aoû';
              break;
            case 8: MMM = 'Sep';
              break;
            case 9: MMM = 'Oct';
              break;
            case 10: MMM = 'Nov';
              break;
            case 11: MMM = 'Déc';
              break;
          }
        }
      }
      var ampm = hours >= 12 ? 'PM' : 'AM';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      //minutes = minutes < 10 ? '0'+minutes : minutes;
      let strDateTime = d + '-' + MMM + '-' + y + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
      if (hours == 12 && minutes == 0 && seconds == 0) {
        strDateTime = d + '-' + MMM + '-' + y;
      }
      dateTime.push(strDateTime);
    });
    return dateTime;
  }
  

  DateTimeTranslation(obj): string  {    
    if (obj == null || obj == undefined) {
      return null;
    }

    let language = parseInt(localStorage.getItem("_languageID"));  

    let date: Date;
    let MMM: string;
    var d: any;
    var m: any;
    var y: any;
    var hours: any;
    var minutes: any;
    var seconds: any;
    let monthName: any
    let dateStr: any;


    switch (language) {  
      case 3: {
        dateStr = obj.toString().split(" ");
        monthName = dateStr[1].toString();
        switch (monthName) {
          case 'Jan': MMM = 'Jan';
            break;
          case 'Feb': MMM = 'Fév';
            break;
          case 'Mar': MMM = 'Mar';
            break;
          case 'Apr': MMM = 'Avr';
            break;
          case 'May': MMM = 'Mai';
            break;
          case 'Jun': MMM = ' Jui';
            break;
          case 'Jul': MMM = 'Jul';
            break;
          case 'Aug': MMM = 'Aoû';
            break;
          case 'Sep': MMM = 'Sep';
            break;
          case 'Oct': MMM = 'Oct';
            break;
          case 'Nov': MMM = 'Nov';
            break;
          case 'Dec': MMM = 'Déc'; 
            break;
        }
        
      
        date = new Date(obj);
        d = date.getDate();

        m = date.getMonth();
        y = date.getFullYear();
        hours = date.getHours();
        minutes = date.getMinutes();
        seconds = date.getSeconds();

        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'        
        var strDateTime = d + '-' + MMM + '-' + y + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
        obj = strDateTime;
        if (hours == 12 && minutes == 0 && seconds == 0) {          
          strDateTime = d + '-' + MMM + '-' + y;
          obj = strDateTime;
        }
        break;
      }
      default: {
        dateStr = obj.toString().split(" ");
        monthName = dateStr[1].toString();
        MMM = monthName;
        date = new Date(obj);
        d = date.getDate();

        m = date.getMonth();
        y = date.getFullYear();
        hours = date.getHours();
        minutes = date.getMinutes();
        seconds = date.getSeconds();

        var ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'        
        var strDateTime = d + '-' + MMM + '-' + y + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
        obj = strDateTime;
        if (hours == 12 && minutes == 0 && seconds == 0) {
          strDateTime = d + '-' + MMM + '-' + y;
          obj = strDateTime;
        }
        //  obj = obj.toString();
        break;
      }
    }   
    return obj.trim();
  }

  // To make entry in the PermitActivityLog table
  // Checks if Logged-In user is Editor and 
  // the if the question is present in the active-question list for the particular section/division
  BindEditorState(value: number) {
    this._PermitVersion.Divisions.forEach(div => {
      if (this._currentLoggedInUserRoles.find(x => x.RoleId == div.Editor)) {
        div.Sections.forEach(sec => {
          sec.Questions.forEach(ques => {
            if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
              let obj: PermitActivityLog = {
                PermitActivityLogId: 0,
                PermitId: this._permitObj.PermitId,
                ResponseTagNumber: div.PermitTypeDivisionId,
                PermitStateId: (value == 0) ? Status.DIVISIONSAVED : Status.DIVISIONEDITED,
                PerformedBy: this._currentLoggedInUser.LoginId,
                PerformedByName: null,
                PerformedDate: new Date(),
                Permit: null,
                PermitState: null,
                PermitRevisionId: this._permitObj.PermitRevisionId
              }
              this._permitActivityLog.push(obj);
            }
          })
        })
      }
      else {
        div.Sections.forEach(sec => {
          if (sec.Editor != null) {
            if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Editor)) {
              sec.Questions.forEach(ques => {
                if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                  let obj: PermitActivityLog = {
                    PermitActivityLogId: 0,
                    PermitId: this._permitObj.PermitId,
                    ResponseTagNumber: sec.PermitTypeSectionId,
                    PermitStateId: (value == 0) ? Status.SECTIONSAVED : Status.SECTIONEDITED,
                    PerformedBy: this._currentLoggedInUser.LoginId,
                    PerformedByName: null,
                    PerformedDate: new Date(),
                    Permit: null,
                    PermitState: null,
                    PermitRevisionId: this._permitObj.PermitRevisionId
                  }
                  this._permitActivityLog.push(obj);
                }
              })
            }
          }
          else {
            sec.Questions.forEach(ques => {
              if (this._activeQuestions.find(x => x == ques.PermitTypeQuestionId)) {
                let obj: PermitActivityLog = {
                  PermitActivityLogId: 0,
                  PermitId: this._permitObj.PermitId,
                  ResponseTagNumber: sec.PermitTypeSectionId,
                  PermitStateId: (value == 0) ? Status.SECTIONSAVED : Status.SECTIONEDITED,
                  PerformedBy: this._currentLoggedInUser.LoginId,
                  PerformedByName: null,
                  PerformedDate: new Date(),
                  Permit: null,
                  PermitState: null,
                  PermitRevisionId: this._permitObj.PermitRevisionId
                }
                this._permitActivityLog.push(obj);
              }
            })
          }

        })
      }
    })
  }

  // To make entry in the PermitActivityLog table
  // Checks if Logged-In user is Editor and 
  // the if the question is present in the active-question list for the particular section/division
  BindApproverState(value: number) {
    // SECOND & THIRD DIVISIONS are considered since, the permit state is ACTIVE
    if (this._permitObj != null && this._permitObj != undefined && this._permitObj.PermitStateId == Status.ACTIVE) {
      // for (let i = 1; i < this._PermitVersion.Divisions.length; i++) {

      let div = this._PermitVersion.Divisions[1];

      div.Sections.forEach(sec => {
        let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
        if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
          if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
            let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
            if (logSectionObj == undefined) {
              let obj: PermitActivityLog = {
                PermitActivityLogId: 0,
                PermitId: this._permitObj.PermitId,
                ResponseTagNumber: sec.PermitTypeSectionId,
                PermitStateId: (value == 0) ? Status.SECTIONAPPROVED : Status.SECTIONREJECTED,
                PerformedBy: this._currentLoggedInUser.LoginId,
                PerformedByName: null,
                PerformedDate: new Date(),
                Permit: null,
                PermitState: null,
                PermitRevisionId: this._permitObj.PermitRevisionId
              }
              this._permitActivityLog.push(obj);
            }
          }
        }
      })
      // }
    }

    // only FIRST DIVISION is considered since, the permit state is ACTIVE
    else {
      if (!this.IsFirstDivisionApproved()) {
        let div = this._PermitVersion.Divisions[0];
        div.Sections.forEach(sec => {
          let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
          if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
            if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
              let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
              if (logSectionObj == undefined) {
                let obj: PermitActivityLog = {
                  PermitActivityLogId: 0,
                  PermitId: this._permitObj.PermitId,
                  ResponseTagNumber: sec.PermitTypeSectionId,
                  PermitStateId: (value == 0) ? Status.SECTIONAPPROVED : Status.SECTIONREJECTED,
                  PerformedBy: this._currentLoggedInUser.LoginId,
                  PerformedByName: null,
                  PerformedDate: new Date(),
                  Permit: null,
                  PermitState: null,
                  PermitRevisionId: this._permitObj.PermitRevisionId
                }
                this._permitActivityLog.push(obj);
              }
            }
          }
        });
      }
      else {
        let permitLevelApprovalList = this._permitTypeLevelApprovalList.filter(x => x.ApprovalSequence == null || x.ApprovalSequence == this._activePermitLevelSequence);
        permitLevelApprovalList.forEach(item => {
          if (this._currentLoggedInUserRoles.find(x => x.RoleId == item.Approver)) {
            let logPermitLevelObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == item.PermitTypeLevelApprovalId && x.PermitStateId == Status.PERMITLEVELAPPROVED);
            if (logPermitLevelObj == undefined) {
              let obj: PermitActivityLog = {
                PermitActivityLogId: 0,
                PermitId: this._permitObj.PermitId,
                ResponseTagNumber: item.PermitTypeLevelApprovalId,
                PermitStateId: (value == 0) ? Status.PERMITLEVELAPPROVED : Status.PERMITLEVELREJECTED,
                PerformedBy: this._currentLoggedInUser.LoginId,
                PerformedByName: null,
                PerformedDate: new Date(),
                Permit: null,
                PermitState: null,
                PermitRevisionId: this._permitObj.PermitRevisionId
              }
              this._permitActivityLog.push(obj);
            }
          }
        })
      }
    }
  }

  onUploadResponseImage(event, questionId) {
    let fileFormatNotAllowed: boolean = false;


    if (event.files.length == 0) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.NoFileFoundToUpload') });
      return;
    }
    else {
      let allowedFileTypeList: string[] = []
      allowedFileTypeList = this._allowedFileTypes.split(',');
      event.files.forEach(file => {
        if (!allowedFileTypeList.includes(file.type)) {
          fileFormatNotAllowed = true
        }
      });
    }
    if (fileFormatNotAllowed) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.FileFormatNotSupported') });
      this.responseAttachments = [];
      return;
    }
    else {
      event.files.forEach(file => {
        this._mediaService.AttachMediaFiles(file).subscribe((res: HttpResponse<any>) => {
          if (res.status == 200) {
            this.onOK();
            this._messageService.add({ key: 'success', sticky: true, severity: 'info', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.MediaMessageUploadSuccessful') });
            this.responseAttachedFiles = [];
            this.SetAttachedFilesArray(res.body, questionId, true);
            setTimeout(() => {
              this._messageService.clear('success');
            }, 5000);
          }
          this.responseAttachments = [];
        },
          (err) => {
            this.responseAttachedFiles = [];
            this.responseAttachments = [];
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.MediaMessageUploadError') });
          }
        );
      });
    }

  }

  onUploadSubQuestionResponseImage(event, questionId, subquestionId) {
    let fileFormatNotAllowed: boolean = false;


    if (event.files.length == 0) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.NoFileFoundToUpload') });
      return;
    }
    else {
      let allowedFileTypeList: string[] = []
      allowedFileTypeList = this._allowedFileTypes.split(',');
      event.files.forEach(file => {
        if (!allowedFileTypeList.includes(file.type)) {
          fileFormatNotAllowed = true
        }
      });
    }
    if (fileFormatNotAllowed) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.FileFormatNotSupported') });
      this.subQuesResponseAttachments = [];
      return;
    }
    else {
      event.files.forEach(file => {
        this._mediaService.AttachMediaFiles(file).subscribe((res: HttpResponse<any>) => {
          if (res.status == 200) {
            this.onOK();
            this._messageService.add({ key: 'success', sticky: true, severity: 'info', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.MediaMessageUploadSuccessful') });
            this.subQuestionResponseAttachedFiles = [];
            this.SubQuestionSetAttachedFilesArray(res.body, questionId, subquestionId, true);
            setTimeout(() => {
              this._messageService.clear('success');
            }, 5000);
          }
          this.subQuesResponseAttachments = [];
        },
          (err) => {
            this.subQuestionResponseAttachedFiles = [];
            this.subQuesResponseAttachments = [];
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.MediaMessageUploadError') });
          }
        );
      });
    }

  }

  

  //Set Attached Image
  SetAttachedFilesArray(azurefileid: number, questionId: number, isActive: boolean) {
    
    this._mediaService.getFileProperties(azurefileid).subscribe(
      (res: HttpResponse<any>) => {
        if (res.body.uri != null && res.body.uri != '') {
          this._commonService.getMediaBlob(this.EscapeSpecialCharacters(res.body.uri)).subscribe(
            (data: any) => {
              let base64Image = "data:image/png;base64," + data.toString();
              let thumbnailImage = this.sanitizer.bypassSecurityTrustUrl(base64Image);
              this.responseAttachedFiles = [];
              //this.responseAttachmentFiles = [];
              this.responseAttachedFiles.push({ source: res.body.uri, thumbnail: thumbnailImage });
              this.responseAttachmentFiles.push({
                AzureFileId: azurefileid,
                ImageArray: this.responseAttachedFiles,
                questionId: questionId,
                Active: isActive,
                ContentType: res.body.contentType,
                BinaryFile: base64Image
              });
            });
        }
        // this.finalAttachmentList = this.responseAttachmentFiles;
      }
    );
  }
  SubQuestionSetAttachedFilesArray(azurefileid: number, questionId: number, subquestionId: number, isActive: boolean) {

    this._mediaService.getFileProperties(azurefileid).subscribe(
      (res: HttpResponse<any>) => {
        if (res.body.uri != null && res.body.uri != '') {
          this._commonService.getMediaBlob(this.EscapeSpecialCharacters(res.body.uri)).subscribe(
            (data: any) => {
              let base64Image = "data:image/png;base64," + data.toString();
              let thumbnailImage = this.sanitizer.bypassSecurityTrustUrl(base64Image);
              this.subQuestionResponseAttachedFiles = [];
              this.subQuestionResponseAttachedFiles.push({ source: res.body.uri, thumbnail: thumbnailImage });
              this.subQuesResponseAttachmentFiles.push({
                AzureFileId: azurefileid,
                ImageArray: this.subQuestionResponseAttachedFiles,
                questionId: questionId,
                subquestionId: subquestionId,
                Active: isActive,
                ContentType: res.body.contentType,
                BinaryFile: base64Image
              });

            });
        }

      }
    );
  }

  //Delete Attached Image
  DeleteAttachedFile(azureFileId) {
    this._confirmationService.confirm({
      message: this.translate.instant('Permit.FileDeleteMsg'),
      header: this.translate.instant('Permit.FileDeleteHdr'),
      key: 'deleteResponseImage',
      icon: 'pi pi-info-circle',
      accept: () => {
       
        this.responseAttachmentFiles.find(x => x.AzureFileId == azureFileId).Active = false;
      },
      reject: () => {
      }
    });
  }
  DeleteSubQuestionAttachedFile(azureFileId) {
    this._confirmationService.confirm({
      message: this.translate.instant('Permit.FileDeleteMsg'),
      header: this.translate.instant('Permit.FileDeleteHdr'),
      key: 'deleteResponseImage',
      icon: 'pi pi-info-circle',
      accept: () => {

        this.subQuesResponseAttachmentFiles.find(x => x.AzureFileId == azureFileId).Active = false;
      },
      reject: () => {
      }
    });
  }

  checkRowForDeletion(rowIndex, tableFieldType, tableData, deletedIndexes): string {
    let index = deletedIndexes.find(x => x == rowIndex);
    //Row Not Deleted
    if (index == undefined || index == null || parseInt(index) < 0) {
      return "ACTIVE";
    }
    else {
      //IF ROw DELETED
      if (this.hasData(rowIndex, tableFieldType, tableData)) {
        if (this._permitObj.PermitId == 0) {
          return "SKIP";
        }
        else {
          return "INACTIVE";

        }

      }
      else {
        if (this._permitObj.PermitId == 0) {
          return "SKIP";
        }
        else {
          return "INACTIVE";

        }
      }

    }
  }

  hasData(rowIndex, tableFieldType, tableData): boolean {

    for (let header of tableFieldType) {
      let obj = (tableData[rowIndex][header.ColumnName] as TableTypeResponse);

      if (obj.SelectedAnswerOptionId != undefined && obj.SelectedAnswerOptionId != null && obj.SelectedAnswerOptionId != 0) {
        return true;
      }
      // else if (obj.ResponseText != undefined && obj.ResponseText != null && obj.ResponseText.trim() != "") {
      else if (obj.ResponseText != undefined && obj.ResponseText != null && obj.ResponseText != '') {
        return true;
      }
      else if (obj.SelectedAnswerCheckBoxForTable != undefined && obj.SelectedAnswerCheckBoxForTable != null && obj.SelectedAnswerCheckBoxForTable.length > 0) {
        return true;
      }
    }

    return false;
  }

  checkRowForDeletionSQ(rowIndex, tableFieldType, tableData, deletedIndexes): string {
    let index = deletedIndexes.find(x => x == rowIndex);
    //Row Not Deleted
    if (index == undefined || index == null || parseInt(index) < 0) {
      return "ACTIVE";
    }
    else {
      //IF ROw DELETED
      if (this.hasDataSQ(rowIndex, tableFieldType, tableData)) {
        if (this._permitObj.PermitId == 0) {
          return "SKIP";
        }
        else {
          return "INACTIVE";

        }

      }
      else {
        if (this._permitObj.PermitId == 0) {
          return "SKIP";
        }
        else {
          return "INACTIVE";

        }
      }

    }
  }

  hasDataSQ(rowIndex, tableFieldType, tableData): boolean {

    for (let header of tableFieldType) {
      let obj = (tableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse);

      if (obj.SelectedAnswerOptionId != undefined && obj.SelectedAnswerOptionId != null && obj.SelectedAnswerOptionId != 0) {
        return true;
      }

      else if (obj.SubQuesResponseText != undefined && obj.SubQuesResponseText != null && obj.SubQuesResponseText != '') {
        return true;
      }
      else if (obj.SelectedAnswerCheckBoxForTable != undefined && obj.SelectedAnswerCheckBoxForTable != null && obj.SelectedAnswerCheckBoxForTable.length > 0) {
        return true;
      }
    }

    return false;
  }

  resetRadioButton(question: PermitTypeQuestion) {
    if (question) {
      question.SelectedAnswerOptionID = 0;
    }
  }

  resetRadioButtonForTable(question: PermitTypeQuestion, rowIndex: number) {
    if (question) {
      if (question.AnswerTypeId == 9) {
        question.TableFieldType.forEach((fields: PermitTypeTableFieldType) => {
          if (fields.AnswerTypeId == 4) {
            question.TableData[rowIndex][fields.ColumnName].SelectedAnswerOptionId = 0;
          }
        })
      }
      question.SelectedAnswerOptionID = 0;
    }

  }

  IsRowDeleted(rowIndex, deletedIndexes) {
    let index = deletedIndexes.find(x => x == rowIndex);
    //Row Not Deleted
    if (index == undefined || index == null || parseInt(index) < 0) {
      return false;
    }
    return true;
  }

  GetRowCount(table): number {
    let rowCount = 0;
    for (let row of table.rows) {
      if (!(row as HTMLTableRowElement).hidden) {
        rowCount++;
      }
    }
    return rowCount;
  }

  showStatusPopUp() {
    this.displayStatusPopUp = true;
  }

  StatusPopupClose() {
    this.displayStatusPopUp = false;
  }

  GetPermitLevelStatus() {

    let divisionStatusObj = {
      DivisionName: 'Permit Level Approval',
      DivisionMessage: null,
      IsApproved: 0,
      Approver: null,
      SectionList: null
    }

    let sectionStatusList: any[] = [];
    let count = 0;
    for (let i = 0; i < this._permitTypeLevelApprovalList.length; i++) {

      let permitTypeLevelApprovalObj = this._permitTypeLevelApprovalList[i];
      let logObj = this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == permitTypeLevelApprovalObj.PermitTypeLevelApprovalId && x.PermitStateId == Status.PERMITLEVELAPPROVED);
      if (logObj == undefined) {
        // if (permitTypeLevelApprovalObj.ApprovalSequence == null || permitTypeLevelApprovalObj.ApprovalSequence == this._activePermitLevelSequence) {
        let msg = '(Pending with ' + permitTypeLevelApprovalObj.RoleName + ')';
        let approver = null;
        let sectionStatusObj: any = {
          SectionName: 'Approver ' + (++count),
          SectionMessage: msg,
          IsApproved: 2,
          Approver: approver
        }
        sectionStatusList.push(sectionStatusObj);
        // }
      }
      else {
        let msg = '(Approved by <a><b>' + logObj.PerformedByName + '</a></b> on ' + this.tolocalTimeString(logObj.PerformedDate) + ')';
        let approver = logObj.PerformedBy;
        let sectionStatusObj: any = {
          SectionName: 'Approver ' + (++count),
          SectionMessage: msg,
          IsApproved: 1,
          Approver: approver
        }
        sectionStatusList.push(sectionStatusObj);
      }
    }
    if (sectionStatusList.length == 0) {
      return null;
    }
    else {
      divisionStatusObj.SectionList = sectionStatusList;
      return divisionStatusObj;
    }

  }

  GetDataForPermitStatus() {
    this.permitApprovalStatus = null;
    if (this._permitObj.PermitId > 0) {
      let divisionStatusList: any[] = [];
      let divisionStatusObj: any;

      this._PermitVersion.Divisions.forEach(div => {
        if (this._permitObj.PermitStateId == 2) {
          if (div.DivisionName.toLocaleLowerCase().startsWith('permit creation')) {
            divisionStatusObj = this.GetApprovalStatusData(div);
          }
        }
        else if (this._permitObj.PermitStateId == 3) {
          divisionStatusObj = this.GetApprovalStatusData(div);
        }

        if (divisionStatusObj != null) {
          divisionStatusList.push(divisionStatusObj);
          divisionStatusObj = null;
        }

        if (div.DivisionName.toLocaleLowerCase().startsWith('permit creation')) {
          divisionStatusObj = this.GetPermitLevelStatus();
          if (divisionStatusObj != null && divisionStatusList.length != 0) {
            divisionStatusList.push(divisionStatusObj);
          }
          divisionStatusObj = null;
        }

      })

      this.permitApprovalStatus = {
        PermitNumber: this._permitObj.PermitNumber,
        PermitState: this._permitObj.PermitState.PermitStateName,
        DivisionStatusList: divisionStatusList
      }
    }
  }

  GetApprovalStatusData(div: PermitTypeDivision) {
    let sectionStatusList: any[] = [];
    let divisionStatusObj: any;
    divisionStatusObj = {
      DivisionName: div.DivisionName,
      DivisionMessage: null,
      IsApproved: 0,
      Approver: null,
      SectionList: null
    }
    div.Sections.forEach(sec => {
      if (sec.Approver) {
        let msg: string;
        msg = this.GetSectionWiseApprovalMessage(sec, divisionStatusObj.IsApproved);
        let approver: string = this.GetSectionApprovedByUser(sec, divisionStatusObj.IsApproved);
        let sectionStatusObj: any = {
          SectionName: sec.SectionName,
          SectionMessage: msg,
          IsApproved: (msg.startsWith('(Pending') == true) ? 2 : ((msg.startsWith('(Approved') == true) ? 1 : 0),
          Approver: approver
        }
        sectionStatusList.push(sectionStatusObj);
      }
    })
    divisionStatusObj.SectionList = sectionStatusList;

    return divisionStatusObj;
  }

  GetDivisionApprovedByUser(div: PermitTypeDivision): string {
    if (div.Approver != null) {
      let logDivisionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == div.PermitTypeDivisionId && x.PermitStateId == Status.DIVISIONAPPROVED);
      if (logDivisionObj) {
        return logDivisionObj.PerformedBy;
      }
    }
    return null;
  }

  GetSectionApprovedByUser(sec: PermitTypeSection, isDivvisionApproved): string {
    if (isDivvisionApproved == 1) {
      return null;
    }
    else if (sec.Approver != null) {
      let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
      if (logSectionObj) {
        return logSectionObj.PerformedBy;
      }
    }
    return null;
  }

  GetDivisionWiseApprovalMessage(div: PermitTypeDivision): string {
    let msg: string;

    if (div.Approver == null) {
      msg = 'Approval not required'
    }

    else if (div.Approver != null) {

      let count = 0;
      div.Sections.forEach(sec => {
        if (sec.Approver != null) {
          let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
          if (logSectionObj == undefined) {
            count++;
          }
        }
      });

      if (count == 0) {
        msg = 'Approval not required';
        return '(' + msg + ')';
      }

      let logDivisionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == div.PermitTypeDivisionId && x.PermitStateId == Status.DIVISIONAPPROVED);
      if (logDivisionObj) {
        msg = 'Approved by <a><b>' + logDivisionObj.PerformedByName + '</b></a> on ' + this.tolocalTimeString(logDivisionObj.PerformedDate)
      }
      else {
        msg = 'Pending with ' + this.getRoleName(div.Approver);
      }
    }
    return '(' + msg + ')';
  }

  GetSectionWiseApprovalMessage(sec: PermitTypeSection, isDivvisionApproved: number): string {
    let msg: string;

    if (isDivvisionApproved == 1 || sec.Approver == null) {
      msg = 'Approval not required'
    }

    else if (sec.Approver != null) {
      let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
      if (logSectionObj) {
        msg = 'Approved by <a><b>' + logSectionObj.PerformedByName + '</b></a> on ' + this.tolocalTimeString(logSectionObj.PerformedDate)
      }
      else {
        msg = 'Pending with ' + this.getRoleName(sec.Approver);
      }
    }
    return '(' + msg + ')';
  }

  GetApproverDetails(loginId) {

    let _approverUserDetailList = localStorage.getItem("_approverUserDetailList") != null ? JSON.parse(localStorage.getItem("_approverUserDetailList")) : [];

    if (_approverUserDetailList) {
      let userObj = _approverUserDetailList.find(x => x.LoginId == loginId);
      if (userObj) {
        this._approverUserDetail = userObj;
        this._showApproverDetail = true;
      }
      else {
        this._userService.getUserByLoginId(loginId).subscribe((data: any) => {
          if (data) {
            let azurefileid = data.Attachment == null ? null : data.Attachment.AzureFileId;
            if (azurefileid != null && azurefileid != 0) {
              this._mediaService.getFileProperties(azurefileid).subscribe(
                (res: HttpResponse<any>) => {
                  if (res.body.uri != null && res.body.uri != '') {
                    this._approverUserDetail = {
                      LoginId: data.LoginId,
                      FullName: data.LastName.toUpperCase() + ', ' + data.FirstName.toUpperCase(),
                      MobileNumber: data.MobileNumber,
                      EmailAddress: data.EmailAddress,
                      SiteName: data.Site != null ? data.Site.SiteName : null,
                      ApproverUserProfilePicture: null
                    }
                    if (res.body.contentType.indexOf('image/') != -1) {
                      this._approverUserDetail.ApproverUserProfilePicture = res.body.thumbUri;
                    }
                    this._showApproverDetail = true;
                    _approverUserDetailList.push(this._approverUserDetail);
                    localStorage.setItem("_approverUserDetailList", JSON.stringify(_approverUserDetailList));
                  }
                }
              );
            }
            else {
              this._approverUserDetail = {
                LoginId: data.LoginId,
                FullName: data.LastName.toUpperCase() + ', ' + data.FirstName.toUpperCase(),
                MobileNumber: data.MobileNumber,
                EmailAddress: data.EmailAddress,
                SiteName: data.Site != null ? data.Site.SiteName : null,
                ApproverUserProfilePicture: null
              }
              this._showApproverDetail = true;
              _approverUserDetailList.push(this._approverUserDetail);
              localStorage.setItem("_approverUserDetailList", JSON.stringify(_approverUserDetailList));
            }
          }
        })
      }
    }
  }

  backToStatusView() {
    this._showApproverDetail = false;
    this._approverUserDetail = null;
  }

  getRoleName(roleId) {
    let roleObj = this.allRoles.find(x => x.RoleId == roleId);
    return (roleObj != undefined && roleObj.RoleName != null) ? roleObj.RoleName : roleId;
  }

  OnStatusPanelHide() {
    this.enableStatusTab();
    this.backToStatusView();
  }

  OLD_showCloseButton() {

    if (this._permitObj && this._permitObj.PermitStateId == Status.ACTIVE && this._currentLoggedInUserRoles) {
      let secList: PermitTypeSection[] = this._PermitVersion.Divisions[2].Sections;

      if (secList.length == 0) {
        return false;
      }

      else {
        let count = 0;
        this._PermitVersion.Divisions[1].Sections.forEach(sec => {
          sec.Questions.forEach(ques => {
            if (sec.Approver != null) {
              let response = this._permitObj.Responses.find(res => res.PermitTypeQuestionId == ques.PermitTypeQuestionId);
              if (response != undefined && response.Approved != true) {
                count++;
              }
            }
          })
        })

        if (count == 0) {

          let approverCount = 0;
          secList.forEach(sec => {
            if (sec.Approver != null) {
              approverCount++;
            }
          });
          if (approverCount == 0) {
            if (this._currentLoggedInUser.LoginId == this._permitObj.CreatedBy) {
              return true;
            }
          }
          else {
            let count = 0;
            secList.forEach(sec => {
              let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
              if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
                if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
                  let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
                  if (logSectionObj == undefined) {
                    count++;
                  }
                }
              }
            });
            if (count > 0) {
              return true;
            }
          }
        }
        return false;
      }
    }


   
  }

  OLD_ClosePermit() {
    this._confirmationService.confirm({
      message: this.translate.instant('Permit.ConfirmCloseMsg'),
      header: this.translate.instant('Permit.ConfirmCloseHeader'),
      key: 'confirmClose',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._displayCommentPopUp = false;
        this.BindCloseState();
        this._permitObj.PermitActivityLogs = this.RemoveDuplicateLogs();
        this.BindCloseResponse();
        this._permitObj.PermitStateId = this.IsLastDivisionApproved() == true ? Status.CLOSED : this._permitObj.PermitStateId;
        this._permitObj.PermitState = null;
        this.SaveValues();
      },
      reject: () => {
      }
    });
  }

  BindCloseState() {
    let div = this._PermitVersion.Divisions[2];
    div.Sections.forEach(sec => {
      let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
      if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
        if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
          let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
          if (logSectionObj == undefined) {
            let obj: PermitActivityLog = {
              PermitActivityLogId: 0,
              PermitId: this._permitObj.PermitId,
              ResponseTagNumber: sec.PermitTypeSectionId,
              PermitStateId: Status.SECTIONAPPROVED,
              PerformedBy: this._currentLoggedInUser.LoginId,
              PerformedByName: null,
              PerformedDate: new Date(),
              Permit: null,
              PermitState: null,
              PermitRevisionId: this._permitObj.PermitRevisionId
            }
            this._permitActivityLog.push(obj);
          }
        }
      }
    })
  }

  BindCloseResponse() {
    let div = this._PermitVersion.Divisions[2];
    this._permitObj.Responses.forEach(res => {
      div.Sections.forEach(sec => {
        let checkSeq = this._activeSequence.filter(x => x.DivisionId == sec.PermitTypeDivisionId && x.SectionSequence == sec.SequenceNumber);
        if ((checkSeq && checkSeq.length > 0) || sec.SequenceNumber == null) {
          if (this._currentLoggedInUserRoles.find(x => x.RoleId == sec.Approver)) {
            if (sec.Questions.find(x => x.PermitTypeQuestionId == res.PermitTypeQuestionId)) {
              let logSectionObj = this._permitObj.PermitActivityLogs == null ? undefined : this._permitObj.PermitActivityLogs.find(x => x.ResponseTagNumber == sec.PermitTypeSectionId && x.PermitStateId == Status.SECTIONAPPROVED);
              if (logSectionObj != undefined) {
                res.Approved = true;
              }
            }
          }
        }
      })
    })
  }

  IsLastDivisionApproved() {
    let count = 0;
    let div = this._PermitVersion.Divisions[2];
    div.Sections.forEach(sec => {
      sec.Questions.forEach(ques => {
        if (sec.Approver != null && this._permitObj.Responses.find(res => res.PermitTypeQuestionId == ques.PermitTypeQuestionId && res.Approved != true)) {
          count++;
        }
      })
    })
    if (count > 0) {
      return false;
    }
    else {
      return true;
    }
  }

  toogleCommentBoxVisibility() {
    if (this.commentBoxIsVisible) {
      this.commentBoxIsVisible = false;
    }
    else {
      this.commentBoxIsVisible = true;
    }
  }

  hideCommentBox() {
    this.commentBoxIsVisible = false;
  }

  showCommentBox() {
    this.commentBoxIsVisible = true;
  }

  receiveCommentsCount($event) {
    this._commentsCount = $event;
  }

  createNewPermit() {
    this._copyPermit = false;
    this._resetFlag = true;
    this.getPermitVersionDetails(this._permitTypeId);

  }

  toggleFilter() {
    this._toggleFilter = !this._toggleFilter;
    if (this._toggleFilter) {
      let containerHeight = window.innerHeight - 423;
      this._gridHeightPX = containerHeight + 'px';
      this._gridRowCount = Math.floor(containerHeight / 35);


    }
    else {
      let containerHeight = window.innerHeight - 357;
      this._gridHeightPX = containerHeight + 'px';
      this._gridRowCount = Math.floor(containerHeight / 35);
    }
  }
  
  filterTabledataFromTable() {
    this.table.first = 0;
    this.filterTabledata();
  }
  filterTabledata(pageIndex: number = 0, rowCount: number = 20) {
    let filterlists = [];
    let orginalLists: Permit[] = [];
    this.AllPermit = [];
    this._permits = [];
    let isFiltered = false;
    this.SetFilteredData(pageIndex, this.rowCount);    
  }
  filterValues(type: string, search: any, permits) {
    if (search != '') {
      switch (type) {
        case 'permit':
          this.perArr = [];
          search.forEach(pno => {
            permits.forEach(item => {
              let permitnos = item.PermitNumber.indexOf(pno) > -1
              if (permitnos) {
                this.perArr.push(item);
              }
            });
          })
          return this.perArr;
          break;
        case 'issuetime':
          this.perArr = [];
          search.forEach(pno => {
            permits.forEach(item => {
              let permitnos = item.CreatedDate.indexOf(pno) > -1
              if (permitnos) {
                this.perArr.push(item);
              }
            });
          })
          return this.perArr;
          break;
        case 'workorder':
          this.perArr = [];
          search.forEach(wno => {
            permits.forEach(item => {
              if (item.WorkOrderNo != null) {
                let workOrdnos = item.WorkOrderNo.indexOf(wno) > -1
                if (workOrdnos) {
                  this.perArr.push(item);
                }
              }
            });
          })
          return this.perArr;
          break;
        case 'area':
          this.perArr = [];
          search.forEach(ano => {
            permits.forEach(item => {
              let permitnos = item.SRUName.indexOf(ano) > -1
              if (permitnos) {
                this.perArr.push(item);
              }
            });
          })
          return this.perArr;
          break;
        case 'field_3':
          this.perArr = [];
          search.forEach(sno => {
            permits.forEach(item => {
              let permitnos = item.SRUID.indexOf(sno) > -1
              if (permitnos) {
                this.perArr.push(item);
              }
            });
          })
          return this.perArr;
          break;
        case 'field_2':
          this.perArr = [];
          search.forEach(sno => {
            permits.forEach(item => {
              let permitnos = item.SRUID.indexOf(sno) > -1
              if (permitnos) {
                this.perArr.push(item);
              }
            });
          })
          return this.perArr;
          break;
        case 'field_1':
          this.perArr = [];
          search.forEach(workdesc => {
            permits.forEach(item => {
              let permitnos = item.SiteId.indexOf(workdesc) > -1
              if (permitnos) {
                this.perArr.push(item);
              }
            });
          })
          return this.perArr;
          break;
        case 'raiseby':
          this.perArr = [];
          search.forEach(pno => {
            permits.forEach(item => {
              let permitnos = item.CreatedByName.indexOf(pno) > -1
              if (permitnos) {
                this.perArr.push(item);
              }
            });
          })
          return this.perArr;
          break;
        default:
          break;
      }

    }
    else {
      return permits;
    }
  }
  removeDuplicates(originalArray, prop) {
    var newArray = [];
    var lookupObject = {};

    for (var i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
    }

    for (i in lookupObject) {
      newArray.push(lookupObject[i]);
    }
    return newArray;
  }

  resetfilter() {
    this.permitNos = [];
    this.field_1 = [];
    this.issuedTime = [];
    this.RaisedBy = [];
    this.Area = [];
    this.workOrder = [];
    this.field_2 = [];
    this.field_3 = [];
    this.field_4 = [];
    this.field_5 = [];
    this.field_6 = [];
    this.field_7 = [];
    this.table.rows = 20;
    this.GetPreviousPermits(0, 20, 'ResetFilter');

  }

  GetPreviousPermits(pageIndex: number, rowsCount: number, calledFrom: string) {
    this._displayPermitSelectPopUp = false;
    this.copyPermitPopupClicked = true;
    this._permitService.getAllIdentifiers(this._permitTypeId).subscribe((res: any) => {
      if (res) {
        let identifers_list = Object.keys(res);
        this.SetDynamicDropDowns(identifers_list, res);
        this.SetFilteredData(pageIndex, rowsCount, "firstLoad");
        this._resetFlag = false;
      }
      else {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CopyError') });
      }
    });
  }

  GetPermitsToLink(pageIndex: number, rowsCount: number, calledFrom: string) {
    this._displayPermitSelectPopUp = false;
    this._linkPermit=false;
    this.FilteredPermits=[];
    //this.copyPermitPopupClicked = true;
    this._permitService.getAllIdentifiers(this._permitTypeId).subscribe((res: any) => {
      if (res) {
        let identifers_list = Object.keys(res);
        this.SetDynamicDropDowns(identifers_list, res);
        this.SetFilteredData(pageIndex, rowsCount, "linkedpermit");
        this._resetFlag = false;
      }
      else {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CopyError') });
      }
    });
  }

  SetDynamicDropDowns(identifers_list, res) {        
    Array.from(res["permitNos"]).forEach((el) => {
      this._permitNumbers.push(el.toString());
      el && el != null ? this._permitno.push({ label: el, value: el }) : '';
    });
    Array.from(res["IssuedTime"]).forEach((el: any) => {
      el = new Date(el.toString()).toUTCString();
       el && el != null ? this._issuetime.push({  label:  this.formatAMPM(this._datePipe.transform(el, 'dd-MMM-yyyy')), value: this.formatAMPM(this._datePipe.transform(el, 'dd-MMM-yyyy hh:mm:ss a')) }) : '';
       //el && el != null ? this._issuetime.push({ label: el: "short":"UTC", value: new Date(this._datePipe.transform(el, 'dd-MMM-yyyy hh:mm:ss a')) }) : '';
     });
    
    Array.from(res["Area"]).forEach((el) => {
      el && el != null ? this._area.push({ label: el, value: el }) : '';
    });
    Array.from(res["WorkOrder"]).forEach((el) => {
      el && el != null ? this._workorder.push({ label: el, value: el }) : '';
    });
    Array.from(res["RaisedBy"]).forEach((el) => {
      el && el != null ? this._raiseby.push({ label: el, value: el }) : '';
    });
    
    this._permitno = this.removeDuplicates(this._permitno, 'value');
    this._raiseby = this.removeDuplicates(this._raiseby, 'value');
    this._raiseby = this._raiseby.sort((a, b) => a.value.toString() - b.value.toString());
    this._area = this.removeDuplicates(this._area, 'value');
    this._area = this._area.sort((a, b) => a.value.toString() - b.value.toString());
    this._issuetime = this.removeDuplicates(this._issuetime, 'value');
    this._workorder = this.removeDuplicates(this._workorder, 'value');
    let fixedAttributes = ['permitNos', 'IssuedTime', 'Area', 'WorkOrder', 'RaisedBy'];

    if (identifers_list) {
      if (identifers_list.length > 0) {
        let iFierQues = identifers_list[0].replace(/<[^>]*>/g, '');
        if (!fixedAttributes.includes(iFierQues)) {
          this.DynamicQus_1 = iFierQues;
          Array.from(res[identifers_list[0]]).forEach((el) => {
            this._permitIdentifierIdsFilter1.push({ 'IdentifierVal': el });
          });
        }
      }

      if (identifers_list.length > 1) {
        let iFierQues = identifers_list[1].replace(/<[^>]*>/g, '');
        if (!fixedAttributes.includes(iFierQues)) {
          this.DynamicQus_2 = iFierQues;
          Array.from(res[identifers_list[1]]).forEach((el) => {
            this._permitIdentifierIdsFilter2.push({ 'IdentifierVal': el });
          });
        }
      }

      if (identifers_list.length > 2) {
        let iFierQues = identifers_list[2].replace(/<[^>]*>/g, '');
        if (!fixedAttributes.includes(iFierQues)) {
          this.DynamicQus_3 = iFierQues;
          Array.from(res[identifers_list[2]]).forEach((el) => {
            this._permitIdentifierIdsFilter3.push({ 'IdentifierVal': el });
          });
        }
      }

      if (identifers_list.length > 3) {
        let iFierQues = identifers_list[3].replace(/<[^>]*>/g, '');
        if (!fixedAttributes.includes(iFierQues)) {
          this.DynamicQus_4 = iFierQues;
          Array.from(res[identifers_list[3]]).forEach((el) => {
            this._permitIdentifierIdsFilter4.push({ 'IdentifierVal': el });
          });
        }
      }

      if (identifers_list.length > 4) {
        let iFierQues = identifers_list[4].replace(/<[^>]*>/g, '');
        if (!fixedAttributes.includes(iFierQues)) {
          this.DynamicQus_5 = iFierQues;
          Array.from(res[identifers_list[4]]).forEach((el) => {
            this._permitIdentifierIdsFilter5.push({ 'IdentifierVal': el });
          });
        }
      }

      if (identifers_list.length > 5) {
        let iFierQues = identifers_list[5].replace(/<[^>]*>/g, '');
        if (!fixedAttributes.includes(iFierQues)) {
          this.DynamicQus_6 = iFierQues;
          Array.from(res[identifers_list[5]]).forEach((el) => {
            this._permitIdentifierIdsFilter6.push({ 'IdentifierVal': el });
          });
        }
      }

      if (identifers_list.length > 6) {
        let iFierQues = identifers_list[6].replace(/<[^>]*>/g, '');
        if (!fixedAttributes.includes(iFierQues)) {
          this.DynamicQus_7 = iFierQues;
          Array.from(res[identifers_list[6]]).forEach((el) => {
            this._permitIdentifierIdsFilter7.push({ 'IdentifierVal': el });
          });
        }
      }
      this._permitIdentifierIdsFilter1 = this.removeDuplicates(this._permitIdentifierIdsFilter1, 'IdentifierVal');
      this._permitIdentifierIdsFilter2 = this.removeDuplicates(this._permitIdentifierIdsFilter2, 'IdentifierVal');
      this._permitIdentifierIdsFilter3 = this.removeDuplicates(this._permitIdentifierIdsFilter3, 'IdentifierVal');
      this._permitIdentifierIdsFilter4 = this.removeDuplicates(this._permitIdentifierIdsFilter4, 'IdentifierVal');
      this._permitIdentifierIdsFilter5 = this.removeDuplicates(this._permitIdentifierIdsFilter5, 'IdentifierVal');
      this._permitIdentifierIdsFilter6 = this.removeDuplicates(this._permitIdentifierIdsFilter6, 'IdentifierVal');
      this._permitIdentifierIdsFilter7 = this.removeDuplicates(this._permitIdentifierIdsFilter7, 'IdentifierVal');
    }
  }
  SetFilteredData(pageIndex, rowsCount, calledFrom = "") {

    var filter = {};

    filter["permitTypeId"] = this._permitTypeId;
    filter["userSiteId"] = this._userSiteId;
    filter["languageId"] = localStorage.getItem("_languageID");
    filter["pageIndex"] = pageIndex;
    filter["rowCount"] = 20; //rowsCount;

    filter["permitNos"] = this.permitNos ? this.permitNos : [];    
    filter["issuedTime"] = this.issuedTime ? this.formatTranslation(this.issuedTime).map(i => new Date(i).toUTCString()) : [];
    filter["RaisedBy"] = this.RaisedBy ? this.RaisedBy : [];
    filter["Area"] = this.Area ? this.Area : [];
    filter["workOrder"] = this.workOrder ? this.workOrder : [];


    filter["field_1"] = this.field_1 ? this.field_1.map(i => i['IdentifierVal']) : [];
    filter["field_2"] = this.field_2 ? this.field_2.map(i => i['IdentifierVal']) : [];
    filter["field_3"] = this.field_3 ? this.field_3.map(i => i['IdentifierVal']) : [];
    filter["field_4"] = this.field_4 ? this.field_4.map(i => i['IdentifierVal']) : [];
    filter["field_5"] = this.field_5 ? this.field_5.map(i => i['IdentifierVal']) : [];
    filter["field_6"] = this.field_6 ? this.field_6.map(i => i['IdentifierVal']) : [];
    filter["field_7"] = this.field_7 ? this.field_7.map(i => i['IdentifierVal']) : [];


    filter["DynamicQus_1"] = this.DynamicQus_1 ? this.DynamicQus_1 : "";
    filter["DynamicQus_2"] = this.DynamicQus_2 ? this.DynamicQus_2 : "";
    filter["DynamicQus_3"] = this.DynamicQus_3 ? this.DynamicQus_3 : "";
    filter["DynamicQus_4"] = this.DynamicQus_4 ? this.DynamicQus_4 : "";
    filter["DynamicQus_5"] = this.DynamicQus_5 ? this.DynamicQus_5 : "";
    filter["DynamicQus_6"] = this.DynamicQus_6 ? this.DynamicQus_6 : "";
    filter["DynamicQus_7"] = this.DynamicQus_7 ? this.DynamicQus_7 : "";


    this._permitService.filterPermits(filter).subscribe((res) => {
      if (res) {        
        this.AllPermit = this._permits = res.body["permits"];
        this.totalPermitCount = res.body["count"];
        if (this._permits.length > 0) {
          for (let i = 0; i < this._permits.length; i++) {
            this._permits[i].PermitIssuedDateTime = this.formatAMPM(this.adjustForTimezone(this._permits[i].PermitIssuedDateTime));
            if (this._permits[i].PermitIdentifier.length > 0) {
              let lstIdentifiers = this._permits[i].PermitIdentifier;
              let pi1 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_1);
              let pi2 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_2);
              let pi3 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_3);
              let pi4 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_4);
              let pi5 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_5);
              let pi6 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_6);
              let pi7 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_7);
              this._permits[i].IdentifierVal1 = (pi1 && pi1 != undefined && pi1 != null) ? pi1.IdentifierVal : '';
              this._permits[i].IdentifierVal2 = (pi2 && pi2 != undefined && pi2 != null) ? pi2.IdentifierVal : '';
              this._permits[i].IdentifierVal3 = (pi3 && pi3 != undefined && pi3 != null) ? pi3.IdentifierVal : '';
              this._permits[i].IdentifierVal4 = (pi4 && pi4 != undefined && pi4 != null) ? pi4.IdentifierVal : '';
              this._permits[i].IdentifierVal5 = (pi5 && pi5 != undefined && pi5 != null) ? pi5.IdentifierVal : '';
              this._permits[i].IdentifierVal6 = (pi6 && pi6 != undefined && pi6 != null) ? pi6.IdentifierVal : '';
              this._permits[i].IdentifierVal7 = (pi7 && pi7 != undefined && pi7 != null) ? pi7.IdentifierVal : '';              
            }           
          }
          if (calledFrom == "firstLoad") {
            this._copyPermit = true;
            this._selectedPermitId = 0 // to be fetched once user selects the permit number from the pop up
            this.popUpHeader = this.translate.instant('Permit.ChoosePermit');
          }
          else if(calledFrom == "linkedpermit")
          {
            this._linkPermit = true;
            this._selectedPermitId = 0 // to be fetched once user selects the permit number from the pop up
            this.popUpHeader = this.translate.instant('Permit.ChoosePermit');

          }
        }
      }
      else {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CopyError') });
      }
    });
  }
  field_1_Filter(event) {
    this.filter_1 = [];
    var pFilter = this.dynamicField_1.filter(x => x.value.toString().toLowerCase().includes(event.query.toString().toLowerCase()));
    pFilter.forEach(x => {
      this.filter_1.push(x.value)
    })
  }
  field_2_Filter(event) {
    this.filter_2 = [];
    var pFilter = this.dynamicField_2.filter(x => x.value.toString().toLowerCase().includes(event.query.toString().toLowerCase()));
    pFilter.forEach(x => {
      this.filter_2.push(x.value)
    })
  }
  field_3_Filter(event) {
    this.filter_3 = [];
    var pFilter = this.dynamicField_3.filter(x => x.value.toString().toLowerCase().includes(event.query.toString().toLowerCase()));
    pFilter.forEach(x => {
      this.filter_3.push(x.value)
    })
  }
  field_4_Filter(event) {
    this.filter_4 = [];
    var pFilter = this.dynamicField_4.filter(x => x.value.toString().toLowerCase().includes(event.query.toString().toLowerCase()));
    pFilter.forEach(x => {
      this.filter_4.push(x.value)
    })
  }
  field_5_Filter(event) {
    this.filter_5 = [];
    var pFilter = this.dynamicField_5.filter(x => x.value.toString().toLowerCase().includes(event.query.toString().toLowerCase()));
    pFilter.forEach(x => {
      this.filter_5.push(x.value)
    })
  }
  field_6_Filter(event) {
    this.filter_6 = [];
    var pFilter = this.dynamicField_6.filter(x => x.value.toString().toLowerCase().includes(event.query.toString().toLowerCase()));
    pFilter.forEach(x => {
      this.filter_6.push(x.value)
    })
  }
  field_7_Filter(event) {
    this.filter_7 = [];
    var pFilter = this.dynamicField_7.filter(x => x.value.toString().toLowerCase().includes(event.query.toString().toLowerCase()));
    pFilter.forEach(x => {
      this.filter_7.push(x.value)
    })
  }
  CancelCopyPermit() {
    this._displayPermitSelectPopUp = false;
  }

  filterPermits(event) {

    this.filteredPermits = [];
    this._permits.forEach(permit => {
      if (permit.PermitNumber.toLowerCase().includes(event.query.toLowerCase())) {
        this.filteredPermits.push(permit.PermitNumber)
      }
    });
  }

  cancleADD() {
    this._copyPermit = false;
  }

  filterOnDateRangeChange() {
    
    if (this._selectedDateRangeFilter.length > 0 && this._selectedDateRangeFilter[0]) {
      this.filterGrid(this.normalpermitLinking);
    }
  }

  showNormalPermitButton()
  {
    if (this._PermitVersion) {
      if (this._permitObj.PermitId == 0 || this._permitObj.PermitId == null || this._permitObj.PermitId == undefined) {
        return false;
      }

       if(!this.validateforClosePermit())
       {
          return false;
       }
      if (this._PermitVersion.PermitType!=null && (this._PermitVersion.PermitType.MasterPermit== null || this._PermitVersion.PermitType.MasterPermit==false)) {
        this.normalpermitLinking = true;
        return true;
      }
    }
    return false;

  }

   validateforClosePermit()
  {
    if(this._permitObj.PermitId>0)
      {
        if(this._permitObj.PermitState.PermitStateName =='Closed' ||  
          this._permitObj.PermitState.PermitStateName == 'Rejected' || 
          this._permitObj.PermitState.PermitStateName =='Cancelled' ||

          this._permitObj.PermitState.PermitStateName =='Suspended' || 
          this._permitObj.PermitState.PermitStateName =='Lapsed')
        {
          return false;
        }
        else 
        return true;
      }

  }
  showLinkPermitButton()
  {
    if (this._PermitVersion) {
      if (this._permitObj.PermitId == 0 || this._permitObj.PermitId == null || this._permitObj.PermitId == undefined) {
        return false;
      }

      if(!this.validateforClosePermit())
        {
           return false;
        }

      
      if (this._PermitVersion.PermitType!=null && this._PermitVersion.PermitType.MasterPermit==true) {
        return true;
      }
    }
    return false;

  }

  continueCopyPermit() {
    if (this.permitSelected != '') {
      this.SelectPermit(this.permitSelected);
    }
  }

  discardCopyPermit() {
    this._displayContinueaction = false;
  }

  CancelPermitLinking()
  {
    this._linkPermit=false;
    this.showChildPermits = false;
    this._multiplePermitsForPrintIDs=[];
    this.FilteredPermits = [];
  }

  selectPermitConfirmation(permit: string) {
    this._displayContinueaction = !this._displayContinueaction;
    this.permitSelected = permit;
  }

  SelectPermit(permit: string) {
    this._selectedPermit = this._permits.find(x => x.PermitNumber.toLowerCase() == permit.toLowerCase());
    this.responseAttachmentFiles = [];
    this.copyPermitNumber = this._selectedPermit ? this._selectedPermit.PermitNumber : '';
    this.getPermit(this._selectedPermit.PermitId, true, false);
  }

  GetPermitCreatedByUserDetails(permitId: number) {
    this.permitCreatedByUser = new PermitCreatedByUserViewModel();
    this._userService.getPermitCreatedByUserDetails(permitId).subscribe((data: PermitCreatedByUserViewModel) => {
      this.permitCreatedByUser = data;
      this.permitCreatedByUserPdf = this.deepCopy(data);
      if (this.permitCreatedByUser) {
        this.userProfilePicture = this.GetMediaForUser(this.permitCreatedByUser.Attachment);
        this.GetPermitStatus(permitId);
      }
    });
  }

  GetMediaForUser(attachment: Attachment) {
    let azurefileid = attachment == null ? null : attachment.AzureFileId;
    if (azurefileid != null && azurefileid != 0) {
      this._mediaService.getFileProperties(azurefileid).subscribe(
        (res: HttpResponse<any>) => {
          if (res.body.uri != null && res.body.uri != '') {
            if (res.body.contentType.indexOf('image/') != -1) {
              this.userProfilePicture = res.body.thumbUri;
            }
          }
        }
      );
    }
  }

  GetPermitStatus(permitId: number) {
    let status = "";
    let flag = 0;
    this._permitService.getPermitStatus(permitId).subscribe((data: any) => {
      this.permitApprovalStatus = data;
      this.permitApprovalStatus.DivisionStatusList.forEach(y => {
        y.SectionList.forEach(i => {
          if (i.IsApproved == 0) {
            status = i.RoleName;
            if (flag == 0) {
              this.roleDisplayedOverCommentBoxUpdated = status;
              flag = 1;
            }
          }
        })
      })
    });
  }

  enableStatusTab() {
    this.showStatus = true;
  }

  enableUserTab() {
    this.showStatus = false;
    this.backToStatusView();
  }

  TakeOver(isTakeOver: boolean) {    
    if (this._PermitVersion) {
      for (let division of this._PermitVersion.Divisions) {
        for (let section of division.Sections) {
          for (let question of section.Questions) {
            if (question.AnswerTypeId == 12) {

              this.entryLeaderName = question.AnswerText;
            }

          }
        }
      }
    }
    if (isTakeOver) {
      this.takeOverMessage = this.translate.instant('Permit.TakeOverHeaderPart1');
      //this.takeOverHeader = this.translate.instant('Permit.TakeOverHeader');
    }
    else {
      this.takeOverMessage = this.translate.instant('Permit.ReallocateHeaderPart1') +
        this.entryLeaderName.split(', ').map(w => w[0].toUpperCase() + w.substr(1).toLowerCase()).join(', ') + '?';
      //this.takeOverHeader = this.translate.instant('Permit.ReallocateHeader');
    }
    this._confirmationService.confirm({
      message: this.takeOverMessage,
      //+ this.translate.instant('Permit.TakeOverPart3'),
      header: this.translate.instant('confirmationHeader'),
      key: 'deleteResponseImage',
      icon: 'pi pi-info-circle',
      accept: () => {        
        if (this._PermitVersion) {
          for (let division of this._PermitVersion.Divisions) {
            for (let section of division.Sections) {
              for (let question of section.Questions) {
                if (question.AnswerTypeId == 12) {
                  if (isTakeOver) {
                    question.AnswerText = localStorage.getItem("_userName").toUpperCase();
                  }
                  else {
                    question.AnswerText = this.entryLeaderName.toUpperCase();
                  }
                }

              }
            }
          }
        }

        let cmnt = new Comment();
        cmnt.CommentId = 0;
        cmnt.PermitId = this._permitObj.PermitId;
        if (isTakeOver) {
          cmnt.CommentText = "Take Over completed";
        }
        else {
          cmnt.CommentText = "Reallocation completed";
        }
        cmnt.Permit = null;
        cmnt.Active = true;
        cmnt.CreatedBy = localStorage.getItem("_loginId");
        cmnt.ModifiedBy = '';
        cmnt.CreatedDate = new Date();
        cmnt.ModifiedDate = null;

        this._permitService.addPermitComment(cmnt).subscribe(comment => {
          if (comment.status == 200) {
            this.updateEntryLeaderQuestion();
            //TODO: Change log
            //_changeLogService.setChangeLog()
          }
        });
       
      },
      reject: () => {
        if (!isTakeOver) {
          if (this._PermitVersion) {
            for (let division of this._PermitVersion.Divisions) {
              for (let section of division.Sections) {
                for (let question of section.Questions) {
                  if (question.AnswerTypeId == 12) {

                    question.AnswerText = this.previousEntryleader;
                  }

                }
              }
            }
          }
        }
      }
    });
  }

  updateEntryLeaderQuestion() {
    if (this._permitObj.SiteId || (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null)) {
      this.AssignValues();
    }
    
    this._permitService.updateEntryLeaderQuestion(this._permitObj).subscribe((data: HttpResponse<any>) => {
      if (data.status === 200) {
        this.getPermit(data.body, false, false);
      }
      else {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.UpdatePermitError') });
      }
    });
  }

  OpenApproverComment(value) {
    if (value == 2)
      this.rejFlag = true;
    if (value == 1)
      this.rejFlag = false;
    let list = this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.SECTIONAPPROVE || x.PermitStateId == Status.PERMITLEVELAPPROVE);
    if (list.length > 0) {
      let roles: number[] = [];
      list.forEach(x => { roles.push(x.RoleId) });
      roles = roles.filter(x => x != null); // remove null values
      roles = Array.from(new Set(roles)); // remove duplicate values
      this.isLMSIntegrationEnabled=this._permitControl?.LmsIntegration;
      if(this.isLMSIntegrationEnabled == true){
      this._commonService.GetTrainingStatusForUser(this._permitObj.PermitId, roles).subscribe((data: RoleTrainingStateViewModel[]) => {
        if (data.length > 0) {
          this._trainingNotPresentForRole = data.filter(x => x.Status == false);
          let objList = data.filter(x => x.Status == true);
          if (objList.length == 0) {
            this._displayLMSErrorForUser = true;
            // this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingNotPresentForUser') });
          }
          else {
            // this._trainingNotPresentForRole = data.filter(x => x.Status == false);
            // 1 - Approve
            // 2 - Reject
            // 3 - Close 
            this._commentFlag = value;
            this._approverComments = null;
            this._displayCommentPopUp = true;
          }
          
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingStatus') });
        }
      },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorLMSServer') });
        });
      }
      else {
        // this._trainingNotPresentForRole = data.filter(x => x.Status == false);
        // 1 - Approve
        // 2 - Reject
        // 3 - Close 
        this._commentFlag = value;
        this._approverComments = null;
        this._displayCommentPopUp = true;
      }
    }
    else if (this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.PERMITCLOSE).length > 0) {
      this._commentFlag = value;
      this._approverComments = null;
      this._displayCommentPopUp = true;
    }
  }

  AddComment() {
   
    if (this._commentFlag == 1) {
      this.ApprovePermit();
    }
    else if (this._commentFlag == 2) {
      this.RejectPermit();
    }
    else if (this._commentFlag == 3) {
      this.ClosePermit();
    }
    
  }

  CancelComment() {
    this._displayCommentPopUp = false;
    this._approverComments = null;
  }

  SaveApproverComments() {
    let approverCommentObj = new Comment();
    approverCommentObj.CommentId = 0;
    approverCommentObj.PermitId = this._permitObj.PermitId;
    approverCommentObj.CommentText = this._approverComments;
    approverCommentObj.Permit = null;
    approverCommentObj.Active = true;
    approverCommentObj.CreatedBy = this._currentLoggedInUser.LoginId;
    approverCommentObj.ModifiedBy = null;
    approverCommentObj.CreatedDate = new Date();
    approverCommentObj.ModifiedDate = null;

    this._permitService.addPermitComment(approverCommentObj).subscribe(comment => {
      if (comment.status == 200) {
        this._approverComments = null;
      }
      else {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SaveCommentError') });
        this._approverComments = null;
      }
    });
  }

  revalidatePermit() {

    if (this._permitControl.rolesForRevalidation.length > 0) {
      let roles: number[] = [];
      for (let i = 0; i < this._permitControl.rolesForRevalidation.length; i++) {
        if (this._currentLoggedInUserRoles.includes(this._permitControl.rolesForRevalidation[i])) {
          roles.push(this._permitControl.rolesForRevalidation[i].RoleId);
        }
      }
      roles = Array.from(new Set(roles));
      this.isLMSIntegrationEnabled=this._permitControl?.LmsIntegration;
      if(this.isLMSIntegrationEnabled == true){
      this._commonService.GetTrainingStatusForUser(this._permitObj.PermitId, roles).subscribe((data: RoleTrainingStateViewModel[]) => {
        if (data.length > 0) {
          let objList = data.filter(x => x.Status == true);
          this._trainingNotPresentForRole = data.filter(x => x.Status == false);
          if (objList.length == 0) {
            this._displayLMSErrorForUser = true;
          
          }
          else {
            this.revalidate();
          }
         
        }
        else {
          this.revalidate();
        }
      },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorLMSServer') });
        });
      }
      else {
        this.revalidate();
      }

    }
    else {
      this.revalidate();
    }
  }

  revalidate() {
    this._revalidateService.revalidatePermit(this._permitObj.PermitTypeId, this._permitObj.PermitId, PermitStates.REVALIDATED, false, this.isRevalidateBeforeLapse).subscribe((permitRevision: PermitRevision) => {
      if (permitRevision) {
        this.responseAttachmentFiles = []
        this.getPermit(this._permitObj.PermitId, false, false);
        this._canBeRevalidated = false;
        this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.RevalidationSuccessful') });
      }
      else {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.RevalidationError') });
      }
    });
  }

  GetMaxOccurance(permitId: number) {
    this._revalidateService.getPermitRevisionById(permitId).subscribe((permitRevision: PermitRevision) => {
      if (permitRevision) {
        this.revisionCounter = permitRevision.Revision;
      }
    });
  }

  CheckAnsOptionWidth(answerOptions: PermitTypeAnswerOption[]) {
    let ansWidthCounter = 0;
    answerOptions.forEach(ao => {
      if (ao.AnswerOptionWidth) {
        ansWidthCounter++;
      }
    });
    if (ansWidthCounter > 0) {
      return true;
    }
    else {
      return false;
    }
  }

   OpenApproverPopup() {

  
  this.ApprovalPopupRequired= this._PermitVersion.PermitType.ApprovalPopUpRequired;
    if(this.ApprovalPopupRequired == null || this.ApprovalPopupRequired == false)
    {    if (this._submitButtonClicked) {
      this._submitButtonClicked = false;
      this.SubmitPermitResponse();
    }
    else {
      this.SaveResponse();
    }
  }
  else if (this.ApprovalPopupRequired == true){
    this.displayuserforrole = true;
    this.ddlUserForRole = 0;
    this._sectionId = [];
    let roleGroups: RoleGroupApproval[] = [];
    if (this._permitObj.SiteId || (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null)) {
      
    }
  }
  }

  getApproverRoles() {
    this._permitTypeVersionService.getUserForPermitRoles(this.userHierarchy.SiteId, this._PermitVersion.PermitTypeVersionId, this._permitId).subscribe((userrolegroup: RoleGroupApproval[]) => {

      userrolegroup = userrolegroup.filter(x => x.UserShortInfo.length > 0);      
      this._permitObj.RoleGroupApproval = this.deepCopy(userrolegroup);      
      this._permitObj.RoleGroupApproval.forEach(rga => {
        if (!rga.UserDetails) {
          rga.UserDetails = [];
        }
      });
      //ritu add code here for getting roles
      userrolegroup.forEach(urg => {
        if (urg.UserDetails) {
          urg.UserDetails = urg.UserDetails.filter(x => x.Active == true);
        }
        else {
          urg.UserDetails = [];
        }
      });
      userrolegroup.forEach(rfu => {
        rfu.ApproverInfo = this.GetApproverInfo(rfu);        
        rfu.ApproverInfo = rfu.ApproverInfo.sort((a, b) => a.label.localeCompare(b.label));        
      });
      this._roleForUser = userrolegroup;
      if (this._mailDistributionRequired) {
        this.AttachPdfToMail(this._permitObj.PermitId);
        this._mailDistributionRequired = false;
      }
      this._displayContinueaction = false;
      this._copyPermit = false;
    });
  }

  getPermitVersionDetails(permitTypeId) {
    let permitId = this._permitId > 0 ? this._permitId : 0;
    this._permitTypeVersionService.getPermitTypeLatestVersion(permitTypeId, permitId, true).subscribe((permitTypeVersion: PermitTypeVersion[]) => {
      if (permitTypeVersion.length > 0) {   
        this._PermitVersionList = permitTypeVersion;
        this._PermitVersion = new PermitTypeVersion();
        this._PermitVersion = permitTypeVersion.find(p => p.PublishStatus == true);    
            
        this._PermitVersionPdf = this.deepCopy(this._PermitVersion);
        this._permitTypeLevelApprovalList = [];
        this._permitTypeLevelApprovalList = this._PermitVersion.PermitType.PermitTypeLevelApprovals.filter(x => x.Active == true);
        this._selectedSectionToPrint = [];
        this.setPrintableSections();
        this.setDefaultValues();
        this.getPermiTypeDetails(this._PermitVersion.PermitTypeId);
        if (this._permitId > 0) {
          this.responseAttachmentFiles = [];
          this.getPermit(this._permitId, false, false);
        }
        else {
          this.getApproverRoles();
          this.GetInitialDataForWorkflow();
          this.AddRowsToTables();
          this.AddRowsToSubQuestionTables();
        }
        this.rows = [];
        this.rows.push({ Prop: "Prop" });
      }
      else {
        this._messageService.clear('error');
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.NoVersionAvailable') });
      }
    });
  }

  buildWorkOrderStructure(AssetWOList: PermitAssetAndWorkOrder[]) {
    if (AssetWOList && AssetWOList.length > 0) {
      this.assetWorkOrderList = [];
      let assetList = AssetWOList.map(a => a.AssetNo);
      assetList = this._helperService.removeDuplicateFromArray(assetList);


      assetList.forEach(assetNumber => {
        let assetWOByAsset = AssetWOList.filter(x => x.AssetNo == assetNumber);
        if (assetWOByAsset && assetWOByAsset.length > 0) {
          this.assetWorkOrderList.push(this.bindAssetWOViewModel(assetWOByAsset));
        }
      });
    }
    else {
      this.assetWorkOrderList = [];
    }
  }

  bindAssetWOViewModel(awo: PermitAssetAndWorkOrder[]): AssetWorkOrderViewModel {
    let aVM = new AssetWorkOrderViewModel();
    let WOVM: WorkOrder[] = [];
    aVM.AssetNo = awo[0].AssetNo;
    aVM.AssetDesc = awo[0].AssetDesc;
    aVM.AssetWorkOrderID = awo[0].AssetWorkOrderID ? awo[0].AssetWorkOrderID : 0;
    awo.forEach(wo => {
      if (wo.workOrderNo && wo.workOrderNo.trim() != "") {
        let wOrder = new WorkOrder();
        wOrder.WorkOrderNo = wo.workOrderNo;
        wOrder.WorkOrderDesc = wo.workOrderDesc;
        wOrder.WOStatus = wo.WOStatus;
        wOrder.opSequence = [];
       
        wOrder.opSequence.forEach(op => {
          wOrder.opSequence = this.bindOPViewModel(op.OPSequenceNo, op.OPSeqDesc ? op.OPSeqDesc : '', op.StartDate, op.CompletionDate);
        })


        WOVM.push(wOrder);
      }
    });
    aVM.workOrder = WOVM;
    return aVM;
  }

  bindOPViewModel(operationNumber: String, operationDesc: String, startDate: Date, completionDate: Date): OPSequence[] {
    let OPVM: OPSequence[] = [];
    let opIndex = 0;
    let opNoArr = operationNumber.split("@@@");
    let opNoDesc = operationDesc.split("@@@");
    if (opNoArr && opNoArr.length > 0) {
      opNoArr.forEach(element => {
        let OPSec = new OPSequence();
        OPSec.OPSequenceNo = element;
        OPSec.OPSeqDesc = '';
        OPSec.StartDate = startDate;
        OPSec.CompletionDate = completionDate;
        if (opNoDesc && opNoDesc.length > opIndex) {
          OPSec.OPSeqDesc = opNoDesc[opIndex];
        }
        opIndex++;
        OPVM.push(OPSec);
      });
    }
    OPVM.forEach((element, index) => {
      if (element.OPSequenceNo == null || element.OPSequenceNo.trim() == '') {
        OPVM.splice(index, 1);
      };
    });
    return OPVM;
  }

  getPermit(permitId: number, permitCloneFlag: boolean, isMailRequred: boolean) {

    if (permitCloneFlag) {
      this._permitService.getPermit(permitId).subscribe((permit: Permit) => {
        if (permit) {          
          this._permitObj = permit;
          this.assetlistingTable = [];
          //this._permitObj.PermitAssetAndWorkOrder;
          this.oPDescList = this.selectedOPDesc.split(',');
          this._permitObj.PermitIssuedDateTime = this.adjustForTimezone(this._permitObj.PermitIssuedDateTime);
          this.permitIssuedDateTime = this._permitObj.PermitIssuedDateTime;
          this._permitObj.PermitExpiryDateTime = this.adjustForTimezone(this._permitObj.PermitExpiryDateTime);
          this.permitExpiryDateTime = this._permitObj.PermitExpiryDateTime;  
          this.WorkArea=this._permitObj.WorkArea;        
          if (this._permitObj.SRUID == 0 || this._permitObj.SRUID == null) {
            this.ddlArea = 0;
          }
          else {
            this.ddlArea = this._permitObj.SRUID;
          }
          if (this._permitObj.WorkOrderNo != null) {
            this.workOrderArray = this._permitObj.WorkOrderNo.split("@@@");
            this._txtWorkOrder = this.workOrderArray[0];
            this.selectedWorkOrderDesc = this.workOrderArray[1];
          }
          this.PopulateResponsesForPermit(permitCloneFlag);
          
          this.nullifyPermitDetails();
          this._permitObj.PermitWorkflowViewModel = new PermitWorkflowViewModel();
          this.GetInitialDataForWorkflow();
          this.getApproverRoles();
          
          this.permitClosedBy = permit.ClosedByName;
        }
      });
    }
    else {
      if (permitId > 0) {
        this._permitService.getPermit(permitId).subscribe((permit: Permit) => {
          if (permit != undefined) {
            this.isMailRequired = isMailRequred;
            this._permitObj = new Permit();
            this._permitObj = permit;
            this.assetlistingTable = this._permitObj.PermitAssetAndWorkOrder;
            this.buildWorkOrderStructure(this.assetlistingTable);
            this._permitObj.PermitIssuedDateTime = this.adjustForTimezone(this._permitObj.PermitIssuedDateTime);
            this.permitIssuedDateTime = this._permitObj.PermitIssuedDateTime;            
            this.permitIssuedDateTimeStr = this.DateTimeTranslation(this.permitIssuedDateTime);            
            this._permitObj.PermitExpiryDateTime = this.adjustForTimezone(this._permitObj.PermitExpiryDateTime);
            this.permitExpiryDateTime = this._permitObj.PermitExpiryDateTime;
            this.permitExpiryDateTimeStr = this.DateTimeTranslation(this.permitExpiryDateTime);
            this.WorkArea=this._permitObj.WorkArea;  
            this.permitClosedBy = permit.ClosedByName;
            if (this._permitObj.SRUID == 0 || this._permitObj.SRUID == null) {
              this.ddlArea = 0;
            }
            else {
              this.ddlArea = this._permitObj.SRUID;
            }
            if (this._permitObj.WorkOrderNo != null) {
              this.workOrderArray = this._permitObj.WorkOrderNo.split("@@@");
              this._txtWorkOrder = this.workOrderArray[0];
              this.selectedWorkOrderDesc = this.workOrderArray[1];
            }            
                    
            this._permitPdf = this.deepCopy(permit);
            this._mailDistributionRequired = isMailRequred;            
            this._PermitVersion = this.fetchVersionBasedOnPermit();            
            this._PermitVersionPdf = this.deepCopy(this.fetchVersionBasedOnPermit());
            this._commentsCount = this._permitObj.CommentsCount;
            this.setCoordinates(this._permitObj.PermitId);
            if (this._currentLoggedInUserRoles != undefined) {
              
              if (this._permitObj.PermitStateId == Status.ACTIVE) {
                this.isPermitActive = true;
              }              
              this.getPermitControlsForPermitVersion();
              this.PopulateResponsesForPermit(false);
              this.responseAttachmentFiles = [];
              this.PopulateAttachmentForPermit();
              this.subQuesResponseAttachmentFiles = [];
              this.PopulateSubQuestionAttachmentForPermit();
              this.GetPermitCreatedByUserDetails(this._permitObj.PermitId);
              this.GetMaxOccurance(permitId);
              this._permitWorkflowViewModel = this._permitObj.PermitWorkflowViewModel;
              this.getAllEditorsDetails(permitId, 21);
              this.EnableGeoTag();
              this.getApproverRoles();
              this.ViewChangeLog(this._permitObj.PermitId);              
            }
            
            if (this._objArrPermitTypeIds.includes(this._permitObj.PermitTypeId)) {
              this.isConfinedSpace = true;
            }
            if (this.isEntryLeader && this.isPermitActive && this.hasEntryLeaderQuestion && this.isConfinedSpace) {
              this.isBtnEntryLeaderVisible = true;
            }            
          }
          else {
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.PermitNotFoundError') });
            let redirectURL = '/permit/' + this._permitTypeId + '/0';
            this._router.navigate([redirectURL]);
          }
        });
      }
    }
  }

  getAllEditorsDetails(permitId, stateId) {
    this._permitService.getEditorsDetails(permitId, stateId).subscribe(data => {
      this.editorsworkFlow = data;      
    })
  }
  EnableGeoTag() {
    if (this.geoTag) {
      if (this._permitObj.PermitStateId == Status.ACTIVE || this._permitObj.PermitStateId == Status.DRAFT || this._permitObj.PermitStateId == Status.AWAITINGEDITORRESPONSE || this._permitObj.PermitStateId == Status.PENDINGAPPROVAL) {
        this.geoTag = true;
      }
      else {
        this.geoTag = false;
      }
    }
  }

  GetInitialDataForWorkflow() {
    let sectionList: PermitTypeSection[] = this._PermitVersion.Divisions[0].Sections;
    let permitWorkFlowList: PermitWorkFlow[] = [];
    for (let sectionObj of sectionList) {
      let userRole = this._currentLoggedInUserRoles.filter(x => x.RoleId == sectionObj.Editor);
      if (sectionObj.Editor == null || userRole.length != 0) {
        let obj: PermitWorkFlow = {
          PermitWorkFlowId: 0,
          PermitId: 0,
          TagId: sectionObj.PermitTypeSectionId,
          RoleId: sectionObj.Editor,
          PermitStateId: Status.SECTIONEDIT,
          ActionTaken: null,
          DivisionNumber: 1,
          Sequence: null,
          Active: true,
          CreatedBy: null,
          CreatedDate: new Date(),
          ModifiedBy: null,
          ModifiedDate: null
        }
        permitWorkFlowList.push(obj);
      }
    }

    if (this._currentLoggedInUser && permitWorkFlowList.length > 0) {
      this._permitWorkflowViewModel = {
        PermitWorkFlows: permitWorkFlowList,
        Export: true,             // button visible & disabled
        SaveSubmit: true,         // button visible & enabled
        ApproveReject: false,     // button visible & enabled
        Close: false,
        User: false            // button not visible
      }
    }
    else if (this._permitObj.PermitId == 0 || this._permitObj.PermitId == null || this._permitObj.PermitId == undefined) {
      this._permitWorkflowViewModel = {
        PermitWorkFlows: [],
        Export: true,             // button visible & disabled
        SaveSubmit: true,         // button visible & enabled
        ApproveReject: false,     // button visible & enabled
        Close: false,
        User: false          // button not visible
      }
    }
  }

  

  showInputElementsForEditor(divisionObj, sectionObj, questionObj) {
    if (this._currentLoggedInUser && this._permitWorkflowViewModel) {
      let objList = this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.TagId == sectionObj.PermitTypeSectionId && x.PermitStateId == 21);
      if (objList.length > 0) {
        return false;
      }
    }
    return true;
  }

  showConfigureApproverButton() {
    if (this._permitObj.PermitStateId == null || this._permitObj.PermitStateId == undefined || this._permitObj.PermitStateId == 1) {
      if (this._roleForUser && this._roleForUser.length > 0) {
        let value = this.showSaveSubmitButton();
        if (value == true) {
          return true;
        }
      }
    }
    return false;
  }

  showExportButton() {
    if (this._currentLoggedInUser && this._permitWorkflowViewModel) {
      return this._permitWorkflowViewModel.Export;
    }
  }

  

  showSaveSubmitButton() {
    if (this._PermitVersion) {
      if (this._permitObj.PermitId == 0 || this._permitObj.PermitId == null || this._permitObj.PermitId == undefined) {
        return true;
      }
      if (this._currentLoggedInUser.LoginId == this._permitObj.CreatedBy && this._permitObj.PermitStateId == 1 && this._permitWorkflowViewModel && this._permitWorkflowViewModel.PermitWorkFlows.length == 0) {
        return true;
      }
      if (this._currentLoggedInUser && this._permitWorkflowViewModel) {
        return this._permitWorkflowViewModel.SaveSubmit;
      }
    }
    return false;
  }
  showApproveRejectButton() {
    if (this._currentLoggedInUser && this._permitWorkflowViewModel) {
      return this._permitWorkflowViewModel.ApproveReject;
    }
  }

  showCloseButton() {
    
    //new features added
    let retvalue = false;
    if (this._PermitVersion) {
      if (this._permitObj.PermitStateId == 1 || this._permitObj.PermitStateId == 17 || this._permitObj.PermitStateId == 2 || this._permitObj.PermitStateId == 25 || this._permitObj.PermitStateId == undefined || this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId != Status.PERMITCLOSE).length > 0 || !this._permitWorkflowViewModel.PermitWorkFlows) {

        retvalue = false;

      }
     

      else {
        if (this._permitControl != null) {
          if (this._permitControl.ClosedBy == true && (this._permitControl.rolesForClosedBy.filter(x => x.Active == true).length != 0)) {
            this._permitControl.rolesForClosedBy.filter(x => x.Active == true).forEach(obj => {
              if (this._currentLoggedInUserRoles.find(x => x.RoleId == obj.RoleId) && this._permitControl.ClosedBy && this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.PERMITCLOSE).length > 0) {
                retvalue = true;
              }
              else {
                //comment 1
                retvalue = false;
              }
            });
          }
          else {
            retvalue = true;
          }
        }
        else {
          retvalue = true;
        }
      }
    }
    return retvalue;
  }


  showApproverButton() {
    if (this._currentLoggedInUser && this._permitObj.PermitStateId == 1) {
      return true;
    }
    else {
      return false
    }
  }

  SaveResponse() {
    this.count = 0
    if (this._permitObj.SiteId || (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null)) {
      this.getPermiTypeDetails(this._PermitVersion.PermitTypeVersionId)
      this.AssignValues();
      if (this.geoTag) {
        this.FetchMapCoordinates(false);
      }

      if (this._permitWorkflowViewModel.PermitWorkFlows.length > 0) {
        this._permitWorkflowViewModel.PermitWorkFlows.forEach(obj => {
          if (obj.PermitStateId == Status.SECTIONEDIT) {
            obj.ActionTaken = 1;
            obj.ModifiedBy = localStorage.getItem("_loginId");
            obj.ModifiedDate = new Date();
          }
        });
      }
      else {
        let obj = new PermitWorkFlow();
        obj.CreatedDate = new Date();
        obj.ActionTaken = 1;
        obj.ModifiedBy = localStorage.getItem("_loginId");
        obj.ModifiedDate = new Date();
        let list: PermitWorkFlow[] = [];
        list.push(obj);
        this._permitWorkflowViewModel.PermitWorkFlows = list;
      }

      this._permitObj.PermitWorkflowViewModel = this._permitWorkflowViewModel;
      this._permitObj.RoleGroupApproval = this._roleGroupApprover;
      if (this._PermitVersion && this._PermitVersion.PermitType.Area == true && this.ddlArea > 0) {
        this._permitObj.SRUID = this.ddlArea;
      }
      this._txtWorkOrder = this._txtWorkOrder == null ? '' : this._txtWorkOrder;
      if (this._PermitVersion && this._PermitVersion.PermitType.WorkOrder == true && this._txtWorkOrder.trim() != '') {
        this._permitObj.WorkOrderNo = this._txtWorkOrder + "@@@" + this.selectedWorkOrderDesc;
      }
      this._buttonSaveClicked = true;
      this._buttonSubmitClicked = false;
      this._buttonApproveClicked = false;
      this._buttonRejectClicked = false;
      if (!this.geoTag) {
        this.SaveValues();
        setTimeout(() => {
          this._messageService.clear('success');
        }, 5000);
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    }
  }

  ShowApproverOnSubmit() {
    if ((this._permitObj.PermitStateId == null || this._permitObj.PermitStateId == undefined || this._permitObj.PermitStateId == 1) && (this._roleForUser && this._roleForUser.length > 0)) {
      if (this.CheckIfApproversConfigured()) {
        this._submitButtonClicked = true;
        this.OpenApproverPopup();
      }
      else {
        this.SubmitPermitResponse();
      }
    }
    else {
      this.SubmitPermitResponse();
    }

  }

  CheckIfApproversConfigured(): boolean {
    for (let roleUserGroup of this._permitObj.RoleGroupApproval) {
      let userDetails = roleUserGroup.UserDetails;
      let flag = 0;
      for (let userdetail of userDetails) {
        if (userdetail.Active == true) {
          flag = 1;
          break;
        }
      }
      if (flag == 0) {
        return true;
      }
    }
    return false;
  }

  SubmitPermitResponse() {

    let list = this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.SECTIONEDIT && x.RoleId != null);
    if (list.length > 0) {
      let roles: number[] = [];
      list.forEach(x => { roles.push(x.RoleId) });
      roles = roles.filter(x => x != null); // remove null values
      roles = Array.from(new Set(roles)); // remove duplicate values
      this.isLMSIntegrationEnabled=this._permitControl?.LmsIntegration;
      
      if (this._permitObj.PermitId && this._permitObj.PermitId > 0) {
        if(this.isLMSIntegrationEnabled == true){

        this._commonService.GetTrainingStatusForUser(this._permitObj.PermitId, roles).subscribe((data: RoleTrainingStateViewModel[]) => {
          if (data.length > 0) {
            this._trainingNotPresentForRole = data.filter(x => x.Status == false);
            let objList = data.filter(x => x.Status == true);    
            if (this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.SECTIONEDIT && x.RoleId == null).length == 0 && objList.length == 0) {
              this._displayLMSErrorForUser = true;
            }
            else
            {
            this.SubmitPermit();
            }
          }
          else {
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingStatus') });
          }
        },
          error => {
            this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorLMSServer') });
          });
        }
        else{
            this.SubmitPermit();
          }
        
      }
      else {
        if (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null) {
          if(this.isLMSIntegrationEnabled == true){
          this._commonService.GetTrainingStatusForUserBySite(this.userHierarchy.SiteId, this._permitTypeId, roles).subscribe((data: RoleTrainingStateViewModel[]) => {
            if (data.length > 0) {
              this._trainingNotPresentForRole = data.filter(x => x.Status == false);
            let objList = data.filter(x => x.Status == true);    
            if (this._permitWorkflowViewModel.PermitWorkFlows.filter(x => x.PermitStateId == Status.SECTIONEDIT && x.RoleId == null).length == 0 && objList.length == 0) {
              this._displayLMSErrorForUser = true;
            }
            else
            {
            this.SubmitPermit();
            }
            }
            else {
              this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorTrainingStatus') });
            }
          },
            error => {
              this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.errorLMSServer') });
            });
          }
          else{
            this.SubmitPermit();
          }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
        }
      }


    }
    else {
      if (this.ErrorCount() == 0) {
        this._confirmationService.confirm({
          message: this.translate.instant('Permit.ConfirmSumbitMsg'),
          header: this.translate.instant('Permit.ConfirmSubmitHeader'),
          key: 'confirmSubmit',
          icon: 'pi pi-info-circle',
          accept: () => {
            this.SubmitResponse();
          },
          reject: () => {
          }
        });
      }
    }
  }


  SubmitPermit() {    
      if (this.ErrorCount() == 0) {
        this._confirmationService.confirm({
          message: this.translate.instant('Permit.ConfirmSumbitMsg'),
          header: this.translate.instant('Permit.ConfirmSubmitHeader'),
          key: 'confirmSubmit',
          icon: 'pi pi-info-circle',
          accept: () => {
            this.SubmitResponse();
          },
          reject: () => {
          }
        });
      }
    
  }

  FetchMapCoordinates(submit: boolean) {
    let isGisPresent: boolean = false;
    if (this._permitControl != null) {
      if (this._permitControl.GeoTag == true) {
        this._indexDbService.getRecord("mapJson", "mapJson_1").subscribe((data) => {
          let mapViewModel: GISMapViewModel;
          const mapValueFromSession = data;
          mapViewModel = mapValueFromSession;
          if (mapViewModel.features[0].coordinates && mapViewModel.features[0].coordinates.length > 0) {
            isGisPresent = true;
            this._indexDbService.getRecord("mapJson", "screengrab").subscribe((gis_snip) => {
              const mapSnip = gis_snip.screengrab;
              if (isGisPresent) {
                this.count++;
                this.UploadGisSnip(mapSnip, mapViewModel);
              }
              else {
                this.count++;
                if (!submit) {
                  this.SaveValues();
                }
                this._messageService.clear('error');
                this.SavePermit();
              }
            })
          }
          else {
            if (!submit) {
              this.SaveValues();
            }
            else {
              this.SavePermit();
            }
          }
        },
          (error) => {
            if (submit) {
              this.count++;
              this.SavePermit();
            }
            else {
              this.SaveValues();
            }
          }
        );
      }
    }
  }


  SubmitResponse() {    
    this._userButton = true;
    let isSatisfied: boolean = false;
    this.count = 0;
    var userRole: RoleGroupApproval[] = [];
    if (this._permitObj.SiteId || (this.userHierarchy.SiteId != undefined && this.userHierarchy.SiteId != null)) {
      this.AssignValues();
      if (this.geoTag) {
        this.FetchMapCoordinates(true);
      }

      if (this._permitWorkflowViewModel.PermitWorkFlows.length > 0) {
        this._permitWorkflowViewModel.PermitWorkFlows.forEach(obj => {
          if (this._trainingNotPresentForRole.filter(x => x.RoleId == obj.RoleId).length == 0) {
            if (obj.PermitStateId == Status.SECTIONEDIT) {
              obj.ActionTaken = 2;
              obj.ModifiedBy = localStorage.getItem("_loginId");
              obj.ModifiedDate = new Date();
            }
          }
        });
      }
      else {
        let obj = new PermitWorkFlow();
        obj.CreatedDate = new Date();
        obj.ActionTaken = 2;
        obj.ModifiedBy = localStorage.getItem("_loginId");
        obj.ModifiedDate = new Date();
        let list: PermitWorkFlow[] = [];
        list.push(obj);
        this._permitWorkflowViewModel.PermitWorkFlows = list;
      }
      this._permitObj.PermitWorkflowViewModel = this._permitWorkflowViewModel;
      this._permitObj.RoleGroupApproval = this._roleGroupApprover;
      if (this._PermitVersion && this._PermitVersion.PermitType.Area == true && this.ddlArea > 0) {
        this._permitObj.SRUID = this.ddlArea;
      }
      this._txtWorkOrder = this._txtWorkOrder == null ? '' : this._txtWorkOrder;
      if (this._PermitVersion && this._PermitVersion.PermitType.WorkOrder == true && this._txtWorkOrder.trim() != '') {
        this._permitObj.WorkOrderNo = this._txtWorkOrder + "@@@" + this.selectedWorkOrderDesc;
      }
      this._buttonSaveClicked = false;
      this._buttonSubmitClicked = true;
      this._buttonApproveClicked = false;
      this._buttonRejectClicked = false;
      if (!this.geoTag) {
        this.SaveValues();
      }
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.SiteNotFoundError') });
    }
  }

  ErrorCount() {
    let errorCount: number = 0;
    if (this._permitWorkflowViewModel.PermitWorkFlows.length == 0) {
      return errorCount;
    }
    let permitDivision = new PermitTypeDivision();
    let permitWorkflowList = this._permitWorkflowViewModel.PermitWorkFlows;
    let divisionNumber = permitWorkflowList.length > 0 ? permitWorkflowList[0].DivisionNumber : null;

    if (divisionNumber == 1) {
      permitDivision = this._PermitVersion.Divisions.find(x => x.DivisionName.toUpperCase().startsWith('PERMIT CREATION'));
    }
    else if (divisionNumber == 2) {
      permitDivision = this._PermitVersion.Divisions.find(x => x.DivisionName.toUpperCase().startsWith('ACTIVE PERMIT'));
    }
    else if (divisionNumber == 3) {
      permitDivision = this._PermitVersion.Divisions.find(x => x.DivisionName.toUpperCase().startsWith('PERMIT CLOSURE'));
    }
    if (this._PermitVersion.PermitType.Area == true) {
      if (this.ddlArea == 0 || this.ddlArea == null) {
        this.ismandatoryFieldValidated = false;
        return this.AddErrorCount(errorCount, 1);
      }
      else {
        this.ismandatoryFieldValidated = true;
      }
    }
    
    if (permitDivision) {
      for (let section of permitDivision.Sections) {
        if (permitWorkflowList.length > 0 && permitWorkflowList.find(x => x.TagId == section.PermitTypeSectionId)) {
          for (let question of section.Questions) {
            // Non table question
            if (question.AnswerTypeId != 9) {
              if (question.Mandatory) {
                if (question.AnswerTypeId == 1) {

                  if (question.AnswerOptions[0].DynamicDataSource) {
                    if (question.AnswerText == undefined || question.AnswerText == '0') {
                      question.MandatoryValidationSatisfied = false
                      return this.AddErrorCount(errorCount, 1);
                    }
                    else {
                      question.MandatoryValidationSatisfied = true
                    }
                  }
                  else {
                    if (question.SelectedAnswerOptionID == undefined || question.SelectedAnswerOptionID == 0) {
                      question.MandatoryValidationSatisfied = false
                      return this.AddErrorCount(errorCount, 1);
                    }
                    else {
                      question.MandatoryValidationSatisfied = true
                    }
                  }

                }
                if (question.AnswerTypeId == 2) {
                  if (question.SelectedAnswerCheckBox == undefined || question.SelectedAnswerCheckBox.length == 0) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 1);
                  }
                  else {
                    var flag = 0;
                    question.SelectedAnswerCheckBox.forEach(item => {
                      if (item) {
                        flag = 1;
                      }
                    });
                    if (flag == 0) {
                      question.MandatoryValidationSatisfied = false
                      return this.AddErrorCount(errorCount, 1);
                    }
                    else {
                      question.MandatoryValidationSatisfied = true
                    }
                  }
                }
                if (question.AnswerTypeId == 3) {
                  if (question.AnswerText == undefined) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }
                if (question.AnswerTypeId == 4) {
                  if (question.SelectedAnswerOptionID == undefined || question.SelectedAnswerOptionID == 0) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 1);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }
                if (question.AnswerTypeId == 6) {
                  if (question.AnswerText == undefined) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }

                if (question.AnswerTypeId == 11) {
                  if (question.FieldDesc == undefined || question.FieldDesc.trim() == "") {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                  if (question.FieldValue == undefined || question.FieldValue.trim() == "") {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }

                if (question.AnswerTypeId == 12) {
                  if (question.AnswerText == undefined) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }

                if (question.AnswerTypeId == 7 || question.AnswerTypeId == 10) {
                  if (question.AnswerText == undefined) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }

                if (question.AnswerTypeId == 10) {
                  if (question.AnswerText == undefined) {
                    question.MandatoryValidationSatisfied = false
                    return this.AddErrorCount(errorCount, 2);
                  }
                  else {
                    question.MandatoryValidationSatisfied = true
                  }
                }
              }
            }
            else {
              let isTableMandatory: boolean = false;
              for (let header of question.TableFieldType) {
                if (header.Mandatory) {
                  isTableMandatory = true;
                }
              }
              if (isTableMandatory && this.GetRowCount(document.getElementById("TableId" + question.PermitTypeQuestionId)) < 2) {
                question.MandatoryValidationSatisfied = false
                return this.AddErrorCount(errorCount, 3);
              }

              //For table 
              let deletedIndexes: number[];
              deletedIndexes = [];
              for (let rowIndex in question.TableData) {
                if (rowIndex.toString().startsWith('deleted')) {
                  if (question.TableData[rowIndex.toString()] == "hidden") {
                    deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                  }
                }
              }
              for (let rowIndex in question.TableData) {
                if (rowIndex.toString().startsWith('delete') == false) {
                  if (!this.IsRowDeleted(rowIndex, deletedIndexes)) {
                    for (let header of question.TableFieldType) {
                      if (header.Mandatory) {

                        if (header.AnswerTypeId == 1) {

                          if (header.AnswerOptions[0].DynamicDataSource) {
                            if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == "") {
                              header.MandatoryValidationSatisfied = false
                              return this.AddErrorCount(errorCount, 1);
                            }
                            else {
                              header.MandatoryValidationSatisfied = true
                            }
                          }
                          else {
                            if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                              header.MandatoryValidationSatisfied = false
                              return this.AddErrorCount(errorCount, 1);
                            }
                            else {
                              header.MandatoryValidationSatisfied = true
                            }
                          }

                          if (header.AnswerOptions[0].DynamicDataSource) {
                            if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == "") {
                              header.MandatoryValidationSatisfied = false
                              return this.AddErrorCount(errorCount, 1);
                            }
                            else {
                              header.MandatoryValidationSatisfied = true
                            }
                          }
                          else {
                            if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                              header.MandatoryValidationSatisfied = false
                              return this.AddErrorCount(errorCount, 2);
                            }
                            else {
                              header.MandatoryValidationSatisfied = true
                            }
                          }

                        }
                        if (header.AnswerTypeId == 2) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable.length == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }
                        if (header.AnswerTypeId == 3) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 2);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }
                        if (header.AnswerTypeId == 4) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }
                        if (header.AnswerTypeId == 6) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 2);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }

                        if (header.AnswerTypeId == 7) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }

                        if (header.AnswerTypeId == 10) {
                          if ((question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).ResponseText == undefined || (question.TableData[rowIndex][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId == 0) {
                            header.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            header.MandatoryValidationSatisfied = true
                          }
                        }
                      }
                    }
                  }
                }
              }
            }

            //SubQ 
            for (let ao of question.AnswerOptions) {
              if (ao.IsSelected == true) {
                for (let sq of ao.SubQuestions) {
                  if (sq.AnswerTypeId != 9) {
                    if (sq.Mandatory) {
                      if (sq.AnswerTypeId == 1) {

                        if (sq.SubQuesAnswerOptions[0].DynamicDataSource) {
                          if (sq.AnswerText == undefined || sq.AnswerText == '0') {
                            sq.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            sq.MandatoryValidationSatisfied = true
                          }
                        }
                        else {
                          if (sq.SelectedAnswerOptionID == undefined || sq.SelectedAnswerOptionID == 0) {
                            sq.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            sq.MandatoryValidationSatisfied = true
                          }
                        }

                      }
                      if (sq.AnswerTypeId == 2) {
                        if (sq.SelectedAnswerCheckBox == undefined || sq.SelectedAnswerCheckBox.length == 0) {
                          sq.MandatoryValidationSatisfied = false
                          return this.AddErrorCount(errorCount, 1);
                        }
                        else {
                          var flag = 0;
                          sq.SelectedAnswerCheckBox.forEach(item => {
                            if (item) {
                              flag = 1;
                            }
                          });
                          if (flag == 0) {
                            sq.MandatoryValidationSatisfied = false
                            return this.AddErrorCount(errorCount, 1);
                          }
                          else {
                            sq.MandatoryValidationSatisfied = true
                          }
                        }
                      }
                      if (sq.AnswerTypeId == 3) {
                        if (sq.AnswerText == undefined) {
                          sq.MandatoryValidationSatisfied = false
                          return this.AddErrorCount(errorCount, 2);
                        }
                        else {
                          sq.MandatoryValidationSatisfied = true
                        }
                      }
                      if (sq.AnswerTypeId == 4) {
                        if (sq.SelectedAnswerOptionID == undefined || sq.SelectedAnswerOptionID == 0) {
                          sq.MandatoryValidationSatisfied = false
                          return this.AddErrorCount(errorCount, 1);
                        }
                        else {
                          sq.MandatoryValidationSatisfied = true
                        }
                      }
                      if (sq.AnswerTypeId == 6) {
                        if (sq.AnswerText == undefined) {
                          sq.MandatoryValidationSatisfied = false
                          return this.AddErrorCount(errorCount, 2);
                        }
                        else {
                          sq.MandatoryValidationSatisfied = true
                        }
                      }



                      if (sq.AnswerTypeId == 7 || sq.AnswerTypeId == 10) {
                        if (sq.AnswerText == undefined) {
                          sq.MandatoryValidationSatisfied = false
                          return this.AddErrorCount(errorCount, 2);
                        }
                        else {
                          sq.MandatoryValidationSatisfied = true
                        }
                      }

                      if (sq.AnswerTypeId == 10) {
                        if (sq.AnswerText == undefined) {
                          sq.MandatoryValidationSatisfied = false
                          return this.AddErrorCount(errorCount, 2);
                        }
                        else {
                          sq.MandatoryValidationSatisfied = true
                        }
                      }
                    }
                  }
                  else {
                    let isTableMandatory: boolean = false;
                    for (let header of sq.PermitTypeTableSubQues) {
                      if (header.Mandatory) {
                        isTableMandatory = true;
                      }
                    }
                    if (isTableMandatory && this.GetRowCount(document.getElementById("TableId" + sq.PermitTypeSubQuestionId)) < 2) {
                      sq.MandatoryValidationSatisfied = false
                      return this.AddErrorCount(errorCount, 3);
                    }

                    //For table 
                    let deletedIndexes: number[];
                    deletedIndexes = [];
                    for (let rowIndex in sq.TableData) {
                      if (rowIndex.toString().startsWith('deleted')) {
                        if (sq.TableData[rowIndex.toString()] == "hidden") {
                          deletedIndexes.push(parseInt(rowIndex.toString().replace("deleted", "")))
                        }
                      }
                    }
                    for (let rowIndex in sq.TableData) {
                      if (rowIndex.toString().startsWith('delete') == false) {
                        if (!this.IsRowDeleted(rowIndex, deletedIndexes)) {
                          for (let header of sq.PermitTypeTableSubQues) {
                            if (header.Mandatory) {

                              if (header.AnswerTypeId == 1) {

                                if (header.AnswerOptions[0].DynamicDataSource) {
                                  if ((sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText == undefined || (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText == "") {
                                    header.MandatoryValidationSatisfied = false
                                    return this.AddErrorCount(errorCount, 1);
                                  }
                                  else {
                                    header.MandatoryValidationSatisfied = true
                                  }
                                }
                                else {
                                  if ((sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId == undefined || (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId == 0) {
                                    header.MandatoryValidationSatisfied = false
                                    return this.AddErrorCount(errorCount, 1);
                                  }
                                  else {
                                    header.MandatoryValidationSatisfied = true
                                  }
                                }

                                if (header.AnswerOptions[0].DynamicDataSource) {
                                  if ((sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText == undefined || (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText == "") {
                                    header.MandatoryValidationSatisfied = false
                                    return this.AddErrorCount(errorCount, 1);
                                  }
                                  else {
                                    header.MandatoryValidationSatisfied = true
                                  }
                                }
                                else {
                                  if ((sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId == undefined || (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId == 0) {
                                    header.MandatoryValidationSatisfied = false
                                    return this.AddErrorCount(errorCount, 2);
                                  }
                                  else {
                                    header.MandatoryValidationSatisfied = true
                                  }
                                }

                              }
                              if (header.AnswerTypeId == 2) {
                                if ((sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable == undefined || (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable.length == 0) {
                                  header.MandatoryValidationSatisfied = false
                                  return this.AddErrorCount(errorCount, 1);
                                }
                                else {
                                  header.MandatoryValidationSatisfied = true
                                }
                              }
                              if (header.AnswerTypeId == 3) {
                                if ((sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText == undefined || (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId == 0) {
                                  header.MandatoryValidationSatisfied = false
                                  return this.AddErrorCount(errorCount, 2);
                                }
                                else {
                                  header.MandatoryValidationSatisfied = true
                                }
                              }
                              if (header.AnswerTypeId == 4) {
                                if ((sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId == undefined || (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId == 0) {
                                  header.MandatoryValidationSatisfied = false
                                  return this.AddErrorCount(errorCount, 1);
                                }
                                else {
                                  header.MandatoryValidationSatisfied = true
                                }
                              }
                              if (header.AnswerTypeId == 6) {
                                if ((sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText == undefined || (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId == 0) {
                                  header.MandatoryValidationSatisfied = false
                                  return this.AddErrorCount(errorCount, 2);
                                }
                                else {
                                  header.MandatoryValidationSatisfied = true
                                }
                              }

                              if (header.AnswerTypeId == 7) {
                                if ((sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText == undefined || (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId == 0) {
                                  header.MandatoryValidationSatisfied = false
                                  return this.AddErrorCount(errorCount, 1);
                                }
                                else {
                                  header.MandatoryValidationSatisfied = true
                                }
                              }

                              if (header.AnswerTypeId == 10) {
                                if ((sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText == undefined || (sq.TableData[rowIndex][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId == 0) {
                                  header.MandatoryValidationSatisfied = false
                                  return this.AddErrorCount(errorCount, 1);
                                }
                                else {
                                  header.MandatoryValidationSatisfied = true
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return errorCount;
  }

  AddErrorCount(errorCount: number, flag: number) {
    this._messageService.clear('error');
    if (flag == 1) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrSelectionBlank') });
    }
    else if (flag == 2) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTextBlank') });
    }
    else {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.ErrTableBlank') });
    }
    errorCount++;
    return errorCount;
  }

  ApprovePermit() {
    this._displayCommentPopUp = false;
    this._permitWorkflowViewModel.PermitWorkFlows.forEach(obj => {
      if (this._trainingNotPresentForRole.filter(x => x.RoleId == obj.RoleId).length == 0) {
        if (obj.PermitStateId == Status.SECTIONAPPROVE || obj.PermitStateId == Status.PERMITLEVELAPPROVE) {
          obj.ActionTaken = 3;
          obj.ModifiedBy = localStorage.getItem("_loginId");
          obj.ModifiedDate = new Date();
        }
      }
    });
    this._permitObj.PermitWorkflowViewModel = this._permitWorkflowViewModel;
    this._buttonSaveClicked = false;
    this._buttonSubmitClicked = false;
    this._buttonApproveClicked = true;
    this._buttonRejectClicked = false;
    this._buttonUserApproverClicked = false;
    if (!this.geoTag) {
      this.SaveValues();
    }
    else {
      this.FetchMapCoordinates(true);
    }
  }

  RejectPermit() {
    this._displayCommentPopUp = false;
    this._permitWorkflowViewModel.PermitWorkFlows.forEach(obj => {
      if (this._trainingNotPresentForRole.filter(x => x.RoleId == obj.RoleId).length == 0) {
        if (obj.PermitStateId == Status.SECTIONAPPROVE || obj.PermitStateId == Status.PERMITLEVELAPPROVE) {
          obj.ActionTaken = 4;
          obj.ModifiedBy = localStorage.getItem("_loginId");
          obj.ModifiedDate = new Date();
        }
      }
    });
    this._permitObj.PermitWorkflowViewModel = this._permitWorkflowViewModel;
    this._buttonSaveClicked = false;
    this._buttonSubmitClicked = false;
    this._buttonApproveClicked = false;
    this._buttonRejectClicked = true;
    this._buttonUserApproverClicked = false;
    this.SaveValues();
  }

  ClosePermit() {
    this._confirmationService.confirm({
      message: this.translate.instant('Permit.ConfirmCloseMsg'),
      header: this.translate.instant('Permit.ConfirmCloseHeader'),
      key: 'confirmClose',
      icon: 'pi pi-info-circle',
      accept: () => {
        this._displayCommentPopUp = false;
        this._displayCommentPopUp = false;
        this._permitWorkflowViewModel.PermitWorkFlows.forEach(obj => {
          if (obj.PermitStateId == Status.PERMITCLOSE) {
            obj.ActionTaken = 5;
            obj.ModifiedBy = localStorage.getItem("_loginId");
            obj.ModifiedDate = new Date();
          }
        });
        this._permitObj.PermitWorkflowViewModel = this._permitWorkflowViewModel;
        this.SaveValues();
      },
      reject: () => {
      }
    });
  }


  SaveValues() {
    this._permitObj.PermitAssetAndWorkOrder = this.assetlistingTable;
    if (this._permitObj.PermitId > 0) {
      this._permitObj.ModifiedBy = localStorage.getItem("_loginId"); 
      let languageId = localStorage.getItem("_languageID");
      this._permitObj.LanguageId = languageId;
      this._permitObj.WorkArea=this.WorkArea;
      this._permitObj.Source = this._permitObj.Source ? this._permitObj.Source : this.getPermitSource();
      this._permitService.updatePermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          if (this._approverComments != null) {
            if (this._approverComments.trim() != '') {
              this.SaveApproverComments();
            }
          }
          this.responseAttachmentFiles = [];          
          this.getPermit(data.body.Item1, false, data.body.Item4);
          this.ShowPermitSuccessMessage(data.body.Item2);
          this.GetNotificationCount();
          // if (data.body.Item4) {
          //   this.AttachPdfToMail(data.body.Item1);
          // }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.UpdatePermitError') });
        }
      });
    }
    else {
      let languageId = localStorage.getItem("_languageID");
      this._permitObj.LanguageId = languageId;
      this._permitObj.Source = this.getPermitSource();
      this._permitService.addPermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          this.responseAttachmentFiles = [];          
          this.getPermit(data.body.Item1, false, data.body.Item4);

          //Do the autoLink to Master permit if its been created from the Master permit create button
          let masterpermitId:any=localStorage.getItem("AutoLinkMasterPermitNumber");
          if(masterpermitId!=null)
          {
          this.PermitsToLink= new LinkedPermitViewModel();
          this.PermitsToLink.LinkedPermits=[];
          this._PermitLink = new LinkedPermits();
          this._PermitLink.PermitId = masterpermitId;
          this._PermitLink.ChildPermitId=data.body.Item1;
          this._PermitLink.Active=true;
          this._PermitLink.CreatedBy=this._currentLoggedInUser.LoginId;
          this._PermitLink.CreatedDate=new Date();
          this.PermitsToLink.LinkedPermits.push(this._PermitLink);
          this._permitService.linkPermit(this.PermitsToLink).subscribe((data: HttpResponse<any>) => {
            if (data.status === 200) {
              
              localStorage.removeItem("AutoLinkMasterPermitNumber");
            }
          }
          )
          }
          let redirectURL = 'permit/' + this._permitTypeId + '/' + data.body.Item1;
          this.ShowPermitSuccessMessage(data.body.Item2);
          this.GetNotificationCount();
          if (!data.body.Item4) {
            this._router.navigate([redirectURL]);
          }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CreatePermitError') });
        }
      });
    }
    //}

  }

  SavePermit() {
    this._permitObj.PermitAssetAndWorkOrder = this.assetlistingTable;
    if (this._permitObj.PermitId > 0) {
      // mapViewModel.PermitId = this._permitId;
      this._permitObj.ModifiedBy = localStorage.getItem("_loginId");
      let languageId = localStorage.getItem("_languageID");
      this._permitObj.LanguageId = languageId;
      this._permitObj.WorkArea = this.WorkArea;
      this._permitService.updatePermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          if (this._approverComments != null) {
            if (this._approverComments.trim() != '') {
              this.SaveApproverComments();
            }
          }
          this.responseAttachmentFiles = [];
          this.getPermit(data.body.Item1, false, data.body.Item4);
          this.ShowPermitSuccessMessage(data.body.Item2);
          this.GetNotificationCount();
          // if (data.body.Item4) {
          //   this.AttachPdfToMail(data.body.Item1);
          // }
        }
        else {

          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.UpdatePermitError') });
        }
      });
    }
    else {
      this._permitObj.Source = this.getPermitSource();
      this._permitService.addPermit(this._permitObj).subscribe((data: HttpResponse<any>) => {
        if (data.status === 200) {
          this.responseAttachmentFiles = []
          this.getPermit(data.body.Item1, false, data.body.Item4);
          let redirectURL = 'permit/' + this._permitTypeId + '/' + data.body.Item1;
          //Do the autoLink to Master permit if its been created from the Master permit create button
          let masterpermitId:any=localStorage.getItem("AutoLinkMasterPermitNumber");
          if(masterpermitId!=null)
          {
          this.PermitsToLink= new LinkedPermitViewModel();
          this.PermitsToLink.LinkedPermits=[];
          this._PermitLink = new LinkedPermits();
          this._PermitLink.PermitId = masterpermitId;
          this._PermitLink.ChildPermitId=data.body.Item1;
          this._PermitLink.Active=true;
          this._PermitLink.CreatedBy=this._currentLoggedInUser.LoginId;
          this._PermitLink.CreatedDate=new Date();
          this.PermitsToLink.LinkedPermits.push(this._PermitLink);
          this._permitService.linkPermit(this.PermitsToLink).subscribe((data: HttpResponse<any>) => {
            if (data.status === 200) {
              
              localStorage.removeItem("AutoLinkMasterPermitNumber");
            }
          }
          )
          }
          this.ShowPermitSuccessMessage(data.body.Item2);
          this.GetNotificationCount();
          if (!data.body.Item4) {
            this._router.navigate([redirectURL]);
          }
        }
        else {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('Permit.CreatePermitError') });
        }
      });
    }
  }

  UploadGisSnip(locationSnip: string, mapViewModel: GISMapViewModel): any {
    var isMapChanged = '';
    this._indexDbService.getRecord("mapJson", "changeStatus").subscribe((data) => {
      isMapChanged = data.changeStatus;
      if (isMapChanged == 'true') {
        this.UploadGisImage(locationSnip, mapViewModel);
      }
      else {
        this.SavePermit();
      }
    });
  }

  UploadGisImage(locationSnip: string, mapViewModel: GISMapViewModel): any {
    if (locationSnip) {
      var block = locationSnip.split(";");
      var contentType = block[0].split(":")[1];
      var extension = contentType.split("/")[1];
      var dataURI = block[1].split(",")[1];
      let imagePath: string;
      let fileName = 'gis_snip.' + extension;
      const imageBlob = this.dataURItoBlob(dataURI, contentType);
      let imageFile: File = new File([imageBlob], fileName, { type: contentType });
      this._mediaService.AttachMediaFiles(imageFile).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {
          if (this._permitObj.PermitId > 0) {
            mapViewModel.PermitId = this._permitId;
          }
          mapViewModel.AzureFileId = res.body;

          //Setting GIS map change status as false
          let status: any = {};
          status.name = "changeStatus";
          status.changeStatus = "false";
          this._indexDbService.editRecordAsync("mapJson", status).subscribe((data) => {
          });

          this._indexDbService.getRecord("mapJson", "site").subscribe((data) => {
            mapViewModel.SiteName = data.sitename;
            this._permitObj.GISMapViewModel = mapViewModel;
            this.SavePermit();
          });
        }
      },
        () => {
        }
      );
    }
  }

  dataURItoBlob(dataURI, contentType) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: contentType });
    return blob;
  }

  ShowPermitSuccessMessage(permitStateId: number) {
    if (permitStateId == Status.DRAFT) {
      if (this._buttonRejectClicked) {
        this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.RejectSuccess') });
      }
      else {
        this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.PermitSavedAsDraft') });
      }
    }
    else if (permitStateId == Status.CLOSED) {
      this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.ClosePermitSuccess') });
    }
    else if (this._buttonSaveClicked) {
      this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.ResponseSaved') });
    }
    else if (this._buttonSubmitClicked) {
      this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.ResponseSubmitted') });
    }
    else if (this._buttonApproveClicked) {
      this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.ApprovedSuccess') });
    }
    else if (this._buttonRejectClicked) {
      this._messageService.add({ key: 'success', sticky: true, severity: 'success', summary: this.translate.instant('successMsgHeader'), detail: this.translate.instant('Permit.RejectSuccess') });
    }
    setTimeout(() => {
      this._messageService.clear('success');
    }, 5000);
  }


  downloadAttachment(attachmentFile) {
    if (attachmentFile) {
      let fi = attachmentFile[0].source.indexOf('mediafiles/') + 11;
      let li = attachmentFile[0].source.indexOf('?');
      let fileName = attachmentFile[0].source.substring(fi, li);
      try {
        this._commonService.getMediaBlob(this.EscapeSpecialCharacters(attachmentFile[0].source)).subscribe(
          (data: any) => {
            let base64Image = "data:image/png;base64," + data.toString();
            const dlink: HTMLAnchorElement = document.createElement('a');
            dlink.download = fileName;
            dlink.href = base64Image.toString();           
            dlink.click();
            dlink.remove();
          }
        );
      }
      catch (ex) {
        console.log('Error getting attachment ', ex.message)
        window.open(attachmentFile[0].source);
      }
    }
    return false;
  }


  showGeoTagButton() {
    let showGeoTag: boolean = false;
    if (this.geoTag) {
      showGeoTag = true;
      switch (this._permitObj.PermitStateId) {
        case PermitStates.PERMITCLOSE, PermitStates.LAPSED, PermitStates.REJECTED,
          PermitStates.CANCELLED, PermitStates.PERMITLEVELREJECTED, PermitStates.SUSPENDED:
          showGeoTag = false;
          break;
        default:
          showGeoTag = true;
          break;
      }
      return showGeoTag;
    }
    else {
      return showGeoTag;
    }
  }

  async exportPdf(event) {
    let sectionNodes: TreeNode[] = [];
    this._pritableSectionTreeNode = [];
   
    this.loading = false;
    this._permitTypeService.IsPermitPrintWOAttachment(this._permitPdf.PermitTypeId).subscribe(
      (isPrintWithoutAttachment: boolean) => {
        this.downloadFilesByPrintSettings(isPrintWithoutAttachment);
      },
      error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: this.translate.instant('PermitType.errorgetPermitType') });
      }
    );   
  }

  nodeSelect(event) {
    let changeNode;
    let attachmentNode: TreeNode<any>[];
    let permitNode: TreeNode<any>[];
    let result: TreeNode<any>[];
    this._selectedSectionToBePrint = [];

    if (event.node.data == 'permitwithoutoperations') {
      permitNode = this._pritableSectionTreeNode.filter(x => x.data == 'permitwithoutoperations');
      this._selectedSectionToBePrint.push(permitNode[0]);
      changeNode = this._selectedSectionToPrint.filter(x => x.data == 'changelog');

      if (changeNode.length > 0) {
        this._selectedSectionToBePrint.push(changeNode[0]);
        this._selectedSectionToPrint = this._selectedSectionToPrint.filter(x => x.data == event.node.data || x.data == changeNode[0].data);
      }
      else
        this._selectedSectionToPrint = this._selectedSectionToPrint.filter(x => x.data == event.node.data);
    }

    if (event.node.data == 'attachmentonly') {
      attachmentNode = this._pritableSectionTreeNode.filter(x => x.label == event.node.label);
      permitNode = this._pritableSectionTreeNode.filter(x => x.data == 'permitwithoutoperations');
      this._selectedSectionToBePrint.push(permitNode[0]);
      this._selectedSectionToBePrint.push(attachmentNode[0]);
      changeNode = this._selectedSectionToPrint.filter(x => x.data == 'changelog');
      if (changeNode.length > 0) {
        this._selectedSectionToBePrint.push(changeNode[0]);
        this._selectedSectionToPrint = this._selectedSectionToPrint.filter(x => x.data == event.node.data || x.data == changeNode[0].data);
      }
      else
        this._selectedSectionToPrint = this._selectedSectionToPrint.filter(x => x.data == event.node.data);
    }

    if (event.node.data == 'changelog') {
      let changeNodeTr = this._pritableSectionTreeNode.filter(x => x.data == 'changelog');
      changeNode = this._selectedSectionToPrint.filter(x => x.data == 'changelog');
      if (changeNode.length > 0) {
        this._selectedSectionToBePrint.push(changeNode[0]);
        this._selectedSectionToPrint = this._selectedSectionToPrint.filter(x => x.data != changeNode[0].data);
        this._selectedSectionToPrint.push(changeNode[0]);
      }
      else
        this._selectedSectionToPrint.push(changeNodeTr[0]);
    }
    result = this._selectedSectionToBePrint.filter((value, index) => this._selectedSectionToBePrint.indexOf(value) === index);
    this._selectedSectionToBePrint = result;
    this._selectedSectionToPrint = this._selectedSectionToPrint.filter((value, index) => this._selectedSectionToPrint.indexOf(value) === index);
    this.downloadFiles('event');
  }

  nodeUnselect(event) {
    this._selectedSectionToPrint = this._selectedSectionToPrint.filter(x => x.data != event.node.data);
  }

  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  ViewPDF(pdf:any) {
    pdf.export().then((group: Group) => {
      exportPDF(group).then((dataURI) => {
        //debugger;        
        let pdfWindow = window.open("")
        pdfWindow.document.write(
          "<html><head><title></title></head><body><iframe  width='100%' height='100%' src='" + encodeURI(dataURI) + "'></iframe></body></html>"
        );
      });
    });
  }


  ViewImage(imagePath: any) {
    //debugger;
    let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<html><head><title></title></head><body><iframe  width='100%' height='100%' src='" + encodeURI(imagePath.BinaryFile) + "'></iframe></body></html>"
    );
  }

  async downloadFiles(event) {
    let permitOrAttachmentData = this._selectedSectionToBePrint.filter(x => x.data == 'permitwithoutoperations' || x.data == 'attachmentonly')
    if (permitOrAttachmentData.length < 1) {
      alert(this.translate.instant('Permit.SelectError'));
      return false;
    }

    let changeLog = this._selectedSectionToBePrint.filter(x => x.data == 'changelog');
    if (changeLog.length > 0) {
      this._isChangeLog = false;
    }
    else {
      this._isChangeLog = true;
    }

    let permitOnly = this._selectedSectionToBePrint.filter(x => x.data == 'permitonly');
    let permitwithoutoperations = this._selectedSectionToBePrint.filter(x => x.data == 'permitwithoutoperations');

    let attacmentOnly = this._selectedSectionToBePrint.filter(x => x.data == 'attachmentonly');
    if (attacmentOnly.length > 0) {
      this._isChangeLog = true;
    }
    else {
      this._isChangeLog = false;
    }

    let permitSections = this._selectedSectionToBePrint.filter(x => x.data != 'permitsection' &&
      x.data != 'permitonly' && x.data != 'attachmentonly' && x.data != 'ProcedureReference' && x.data != 'RiskAssesment' && x.data != 'permitwithoutoperations' && x.data != 'changelog');
    if (permitSections && permitSections.length > 0) {
      this._sectionToPrint = [];
      permitSections.forEach(sectionNode => {
        this._sectionToPrint.push(sectionNode.data.toString());
      });
      this.findLastSection();
      //this.childPDFComponent.BindSectionForPrint(this._sectionToPrint);
    }
    if ((permitOnly && permitOnly.length > 0) || (permitSections && permitSections.length > 0) || (permitwithoutoperations && permitwithoutoperations.length > 0)) {
      if (this._permitObj.PermitStateId != 17 && this._permitObj.PermitStateId != 3 && this._permitObj.PermitStateId != 2 && this._permitObj.PermitStateId != 20 && this._permitObj.PermitStateId != 1 && this._permitObj.PermitStateId != 25) {
        return;
      }
      this._spinner.show();
      // Enforcing the loader to show for 10 seconds
      await this.DelayExecution();
      let fileName = this._permitObj.PermitNumber.toString() + '_Export';
      if (permitOnly.length > 0) {
        this.showPermitWithoutOperations = false;        
        this.pdfExport.saveAs(fileName);
        setTimeout(() => {
          this.ViewPDF(this.pdfExport);
        }, 100);

      }
      if (permitwithoutoperations.length > 0) {
        this.showPermitWithoutOperations = true;       
        this.pdfExport.saveAs(fileName);
        setTimeout(() => {
          this.ViewPDF(this.pdfExport);     
        }, 100);
         
      }
      else {
        this.showPermitWithoutOperations = false;
      }
      if (permitSections.length > 0) {
        let selectedFileName = this._permitObj.PermitNumber.toString() + '_Partial_Export';
        this.pdfSelectedExport.saveAs(selectedFileName);
        // this.ViewPDF(this.pdfSelectedExport);
        setTimeout(() => {
          this.ViewPDF(this.pdfSelectedExport);
        }, 100);
      }

      this.showdownloadmodal = false;
      this._spinner.hide();
    }
    if (attacmentOnly && attacmentOnly.length > 0) {
      this._spinner.show();
      let countImage = 0;
      let permitNumberToExport = this._permitObj.PermitNumber.toString();
      this.responseAttachmentFiles.forEach(function (downloadfiles) {
        if (downloadfiles.ContentType.indexOf('image/') >= 0) {
          countImage++;       
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<html><head><title></title></head><body><iframe  width='100%' height='100%' src='" + encodeURI(downloadfiles.BinaryFile) + "'></iframe></body></html>"
          );        
          saveAs(downloadfiles.BinaryFile, permitNumberToExport + "_image_" + countImage.toString() + ".png");
        }
        else if (downloadfiles.ContentType.indexOf('/pdf') >= 0) {
          countImage++; 
          saveAs(downloadfiles.BinaryFile, permitNumberToExport + "_pdf_" + countImage.toString() + ".pdf");
        }
        else {
          window.open(downloadfiles.ImageArray[0].source);
        }
      });

      //Sub Question 
      this.subQuesResponseAttachmentFiles.forEach(function (downloadfiles) {
        if (downloadfiles.ContentType.indexOf('image/') >= 0) {
          countImage++;       
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<html><head><title></title></head><body><iframe  width='100%' height='100%' src='" + encodeURI(downloadfiles.BinaryFile) + "'></iframe></body></html>"
          );   
          saveAs(downloadfiles.BinaryFile, permitNumberToExport + "_image_" + countImage.toString() + ".png");
        }
        else if (downloadfiles.ContentType.indexOf('/pdf') >= 0) {
          countImage++;                   
          saveAs(downloadfiles.BinaryFile, permitNumberToExport + "_pdf_" + countImage.toString() + ".pdf");
        }
        else {
          window.open(downloadfiles.ImageArray[0].source);
        }
      });
      this.showdownloadmodal = false;
      this._spinner.hide();
    }
    let ProcedureReference = this._selectedSectionToBePrint.filter(x => x.data == 'ProcedureReference');
    let RiskAssesment = this._selectedSectionToBePrint.filter(x => x.data == 'RiskAssesment');

    if (ProcedureReference.length > 0 || RiskAssesment.length > 0) {
      if (this._procRefVal && this._procRefVal.trim() != '' && ProcedureReference.length > 0) {
        let procRef = this._permitObj.PermitNumber.toString() + '_Procedure_Reference.pdf';
        FileSaver.saveAs("http://cds101.alcoa.com/Health%20and%20Safety/" + this._procRefVal + ".pdf", procRef)
      }

      if (this._riskAssesmentVal && this._riskAssesmentVal.trim() != '' && RiskAssesment.length > 0) {
        let RiskAssVal = this._permitObj.PermitNumber.toString() + '_Risk_Assesment.pdf';
        FileSaver.saveAs("http://cds101.alcoa.com/Health%20and%20Safety/" + this._riskAssesmentVal + ".pdf", RiskAssVal)
      }
      this.showdownloadmodal = false;
    }
    this._selectedSectionToBePrint = [];
    this._selectedSectionToPrint = [];
  }

  async downloadFilesByPrintSettings(isPrintWOAttachment: boolean) {
    let permitOnly: boolean = false;

    if (isPrintWOAttachment == true) {
      this._isChangeLog = false;
      permitOnly = true;
    }

    else {
      this._isChangeLog = true;
      permitOnly = false;
    }

    if (isPrintWOAttachment != null) {
      if (this._permitObj.PermitStateId != 17 && this._permitObj.PermitStateId != 3 && this._permitObj.PermitStateId != 2 && this._permitObj.PermitStateId != 20 && this._permitObj.PermitStateId != 1 && this._permitObj.PermitStateId != 25) {
        return;
      }
      this._spinner.show();
      // Enforcing the loader to show for 10 seconds
      await this.DelayExecution();
      let fileName = this._permitObj.PermitNumber.toString() + '_Export';
      this.showPermitWithoutOperations = true; 
      this.pdfExport.saveAs(fileName);
      setTimeout(() => {
        this.ViewPDF(this.pdfExport);
      }, 100);
      this._spinner.hide();
    }

    if (permitOnly == false) {
      this._spinner.show();
      let countImage = 0;
      let permitNumberToExport = this._permitObj.PermitNumber.toString();
      this.responseAttachmentFiles.forEach(function (downloadfiles) {
        if (downloadfiles.ContentType.indexOf('image/') >= 0) {
          countImage++;       
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<html><head><title></title></head><body><iframe  width='100%' height='100%' src='" + encodeURI(downloadfiles.BinaryFile) + "'></iframe></body></html>"
          );        
          saveAs(downloadfiles.BinaryFile, permitNumberToExport + "_image_" + countImage.toString() + ".png");
        }
        else if (downloadfiles.ContentType.indexOf('/pdf') >= 0) {
          countImage++; 
          saveAs(downloadfiles.BinaryFile, permitNumberToExport + "_pdf_" + countImage.toString() + ".pdf");
        }
        else {
          window.open(downloadfiles.ImageArray[0].source);
        }
      });

      //Sub Question 
      this.subQuesResponseAttachmentFiles.forEach(function (downloadfiles) {
        if (downloadfiles.ContentType.indexOf('image/') >= 0) {
          countImage++;       
          let pdfWindow = window.open("");
          pdfWindow.document.write(
            "<html><head><title></title></head><body><iframe  width='100%' height='100%' src='" + encodeURI(downloadfiles.BinaryFile) + "'></iframe></body></html>"
          );   
          saveAs(downloadfiles.BinaryFile, permitNumberToExport + "_image_" + countImage.toString() + ".png");
        }
        else if (downloadfiles.ContentType.indexOf('/pdf') >= 0) {
          countImage++;                   
          saveAs(downloadfiles.BinaryFile, permitNumberToExport + "_pdf_" + countImage.toString() + ".pdf");
        }
        else {
          window.open(downloadfiles.ImageArray[0].source);
        }
      });
      this._spinner.hide();
    }
  }

  myshow() {
    this.showdownloadmodal = false;
  }

  TriggerMail(event) {
    if (event) {
      this._mailDistributionRequired = true;
    }
  }

  AttachPdfToMail(permitId) {
    this.pdfExport.export().then((group: Group) => exportPDF(group)).then((dataUri) => {
      var block = dataUri.split(";");
      var contentType = block[0].split(":")[1];
      var extension = contentType.split("/")[1];
      var dataURI = block[1].split(",")[1];
      let imagePath: string;
      let fileName = 'active_permit.' + extension;
      const pdfBlob = this.dataURItoBlob(dataURI, contentType);
      let pdfFile: File = new File([pdfBlob], fileName, { type: contentType });
      this._mediaService.AttachMediaFiles(pdfFile).subscribe((res: HttpResponse<any>) => {
        if (res.status == 200) {
          this._permitService.sendConfiguredEmail(permitId, res.body).subscribe((data) => {
            let redirectURL = '/permit/' + this._permitTypeId + '/' + permitId;
            this._router.navigate([redirectURL]);
          });
        }
      },
        () => {
        }
      );
    });
  }


  InitializeDb() {
    this._indexDbService.openDBAsync("myDB", 1).forEach(
      (readyState: string) => {
        console.log('IndexedDB service: opening db: ' + readyState);
        this.ClearDb();
      }, null
    );
  }

  ClearDb() {
    this._indexDbService.clearObjectStoreAsync("mapJson").subscribe((data) => {
      console.log("Cleared Object Store");

      //Setting GIS map change status as false
      let status: any = {};
      status.name = "changeStatus";
      status.changeStatus = "false";
      this._indexDbService.editRecordAsync("mapJson", status).subscribe((data) => {
      });

    })
  }

  GetApproverInfo(userRole: RoleGroupApproval): SelectItem[] {
    let userInfoforDropDownValue: SelectItem[] = [];
    userRole.UserShortInfo.forEach((us) => {
      let userDetails: any = {};
      let lstUserDetails: RoleGroupUserDetails[] = [];
      if (userRole.UserDetails) {
        lstUserDetails = userRole.UserDetails.filter(x => x.LoginId == us.LoginId);
      }

      if (lstUserDetails.length > 0) {
        userDetails = lstUserDetails[0];
      }
      else {
        userDetails = {
          LoginId: us.LoginId,
          RoleGroupApprovalId: 0,
          RoleGroupUserDetailsId: 0
        }
      }
      userInfoforDropDownValue.push({ label: us.UserName, value: userDetails });
    });

    return userInfoforDropDownValue;

  }
  AddUserPopupCancel() {
    this.displayuserforrole = false;
    this._submitButtonClicked = false;
  }
  deepCopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this.deepCopy(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
      }
      return copy;
    }
    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  setPrintableSections() {
    let sectionNames: string[] = [];
    if (this._PermitVersion && this._PermitVersion.Divisions && this._PermitVersion.Divisions.length > 0) {
      this._PermitVersion.Divisions.forEach(divisions => {
        if (divisions.Sections && divisions.Sections.length > 0) {
          divisions.Sections.forEach(section => {
            if (section.Printable) {
              sectionNames.push(section.SectionName);
            }
            this._allSectionNames.push(section.SectionName);
          });
        }
      });
    }
    this._printableSections = [];
    this._printableSections = sectionNames;
  }

  private assignSectionToTreeNode(name: string): TreeNode {
    return {
      label: name,
      data: name,
      leaf: true
    }
  }

  private makePermitTreeNode(label: string, data: string, childNodes: TreeNode[]): TreeNode {
    return {
      label: label,
      data: data,
      leaf: true,
      expanded: true,
      children: childNodes
    }
  }

  private findLastSection() {
    this._sectionToPrint;
    this._allSectionNames;
    let maxIndex = 0;
    if (this._sectionToPrint && this._sectionToPrint.length > 0) {
      this._sectionToPrint.forEach(sectionName => {
        let secIndex = this._allSectionNames.indexOf(sectionName);
        if (secIndex > maxIndex) {
          maxIndex = secIndex;
          this.lastSectionName = sectionName;
        }
      });
    }
  }

  adjustForTimezone(date: Date): Date {
    try {
      if (date) {
        let dateString: string = date.toString();
        dateString = dateString.toLowerCase().indexOf('z') > 0 ? dateString : dateString + 'Z';
        let convertedDate = new Date(dateString);
        return convertedDate;
      }
      else {
        return null;
      }
    }
    catch (ex) {
      return null;
    }
  }

  tolocalTimeString(obj: Date): string {
    try {
      if (obj) {
        let dateString = obj.toString();
        dateString = dateString.toLowerCase().indexOf('z') > 0 ? dateString : dateString + 'Z';
        let dateObj: Date = new Date(dateString);
        return this._datePipe.transform(dateObj, 'dd-MMM-yyyy hh:mm:ss a');
      }
      else {
        return '';
      }
    }
    catch (ex) {
      return '';
    }
  }

  GetNotificationCount() {
    let loggedUserId = localStorage.getItem("_loginId")
    this._notificationService.GetNotificationCount(loggedUserId).subscribe(
      (data: string) => {
        this.notificationCount = data;
        this._helperService._notificationCount = +data;        
        localStorage.setItem("_notificationCount", this.notificationCount);
        this._helperService.notificationCountEventEmit(this.notificationCount.toString());
      },
      () => {
        this._messageService.clear();
        
      }
    )
  }

  // open sub questions on click for dropdown control
  SetNewSubQuestionForDropdown(objDivId, objSecId, objQuesId, event) {
    let objAnsOptId = event.target.value;
    let objevent = (objAnsOptId != null) ? true : false;
    let AnsOpt: PermitTypeAnswerOption;
    let sec = this._PermitVersion.Divisions.find(x => x.PermitTypeDivisionId == objDivId).Sections.find(y => y.PermitTypeSectionId == objSecId);
    if (sec) {
      let ques = sec.Questions.find(x => x.PermitTypeQuestionId == objQuesId);
      if (ques) {

        if (objAnsOptId > 0) {
          AnsOpt = ques.AnswerOptions.find(x => x.PermitTypeAnswerOptionId == objAnsOptId);
          if (!AnsOpt)
            AnsOpt = ques.AnswerOptions.find(x => x.PermitTypeAnswerOptionId == objAnsOptId); //confirm id

          if (AnsOpt) {
            this.SetNewSubQuestion(objDivId, objSecId, objQuesId, AnsOpt.PermitTypeAnswerOptionId, objevent);
          }
        }
        else {
          ques.AnswerOptions.forEach(objAO => {
            objAO.IsSelected = false;
            if (objAO.SubQuestions.length > 0) {
              objAO.SubQuestions.forEach(objSQ => {
                
              });
            }
          });
        }
      }
    }
  }

  SetNewSubQuestion(objDivId, objSecId, objQuesId, objAnsOptId, event) {    
    this._PermitVersion.Divisions.forEach(objDiv => {
      if (objDiv.PermitTypeDivisionId == objDivId) {
        objDiv.Sections.forEach(objSec => {
          if (objSec.PermitTypeSectionId == objSecId) {
            objSec.Questions.forEach(objQues => {
              if (objQues.PermitTypeQuestionId == objQuesId) {
                if (objQues.AnswerTypeId == 1 || objQues.AnswerTypeId == 4) {
                  objQues.AnswerOptions.forEach(objAO => {
                    if (objAO.PermitTypeAnswerOptionId == objAnsOptId) {
                      if (objAO.SubQuestions.length > 0) {
                        objAO.IsSelected = true;
                      }

                    }
                    else {
                      objAO.IsSelected = false;
                    }
                  })
                }
                else if (objQues.AnswerTypeId == 2) {
                  objQues.AnswerOptions.forEach(objAO => {
                    if (objAO.PermitTypeAnswerOptionId == objAnsOptId) {
                      if (event) {
                        if (objAO.SubQuestions.length > 0) {
                          objAO.IsSelected = true;
                        }
                      }
                      else {
                        objAO.IsSelected = false;
                      }


                    }

                  })
                }

              }
            })
          }
        })
      }

    })
  }  

  openNonCSEAssetPopUp(isPermitTypeAsset: boolean) {
    if (isPermitTypeAsset) {
      this.nonCSEAssetPopUp = true;
      this.childAssetTree.loadBaicAssetNode();
    }
    else {
      this.EAMNonCSEassetListFlag = true;
      this.childAssetTreeResponse.loadBaicAssetNode();
    }
  }

  openNonCSEWOPopUp(assetData: AssetWorkOrderViewModel) {
    if (assetData) {
      this.selectedAssetForWOSelection = assetData.AssetNo;
      this.selectWOOperationActiveIndex = 1;
      this.nonCSEWOPopUp = true;
      this.childWOSelection.loadWorkOrder(assetData.AssetNo);

    }
  }
  openSelectedAssetNonCSEWOPopUp(assetNo) {
    //let AssetNo="BUSINESS-PJALUMINA";
    if (assetNo != null) {
      this.selectedAssetForWOSelection = assetNo;
      this.selectWOOperationActiveIndex = 1;
      this.nonCSEWOPopUp = true;
      this.childWOSelection.loadWorkOrder(assetNo);

    }
  }

  openNonCSEWOPopUpWOAsset() {
    this.childWOSelection.clearSelection();
    let assetData: AssetWorkOrderViewModel = new AssetWorkOrderViewModel();
    assetData.AssetNo = "NA";
    assetData.AssetDesc = "NA";
    this.selectedAssetForWOSelection = assetData.AssetNo;
    this.selectWOOperationActiveIndex = 1;
    this.nonCSEWOPopUp = true;
    this.childWOSelection.loadWorkOrder(assetData.AssetNo);

  }

  OnClickDoneWOOperation(activeIndex: number) {
    this.goToOperationClicked = false;
    this.selectAllOperationClicked = true;
    this.isAssetChecked = false;
    this.isAssetSelected = false;
    //Active WO Component
    if (activeIndex == 1) {
      this.childWOSelection.OnSelectWODone();
      this.loadAllOperationSequence(this._selectedWorkOrder);
    }
    //Active OPeration Component
    if (activeIndex == 2) {
      this.childOPSelection.OnSelectOPDone();
    }

  }
  loadAllOperationSequence(_WOList: any[]) {
    if (_WOList.length > 0) {
      this._selectedWoNumber = [];
      _WOList.forEach(element => {
        this._selectedWoNumber.push(element.WorkOrderNumber);
      });
      this._permitService.getOperationSequence(this._selectedWoNumber, 'null', this.userHierarchy.SiteId, 1, 50).subscribe(data => {
        if (data && data.OperationList) {
          this._selectedOperations = data.OperationList;
          this.AllOperationSelectedFromChild(this._selectedOperations);
        }

      }, error => {
        this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: error.error });
      });
    }



  }
  AllOperationSelectedFromChild(objSelectedOPList: any[]) {
    if (objSelectedOPList && objSelectedOPList.length > 0) {
      this.assetlistingTable.forEach(assetListing => {
        let operationSeqSelected = '';
        let operationDescSelected = '';
        objSelectedOPList.forEach(operationList => {
          if (assetListing.workOrderNo != null && assetListing.workOrderNo.trim().toUpperCase() == operationList.WorkOrderNumber.trim().toUpperCase()) {
            assetListing.AllOperationSelected = true;
            if (assetListing.PermitOperationSequences == undefined) {
              assetListing.PermitOperationSequences = [];
            }
            operationSeqSelected = operationSeqSelected + '@@@' + operationList.OperationSeqNum;
            operationDescSelected = operationDescSelected + '@@@' + operationList.Description;

            let opSequenceCount = assetListing.PermitOperationSequences.filter(x => x.OperationSequence == operationList.OperationSeqNum && x.OperationDesc.trim().toUpperCase() == operationList.Description.trim().toUpperCase());
            if (opSequenceCount.length == 0) {
              let opSequence = new PermitOperationSequence();
              opSequence.OperationSequence = operationList.OperationSeqNum;
              opSequence.OperationDesc = operationList.Description;
              opSequence.StartDate = operationList.StartDate;
              opSequence.CompletionDate = operationList.CompletionDate;
              opSequence.Active = true;
              opSequence.CreatedBy = this._permitObj.CreatedBy;
              opSequence.CreatedDate = this._permitObj.CreatedDate;
              assetListing.PermitOperationSequences.push(opSequence);
            }

          }
        });
        assetListing.oPNumber = operationSeqSelected;
        assetListing.oPdesc = operationDescSelected;

      });
      this.buildWorkOrderStructure(this.assetlistingTable);
      this.nonCSEWOPopUp = false;
    }
  }

  AssetSelectedFromChild(event) {
    let objSelectedAsset = event;
    let assetNumber = objSelectedAsset.AssetNumber;
    let assetDesc = objSelectedAsset.AssetDescription;
    if (this.EAMNonCSEassetListFlag) {
      this.selectAsset(assetNumber, assetDesc);
      this.EAMNonCSEassetListFlag = false;
    }
    else {
      this.AddAssetToList(assetNumber, assetDesc);
    }
  }

  AddAssetToList(aNo: string, aDesc: string) {
    let obj: PermitAssetAndWorkOrder = new PermitAssetAndWorkOrder()
    obj.PermitId = this._permitObj.PermitId;
    obj.AssetNo = aNo;
    obj.AssetDesc = aDesc;
    obj.workOrderNo = "";
    obj.workOrderDesc = "";
    obj.oPNumber = "";
    obj.oPdesc = "";
    obj.Active = true;
    obj.CreatedBy = this._permitObj.CreatedBy;
    obj.CreatedDate = this._permitObj.CreatedDate;
    this.assetlistingTable.push(obj);
    this.buildWorkOrderStructure(this.assetlistingTable);
    this.workorderFlag = false;
    this._txtWorkOrder = "";
    this.selectedWorkOrderDesc = "";
    this._txtOP = "";
    this.selectedOPDesc = "";
    this.nonCSEAssetPopUp = false;
  }

  WOSelectedFromChild(event) {
    let objSelectedWO = event;
    let assetNumber: string = '';

    if (objSelectedWO.length > 0) {
      assetNumber = objSelectedWO[0].AssetNumber;
      this._selectedWorkOrder = objSelectedWO;

    }
    this.AddWOToList(assetNumber, objSelectedWO);
  }



  AddWOToList(aNo: string, objSelectedWO: any[]) {
    if (this.assetlistingTable && this.assetlistingTable.length > 0 && objSelectedWO && objSelectedWO.length > 0) {
      this.assetlistingTable.forEach((elementA, index) => {
        objSelectedWO.forEach(elementWO => {
          if (elementA.AssetNo.trim().toUpperCase() == aNo.trim().toUpperCase()
            && elementWO.WorkOrderNumber.trim().toUpperCase() == elementA.workOrderNo.trim().toUpperCase()) {
            this.assetlistingTable.splice(index, 1)
          };
        });
      });
    }
    if (objSelectedWO && objSelectedWO.length > 0) {
      objSelectedWO.forEach(element => {
        let obj: PermitAssetAndWorkOrder = new PermitAssetAndWorkOrder()
        obj.PermitId = this._permitObj.PermitId;
        obj.AssetNo = element.AssetNumber;
        obj.AssetDesc = element.AssetDescription;
        obj.workOrderNo = element.WorkOrderNumber;
        obj.workOrderDesc = element.WorkDescription;
        obj.WOStatus = element.Status;
        obj.oPNumber = "";
        obj.oPdesc = "";
        obj.Active = true;
        obj.CreatedBy = this._permitObj.CreatedBy;
        obj.CreatedDate = this._permitObj.CreatedDate;
        this.assetlistingTable.push(obj);
      });
    }
    this.workorderFlag = false;
    this._txtWorkOrder = "";
    this.selectedWorkOrderDesc = "";
    this._txtOP = "";
    this.selectedOPDesc = "";
    this.nonCSEAssetPopUp = false;
    this.buildWorkOrderStructure(this.assetlistingTable);
    if (this.goToOperationClicked) {
      this._selectedWorkOrderPop = [];
      this._selectedWorkOrderPop = objSelectedWO;
      this.selectWOOperationActiveIndex = 2;
      this.childOPSelection.loadOperationSequence(this._selectedWorkOrderPop);
    }
    else {
      this.nonCSEWOPopUp = false;
    }
  }

  deleteAsset(assetNo) {
    this.assetlistingTable.forEach((element, index) => {
      if (element.AssetNo.trim().toUpperCase() == assetNo.trim().toUpperCase()) {
        this.assetlistingTable.splice(index, 1);
      };
    });
    this.buildWorkOrderStructure(this.assetlistingTable);
  }

  deleteWO(assetNo, workOrderNo) {
    this.assetlistingTable.forEach((element, index) => {
      if (element.AssetNo.trim().toUpperCase() == assetNo.trim().toUpperCase()
        && element.workOrderNo.trim().toUpperCase() == workOrderNo.trim().toUpperCase()) {
        this.assetlistingTable.splice(index, 1);
      };
    });
    this.buildWorkOrderStructure(this.assetlistingTable);
  }

  deleteOP(workOrderNo, opSeqNo) {
    let operationSeqSelected = '';
    let operationDescSelected = '';
    this.assetlistingTable.forEach((assetElement, index) => {
      if (assetElement.workOrderNo.trim().toUpperCase() == workOrderNo.trim().toUpperCase()
        && assetElement.oPNumber.indexOf(opSeqNo.trim()) >= 0) {
        let OPVM: OPSequence[] = [];
        let opIndex = 0;
        assetElement.oPNumber = assetElement.oPNumber ? assetElement.oPNumber : '';
        assetElement.oPdesc = assetElement.oPdesc ? assetElement.oPdesc : '';
        let opNoArr = assetElement.oPNumber.split("@@@");
        let opNoDesc = assetElement.oPdesc.split("@@@");
        if (opNoArr && opNoArr.length > 0) {
          opNoArr.forEach(element => {
            let OPSec = new OPSequence();
            OPSec.OPSequenceNo = element;
            OPSec.OPSeqDesc = '';
            if (opNoDesc && opNoDesc.length > opIndex) {
              OPSec.OPSeqDesc = opNoDesc[opIndex];
            }
            opIndex++;
            OPVM.push(OPSec);
          });
        }
        OPVM.forEach(opElement => {
          if (!(opElement.OPSequenceNo.trim().toUpperCase() == opSeqNo.trim().toUpperCase())) {
            operationSeqSelected = operationSeqSelected + '@@@' + opElement.OPSequenceNo;
            operationDescSelected = operationDescSelected + '@@@' + opElement.OPSeqDesc;
          }
        });
        assetElement.oPNumber = operationSeqSelected;
        assetElement.oPdesc = operationDescSelected;
      };
    });
    this.buildWorkOrderStructure(this.assetlistingTable);
  }

  GoToOperation(event) {
    this.goToOperationClicked = true;
    this.childWOSelection.OnSelectWODone();
  }

  BackToWorkOrder() {
    this.childOPSelection.clearSelection();
    this.selectWOOperationActiveIndex = 1;

    this.assetlistingTable.forEach((elementA, index) => {
      this._selectedWorkOrder.forEach(elementWO => {
        if (elementWO.WorkOrderNumber.trim().toUpperCase() == elementA.workOrderNo.trim().toUpperCase() && elementA.oPNumber.trim() == '') {
          this.deleteWO(elementA.AssetNo, elementA.workOrderNo)
        };
      });
    });
  }

  OperationSelectedFromChild(event) {
    let objSelectedOPList = event;

    if (objSelectedOPList && objSelectedOPList.length > 0) {
      this.assetlistingTable.forEach(assetListing => {
        let operationSeqSelected = '';
        let operationDescSelected = '';
        objSelectedOPList.forEach(operationList => {
          if (assetListing.workOrderNo != null && assetListing.workOrderNo.trim().toUpperCase() == operationList.WorkOrderNumber.trim().toUpperCase()) {
            assetListing.AllOperationSelected = false;
            if (assetListing.PermitOperationSequences == undefined) {
              assetListing.PermitOperationSequences = [];
            }
            operationSeqSelected = operationSeqSelected + '@@@' + operationList.OperationNumber;
            operationDescSelected = operationDescSelected + '@@@' + operationList.Description;

            let opSeqCount = assetListing.PermitOperationSequences.filter(x => x.OperationSequence == operationList.OperationNumber && x.OperationDesc.trim().toUpperCase() == operationList.Description.trim().toUpperCase());
            if (opSeqCount.length == 0) {
              let opSequence = new PermitOperationSequence();
              opSequence.OperationSequence = operationList.OperationNumber;
              opSequence.OperationDesc = operationList.Description;
              opSequence.StartDate = operationList.StartDate;
              opSequence.CompletionDate = operationList.CompletionDate;
              opSequence.Active = true;
              opSequence.CreatedBy = this._permitObj.CreatedBy;
              opSequence.CreatedDate = this._permitObj.CreatedDate;
              assetListing.PermitOperationSequences.push(opSequence);
            }
          }
        });
        assetListing.oPNumber = operationSeqSelected;
        assetListing.oPdesc = operationDescSelected;

      });
      this.buildWorkOrderStructure(this.assetlistingTable);
      this.nonCSEWOPopUp = false;
    }
  }
  viewOperation(workOrderNo) {
    let suffix: any;
    this.assetlistingTable.forEach(a => {
      if (a.workOrderNo == workOrderNo) {
        a.PermitOperationSequences.forEach(pos => {

          if (pos.PermitOperationSequenceId > 0) {
            suffix = '.0000000+00:00';
          }
          else {
            suffix = '';
          }

          pos.ConvertedStartDate = pos.StartDate.toLocaleString() + suffix;
          pos.ConvertedCompletionDate = pos.CompletionDate.toLocaleString() + suffix;

        })
        this.operationSequence = a.PermitOperationSequences;

      }
    })
    if (this.operationSequence.length > 0) {

      this._displayOperationPopup = true;
    }
    else {
      this._displayOperationPopup = false;
    }


  }
  assetSelected(AssetNo, event, assetWorkOrderList) {
    if (event) {
      if (AssetNo != null) {
        this.isAssetSelected = true;
        this.selectedAsset = AssetNo;
        this._assetCheckboxDetails.push(AssetNo, true);
      }
    }
    else {
      this.isAssetSelected = false;
      this._assetCheckboxDetails.push(AssetNo, false);
    }

    let assetSelectedCount = this._assetCheckboxDetails.find(x => x.Checked == true).length;
    if (assetSelectedCount > 1) {
      this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('errorMsgHeader'), detail: "Please Select one asset at a time" });
    }
  }

  ViewChangeLog(permitId) {
    this.displayChangeLog = true;
    this._changeLogService.GetEntryLeaderChangeLogDeatils(permitId).subscribe((data: ChangeLog[]) => {
      this._changeLog = [];
      this._changeLog = data;
      this.revalidateCount = this._changeLog.filter(x => x.PermitState == "Revalidated").length;
      if (this._changeLog && this._changeLog.length > 0) {
        this._changeLog.forEach(element => {
          element.CreatedDate = this.adjustForTimezone(element.CreatedDate);
        });
      }
    });
  }
  DisablePermitIssuedDateTime() {
    let count = 0;
    // let objList = this._permitWorkflowViewModel.PermitWorkFlows.filter(x =>x.PermitStateId == 21);
    this._PermitVersion.Divisions[0].Sections.forEach(sec => {
      if (sec.Approver != null) {
        count++;
      }
    })

    if (this._permitControl != null && this._permitId == 0 && this.permitDateTimeEditable == true) {

      if (this._permitTypeLevelApprovalList.length > 0 || count > 0) {
        this.defaultValueIssuedDateTime = true;
      }
      else {
        this.defaultValueIssuedDateTime = false;
      }
    }
    else {
      if (this._permitTypeLevelApprovalList.length == 0 && count == 0 && this.permitDateTimeEditable == true && this._permitObj.PermitStateId == Status.DRAFT) {
        this.defaultValueIssuedDateTime = false;
      }
      else {
        this.defaultValueIssuedDateTime = true;
      }

    }

  }
  UnlinkPermit(rowData) {
    var linkedPermitObj = new LinkedPermits ();
    linkedPermitObj.PermitId = this._permitObj.PermitId;
    linkedPermitObj.ChildPermitId = rowData.PermitId;
    var requestObj = new LinkedPermitViewModel ();
    requestObj.LinkedPermits = [];
    requestObj.LinkedPermits.push(linkedPermitObj);
    this._permitService.unlinkPermit(requestObj).subscribe(
      (data)=>{
        this.ShowChildPermits();
      },
      error =>
      {
        console.log('error');
      }
    );
  }

  setPermitExpiryDateTime(permitIssuedDateTime) {    
    //debugger;
    let presentTime = new Date();
    let ExpiryDateTime = new Date(permitIssuedDateTime);
    if (ExpiryDateTime < presentTime) {
      
      this.permitIssuedDateTime = presentTime;
      ExpiryDateTime = new Date(presentTime);

    }
    if (this.revalidationHours > 0) {
      ExpiryDateTime.setMilliseconds(ExpiryDateTime.getMilliseconds() + (this.revalidationHours * 3600 * 1000))
      
      this.permitExpiryDateTime = ExpiryDateTime;
    }
  }
  loadCopyPermitData(event: LazyLoadEvent) {
    this.loading = false;
    this.rowCount = event.rows;
    if (event.first / event.rows == 0) {
      event.first = 0;
    }
    else {
      event.first = (event.first / event.rows);

    }
    if (this._permitTypeId != null && this._permitTypeId != 0 && this.copyPermitPopupClicked == true) {
      this.GetPreviousPermits(event.first, event.rows, 'LazyLoadEvent');
    }

  }

  loadLinkPermitData(event: LazyLoadEvent) {
    this.loading = false;
    this.rowCount = event.rows;
    if (event.first / event.rows == 0) {
      event.first = 0;
    }
    else {
      event.first = (event.first / event.rows);

    }
    if (this._permitTypeId != null && this._permitTypeId != 0 ) {
      this.GetPermitsToLink(event.first, event.rows, 'LazyLoadEvent');
    }

  }
  GetPreDefinedDropdownValues() {    
    this._permitno = [];
    this._issuetime = [];
    this._raiseby = [];
    this._area = [];

    if (this._permitTypeId != undefined && this._permitTypeId != 0 && this._userSiteId != undefined && this._userSiteId != 0) {
      this._permitService.getAllPermitsByPermitTypeId(this._permitTypeId, this._userSiteId).subscribe(
        (data: Permit[]) => {
          data = data.filter((v, i, a) => a.findIndex(t => (t.PermitId === v.PermitId)) === i)
          this.AllPermitList = data.sort((a, b) => <any>new Date(b.CreatedDate) - <any>new Date(a.CreatedDate));
          if (this.AllPermitList.length > 0) {

            this.AllPermitList.forEach(p => {
              this._permitNumbers.push(p.PermitNumber);
              p.PermitNumber && p.PermitNumber != null ? this._permitno.push({ label: p.PermitNumber, value: p.PermitNumber }) : '';
              p.CreatedDate = this.adjustForTimezone(p.CreatedDate);              
              p.CreatedDate && p.CreatedDate != null ? this._issuetime.push({ label: this._datePipe.transform(p.CreatedDate, 'dd-MMM-yyyy hh:mm:ss a'), value: this._datePipe.transform(p.CreatedDate, 'dd-MMM-yyyy hh:mm:ss a') }) : '';
              p.CreatedByName && p.CreatedByName != null ? this._raiseby.push({ label: p.CreatedByName, value: p.CreatedByName }) : '';
              p.SRUName && p.SRUName != null ? this._area.push({ label: p.SRUName, value: p.SRUName }) : '';
              p.WorkOrderNo && p.WorkOrderNo != null ? this._workorder.push({ label: p.WorkOrderNo, value: p.WorkOrderNo }) : '';
            });


            this._raiseby = this.removeDuplicates(this._raiseby, 'value');
            this._raiseby = this._raiseby.sort((a, b) => a.value.toString() - b.value.toString());
            this._area = this.removeDuplicates(this._area, 'value');
            this._area = this._area.sort((a, b) => a.value.toString() - b.value.toString());            
            this._issuetime = this.removeDuplicates(this._issuetime, 'value');
            this._workorder = this.removeDuplicates(this._workorder, 'value');
          }

        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('PermitType.errorPredefinedDropdownValues') });
        }
      );
    }

  }
  GetPermitIdentifierFilter_MasterData() {
    let param: string = '';
    if (this.IdentifierQRef_1 != undefined && this.IdentifierQRef_1 != null) {
      param = param + this.IdentifierQRef_1 + ',';
    }
    if (this.IdentifierQRef_2 != undefined && this.IdentifierQRef_2 != null) {
      param = param + this.IdentifierQRef_2 + ',';
    }
    if (this.IdentifierQRef_3 != undefined && this.IdentifierQRef_3 != null) {
      param = param + this.IdentifierQRef_3 + ',';
    }
    if (this.IdentifierQRef_4 != undefined && this.IdentifierQRef_4 != null) {
      param = param + this.IdentifierQRef_4 + ',';
    }
    if (this.IdentifierQRef_5 != undefined && this.IdentifierQRef_5 != null) {
      param = param + this.IdentifierQRef_5 + ',';
    }
    if (this.IdentifierQRef_6 != undefined && this.IdentifierQRef_6 != null) {
      param = param + this.IdentifierQRef_6 + ',';
    }
    if (this.IdentifierQRef_7 != undefined && this.IdentifierQRef_7 != null) {
      param = param + this.IdentifierQRef_7 + ',';
    }
    if (param != '') {
      let identifiers = this.EscapeSpecialCharacters(param);
      this._permitService.getPermitIdentifier_MasterData(identifiers).subscribe(
        (data: PermitIdentifier[]) => {
          if (this.IdentifierQRef_1 != undefined && this.IdentifierQRef_1 != null) {
            this._permitIdentifierIdsFilter1_MasterData = data.filter(x => x.IdentifierQRef == this.IdentifierQRef_1) ? data.filter(x => x.IdentifierQRef == this.IdentifierQRef_1 && x.IdentifierVal != null) : [];
          }
          if (this.IdentifierQRef_2 != undefined && this.IdentifierQRef_2 != null) {
            this._permitIdentifierIdsFilter2_MasterData = data.filter(x => x.IdentifierQRef == this.IdentifierQRef_2) ? data.filter(x => x.IdentifierQRef == this.IdentifierQRef_2 && x.IdentifierVal != null) : [];
          }
          if (this.IdentifierQRef_3 != undefined && this.IdentifierQRef_3 != null) {
            this._permitIdentifierIdsFilter3_MasterData = data.filter(x => x.IdentifierQRef == this.IdentifierQRef_3) ? data.filter(x => x.IdentifierQRef == this.IdentifierQRef_3 && x.IdentifierVal != null) : [];
          }
          if (this.IdentifierQRef_4 != undefined && this.IdentifierQRef_4 != null) {
            this._permitIdentifierIdsFilter4_MasterData = data.filter(x => x.IdentifierQRef == this.IdentifierQRef_4) ? data.filter(x => x.IdentifierQRef == this.IdentifierQRef_4 && x.IdentifierVal != null) : [];
          }
          if (this.IdentifierQRef_5 != undefined && this.IdentifierQRef_5 != null) {
            this._permitIdentifierIdsFilter5_MasterData = data.filter(x => x.IdentifierQRef == this.IdentifierQRef_5) ? data.filter(x => x.IdentifierQRef == this.IdentifierQRef_5 && x.IdentifierVal != null) : [];
          }
          if (this.IdentifierQRef_6 != undefined && this.IdentifierQRef_6 != null) {
            this._permitIdentifierIdsFilter6_MasterData = data.filter(x => x.IdentifierQRef == this.IdentifierQRef_6) ? data.filter(x => x.IdentifierQRef == this.IdentifierQRef_6 && x.IdentifierVal != null) : [];
          }
          if (this.IdentifierQRef_7 != undefined && this.IdentifierQRef_7 != null) {
            this._permitIdentifierIdsFilter7_MasterData = data.filter(x => x.IdentifierQRef == this.IdentifierQRef_7) ? data.filter(x => x.IdentifierQRef == this.IdentifierQRef_7 && x.IdentifierVal != null) : [];
          }
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('PermitType.errorIdentifierMasterData') });
        }
      );
    }
  }
  GetPermitIdentifierFilter1_MasterData() {
    if (this.IdentifierQRef_1 != undefined && this.IdentifierQRef_1 != null) {
      this._permitService.getPermitIdentifier_MasterData(this.IdentifierQRef_1).subscribe(
        (data: PermitIdentifier[]) => {
          this._permitIdentifierIdsFilter1_MasterData = data ? data : [];
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('PermitType.errorIdentifierMasterData') });
        }
      );
    }

  }
  GetPermitIdentifierFilter2_MasterData() {
    if (this.IdentifierQRef_2 != undefined && this.IdentifierQRef_2 != null) {
      this._permitService.getPermitIdentifier_MasterData(this.IdentifierQRef_2).subscribe(
        (data: PermitIdentifier[]) => {
          this._permitIdentifierIdsFilter2_MasterData = data ? data : [];
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('PermitType.errorIdentifierMasterData') });
        }
      );
    }

  }
  GetPermitIdentifierFilter3_MasterData() {
    if (this.IdentifierQRef_3 != undefined && this.IdentifierQRef_3 != null) {
      this._permitService.getPermitIdentifier_MasterData(this.IdentifierQRef_3).subscribe(
        (data: PermitIdentifier[]) => {
          this._permitIdentifierIdsFilter3_MasterData = data ? data : [];
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('PermitType.errorIdentifierMasterData') });
        }
      );
    }

  }
  GetPermitIdentifierFilter4_MasterData() {
    if (this.IdentifierQRef_4 != undefined && this.IdentifierQRef_4 != null) {
      this._permitService.getPermitIdentifier_MasterData(this.IdentifierQRef_4).subscribe(
        (data: PermitIdentifier[]) => {
          this._permitIdentifierIdsFilter4_MasterData = data ? data : [];
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('PermitType.errorIdentifierMasterData') });
        }
      );
    }

  }
  GetPermitIdentifierFilter5_MasterData() {
    if (this.IdentifierQRef_5 != undefined && this.IdentifierQRef_5 != null) {
      this._permitService.getPermitIdentifier_MasterData(this.IdentifierQRef_5).subscribe(
        (data: PermitIdentifier[]) => {
          this._permitIdentifierIdsFilter5_MasterData = data ? data : [];
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('PermitType.errorIdentifierMasterData') });
        }
      );
    }

  }
  GetPermitIdentifierFilter6_MasterData() {
    if (this.IdentifierQRef_6 != undefined && this.IdentifierQRef_6 != null) {
      this._permitService.getPermitIdentifier_MasterData(this.IdentifierQRef_6).subscribe(
        (data: PermitIdentifier[]) => {
          this._permitIdentifierIdsFilter6_MasterData = data ? data : [];
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('PermitType.errorIdentifierMasterData') });
        }
      );
    }

  }
  GetPermitIdentifierFilter7_MasterData() {
    if (this.IdentifierQRef_7 != undefined && this.IdentifierQRef_7 != null) {
      this._permitService.getPermitIdentifier_MasterData(this.IdentifierQRef_7).subscribe(
        (data: PermitIdentifier[]) => {
          this._permitIdentifierIdsFilter7_MasterData = data ? data : [];
        },
        error => {
          this._messageService.add({ key: 'error', sticky: true, severity: 'error', summary: this.translate.instant('validationMsgHeader'), detail: this.translate.instant('PermitType.errorIdentifierMasterData') });
        }
      );
    }
  }

  BindIdentifiers() {
    if (this._permits.length > 0) {
      for (let i = 0; i < this._permits.length; i++) {
        if (this._permits[i].PermitIdentifier.length > 0) {
          let lstIdentifiers = this._permits[i].PermitIdentifier;
          let pi1 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_1);
          let pi2 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_2);
          let pi3 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_3);
          let pi4 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_4);
          let pi5 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_5);
          let pi6 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_6);
          let pi7 = lstIdentifiers.find(x => x.IdentifierQText.replace(/<[^>]*>/g, '') == this.DynamicQus_7);
          this._permits[i].IdentifierVal1 = (pi1 && pi1 != undefined && pi1 != null) ? pi1.IdentifierVal : '';
          this._permits[i].IdentifierVal2 = (pi2 && pi2 != undefined && pi2 != null) ? pi2.IdentifierVal : '';
          this._permits[i].IdentifierVal3 = (pi3 && pi3 != undefined && pi3 != null) ? pi3.IdentifierVal : '';
          this._permits[i].IdentifierVal4 = (pi4 && pi4 != undefined && pi4 != null) ? pi4.IdentifierVal : '';
          this._permits[i].IdentifierVal5 = (pi5 && pi5 != undefined && pi5 != null) ? pi5.IdentifierVal : '';
          this._permits[i].IdentifierVal6 = (pi6 && pi6 != undefined && pi6 != null) ? pi6.IdentifierVal : '';
          this._permits[i].IdentifierVal7 = (pi7 && pi7 != undefined && pi7 != null) ? pi7.IdentifierVal : '';
        }
      }
    }
  }
  setPermitTitle(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.permitDrpTitle = str;
    });
  }

  setIssueDateTitle(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.issueDrpTitle = str;
    });
  }

  setRaisedByTitle(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.raisedBydropDownTitle = str;
    });
  }

  setSRUTitle(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.SRUDrpTitle = str;
    });
  }

  setDynamicQus_1Title(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.DynamicQus_1DrpTitle = str;
    });
  }

  setDynamicQus_2Title(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.DynamicQus_2DrpTitle = str;
    });
  }

  setDynamicQus_3Title(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.DynamicQus_3DrpTitle = str;
    });
  }

  setDynamicQus_4Title(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.DynamicQus_4DrpTitle = str;
    });
  }

  setDynamicQus_5Title(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.DynamicQus_5DrpTitle = str;
    });
  }

  setDynamicQus_6Title(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.DynamicQus_6DrpTitle = str;
    });
  }

  setDynamicQus_7Title(sleectedText) {
    let str: string = '';
    sleectedText.forEach((ele, index) => {
      if (ele.IdentifierVal != undefined)
        str = str + ele.IdentifierVal + ', ';
      else
        str = str + ele + ', ';

      this.DynamicQus_7DrpTitle = str;
    });
  }

  ReturnFalse() {
    return false;
  }


  UploadPermit(){
    this.ResetFilters();
    if(this.UploadPermitValue == true){
      this.UploadPermitValue = false;
      this.GetManualPermitPdfData();
    }
    else{
      this.UploadPermitValue = true;
      this.GetManualPermitPdfData();
    }
    
  }

  SaveManulUploadDetails()
  {
    if (this._permitObj && this._permitObj.PermitId)
    {
      var formData = new FormData();
      if(this.uploadedFile)
      {
        formData.append("file", this.uploadedFile);
      }
    
    // this.entitySourceId = this.inputValue;
    // this.source = 'WPSAUA';
    // this.entityType = 'MANUAL_PERMITS';
    // this.appRegion = 'AUA';
    // this.site='N06'; //Need to get the Site code from the site API using Site ID
    if (
      // this.entitySourceId != null && 
      this._selectedManualPermitStateFilter !=null && this._selectedManualPermitTypeFilter != null && this._selectedManualDateRangeFilter != null)
       {
          // this._mediaService.getEntitySourceAsset(this.entitySourceId, this.source, this.entityType, this.site).subscribe((res: Entity) => {
          //   this.entityId = res.entityId;
          //   this.UploadFileToDas();   
          // },
          //   (err: any) => {
          //     this.addEntityAsset = { sourceEntityId: this.entitySourceId, source: this.source, entityType: this.entityType, site: this.site, entityTags: this.newEntityTag };
          //     this._mediaService.addEntity(this.addEntityAsset).subscribe((res: Entity) => {
          //       this.entityId = res.entityId;
          //     })
          //     this.UploadFileToDas();
          //   })
          // this._permitService.uploadManualPermits(formData).subscribe((fileUrl) => {
          //   this.manualUploadData.PermitTypeId = this._selectedManualPermitTypeFilter ? this._selectedManualPermitTypeFilter.Value : 0;
          //   this.manualUploadData.PermitStateId = this._selectedManualPermitStateFilter ? this._selectedManualPermitStateFilter.Value : 0;
          //   this.manualUploadData.IssueDate = this._selectedManualDateRangeFilter ? this._selectedManualDateRangeFilter : new Date();
          //   this.manualUploadData.PermitNumber = this.inputValue ? this.inputValue : '';
          //   this.manualUploadData.PermitId = this._permitId;
          //   this.manualUploadData.Active=true;
          //   this.manualUploadData.CreatedBy=this._currentLoggedInUser.LoginId;
          //   this.manualUploadData.CreatedDate=new Date();
          //   // this.manualUploadData.FileOriginalUrl = fileUrl;
          //   this._permitService.addManualPermits(this.manualUploadData).subscribe((res) => {
          //   });
            
          // });
          this._mediaService.uploadManualPermits(this.uploadedFile).subscribe((res: any) => {
            this.UploadPermitValue = false;
            this.manualUploadData.PermitTypeId = this._selectedManualPermitTypeFilter ? this._selectedManualPermitTypeFilter.Value : 0;
            this.manualUploadData.PermitStateId = this._selectedManualPermitStateFilter ? this._selectedManualPermitStateFilter.Value : 0;
            this.manualUploadData.IssueDate = this._selectedManualDateRangeFilter ? this._selectedManualDateRangeFilter : new Date();
            this.manualUploadData.PermitNumber = this.inputValue ? this.inputValue : '';
            this.manualUploadData.PermitId = this._permitId;
            this.manualUploadData.Active=true;
            this.manualUploadData.CreatedBy=this._currentLoggedInUser.LoginId;
            this.manualUploadData.CreatedDate=new Date();
            this.manualUploadData.FileOriginalUrl = res.body;
            this.manualUploadData.FileName = this.fileData.name;
            this._permitService.addManualPermits(this.manualUploadData).subscribe((res) => {
            this.RefreshManualPermits();
            });
          });
    }
    }
  }

  GetManualPermitPdfData()
  {
    this._selectedManualPermitTypeFilter = new FilterKeyValue();
    this._selectedManualPermitStateFilter = new FilterKeyValue();
    this._selectedManualDateRangeFilter = null;
    this.inputValue = null;
    if(this.fileUploader){
      this.fileUploader.clear();
    }
  }
 
  uploadedFileName: string ='';
  uploadedFileContent: string | ArrayBuffer | null ='';

  fileData: {name: string; content: string | ArrayBuffer | null } | null =null;
  uploadedFile : File | null = null;

  onFileUpload(event : any) {
    const file = event.files[0];
    this.uploadedFile = event.files[0];
    this.uploadedFileName = file.name;

    const reader = new FileReader();
    reader.onload = () => {
      this.uploadedFileContent = reader.result;
      this.fileData ={
        name: this.uploadedFileName,
        content : this.uploadedFileContent,
      };
      console.log('File Data:' , this.fileData);
    };
    reader.readAsText(file);
  } 

  UploadFileToDas()
  {
    if (this.fileData) {
      const formData = new FormData();
      formData.append("file", this.uploadedFile);
      this._mediaService.uploadEntityAsset(this.entityId, formData, this.appRegion).subscribe((res: EntityAsset) => {
        //Catch the response from DAS Upload API and use Entity Asset ID and Original Url in Manual Permit Table
        this.manualUploadData.FileOriginalUrl = res.originalUrl;

        // Now call the WPS API for storing the Manual Permit.
        
        this._permitService.addManualPermits(this.manualUploadData).subscribe((res) => {
          console.log(res);
        });

      })
    }
  }
  UpdateManualPermit(rowdData : ManualPermitType)
  {
    this.editableManualPermitId = rowdData.Id;
    this._selectedManualDateRangeFilter = new Date(rowdData.IssueDate);
    this._selectedManualPermitTypeFilter.Value = rowdData.PermitTypeId;
    this._selectedManualPermitTypeFilter.Label = rowdData.PermitTypeName;
    this._selectedManualPermitStateFilter.Value = rowdData.PermitStateId;
    this._selectedManualPermitStateFilter.Label = rowdData.PermitStateName;
  }
  OpenPermit(rowData) {
    let redirectURL: string;
    localStorage.setItem('SourceURL', 'child_permit');
    redirectURL = '/permit/' + rowData.PermitTypeId + '/' + rowData.PermitId;
    // localStorage.setItem('redirectURL', redirectURL);
    let baseUrl = window.location.href.replace(this._router.url, '');
    const url = new URL(redirectURL, baseUrl).href
    window.open(url, '_blank');
  }
  ConfirmUnlinkPPermit(rowData)
 {
  this._confirmationService.confirm({
    message: this.translate.instant('Permit.ConfirmUnlink'),
    header: this.translate.instant('Permit.ConfirmSubmitHeader'),
    key: 'confirmUnlinkPermit',
    icon: 'pi pi-info-circle',
    accept: () => {
      this.UnlinkPermit(rowData);
    },
    reject: () => {
    }
  });
 }

 ConFirmManualPermitUpdate(rowData :ManualPermitType, operation: string)
 {
  var messageStr="";
  if(operation=="edit")
    messageStr='Permit.ConfirmManualUpdate';
  else if(operation="delete")
    messageStr='Permit.ConfirmManualDelete';

  this._confirmationService.confirm({
    message: this.translate.instant(messageStr),
    header: this.translate.instant('Permit.ConfirmSubmitHeader'),
    key: 'confirmManualUpdate',
    icon: 'pi pi-info-circle',
    accept: () => {
      this.SaveUpdateManualPermit(rowData, operation);
    },
    reject: () => {
      this.editableManualPermitId=0;
    }
  });
 }

RefreshManualPermits()
{
  this._permitService.getManualPermits(this._permitObj.PermitId).subscribe(
    data=>{
      this._manualPermits = data;
      this._manualPermits.forEach((el)=>{el.IssueDate = new Date(el.IssueDate)});
  });
}

  SaveUpdateManualPermit(rowData :ManualPermitType, operation: string)
  {
    rowData.PermitTypeId = this._selectedManualPermitTypeFilter ? this._selectedManualPermitTypeFilter.Value : 0;
    rowData.PermitStateId = this._selectedManualPermitStateFilter ? this._selectedManualPermitStateFilter.Value : 0;
    rowData.IssueDate = this._selectedManualDateRangeFilter ? this._selectedManualDateRangeFilter : new Date();
    this._permitService.updateManualPermit(rowData, operation).subscribe((res) => {
    this.RefreshManualPermits();
    this._selectedManualDateRangeFilter = null;
    this._selectedManualPermitStateFilter = new FilterKeyValue();
    this._selectedManualPermitTypeFilter = new FilterKeyValue();
    this.editableManualPermitId = 0;
    });
  }
  CancelUpdateManualPermit()
  {
    this.editableManualPermitId = 0;
  }
  async downloadFile(rowData)
  {
   var str=rowData.FileName.split('.');
   var docExtension='pdf';
   
   var Item: DType = new DType();
   this._permitService.getSingleImageAsset(rowData.FileOriginalUrl).subscribe((res)=>{
     Item.sourceData=res['Result'];
     console.log(Item.sourceData);   
     const byteArray= new Uint8Array(
       atob(Item.sourceData).split('').map((char)=>char.charCodeAt(0))
     );
   
      const file= new Blob([byteArray],{type:this.GetDocType(docExtension)});
      const fileURL= URL.createObjectURL(file);
      let fileName=rowData.FileName;
      let link=document.createElement('a');
      link.download=fileName;
      link.target="_blank";
      link.href=fileURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   });
 
  }
 getPermitSource()
 {
  return this._deviceDetetorService.browser + ", " + this._deviceDetetorService.os_version;
 }
 GetDocType(e:any) {

  var doctype="";

  switch (e) {
    case "docx":
      doctype="data:application/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,";
        break;
    case "pdf":
      doctype="data:application/pdf;base64,";
        break;
    case "mp4":
     doctype="data:video/mp4;base64,";
      break;
   
  }
return doctype;

}
}
export class DType {
  IconUrl:SafeUrl;
  imgUrl: SafeUrl;
  CreatedBy: any;
  CreatedDate: Date;
  entityAssetId: any;
  originalUrl: any;
  name: any;
  sourceData : any;
  entityAssetsTags: EntityAssetTag[];
  doctype:any;
  docextension:any;
  size: any;

}
