import { HttpHeaders, HttpInterceptorFn } from '@angular/common/http';
import { inject, Type } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
const authScopes: string[] = [
    //'User.Read'
    ...environment.apiScope,
    //...environment.myMrApiScope,
    //...environment.graphScope
  ];
 const graphAuthScopes: string[] = [
    ...environment.graphScope
  ];
export const AppMsalInterceptor: HttpInterceptorFn = (req, next) => {
  const msalService: MsalService = inject(MsalService);

  const accounts = msalService.instance.getAllAccounts();
  const account = accounts[0];
  const accessCheck = 'Security/GetUserAccess';
  if (account && !req.url.includes(accessCheck)) {
    let gSamAccountName = account.idTokenClaims.samAccountName;
    let gUserName = account.username.replace(/@.*$/, "");
    if (gSamAccountName == null || gSamAccountName == "" || gSamAccountName == undefined) {
      gSamAccountName = msalService.instance.getActiveAccount().idTokenClaims.upn;
    }
    let loginId = gSamAccountName != null ? gSamAccountName : gUserName;
    let username = account.name.replace(/ *\([^)]*\) */g, "");
    let email = account.username;

    localStorage.setItem("_loginId", loginId.toString());
    localStorage.setItem("_userName", username);
    localStorage.setItem("_emailId", email);
    localStorage.setItem("_gUsername", gUserName); 
     
    return from(msalService.instance.acquireTokenSilent({
      account,
      scopes: req.url.includes('me/checkMemberGroups') || req.url.includes('me/photo/$value')
      || req.url.includes('https://graph.microsoft.com/')
              ? graphAuthScopes : authScopes ,
    })).pipe(
      switchMap((tokenResponse) => {
        let URL1 = 'me/checkMemberGroups';
        let URL2 = 'me/photo/$value';
        let URL3 = 'https://graph.microsoft.com/'
        localStorage.setItem("app_token",tokenResponse.accessToken)

        if(req.url.includes(URL1) || req.url.includes(URL2) || req.url.includes(URL3))
        {
          localStorage.setItem("graph_token",tokenResponse.accessToken)
          let commonHeaders = new HttpHeaders()
                .append('Content-Type', 'application/json')
                .append('Authorization', 'Bearer ' + tokenResponse.accessToken);
                const newReq = req.clone({ headers : commonHeaders });
                return next(newReq);
        }
        else{
          const authReq = req.clone({
            setHeaders: {
              Authorization: `Bearer ${tokenResponse.accessToken}`
            }
          });
          return next(authReq);
        }
      })
    );
  } else {
    return next(req);
  }  
};
