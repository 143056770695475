import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { PermitTypeQuestion } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeQuestion';
import { PermitCreatedByUserViewModel } from '../ViewModel/PermitCreatedByUserViewModel';
import { Permit } from '../ViewModel/Permit';
import { PermitTypeVersion } from '../ViewModel/PermitBuilder/PermitTypeVersion';
import { PermitTypeAnswerOption } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeAnswerOption';
import { PermitTypeDivision } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeDivision';
import { PermitTypeSection } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeSection';
import { TableTypeResponse } from '../ViewModel/TableTypeResponse';
import { SubQuesTableTypeResponse } from '../ViewModel/SubQuesTableTypeResponse';
import { SafeHtmlPipe } from '../Pipes/SafeHtmlPipe';
import { DefaultPipeModule } from '../DefaultPipeModule';
import { DomSanitizer } from '@angular/platform-browser';
import { HierarchySearchViewModel } from '../ViewModel/HierarchySearchViewModel';
import { SRUService } from '../Services/sru.service';
import { SRU } from 'src/app/ViewModel/sru';
import { PermitAssetAndWorkOrder } from '../ViewModel/PermitAssetAndWorkOrder';
import { AssetWorkOrderViewModel } from '../ViewModel/AssetWorkOrderViewModel'
import { WorkOrder } from '../ViewModel/WorkOrderViewModel';
import { OPSequence } from '../ViewModel/OPSequenceViewModel';
import { PermitTypeSubQuestion } from '../ViewModel/PermitBuilder/PermitDivision/PermitTypeSubQuestion';
import { HelperService } from '../Services/helper.service';
import { ChangeLog } from '../ViewModel/Dashboard/ChangeLog';
import { ChangelogService } from '../Services/changelog.service';
import { PermitOperationSequence } from '../ViewModel/PermitOperationSequence';
import { PrimeNGConfig } from 'primeng/api';
import jsPDF from 'jspdf';
import { PermitAdditionalData, SecurityDashboardRootObject, SecurityDashboardViewModel } from '../ViewModel/SecurityDashboardViewModel';
import { ManualPermitType } from '../ViewModel/ManualPermitType';
import { PermitService } from '../Services/permit.service';
import { HierarchyTree } from '../ViewModel/HierarchyTree';
import { CommonService } from '../Services/common.service';
declare var kendo: any;

@Component({
  selector: 'app-print-pdf',
  templateUrl: './print-pdf.component.html',
  styleUrls: ['./print-pdf.component.css']
})
export class PrintPdfComponent implements OnInit, OnChanges {
  @Input() _isChangeLog: boolean = true;
  @Input() _PermitVersion: PermitTypeVersion;
  @Input() permitTypeName: string;
  @Input() permitTypeImage: any;
  @Input() _permitBackColor: string;
  @Input() _permitForeColor: string;
  @Input() previewFlag: string;
  @Input() revalidationHours: Number;
  @Input() revalidationCount: Number;
  @Input() revalidationPeriod: Number;
  @Input() checkRevalidation: boolean;
  @Input() _permitObj: Permit = new Permit();
  
  @Input() _showImage: boolean;
  @Input() _screenGrab: any;
  @Input() preview: boolean;
  @Input() permitCreatedByUser: PermitCreatedByUserViewModel = new PermitCreatedByUserViewModel();
  @Input() responseAttachmentFiles: any[] = [];
  @Input() subQuesResponseAttachmentFiles: any[] = [];
  @Input() MailRequired: boolean;
  @Input() ShowPermitWithoutOperations: boolean;
  @Output() TriggereMail = new EventEmitter<boolean>();

  @Input() permitIssuedDateTimeStr: any;
  @Input() permitExpiryDateTimeStr: any;
  
  au: any;
  _changeLog: ChangeLog[] = [];
  _isMasterPermit :boolean = false;
  _childPermits: SecurityDashboardViewModel[] = [];
   _manualPermits: ManualPermitType[]=[];
  displayChangeLog: boolean = false;
  questionFontSize: number = 0;
  sectionFontSize: number = 0;
  headerFontSize: number = 0;
  isMailRequired: boolean = false;
  showPermitWithoutOperations: boolean = false;
  operationSequence: PermitOperationSequence[] = [];
  permitPdfStatus: string = 'DRAFT';
  permitPdfClosedStatus: string = 'CLOSED';
  _excelContentType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  _pdfContentType: string = 'application/pdf'
  _wordContentType: string = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  _pptContentType: string = ''
 
  _excelThumbnailIconPath: string = '../../assets/Images/excel-icon.png'
  _pdfThumbnailIconPath: string = '../../assets/Images/pdf-icon.png'
  _imageThumbnailIconPath: string = '../../assets/Images/image-icon.png'
  _wordThumbnailIconPath: string = '../../assets/Images/word-icon.png'
  _pptThumbnailIconPath: string = '../../assets/Images/ppt-icon.png'
  HasPermitNoTextChanged: boolean=false;
  HasPermitIssueDateTimeText: boolean=false;
  HasPermitExpireDateTimeText : boolean= false;
  HasSiteTextChanged : boolean=false;
  HasAreaTextChanged : boolean=false;
  HasRequestorNameTextChanged :boolean=false;
  data: any[] = []
  WorkArea : string="";
  
  assetlistingTable: PermitAssetAndWorkOrder[] = [];
  assetWorkOrderList: AssetWorkOrderViewModel[] = [];
  repeatOne: number = 0;
  revalidateCount = 0;
  _gridRowCountChangeLog: number = 10;
  PermitIssuedDateTime: Date;
  
  
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    this.setLangugae();
    
    if (changes._PermitVersion) {
      if (!changes._PermitVersion.isFirstChange()) {
        if (changes._PermitVersion.currentValue) {
          this._PermitVersion = changes._PermitVersion.currentValue;          
          this.questionFontSize = this._PermitVersion.PermitType.QuestionFontSize + 5;
          this.sectionFontSize = this._PermitVersion.PermitType.SectionHeaderFontSize + 5;
          this.headerFontSize = this._PermitVersion.PermitType.HeaderFontSize + 5;
        let DefaultPermitNoText='Permit No';
        let DefaultPermitExpiryDateTimeText='Permit Expire Date/Time';
        let DefaultIssueDateTimeText='Permit Issue Date/Time';
        let DefaultPermitAreaText='Area';
        let DefaultPermitRequestorText='Permit Requestor Name';
        let DefaultPermitSiteText='Site';
        this._isMasterPermit= this._PermitVersion.PermitType.MasterPermit;
        if(this._PermitVersion.PermitTypeControl.PermitNoText!=DefaultPermitNoText) 
          {
            this.HasPermitNoTextChanged=true;
          }
          if(this._PermitVersion.PermitTypeControl.PermitIssueDateTimeText!=DefaultIssueDateTimeText) 
            {
              this.HasPermitIssueDateTimeText=true;
            }
            if(this._PermitVersion.PermitTypeControl.PermitExpireDateTimeText!=DefaultPermitExpiryDateTimeText) 
              {
                this.HasPermitExpireDateTimeText=true;
              }
              if(this._PermitVersion.PermitTypeControl.PermitRequestorText!=DefaultPermitRequestorText) 
                {
                  this.HasRequestorNameTextChanged=true;
                }
                if(this._PermitVersion.PermitTypeControl.SiteText!=DefaultPermitSiteText) 
                  {
                    this.HasSiteTextChanged=true;
                  }
                  if(this._PermitVersion.PermitTypeControl.AreaText!=DefaultPermitAreaText) 
                    {
                      this.HasAreaTextChanged=true;
                    }
            
        }
      }
    }
    if (changes.preview != undefined) {
      if (!changes.preview.isFirstChange()) {
        this.preview = changes.preview.currentValue;
      }
    }
    if (changes.permitTypeName != undefined) {
      if (!changes.permitTypeName.isFirstChange()) {
        this.permitTypeName = changes.permitTypeName.currentValue;
      }
    }

   
    if (changes._permitBackColor != undefined) {
      if (!changes._permitBackColor.isFirstChange()) {
        this._permitBackColor = changes._permitBackColor.currentValue;
      }
    }
    if (changes._permitForeColor != undefined) {
      if (!changes._permitForeColor.isFirstChange()) {
        this._permitForeColor = changes._permitForeColor.currentValue;
      }
    }
    if (changes.permitTypeImage != undefined) {
      if (!changes.permitTypeImage.isFirstChange()) {
        this.permitTypeImage = changes.permitTypeImage.currentValue;
      }
    }
    if (changes.previewFlag != undefined) {
      if (!changes.previewFlag.isFirstChange()) {
        this.previewFlag = changes.previewFlag.currentValue;
      }
    }
    if (changes.revalidationHours != undefined) {
      if (!changes.revalidationHours.isFirstChange()) {
        this.revalidationHours = changes.revalidationHours.currentValue;
      }
    }
    if (changes._screenGrab != undefined) {
      if (!changes._screenGrab.isFirstChange()) {
        this._screenGrab = changes._screenGrab.currentValue;
      }
    }
    if (changes.MailRequired != undefined) {
      if (!changes.MailRequired.isFirstChange()) {
        this.isMailRequired = changes.MailRequired.currentValue;
      }
    }
    if (changes._permitObj != undefined) {      
      if (!changes._permitObj.isFirstChange()) {
        this._permitObj = new Permit();
        if (changes._permitObj.currentValue) {          
          this._permitObj = changes._permitObj.currentValue; 
          this.PermitIssuedDateTime = this._permitObj.PermitIssuedDateTime;
          this.assetlistingTable = this._permitObj.PermitAssetAndWorkOrder;
          
          this.buildWorkOrderStructure(this.assetlistingTable);
          if(this._permitObj.SRUID>0){
            this.getSRUByID(this._permitObj.SRUID);
          }
          this.PopulateResponsesForPermit();
          this.ViewChangeLog(this._permitObj.PermitId);
         
        
         if(this._isMasterPermit)
         {
          
          // Linked permits printing.
          this.GetAllLinkedPermitData(0, 50, null, null, false, false,false,true);
         }
          

        }
      }
    }
    if (changes.permitCreatedByUser != undefined) {
      if (!changes.permitCreatedByUser.isFirstChange()) {
        if (this.permitCreatedByUser && changes.permitCreatedByUser.currentValue && this.permitCreatedByUser.LoginId != changes.permitCreatedByUser.currentValue.LoginId) {
          this.permitCreatedByUser = changes.permitCreatedByUser.currentValue;
        }
        else if (!this.permitCreatedByUser && changes.permitCreatedByUser.currentValue) {
          this.permitCreatedByUser = changes.permitCreatedByUser.currentValue;
        }
      }
    }

    if (changes.responseAttachmentFiles) {
      if (!changes.responseAttachmentFiles.isFirstChange()) {
        if (changes.responseAttachmentFiles.currentValue) {
          this.responseAttachmentFiles = changes.responseAttachmentFiles.currentValue;
        }
      }
    }
    if (changes.subQuesResponseAttachmentFiles) {
      if (!changes.subQuesResponseAttachmentFiles.isFirstChange()) {
        if (changes.subQuesResponseAttachmentFiles.currentValue) {
          this.subQuesResponseAttachmentFiles = changes.subQuesResponseAttachmentFiles.currentValue;
        }
      }
    }
    if (changes.ShowPermitWithoutOperations != undefined) {
      if (!changes.ShowPermitWithoutOperations.isFirstChange()) {
        this.showPermitWithoutOperations = changes.ShowPermitWithoutOperations.currentValue;
      }
    }
    if (changes._isChangeLog) {
      if (!changes._isChangeLog.isFirstChange()) {
        if (changes._isChangeLog.currentValue) {
          this._isChangeLog = changes._isChangeLog.currentValue;
        }
      }
    } 
    if (changes.permitIssuedDateTimeStr != undefined) {
      if (!changes.permitIssuedDateTimeStr.isFirstChange()) {
        this.permitIssuedDateTimeStr = changes.permitIssuedDateTimeStr.currentValue;
      }
    }
    
    
    if (changes.permitExpiryDateTimeStr != undefined) {
      if (!changes.permitExpiryDateTimeStr.isFirstChange()) {
        this.permitExpiryDateTimeStr = changes.permitExpiryDateTimeStr.currentValue;
      }
    }
    
  }

  constructor(private sanitizer: DomSanitizer, private _SruService: SRUService, private _helperService: HelperService, private _changeLogService: ChangelogService, private primengConfig: PrimeNGConfig,
     private _permitService: PermitService, private _commonService: CommonService) {
    this.setLangugae();
  }

  clsBorderColor: string = "#dbdbdb";
  ngOnInit() {
    for (let index = 0; index < 1000; index++) {
      this.data.push({
        'productId': index,
        'unitPrice': 100,
        'qty': 1,
        'total': 100
      })

    }
    //this.permitContainerHeight = window.innerHeight - 280;
    // this.getLocalCalender();
    if (this._PermitVersion) {
      if (this._PermitVersion.PermitType) {        
        this.questionFontSize = this._PermitVersion.PermitType.QuestionFontSize + 5;
        this.sectionFontSize = this._PermitVersion.PermitType.SectionHeaderFontSize + 5;
        this.headerFontSize = this._PermitVersion.PermitType.HeaderFontSize + 5;
      }
    }
  }
  get userHierarchy(): HierarchyTree {
    return this._commonService.getUserHierarchy();
  }

  
  getClosedByStatus(SecurityData: SecurityDashboardViewModel[]) {
    let permitIds = '';
    if (SecurityData && SecurityData.length > 0) {
      SecurityData.forEach(element => {
        permitIds = permitIds + element.PermitId.toString() + ',';
      });
    }
    if (permitIds != '') {
      let languageId = +localStorage.getItem("_languageID");
      this._permitService.getDashboardClosedByRole(permitIds, languageId).subscribe((data: PermitAdditionalData[]) => {
        if (data && data.length > 0) {
          data.forEach(element => {
            this._childPermits.forEach(fp => {
              if (fp.PermitId == element.PermitId) {
                fp.PendingOn = element.PendingOn;
                if (element.AssetName != null) {
                  let asset_array = element.AssetName.split("@@@")
                  fp.AssetName = asset_array[0];
                }
                else {
                  fp.AssetName = '';
                }
                fp.ClosedBy = element.ClosedBy;
              }
            });
          });
        }
      });
    }
  }

  GetAllLinkedPermitData(startIndex, endIndex, loginId, globalFilter, isMyAction, isAllPermits,isMasterPermit, isChildPermit=false, allMasterPermits=true) {    
    
    let typeIds: number[] = [];
    let statusIds: number[] = [];
    let areaIds: number[] = [];
    let dateRange: Date[] = [];

    //Just the declaration in place , in case future we want to filter by identifiers , we can use this
    let identifier1_Ids: string[] = [];
    let identifier1_Val: string[] = [];
    let identifier2_Ids: string[] = [];
    let identifier2_Val: string[] = [];
    let identifier3_Ids: string[] = [];
    let identifier3_Val: string[] = [];
    let identifier4_Ids: string[] = [];
    let identifier4_Val: string[] = [];
    let identifier5_Ids: string[] = [];
    let identifier5_Val: string[] = [];
    let identifier6_Ids: string[] = [];
    let identifier6_Val: string[] = [];
    let identifier7_Ids: string[] = [];
    let identifier7_Val: string[] = [];

    
    
    if (this.userHierarchy && this.userHierarchy.SiteId) {
      this._permitService.getPermitDetails(this.userHierarchy, startIndex, endIndex, loginId, globalFilter, isMyAction, isAllPermits, isMasterPermit, isChildPermit, this._permitObj.PermitId, allMasterPermits, typeIds, statusIds, areaIds, dateRange, 
        identifier1_Ids, identifier1_Val, identifier2_Ids, identifier2_Val, identifier3_Ids, identifier3_Val, identifier4_Ids, identifier4_Val, identifier5_Ids, identifier5_Val, identifier6_Ids, identifier6_Val, identifier7_Ids, identifier7_Val).subscribe(securityDashboardObject => {        
        let data: SecurityDashboardRootObject;
        if (securityDashboardObject.status == 200) {
          data = securityDashboardObject.body;
          if (data.SecurityData && data.SecurityData.length > 0) {
            data.SecurityData.forEach(element => {
              element.PermitIssuedDateTime = this.adjustForTimezone(element.PermitIssuedDateTime);
              element.PermitExpiryDateTime = this.adjustForTimezone(element.PermitExpiryDateTime);
            });
          }

          this.getClosedByStatus(data.SecurityData);
          this._childPermits = data.SecurityData;

         
          
          this._permitService.getManualPermits(this._permitObj.PermitId).subscribe(
            (data: ManualPermitType[]) => {
              data.forEach(element => {
                element.IssueDate= this.adjustForTimezone(element.IssueDate);
              });
              this._manualPermits = data;
             
          });
        }
      });
    }

    
  }


  setLangugae() {    
    this.setCalender();
    this.primengConfig.setTranslation(this.au);
  }

  setCalender() {
    // Set Calendar's Month, Day
    let language = parseInt(localStorage.getItem("_languageID"));
    switch (language) {
      case 1: {
        this.au = {
          firstDayOfWeek: 1,
          dayNames: [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday, Saturday',
          ],
          dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
          dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
          monthNames: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ],
          monthNamesShort: [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
          ],
          today: 'Today',
          weekHeader: 'Wk',
        };
        break;
      }
      case 3: {
        //statements;
        this.au = {
          firstDayOfWeek: 0,
          dayNames: [
            'Dimanche',
            'Lundi',
            'Mardi',
            'Mercredi',
            'Jeudi',
            'Vendredi',
            'Samedi',
          ],
          dayNamesShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
          dayNamesMin: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
          monthNames: [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Septembre',
            'Octobre',
            'Novembre',
            'Décembre',
          ],
          monthNamesShort: [
            'Jan',
            'Fév',
            'Mar',
            'Avr',
            'Mai',
            'Jui',
            'Jul',
            'Aoû',
            'Sep',
            'Oct',
            'Nov',
            'Déc',
          ],
          today: "Aujourd'hui",
          clear: 'Vider',
          dateFormat: 'dd/M/yy',
          weekHeader: 'Semaine',
        };


        break;
      }
      default: {
        this.au = {
          firstDayOfWeek: 1,
          dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
          dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
          monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
          monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          today: "Today",
          clear: "Clear"
        };
        break;
      }
    }
  }

  getLocalCalender() {
    this.au = {
      firstDayOfWeek: 1,
      dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
      dayNamesShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      dayNamesMin: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
      monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
      monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
      today: 'Today',

      clear: 'Clear'
    }
  }
  CheckAnsOptionWidth(answerOptions: PermitTypeAnswerOption[]) {
    let ansWidthCounter = 0;
    answerOptions.forEach(ao => {
      if (ao.AnswerOptionWidth) {
        ansWidthCounter++;
      }
    });
    if (ansWidthCounter > 0) {
      return true;
    }
    else {
      return false;
    }
  }
  getAnswerOption(question: PermitTypeQuestion) {
    
  }
  

  buildWorkOrderStructure(AssetWOList: PermitAssetAndWorkOrder[]) {
    if (AssetWOList && AssetWOList.length > 0) {
      this.assetWorkOrderList = [];
      let assetList = AssetWOList.map(a => a.AssetNo);
      assetList = this._helperService.removeDuplicateFromArray(assetList);

      assetList.forEach(assetNumber => {
        let assetWOByAsset = AssetWOList.filter(x => x.AssetNo == assetNumber);
        if (assetWOByAsset && assetWOByAsset.length > 0) {
          this.assetWorkOrderList.push(this.bindAssetWOViewModel(assetWOByAsset));
        }
      });
    }
  }

  bindAssetWOViewModel(awo: PermitAssetAndWorkOrder[]): AssetWorkOrderViewModel {
    let aVM = new AssetWorkOrderViewModel();
    let WOVM: WorkOrder[] = [];
    aVM.AssetNo = awo[0].AssetNo;
    aVM.AssetDesc = awo[0].AssetDesc;
    awo.forEach(wo => {
      if (wo.workOrderNo && wo.workOrderNo.trim() != "") {
        let wOrder = new WorkOrder();
        wOrder.WorkOrderNo = wo.workOrderNo;
        wOrder.WorkOrderDesc = wo.workOrderDesc;
        wOrder.WOStatus = wo.WOStatus;
        wOrder.opSequence = [];

        

        wo.PermitOperationSequences.forEach(operationList => {
          let opSequence = new OPSequence();
          opSequence.OPSequenceNo = operationList.OperationSequence;
          opSequence.OPSeqDesc = operationList.OperationDesc;
          opSequence.StartDate = operationList.StartDate;
          opSequence.CompletionDate = operationList.CompletionDate;
          opSequence.ConvertedStartDate = operationList.StartDate.toLocaleString() + '.0000000+00:00';
          opSequence.ConvertedCompletionDate = operationList.CompletionDate.toLocaleString() + '.0000000+00:00';
          wOrder.opSequence.push(opSequence);
        })
        wOrder.opSequence.sort((a, b) => parseInt(a.OPSequenceNo) - parseInt(b.OPSequenceNo));
        WOVM.push(wOrder);
      }
    });
    aVM.workOrder = WOVM;
    return aVM;
  }

  bindOPViewModel(operationNumber: String, operationDesc: String, startDate: Date, completionDate: Date): OPSequence[] {
    let OPVM: OPSequence[] = [];
    let opIndex = 0;
    let opNoArr = operationNumber.split("@@@");
    let opNoDesc = operationDesc.split("@@@");
    if (opNoArr && opNoArr.length > 0) {
      opNoArr.forEach(element => {
        let OPSec = new OPSequence();
        OPSec.OPSequenceNo = element;
        OPSec.OPSeqDesc = '';
        OPSec.StartDate = startDate;
        OPSec.CompletionDate = completionDate;
        if (opNoDesc && opNoDesc.length > opIndex) {
          OPSec.OPSeqDesc = opNoDesc[opIndex];
        }
        opIndex++;
        OPVM.push(OPSec);
      });
    }
    OPVM.forEach((element, index) => {
      if (element.OPSequenceNo == null || element.OPSequenceNo.trim() == '') {
        OPVM.splice(index, 1);
      };
    });
    return OPVM;
  }

  PopulateResponsesForPermit() {
    let permitDivisions = this._PermitVersion.Divisions;
    this._PermitVersion.Divisions = [];
    permitDivisions.forEach((div: PermitTypeDivision) => {
      div.Sections.forEach((sec: PermitTypeSection) => {
        sec.Questions.forEach((ques: PermitTypeQuestion) => {
          if (ques) {
            if (ques.AnswerTypeId == 2) {
              ques.SelectedAnswerCheckBox = this.bindCheckBoxResponses(ques);
              //For Sub Question Response
              ques.AnswerOptions.forEach(ao => {
                if (ques.SelectedAnswerCheckBox.includes(ao.PermitTypeAnswerOptionId)) {
                  ao.IsSelected = true;
                  if (ao.SubQuestions.length > 0) {
                    ao.SubQuestions.forEach(sq => {
                      if (sq.AnswerTypeId == 2) {
                        sq.SelectedAnswerCheckBox = this.bindCheckBoxResponsesSubQuestion(sq, ques, ao);
                      }
                      else if (sq.AnswerTypeId == 9) {
                        sq.TableData = [];
                        let tableTypeResponses: SubQuesTableTypeResponse[] = [];
                        let deletedTableTypeResponses: SubQuesTableTypeResponse[] = [];

                        this._permitObj.Responses.forEach(x => {
                          if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                            x.SubQuesResponse.forEach(sr => {
                              if (sr.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) {
                                tableTypeResponses = sr.SubQuesTableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                                deletedTableTypeResponses = sr.SubQuesTableTypeResponse.filter(x => x.Active == false);

                                if (tableTypeResponses.length > 0) {
                                  sq.TableData = [];
                                  const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                                  let distinctRowIndexes = new Set(indexArray);
                                  let counter = 0;

                                  const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                                  let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                                  distinctRowIndexes.forEach(idx => {

                                    let rowCheck = 0;

                                    deletedDistinctRowIndexes.forEach(function (value) {

                                      if (idx == value) {
                                        rowCheck++;

                                      }
                                    });

                                    sq.TableData[counter] = [];

                                    for (let header of sq.PermitTypeTableSubQues) {
                                      {
                                        sq.TableData[counter][header.ColumnName] = {};
                                      }
                                    }

                                    for (let header of sq.PermitTypeTableSubQues) {

                                      if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                        if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                          (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                        }
                                        else {
                                          (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId;

                                          (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                                        }
                                      }
                                      else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                        (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;                                        
                                      }
                                      else if (header.AnswerTypeId == 2) {
                                        let responses: number[] = []
                                        tableTypeResponses.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).forEach(x => {
                                          if (x.SelectedAnswerOptionId != null) {
                                            responses.push(x.SelectedAnswerOptionId);
                                          }
                                        });
                                        (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                                      }
                                    }
                                    counter++;
                                  });
                                }
                              }
                            })
                          }
                        });
                      }
                      else {

                        let SelectedAnswerOptionIDSQ = this.bindSelectedAnswerOptionIdSubQuestion(sq, ques, ao);
                        sq.SelectedAnswerOptionID = SelectedAnswerOptionIDSQ != undefined ? SelectedAnswerOptionIDSQ : null;
                        sq.AnswerText = this.bindResponseTextSubQuestion(sq, ques, ao);

                      }

                    })
                  }

                }

              })
            }
            else if (ques.AnswerTypeId == 9) {
              ques.TableData = [];
              let tableTypeResponses: TableTypeResponse[] = [];
              let deletedTableTypeResponses: TableTypeResponse[] = [];

              this._permitObj.Responses.forEach(x => {
                if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                  tableTypeResponses = x.TableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                  deletedTableTypeResponses = x.TableTypeResponse.filter(x => x.Active == false);

                  if (tableTypeResponses.length > 0) {
                    ques.TableData = [];
                    const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                    let distinctRowIndexes = new Set(indexArray);
                    let counter = 0;

                    const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                    let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                    distinctRowIndexes.forEach(idx => {
                      let rowCheck = 0;
                      deletedDistinctRowIndexes.forEach(function (value) {
                        if (idx == value) {
                          rowCheck++;
                        }
                      });
                      

                      ques.TableData[counter] = [];

                      for (let header of ques.TableFieldType) {
                        {
                          ques.TableData[counter][header.ColumnName] = {};
                        }
                      }

                      for (let header of ques.TableFieldType) {
                        if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                          //(ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;
                          if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;
                          }
                          else {
                            (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId;

                            let tableTypeResponseObj = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx);
                            if (tableTypeResponseObj) {
                              let headerObj = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponseObj.SelectedAnswerOptionId);
                              if (headerObj) {
                                (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = headerObj.OptionText;
                              }
                            }

                            // (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = header.AnswerOptions.find(x => x.PermitTypeTableAnswerOptionId == tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText;
                          }
                        }
                        else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                          (ques.TableData[counter][header.ColumnName] as TableTypeResponse).ResponseText = tableTypeResponses.find(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).ResponseText;                          
                        }
                        else if (header.AnswerTypeId == 2) {
                          let responses: number[] = []
                          tableTypeResponses.filter(x => x.TableFieldTypeId == header.PermitTypeTableFieldTypeId && x.RowIndex == idx).forEach(x => {
                            if (x.SelectedAnswerOptionId != null) {
                              responses.push(x.SelectedAnswerOptionId);
                            }
                          });
                          (ques.TableData[counter][header.ColumnName] as TableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                        }
                      }
                      counter++;
                    });
                  }
                }
              });
            }
            else if (ques.AnswerTypeId == 11) {
              let response = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId);
              let ansText = response ? response.ResponseText : "";
              if (ansText != undefined && ansText != null && ansText.trim() != "") {
                let data = ansText.split("@@@");
                ques.FieldValue = data[0];
                ques.FieldDesc = data[1];
                if (data[2] != null && data[2] != undefined) {
                  if (data[2].trim() == "NULL") {
                    ques.ProcName = '';
                  }
                  else {
                    ques.ProcName = data[2];
                  }
                }

              }
            }
            else if (ques.AnswerTypeId == 13) {
              let response = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId);
              let ansText = response ? response.ResponseText : "";
              if (ansText != undefined && ansText != null && ansText.trim() != "") {
                let data = ansText.split("@@@");
                ques.Approver = data[0];
                ques.TimeStamp = data[1];
                ques.ContactNumber = data[2];
              }
            }
            // else if (ques.AnswerTypeId == 1) {
            else {
              ques.SelectedAnswerOptionID = this.bindSelectedAnswerOptionId(ques);
              ques.AnswerText =  this.bindResponseText(ques);
              //Sub Question Section
              if (ques.AnswerTypeId == 1 || ques.AnswerTypeId == 4) {
                ques.AnswerOptions.forEach(ao => {
                  if (ao.PermitTypeAnswerOptionId == ques.SelectedAnswerOptionID && ques.SelectedAnswerOptionID != undefined) {
                    ao.IsSelected = true;
                    if (ao.SubQuestions.length > 0) {
                      ao.SubQuestions.forEach(sq => {
                        if (sq.AnswerTypeId == 2) {
                          sq.SelectedAnswerCheckBox = this.bindCheckBoxResponsesSubQuestion(sq, ques, ao);
                        }
                        else if (sq.AnswerTypeId == 9) {
                          sq.TableData = [];
                          let tableTypeResponses: SubQuesTableTypeResponse[] = [];
                          let deletedTableTypeResponses: SubQuesTableTypeResponse[] = [];

                          this._permitObj.Responses.forEach(x => {
                            if (x.PermitTypeQuestionId == ques.PermitTypeQuestionId) {
                              x.SubQuesResponse.forEach(sr => {
                                if (sr.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId) {
                                  tableTypeResponses = sr.SubQuesTableTypeResponse.sort((a, b) => a.RowIndex - b.RowIndex);
                                  deletedTableTypeResponses = sr.SubQuesTableTypeResponse.filter(x => x.Active == false);

                                  if (tableTypeResponses.length > 0) {
                                    sq.TableData = [];
                                    const indexArray = tableTypeResponses.map(resp => resp.RowIndex);
                                    let distinctRowIndexes = new Set(indexArray);
                                    let counter = 0;

                                    const deletedIndexArray = deletedTableTypeResponses.map(resp => resp.RowIndex);
                                    let deletedDistinctRowIndexes = new Set(deletedIndexArray);
                                    distinctRowIndexes.forEach(idx => {

                                      let rowCheck = 0;

                                      deletedDistinctRowIndexes.forEach(function (value) {

                                        if (idx == value) {
                                          rowCheck++;

                                        }
                                      });

                                      sq.TableData[counter] = [];

                                      for (let header of sq.PermitTypeTableSubQues) {
                                        {
                                          sq.TableData[counter][header.ColumnName] = {};
                                        }
                                      }

                                      for (let header of sq.PermitTypeTableSubQues) {

                                        if (header.AnswerTypeId == 1 || header.AnswerTypeId == 4) {
                                          if (header.AnswerTypeId == 1 && header.AnswerOptions[0].DynamicDataSource == true) {
                                            (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;
                                          }
                                          else {
                                            (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerOptionId = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId;

                                            (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = (header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId)) ? header.AnswerOptions.find(x => x.PermitTypeTableSubQuesAnswerOptionId == tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SelectedAnswerOptionId).OptionText : '';
                                          }
                                        }
                                        else if (header.AnswerTypeId == 3 || header.AnswerTypeId == 6 || header.AnswerTypeId == 7 || header.AnswerTypeId == 10) {
                                          (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SubQuesResponseText = tableTypeResponses.find(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).SubQuesResponseText;                                          
                                        }
                                        else if (header.AnswerTypeId == 2) {
                                          let responses: number[] = []
                                          tableTypeResponses.filter(x => x.PermitTypeTableSubQuesId == header.PermitTypeTableSubQuesId && x.RowIndex == idx).forEach(x => {
                                            if (x.SelectedAnswerOptionId != null) {
                                              responses.push(x.SelectedAnswerOptionId);
                                            }
                                          });
                                          (sq.TableData[counter][header.ColumnName] as SubQuesTableTypeResponse).SelectedAnswerCheckBoxForTable = responses;
                                        }
                                      }
                                      counter++;
                                    });
                                  }
                                }
                              })
                            }
                          });
                        }
                        else {
                          let SelectedAnswerOptionIDSQ = this.bindSelectedAnswerOptionIdSubQuestion(sq, ques, ao);
                          sq.SelectedAnswerOptionID = SelectedAnswerOptionIDSQ != undefined ? SelectedAnswerOptionIDSQ : null;
                          sq.AnswerText = this.bindResponseTextSubQuestion(sq, ques, ao);

                        }

                      })
                    }

                  }
                })
              }
            }
          }
        })
      })
    });    
    this._PermitVersion.Divisions = permitDivisions;     
    if (this.isMailRequired) {
      if (this._permitObj.PermitStateId == 3 || this._permitObj.PermitStateId == 17) {
        this.TriggereMail.emit(true);
      }
    }
  }
 
  bindCheckBoxResponsesSubQuestion(sq: PermitTypeSubQuestion, ques: PermitTypeQuestion, ao: PermitTypeAnswerOption): number[] {
    let responses = this._permitObj.Responses.find(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId && x.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.filter(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId);
    let arrayForCheckBoxSQ: number[] = [];
    responses.forEach(response => {
      arrayForCheckBoxSQ.push(response.SelectedAnswerOptionId);
    });
    return arrayForCheckBoxSQ;
  }

  bindSelectedAnswerOptionIdSubQuestion(sq: PermitTypeSubQuestion, ques: PermitTypeQuestion, ao: PermitTypeAnswerOption): number {
    //console.log("bindSelectedAnswerOptionIdSubQuestion" +this._permitObj.Responses);
    let ansID = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
      this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SelectedAnswerOptionId : null;
    return ansID;

  }

  bindResponseTextSubQuestion(sq: PermitTypeSubQuestion, ques: PermitTypeQuestion, ao: PermitTypeAnswerOption) {
    let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.length > 0 ?
      this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId && r.SelectedAnswerOptionId == ao.PermitTypeAnswerOptionId).SubQuesResponse.find(s => s.PermitTypeSubQuestionId == sq.PermitTypeSubQuestionId).SubQuesResponseText : null;
   
    if (sq.AnswerTypeId == 10 || sq.AnswerTypeId == 7) {
      let language = parseInt(localStorage.getItem("_languageID"));
      if (language != 3)
      ansText = this.formatAMPM(ansText);
    }
    return ansText;
  }
  bindCheckBoxResponses(ques: PermitTypeQuestion): number[] {
    let responses = this._permitObj.Responses.filter(x => x.PermitTypeQuestionId == ques.PermitTypeQuestionId);
    let arrayForCheckBox: number[] = [];
    responses.forEach(response => {
      arrayForCheckBox.push(response.SelectedAnswerOptionId);
    });
    return arrayForCheckBox;
  }

  // Method to get responses for a permit
  bindResponseText(ques: PermitTypeQuestion) {
    let ansText = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).ResponseText;    
    if (ques.AnswerTypeId == 10 || ques.AnswerTypeId == 7) {      
      let language = parseInt(localStorage.getItem("_languageID"));
      if (language != 3) 
      ansText = this.formatAMPM(ansText);
    }    
    return ansText;
  }

  
  formatAMPM(obj): string {    
    if (obj == null || obj == undefined) {
      return null;
    }
    let date: Date = new Date(obj);
    let MMM: string;
    let language = parseInt(localStorage.getItem("_languageID"));
    if ((language == 1 || language == 2 || language == 4 || language == 5 || language == 6) && (Object.prototype.toString.call(date) === "[object Date]" && isNaN(date.getTime()))) {
      let dateStr = obj.split('-');
      if (dateStr == null || dateStr == '') {
        dateStr = obj.split(' ');
      }

      // let MMM: string;
      let monthName = dateStr[1];
      switch (monthName) {
        case 'Jan': MMM = 'Jan';
          break;
        case 'Fév': MMM = 'Feb';
          break;
        case 'Mar': MMM = 'Mar';
          break;
        case 'Avr': MMM = 'Apr';
          break;
        case 'Mai': MMM = 'May';
          break;
        case 'Jui': MMM = 'Jun';
          break;
        case 'Jul': MMM = 'Jul';
          break;
        case 'Aoû': MMM = 'Aug';
          break;
        case 'Sep': MMM = 'Sep';
          break;
        case 'Oct': MMM = 'Oct';
          break;
        case 'Nov': MMM = 'Nov';
          break;
        case 'Déc': MMM = 'Dec';
          break;
      }

      obj = dateStr[0] + '-' + MMM + '-' + dateStr[2];
      let date: Date = new Date(obj);
      var d = date.getDate();

      var m = date.getMonth();
      var y = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
    }

    if (!isNaN(date.getTime())) {
      var d = date.getDate();
      var m = date.getMonth();

      var y = date.getFullYear();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      switch (m) {
        case 0: MMM = 'Jan';
          break;
        case 1: MMM = 'Feb';
          break;
        case 2: MMM = 'Mar';
          break;
        case 3: MMM = 'Apr';
          break;
        case 4: MMM = 'May';
          break;
        case 5: MMM = 'Jun';
          break;
        case 6: MMM = 'Jul';
          break;
        case 7: MMM = 'Aug';
          break;
        case 8: MMM = 'Sep';
          break;
        case 9: MMM = 'Oct';
          break;
        case 10: MMM = 'Nov';
          break;
        case 11: MMM = 'Dec';
          break;
      }
      language = parseInt(localStorage.getItem("_languageID"));
      if (language == 3) {
        switch (m) {
          case 0: MMM = 'Jan';
            break;
          case 1: MMM = 'Fév';
            break;
          case 2: MMM = 'Mar';
            break;
          case 3: MMM = 'Avr';
            break;
          case 4: MMM = 'Mai';
            break;
          case 5: MMM = 'Jui';
            break;
          case 6: MMM = 'Jul';
            break;
          case 7: MMM = 'Aoû';
            break;
          case 8: MMM = 'Sep';
            break;
          case 9: MMM = 'Oct';
            break;
          case 10: MMM = 'Nov';
            break;
          case 11: MMM = 'Déc';
            break;
        }
      }
    }
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    //minutes = minutes < 10 ? '0'+minutes : minutes;
    var strDateTime = d + '-' + MMM + '-' + y + ' ' + hours + ':' + minutes + ':' + seconds + ' ' + ampm;
    if (hours == 12 && minutes == 0 && seconds == 0) {
      strDateTime = d + '-' + MMM + '-' + y;
    }
    return strDateTime;
  }
  bindSelectedAnswerOptionId(ques: PermitTypeQuestion): number {
    if (this._permitObj.Responses.length > 0) {
      let ansID = this._permitObj.Responses.find(r => r.PermitTypeQuestionId == ques.PermitTypeQuestionId).SelectedAnswerOptionId;
      return ansID;
    }
    return 0;
  }
  getSRUByID(SRUId) {
    let objVMHierarchySearch = new HierarchySearchViewModel();
    objVMHierarchySearch.SruId = SRUId;
    this._SruService.getSruByID(objVMHierarchySearch).subscribe(
      (data: SRU[]) => {

        this._permitObj.SRUName = data.filter(x => x.SRUId == this._permitObj.SRUID)[0].SRUName;

      },
      error => {
       
      });
  }
 


  ViewChangeLog(permitId) {
    this.displayChangeLog = true;
    let languageId = localStorage.getItem("_languageID");
    this._changeLogService.GetChangeLogDeatils(permitId, languageId).subscribe((data: ChangeLog[]) => {
      this._changeLog = [];
      this._changeLog = data;
      this.revalidateCount = this._changeLog.filter(x => x.PermitState == "Revalidated").length;
      if (this._changeLog && this._changeLog.length > 0) {
        this._changeLog.forEach(element => {
          element.CreatedDate = this.adjustForTimezone(element.CreatedDate);
          if (element.PendingWith != null && element.PendingWith != undefined && element.PendingWith != '') {
            element.CSELeadersName = element.PendingWith.split('<br/>')[0].toString();
            let pendingWith = element.PendingWith.split('<br/>');
            let pendingName: string = '';
            pendingWith.forEach((ele, index) => {
              if (index > 0 && index < pendingWith.length) {
                if (ele != null && ele != undefined && ele != '')
                  pendingName = pendingName + ele + '<br/>';
              }
            });
            element.PendingWith = pendingName;
          }
        });
      }
    });
  }

  adjustForTimezone(date: Date): Date {
    try {
      if (date) {
        let dateString: string = date.toString();
        dateString = dateString.toLowerCase().indexOf('z') > 0 ? dateString : dateString + 'Z';
        let convertedDate = new Date(dateString);
        return convertedDate;
      }
      else {
        return null;
      }
    }
    catch (ex) {
      return null;
    }
  }
}
