import { Injectable } from '@angular/core';
import {jsPDF} from 'jspdf';
import 'jspdf-autotable';
import autoTable from 'jspdf-autotable';
const EXTENSION = '.pdf';
const pdfsize = 'a4';
// declare var jsPDF: any;

@Injectable()
export class PDFService {

    constructor() { }
    datetime = new Date();
    exportToPDF(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns:columns, body:rows, 
            theme: 'grid',
            styles: {
                overflow: 'linebreak'
            },
            columnStyles: {
                id: { fillColor: 255 }
            },
            margin: { top: 50 },
            didDrawPage: function (data) {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
    exportToPDFLandscape(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 7,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { cellWidth: 150 }
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
    exportToPDFDepartment(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 5,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { cellWidth: 150 }
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
    exportToPDFPermitType(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 5,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { cellWidth: 150 }
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
    exportToPDFCrew(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 4,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { cellWidth: 150 }
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFRole(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 4,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { cellWidth: 150 }
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFContractor(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 4,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { cellWidth: 150 }
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
    
    exportToPDFUsers(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 4,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                LoginId: { cellWidth: 30 },
                ClockNo: { cellWidth: 40 },
                Name: { cellWidth: 40 },
                BU : { cellWidth: 30 },
                Region: { cellWidth: 30 },
                SubBU: { cellWidth: 30 },
                Site: { cellWidth: 30 },
                SubSite: { cellWidth: 30 },
                SRU: { cellWidth: 30 },
                Department: { cellWidth: 30 },
                Crew: { cellWidth: 30 },
                Language: { cellWidth: 30 },
                Contractor: { cellWidth: 40 },
                EmailAddress: { cellWidth: 40 },
                Active: { cellWidth: 30 }
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFAssignment(columns, rows, header, fileName) {
       
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 7,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                
                    'Org Level': { cellWidth: 70 },
                    Role: { cellWidth: 70 },
                    Type: { cellWidth: 60 },
                    Category : { cellWidth: 80 },
                    Template: { cellWidth: 70 },
                    'Allocation Method': { cellWidth: 130 },
                    Frequency: { cellWidth: 100 },
                    'Target' : { cellWidth: 45 },
                    Hierarchy: { cellWidth: 150 }
                
            
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFTeamWorklist(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 7,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
               
        Category: { cellWidth: 60 },
        Assigned: { cellWidth: 60 },
        'Completed (On Time)' : { cellWidth: 60 },
        'Completed (Late)': { cellWidth: 70 },
        '% Completed (On Time)': { cellWidth: 55 }
            
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFSearchAndView(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 5,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
                Hierarchy: { cellWidth: 150 }
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFRiskNotification(columns, rows, header, fileName) {
       
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 7,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
               
                RNId: { cellWidth: 20 },
                UserWLId: { cellWidth: 20 },
                RNNumber: { cellWidth: 130 },
                AIEntityCode : { cellWidth: 60 },
                IsRNPosted: { cellWidth: 70 },
                RaisedBy: { cellWidth: 55 },
                BriefStatement: { cellWidth: 55 },
                ObservedDate: { cellWidth: 125 },
                CorrectiveAction: { cellWidth: 110 },
                Template: { cellWidth: 70 },
                Category: { cellWidth: 60 },
                Type: { cellWidth: 80 },
            
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }

    exportToPDFWorkRequest(columns, rows, header, fileName) {
        const doc = new jsPDF('l', 'pt', pdfsize);
        autoTable(doc, {columns: columns, body: rows, 
            theme: 'grid',
            styles: {
                cellPadding: 2,
                fontSize: 7,
                overflow: 'linebreak'                                             
            },  
            columnStyles: {
               
        Type: { cellWidth: 50 },
        Category: { cellWidth: 60 },
        Template: { cellWidth: 60 },
        Site : { cellWidth: 60 },
        WRNumber: { cellWidth: 70 },
        Priority: { cellWidth: 55 },
        WRPosted: { cellWidth: 55 },
        AssetNumber: { cellWidth: 125 },
        WRDescription: { cellWidth: 125 },
        RaisedBy: { cellWidth: 70 },
        RaisedDate: { cellWidth: 60 }
            
            },          
            margin: { top: 50 },
            didDrawPage: function() {
                doc.text(header, 40, 30);
            }
        });
        doc.save(fileName + '_' + new Date().toDateString() + EXTENSION);
    }
}
