// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.asset-number {
    margin-right: 10px !important;
    display: inline-flex;
    margin-top: 5px;
}
.asset-header-wo {
    height: 40px; 
    display: inline-block; 
    margin-top: 5px;
    margin-right: 10px;
}


`, "",{"version":3,"sources":["webpack://./src/app/select-work-order/select-work-order.component.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,oBAAoB;IACpB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,qBAAqB;IACrB,eAAe;IACf,kBAAkB;AACtB","sourcesContent":[".asset-number {\r\n    margin-right: 10px !important;\r\n    display: inline-flex;\r\n    margin-top: 5px;\r\n}\r\n.asset-header-wo {\r\n    height: 40px; \r\n    display: inline-block; \r\n    margin-top: 5px;\r\n    margin-right: 10px;\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
