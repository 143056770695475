import { UpdateStamp } from './UpdateStamp';

export class ManualPermitType extends UpdateStamp {
Id:number;
PermitNumber:string;
IssueDate:Date;
IssueDateString: string;
PermitTypeId:number;
PermitStateId:number;
PermitId:number;
PermitTypeName:string;
PermitStateName:string;
FileOriginalUrl:string;
FileName :string;
}
