import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';
import { ObjectStore } from '../ViewModel/IndexedDB/ObjectStore';

@Injectable({
  providedIn: 'root'
})
export class IndexedDBService {

  _db: IDBDatabase;

  constructor() { }

  openDBAsync(dbName: string, version: number) {

    return new Observable((observer: Observer<string>) => {

      // Opens the database.
      var request: IDBOpenDBRequest = indexedDB.open(dbName, version);

      // Success.
      request.onsuccess = (event: Event) => {

        // Instances the db object.
        this._db = (<IDBOpenDBRequest>event.target).result;

        observer.next((<IDBOpenDBRequest>event.target).readyState);
        observer.complete();

      };
      // Error.
      request.onerror = (event: Event) => {

        console.log('IndexedDB service: ' + (<IDBOpenDBRequest>event.target).error.name);

        observer.error((<IDBOpenDBRequest>event.target).error.name);


      };
      // The db doesn't exist, so cretes it.
      request.onupgradeneeded = (event: Event) => {

        // Instances the db object.
        this._db = (<IDBOpenDBRequest>event.target).result;;

        //Instances the ObjectStores class and calls the createStores method.
        var objectStores: ObjectStore = new ObjectStore();
        objectStores.createStores(this._db);

        console.log('IndexedDB service: creating ' + dbName + ' completed.');

      }

    });

  }

  getAllRecordsAsync(storeName: string) {

    // Gets the object store.
    var store: IDBObjectStore = this.getObjectStore(storeName, "readonly");

    return new Observable((observer: Observer<any>) => {

      var request: IDBRequest = store.openCursor();

      // Success.
      request.onsuccess = (event: Event) => {

        // Steps through all the values in the object store.
        var cursor: IDBCursorWithValue = (<IDBRequest>event.target).result;

        if (cursor) {

          observer.next(cursor.value);
          cursor.continue();

        }
        else {

          observer.complete();

        }

      }
      // Error.
      request.onerror = (event: Event) => {

        console.log('IndexedDB service: ' + (<IDBRequest>event.target).error.name);

        observer.error((<IDBRequest>event.target).error.name);

      }

    });

  }

  getRecord(storeName: string, key: string) {
    var store: IDBObjectStore = this.getObjectStore(storeName, "readonly");

    return new Observable((observer: Observer<any>) => {

      var request: IDBRequest = store.get(key);

      // Success.
      request.onsuccess = (event: Event) => {

        // Steps through all the values in the object store.
        var data: IDBRequest = (<IDBRequest>event.target).result;

        if (data) {
          observer.next(data);
        }
        else {
          observer.error("No Data found");
        }

      }
      // Error.
      request.onerror = (event: Event) => {

        console.log('IndexedDB service: ' + (<IDBRequest>event.target).error.name);

        observer.error((<IDBRequest>event.target).error.name);

      }

    });
  }


  addRecordAsync(storeName: string, record: any) {

    // Gets the object store.
    var store: IDBObjectStore = this.getObjectStore(storeName, "readwrite");

    return new Observable((observer: Observer<string>) => {

      var request: IDBRequest = store.add(record); // Adds a new record.

      // Success.
      request.onsuccess = (event: Event) => {

        observer.next((<IDBRequest>event.target).readyState);
        observer.complete();

      }
      // Error.
      request.onerror = (event: Event) => {

        console.log('IndexedDB service: ' + (<IDBRequest>event.target).error.name);

        observer.error((<IDBRequest>event.target).error.name);

      }

    });

  }


  deleteRecordAsync(storeName: string, key: string) {

    // Gets the object store.
    var store: IDBObjectStore = this.getObjectStore(storeName, "readwrite");

    return new Observable((observer: Observer<string>) => {

      var request: IDBRequest = store.delete(key); // Deletes the record by the key.

      // Success.
      request.onsuccess = (event: Event) => {

        observer.next((<IDBRequest>event.target).readyState);
        observer.complete();

      }
      // Error.
      request.onerror = (event: Event) => {

        console.log('IndexedDB service: ' + (<IDBRequest>event.target).error.name);

        observer.error((<IDBRequest>event.target).error.name);

      }

    });

  }


  editRecordAsync(storeName: string, record: any) {

    // Gets the object store.
    var store: IDBObjectStore = this.getObjectStore(storeName, "readwrite");

    return new Observable((observer: Observer<string>) => {

      var request: IDBRequest = store.put(record); // Puts the updated record back into the database.

      // Success.
      request.onsuccess = (event: Event) => {

        observer.next((<IDBRequest>event.target).readyState);
        observer.complete();

      }
      // Error.
      request.onerror = (event: Event) => {

        console.log('IndexedDB service: ' + (<IDBRequest>event.target).error.name);

        observer.error((<IDBRequest>event.target).error.name);

      }

    });

  }

  clearObjectStoreAsync(storeName: string) {

    // Gets the object store.
    var store: IDBObjectStore = this.getObjectStore(storeName, "readwrite");

    return new Observable((observer: Observer<string>) => {

      var request: IDBRequest = store.clear(); // Clears the object store.

      // Success.
      request.onsuccess = (event: Event) => {

        observer.next((<IDBRequest>event.target).readyState);
        observer.complete();

      }
      // Error.
      request.onerror = (event: Event) => {

        console.log('IndexedDB service: ' + (<IDBRequest>event.target).error.name);

        observer.error((<IDBRequest>event.target).error.name);

      }

    });

  }

  closeDB() {
    this._db.close();
  }

  private getObjectStore(storeName: string, mode) {
    var tx: IDBTransaction = this._db.transaction(storeName, mode);
    return tx.objectStore(storeName);
  }
}
