import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { catchError  } from 'rxjs/operators';
import { Common } from '../ViewModel/Common';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { AddEntityAsset } from '../ViewModel/DASModels/addEntityAssetModel';
import { Entity } from '../ViewModel/DASModels/EntityModel';

@Injectable()
export class MediaService {
  _common = new Common();
  mediaGetURL = environment.MediaWebApiUrl + 'File';
  mediaGetArrayURL = environment.MediaWebApiUrl + 'Files';
  mediaUploadURL = environment.MediaWebApiUrl + 'File/Create';
  mediaDeleteURL = environment.MediaWebApiUrl + 'File/Delete';
  attachmentFileURL = environment.WEBAPIURL + 'common/upload-media';
  serviceUrl =  environment.DASURL + '/api/Entity';
  _uploadManualPermitUrl = environment.WEBAPIURL + 'common/upload-manual'; 
  getImageBlobFromMediaAPI = this._common.MediaURL + 'Media/GetImageByte';
  
  dasHeaderDict  =  {
    'Content-Type':  'application/json',
    'Accept':  'application/json',
    'Access-Control-Allow-Origin':  '*',
    'Access-Control-Allow-Methods':  'GET, POST, PATCH, PUT, DELETE, OPTIONS',
  };
  constructor(private _http: HttpClient ) {       
  }
  
  getFileProperties(AzureFileId: number) {
    
    return this._http.post<any>(this.mediaGetURL +"/" + AzureFileId, AzureFileId, {observe: 'response'});
  }
  getMediaBlob(callUri: string){ 
    return this._http.get<any>(this.getImageBlobFromMediaAPI, {headers: this._common.MediaHeaders,
    params: new HttpParams().set('ImageUrl', callUri)
    });
  }
  getFilePropertiesArray(AzureFileArrayId:any[]) {
    return this._http.post<any>(this.mediaGetArrayURL,AzureFileArrayId, {observe: 'response'});
  }

  UploadMediaFiles(AzureFile: File) {
    const formData = new FormData();
    formData.append('file', AzureFile);
    return this._http.post<any>(this.mediaUploadURL, formData, {observe: 'response'});
  }

  AttachMediaFiles(AzureFile: File) {
    const formData = new FormData();
    formData.append('file', AzureFile);
    return this._http.post<any>(this.attachmentFileURL, formData, {observe: 'response'});
  }

  uploadManualPermits(AzureFile: File) {
    const formData = new FormData();
    formData.append('file', AzureFile);
    formData.append('function','manual');
    return this._http.post<any>(this.attachmentFileURL, formData, {observe: 'response'});
  }
  getFileByUri (callUri: string) {    
    return this._http.get<any>(callUri);
  }

  DeleteMediaFileById(AzureFileId: number) { 
    return this._http.post<any>(this.mediaDeleteURL+'/'+AzureFileId, { observe: 'response' });
  }
  
  public getEntitySourceAsset(entitySourceId: string, source: string, entityType : string,site : string): Observable<Entity>{

    const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Inp4ZWcyV09OcFRrd041R21lWWN1VGR0QzZKMCIsImtpZCI6Inp4ZWcyV09OcFRrd041R21lWWN1VGR0QzZKMCJ9.eyJhdWQiOiJhcGk6Ly85NTQ2ZDk1NS00ZWJlLTQyNTctOWQ4My1iZWJlOWQ1ZGU2ODEuYWxjb2EuY29tIiwiaXNzIjoiaHR0cHM6Ly9zdHMud2luZG93cy5uZXQvMzdhNzJlYjctMWZhZi00OWQzLTlkZGMtNmI5MjNiNzUxYmMwLyIsImlhdCI6MTczMzM4MTEzNywibmJmIjoxNzMzMzgxMTM3LCJleHAiOjE3MzMzODU3NjEsImFjciI6IjEiLCJhaW8iOiJBVlFBcS84WUFBQUFSZ1psbDBoQXFlVy96SXl3MnYzNDJXQm01dkZKcmxHTjY2Q3IvWVN4eE1QWkNIZWlYc3A2YTZGWVREdWRiZk5WVDl1UXdSaFVaR1JPT3pnTjZMUWM2LzBCZGltQTlRNlpXTEtvZERVQ0Nvcz0iLCJhbXIiOlsicHdkIiwibWZhIl0sImFwcGlkIjoiMzlhOWQ5YmUtMzhhMi00Yzk0LTlkMjYtY2UzNTJjN2NlMDNkIiwiYXBwaWRhY3IiOiIwIiwiZmFtaWx5X25hbWUiOiJLdWxrYXJuaSIsImdpdmVuX25hbWUiOiJTaGFzaGlrYW50IiwiaXBhZGRyIjoiMTQyLjc5LjE3OS4xIiwibmFtZSI6Ikt1bGthcm5pLCBTaGFzaGlrYW50IChUQ1MpIiwib2lkIjoiYzA3OWE2NjMtNmRkYi00YjM1LTgxMmItZjg0MzU2MWVkNzQyIiwib25wcmVtX3NpZCI6IlMtMS01LTIxLTIwNTU4Mjg1NjQtNDM4Nzc4Mjk4LTEzNjcyODU0MzUtODQ2Nzc4NSIsInJoIjoiMS5BUVFBdHk2bk42OGYwMG1kM0d1U08zVWJ3RlhaUnBXLVRsZENuWU8tdnAxZDVvRUVBS1lFQUEuIiwic2NwIjoidXNlcl9pbXBlcnNvbmF0aW9uIiwic3ViIjoieE14QlItZ0Y0RktZSjlDbW92dVhGUU5wN3JieTlBZ2NlVVdpRi01Zk1SZyIsInRpZCI6IjM3YTcyZWI3LTFmYWYtNDlkMy05ZGRjLTZiOTIzYjc1MWJjMCIsInVuaXF1ZV9uYW1lIjoic2hhc2hpa2FudC5rdWxrYXJuaUBhbGNvYS5jb20iLCJ1cG4iOiJzaGFzaGlrYW50Lmt1bGthcm5pQGFsY29hLmNvbSIsInV0aSI6IkduV1N4REN3OFVTajhiSnJwQXZjQUEiLCJ2ZXIiOiIxLjAiLCJzYW1BY2NvdW50TmFtZSI6InYta3Vsa2FzMSJ9.R0VD9zjCNfNKSi8MlDSHaiyJYkxVxiyHMRTlH_D1DOYKS42yq4Ms8CwT0oLTpi-wQWoG2AS1TqyT1INz8Qw4bQ1jWDGG5XialUHjMkSsPFwheA75pOPaQeKmOebOc1ck7ZMz0GyoafR-nnfCcVh34k2jT3JaLLqWnPZ49t754tfC6f2r67XGcm_0BeTUH4-a3V1XJxVjFx06hZw5_WrV2Mi5UTeNW4aEw9PahTKaZ8eHmNHJNPAuCWhjGm-_9wWJpO6jCY0YHp313kkjBc5n_jj3gjmVlRszY_DtRrEwtzt7Pgq6JKuDLolc-vT_3h5c4dQ3emdZxLcDFA8z1uD7jg';
    
    
    //var requestHeaderDetails= this._common.dasFetchHeaders;
    var requestHeaderDetails: HttpHeaders = new  HttpHeaders(this.dasHeaderDict);
    requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key", 'c08821ae1d344e559c56daddc4c68a21');
    requestHeaderDetails=requestHeaderDetails.append('Authorization',`Bearer ${token}`);

    return this._http.get<Entity>(`${this.serviceUrl}/${entitySourceId},${source},${entityType},${site}`, {headers: requestHeaderDetails});

  }


  public addEntity(data: AddEntityAsset): Observable<Entity>{
  
    var requestHeaderDetails= new HttpHeaders({'Ocp-Apim-Subscription-Key':'c08821ae1d344e559c56daddc4c68a21'});
    
    return this._http.post<any>(`${this.serviceUrl}`, data,{headers: requestHeaderDetails})
  }

  public uploadEntityAsset(entityId: any, data: any, appRegion: string): Observable<any> {

    var returnDigitalAssetBinary : boolean = true;
  
    var requestHeaderDetails = new HttpHeaders({'AppRegion': appRegion});
    requestHeaderDetails=requestHeaderDetails.append("Ocp-Apim-Subscription-Key",'c08821ae1d344e559c56daddc4c68a21');
   
    return this._http.post<any>(`${this.serviceUrl}/${entityId}/${'DigitalAsset'}/${returnDigitalAssetBinary}`, data, { headers: requestHeaderDetails})
  }
}
  
  // async UploadMediaFiles(AzureFile: File): Promise<any> {
  //   try {
  //     this._common = new Common();
  //     const formData = new FormData();
  //     formData.append('file', AzureFile);
  //     // return this._http.post<any>(this.mediaUploadURL, formData, {
  //     //   observe: 'response',
  //     //   headers: this._common.mediaPostHeader
  //     // })
  //     return await this._http.post<any>(this.mediaUploadURL, formData, {
  //       // observe: 'response',
  //       headers: this._common.mediaPostHeader
  //     }).toPromise();
  //   } 
  //   catch (error) {
  //     await this.handleError(error);
  //   }
  //     // .pipe(
  //     //   catchError(this.handleError)
  //     // );
  // }
