import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Message } from 'primeng/api';
import { Common } from '../ViewModel/Common';
import { Languages } from '../ViewModel/Languages';
import { AttributesType } from '../ViewModel/AttributesType';
import { BU } from '../ViewModel/BU';
import { User } from '../ViewModel/User';
import { SectionType } from '../ViewModel/SectionType';
import { AnswerType } from '../ViewModel/AnswerType';
import { environment } from 'src/environments/environment';
import { PermitTypeVersion } from '../ViewModel/PermitBuilder/PermitTypeVersion';
import { HierarchyTree } from '../ViewModel/HierarchyTree';
import { TrainingStatusInputViewModel } from '../ViewModel/TrainingStatusInputViewModel';
import { HelperService } from './helper.service';
import { RoleTrainingStateViewModel } from '../ViewModel/RoleTrainingStateViewModel';
import { InitializeAppViewModel } from '../ViewModel/InitializeAppViewModel';



@Injectable({
  providedIn: 'root'
})
export class CommonService {
  _common = new Common();
  getAllLanguageURL = environment.WEBAPIURL + 'common/languages';
  getTreeURL = environment.WEBAPIURL + 'common/trees';
  getAllAttributeTypeURL = environment.WEBAPIURL + 'Attribute/AttributeTypes';

  _selfServiceUserObject: User;
  _userHierarchy: HierarchyTree;
  _title: string;
  private getSectionTypes = environment.WEBAPIURL + 'common/section-types';
  private getAnswerTypes = environment.WEBAPIURL + 'common/answer-types/';
  private getQuestions = environment.WEBAPIURL + 'common/getQuestions';
  private getImageByte = environment.MediaWebApiUrl + 'Media/GetImageByte';
  //private getImageByte = environment.WEBAPIURL + 'common/image-string';
  private getTrainingStatusForUser = environment.WEBAPIURL + 'common/training-status-user/';
  private getTrainingStatusForUserBySite = environment.WEBAPIURL + 'common/user-training-status/';
  private getTrainingStatusForCreateNew = environment.WEBAPIURL + 'common/user-training-status-create-new/';
  private graphAPIURL = environment.graphAPI + "me/checkMemberGroups";
  private graphAPIURLUserImage = environment.graphAPI + "me/photo/$value";
  private initializeApp = environment.WEBAPIURL + 'common/InitializeApp/';

  constructor(private _http: HttpClient, private _helperService: HelperService) {
  }

  //Get All Languages
  getAllLanguages() {
    return this._http.get<Languages[]>(this.getAllLanguageURL + '/' + localStorage.getItem("_languageID"));
  }

  // Get all data for tree component
  getTreeData() {
    let languageId = localStorage.getItem("_languageID");
    languageId = languageId ? languageId : '1';
    return this._http.get<BU[]>(this.getTreeURL + '/' + languageId);
  }

  // getUserGroups(){
  //   return this._http.get<any>("https://graph.microsoft.com/v1.0/me/memberOf");
  // }

  getUserGroups(groups: string) {
    return this._http.post<any>(this.graphAPIURL, groups, { observe: 'response' });
  }

  //Get All Table Section
  GetSectionTypes() {
    return this._http.get<SectionType[]>(this.getSectionTypes);
  }
  //Get Answer Types
  GetAnswerTypes(languageId) {
    //debugger;
    return this._http.get<AnswerType[]>(this.getAnswerTypes+languageId);
  }

  //Get Answer Types
  GetQuestions() {
    return this._http.get<PermitTypeVersion[]>(this.getQuestions);
  }

  //Get All Attribute Type
  getAllAttributeType() {
    return this._http.get<AttributesType[]>(this.getAllAttributeTypeURL);
  }

  setSelfServiceData(user) {
    this._selfServiceUserObject = user;
  }

  getSelfServiceData(): User {
    return this._selfServiceUserObject;
  }

  setUserHierarchy(nodeValueFromParent) {
    this._userHierarchy = nodeValueFromParent;
  }

  getUserHierarchy() {
    return this._userHierarchy;
  }

  getMediaBlob(callUri: string) {
    return this._http.get<any>(this.getImageByte, {
      params: new HttpParams().set('ImageUrl', callUri)
    });
  }

  getBase64Image(img) {
    var canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }

  GetTrainingStatusForUser(permitId: number, roles: number[]) {
    let loginId = localStorage.getItem("_loginId");
    let params = new HttpParams().set("rolesJSON", this._helperService.EscapeSpecialCharacters(JSON.stringify(roles)));
    return this._http.get<RoleTrainingStateViewModel[]>(this.getTrainingStatusForUser + permitId + '/' + loginId, { params: params });
  }

  GetTrainingStatusForUserBySite(siteId: number, permitTypeId: number, roles: number[]) {
    let loginId = localStorage.getItem("_loginId");
    let params = new HttpParams().set("rolesJSON", this._helperService.EscapeSpecialCharacters(JSON.stringify(roles)));
    return this._http.get<RoleTrainingStateViewModel[]>(this.getTrainingStatusForUserBySite + siteId + '/' + loginId + '/' + permitTypeId, { params: params });
  }

  GetTrainingStatusForCreateNew(siteId: number, permitTypeId: number) {
    let loginId = localStorage.getItem("_loginId");
    return this._http.get<RoleTrainingStateViewModel[]>(this.getTrainingStatusForCreateNew + siteId + '/' + loginId + '/' + permitTypeId);
  }

  getUserProfileImage() {
    return this._http.get(
      this.graphAPIURLUserImage, { responseType: 'blob' }
    );
  }

  getInitializationDetails(loginId: string) {
    let languageId = localStorage.getItem("_languageID");
    languageId = languageId ? languageId : '1';
    return this._http.get<InitializeAppViewModel>(this.initializeApp + loginId + '/' + languageId);
  }

  getArcGISKey() {
    return environment.ArcGISMapKey;
  }

}
